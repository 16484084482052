/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeStatusByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type BackofficeStatusByIdQuery = {
  __typename?: 'Query'
  backofficeStatusById: {
    __typename?: 'BackofficeStatus'
    id: string
    creationDate: string
    title: string
    type: Types.BackofficeStatusType
    notes: string
  }
}

export const BackofficeStatusByIdDocument = gql`
  query backofficeStatusById($id: ID!) {
    backofficeStatusById(id: $id) {
      id
      creationDate
      title
      type
      notes
    }
  }
`

/**
 * __useBackofficeStatusByIdQuery__
 *
 * To run a query within a React component, call `useBackofficeStatusByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeStatusByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBackofficeStatusByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    BackofficeStatusByIdQuery,
    BackofficeStatusByIdQueryVariables
  > &
    (
      | { variables: BackofficeStatusByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeStatusByIdQuery,
    BackofficeStatusByIdQueryVariables
  >(BackofficeStatusByIdDocument, options)
}
export function useBackofficeStatusByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeStatusByIdQuery,
    BackofficeStatusByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeStatusByIdQuery,
    BackofficeStatusByIdQueryVariables
  >(BackofficeStatusByIdDocument, options)
}
export function useBackofficeStatusByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeStatusByIdQuery,
        BackofficeStatusByIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeStatusByIdQuery,
    BackofficeStatusByIdQueryVariables
  >(BackofficeStatusByIdDocument, options)
}
export type BackofficeStatusByIdQueryHookResult = ReturnType<
  typeof useBackofficeStatusByIdQuery
>
export type BackofficeStatusByIdLazyQueryHookResult = ReturnType<
  typeof useBackofficeStatusByIdLazyQuery
>
export type BackofficeStatusByIdSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeStatusByIdSuspenseQuery
>
export type BackofficeStatusByIdQueryResult = Apollo.QueryResult<
  BackofficeStatusByIdQuery,
  BackofficeStatusByIdQueryVariables
>
