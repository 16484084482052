/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUserManagementGroupsQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeUserManagementGroupsQuery = {
  __typename?: 'Query'
  backofficeUserManagementGroups: Array<{
    __typename?: 'BackofficeUserManagementGroup'
    name: string
    rights: Array<string> | null
    DEDescription: string | null
    ENDescription: string | null
    gdpr: boolean
    critical: boolean
  }> | null
}

export const BackofficeUserManagementGroupsDocument = gql`
  query backofficeUserManagementGroups {
    backofficeUserManagementGroups {
      name
      rights
      DEDescription
      ENDescription
      gdpr
      critical
    }
  }
`

/**
 * __useBackofficeUserManagementGroupsQuery__
 *
 * To run a query within a React component, call `useBackofficeUserManagementGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUserManagementGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUserManagementGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeUserManagementGroupsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BackofficeUserManagementGroupsQuery,
    BackofficeUserManagementGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeUserManagementGroupsQuery,
    BackofficeUserManagementGroupsQueryVariables
  >(BackofficeUserManagementGroupsDocument, options)
}
export function useBackofficeUserManagementGroupsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeUserManagementGroupsQuery,
    BackofficeUserManagementGroupsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeUserManagementGroupsQuery,
    BackofficeUserManagementGroupsQueryVariables
  >(BackofficeUserManagementGroupsDocument, options)
}
export function useBackofficeUserManagementGroupsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeUserManagementGroupsQuery,
        BackofficeUserManagementGroupsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeUserManagementGroupsQuery,
    BackofficeUserManagementGroupsQueryVariables
  >(BackofficeUserManagementGroupsDocument, options)
}
export type BackofficeUserManagementGroupsQueryHookResult = ReturnType<
  typeof useBackofficeUserManagementGroupsQuery
>
export type BackofficeUserManagementGroupsLazyQueryHookResult = ReturnType<
  typeof useBackofficeUserManagementGroupsLazyQuery
>
export type BackofficeUserManagementGroupsSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeUserManagementGroupsSuspenseQuery
>
export type BackofficeUserManagementGroupsQueryResult = Apollo.QueryResult<
  BackofficeUserManagementGroupsQuery,
  BackofficeUserManagementGroupsQueryVariables
>
