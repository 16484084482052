import { createContext, useContext, useState } from 'react'
import { useErrorHandlingQuery } from '@backoffice-frontend/data-fetching'
import { useAlertModalQuery } from './AlertModal.hook'

function useAlertModalContextValue() {
  const [alertId, setAlertId] = useState<string>()

  const { data } = useErrorHandlingQuery(
    useAlertModalQuery,
    alertId ? { variables: { alertId } } : { skip: true },
  )

  const open = (id: string) => setAlertId(id)
  const close = () => setAlertId(undefined)
  return {
    alert: data?.alert ?? data?.adAlert,
    isOpen: Boolean(data?.alert ?? data?.adAlert),
    open,
    close,
  }
}

type AlertModalContextValue = ReturnType<typeof useAlertModalContextValue>
const AlertModalContext = createContext<AlertModalContextValue | null>(null)

export function AlertModalProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const alertModalContextValue = useAlertModalContextValue()

  return (
    <AlertModalContext.Provider value={alertModalContextValue}>
      {children}
    </AlertModalContext.Provider>
  )
}

export const useAlertModal = () => {
  const alertModalContextValue = useContext(AlertModalContext)
  if (alertModalContextValue === null) {
    console.warn(
      'useAlertModal is being used outside of AlertModalProvider, hook is no-op',
    )
    return { open: () => {}, close: () => {}, alert: undefined, isOpen: false }
  }
  return alertModalContextValue
}
