import { isAfter, isBefore, isDate, isValid } from 'date-fns'
import type { FieldValidator } from 'final-form'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import {
  PHONE_NUMBER_MAX_LENGTH,
  PHONE_NUMBER_MIN_LENGTH,
  formatDate,
  transformFloatToEnglishLocale,
} from '@backoffice-frontend/common'
import { ValidatorsAreaId } from './ValidatorsAreaId'

export const useValidators = () => {
  const { t } = useTranslation(ValidatorsAreaId)
  return {
    required: required(t),
    requiredDate: requiredDate(t),
    exactLengthWithProperties: exactLengthWithProperties(t),
    lengthRangeWithProperties: lengthRangeWithProperties(t),
    requiredArrayElement: requiredArrayElement(t),
    lengthRange: lengthRange(t),
    minLength: minLength(t),
    maxLength: maxLength(t),
    minLength3: minLength3(t),
    validateUuidStandardFormat: validateUuidStandardFormat(t),
    validateDateTimeInPast: validateDateTimeInPast(t),
    validateDateTimeInFuture: validateDateTimeInFuture(t),
    validateDateTimeIsBefore: validateDateTimeIsBefore(t),
    isValidDate: isValidDate(t),
    validateDateTime: validateDateTime(t),
    validateDate: validateDate(t),
    validateDateTimeMui: validateDateTimeMui(t),
    validateGeoCoordLat: validateGeoCoordLat(t),
    validateGeoCoordLong: validateGeoCoordLong(t),
    validateLicensePlate: validateLicensePlate(t),
    validatePrice: validatePrice(t),
    validateTime: validateTime(t),
    noWhitespace: noWhitespace(t),
    phoneNumber: phoneNumber(t),
    email: email(t),
    minValue: minValue(t),
    maxValue: maxValue(t),
    integerNumber: integerNumber(t),
    numberRangeWithProperties: numberRangeWithProperties(t),
    number: number(t),
    validateMonthYear: validateMonthYear(t),
    englishNumber: englishNumber(t),
    noSpecialChars: noSpecialChars(t),
    onlyNumbersAndLetters: onlyNumbersAndLetters(t),
  }
}
export const usePaceValidators = () => {
  const { t } = useTranslation(ValidatorsAreaId)
  return {
    required: t('Required Field'),
    emailPattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: t('Invalid Email'),
    },
  }
}

const validateTime =
  (t: TFunction) =>
  (value: Date): string | undefined =>
    isValid(value) ? undefined : t('Invalid Time Format (hh:mm)')

// please add a comment here or fix the issue
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const required = (t: TFunction) => (value: any) => {
  if (typeof value === 'boolean' || typeof value === 'number') {
    return undefined
  }
  return value ? undefined : t('Required Field')
}

export const requiredDate = (t: TFunction) => (value: Date | null) => {
  // the check in the regular required:  if (typeof value === 'boolean' || typeof value === 'number') is causing date required validation to fail
  // that is why we need this additional requiredDate validator
  return value ? undefined : t('Required Field')
}

export const isValidDate = (t: TFunction) => (value: Date | null) => {
  return !value || isValid(value) ? undefined : t('Invalid Date')
}

export const requiredArrayElement =
  (t: TFunction) =>
  <T>(value: T[]) =>
    value && value.length > 0 ? undefined : t('Required Field')

export const maxLength = (t: TFunction) => (max: number) => (value: string) =>
  value && value.length > max
    ? t('Max. length {{length}}', { length: max })
    : undefined

export const minLength = (t: TFunction) => (min: number) => (value: string) =>
  value && value.length < min
    ? t('Min. length {{length}}', { length: min })
    : undefined

export const lengthRangeWithTranslation =
  <T>(min: number, max: number, translation: string) =>
  (value: T) => {
    if (typeof value === 'string') {
      const length = Number(value.length)
      return length < min || length > max ? translation : undefined
    }

    return undefined
  }
export const lengthRange = (t: TFunction) => (min: number, max: number) =>
  lengthRangeWithTranslation(
    min,
    max,
    t('Must be between {{min}} and {{max}}', { min, max }),
  )
export const lengthRangeWithProperties =
  (t: TFunction) => (min: number, max: number) =>
    lengthRangeWithTranslation(
      min,
      max,
      t('Must be between {{min}} and {{max}}', { min, max }),
    )

export const exactLengthWithProperties =
  (t: TFunction) => (exactLength: number) =>
    lengthRangeWithTranslation(
      exactLength,
      exactLength,
      t('Must be exactly {{exactLength}} characters long', { exactLength }),
    )

export const minLength3 = (t: TFunction) => minLength(t)(3)
export const maxLength15 = (t: TFunction) => maxLength(t)(15)

export const number = (t: TFunction) => (value: number | string) => {
  if (!value) {
    return undefined
  }

  const number = transformFloatToEnglishLocale(value)
  return Number.isNaN(Number(number)) ? t('Invalid Number') : undefined
}

// NOTE: if the translationKeyOrFunction is set to a function, it's used later,
//       in the TextField component as a way to provide payload to the translation fn t
export const numberRange =
  (min: number, max: number, translation: string, t: TFunction) =>
  // please add a comment here or fix the issue
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (value?: any) => {
    const numberValidation = value && number(t)(value)
    if (numberValidation) {
      return numberValidation
    }

    const numberValue = Number(value)
    return !Number.isNaN(numberValue) &&
      (min > numberValue || max < numberValue)
      ? translation
      : undefined
  }

export const numberRangeWithProperties =
  (t: TFunction) => (min: number, max: number) =>
    numberRange(
      min,
      max,
      t('Must be between {{min}} and {{max}}', { min, max }),
      t,
    )

export const integerNumber =
  (t: TFunction) =>
  <T>(value: T) =>
    value && !Number.isInteger(Number(value))
      ? t('No decimals allowed')
      : undefined

const castFloat = <T>(floatString: T) => {
  if (typeof floatString === 'number') return floatString
  if (typeof floatString === 'string')
    return parseFloat(floatString.replace(',', '.'))

  return undefined
}

export const maxValue =
  (t: TFunction) => (max?: string | number) => (value: string | number) => {
    if (!value || !max) {
      return undefined
    }

    const parsedMax = castFloat(max)
    const parsedValue = castFloat(value)
    return value && parsedValue && parsedMax && parsedValue > parsedMax
      ? t("Can't be higher than {{max}}", { max })
      : undefined
  }

export const minValue =
  <T>(t: TFunction) =>
  (min: string | number) =>
  (value: T) => {
    if (!value || !min) {
      return undefined
    }

    const parsedMin = castFloat(min)
    const parsedValue = castFloat(value)
    return value && parsedValue && parsedMin && parsedValue < parsedMin
      ? t('Must be at least {{min}}', { min })
      : undefined
  }

export const positiveValue = <T>(value: T) => {
  const parsedValue = castFloat(value)
  return parsedValue && parsedValue < 0
}

export const email = (t: TFunction) => (value: unknown) =>
  value &&
  typeof value === 'string' &&
  !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)
    ? t('Invalid Email')
    : undefined

export const phoneNumber = (t: TFunction) => (value: string) => {
  if (!value) return undefined

  const isFormattedCorrectly = /^\+[\d]{9,17}$/i.test(value)
  const hasCorrectLength =
    value.length >= PHONE_NUMBER_MIN_LENGTH &&
    value.length <= PHONE_NUMBER_MAX_LENGTH

  return !(isFormattedCorrectly && hasCorrectLength)
    ? t('Invalid Phone Number (+4912345678910)')
    : undefined
}

export const noWhitespace = (t: TFunction) => (value: string) =>
  /\s/g.test(value) ? t('Spaces are not allowed') : undefined

export const validatePrice =
  <T>(t: TFunction) =>
  (value: T) => {
    return value && Number.isNaN(transformFloatToEnglishLocale(value))
      ? t('Invalid Price')
      : undefined
  }

export const validateGeoCoordLat = (t: TFunction) => (value: string) =>
  value && !/^([-+]?)([\d]{1,2})(((\.)(\d+)))$/i.test(value)
    ? t('Invalid latitude')
    : undefined

export const validateGeoCoordLong = (t: TFunction) => (value: string) =>
  value && !/^(([-+]?)([\d]{1,3})((\.)(\d+))?)$/i.test(value)
    ? t('Invalid longitude')
    : undefined

export const validateDate =
  (t: TFunction) =>
  <T>(value: T) => {
    if (!value || isDate(value)) return undefined
    if (typeof value === 'string' || typeof value === 'number')
      return !isValid(new Date(value))
        ? t('Invalid Date Format (DD/MM/YYYY)')
        : undefined

    return t('Invalid Date Format (DD/MM/YYYY)')
  }

export const validateDateTime =
  <T>(t: TFunction) =>
  (value: T) => {
    if (!value || isDate(value)) return undefined
    if (typeof value === 'string' || typeof value === 'number')
      return !isValid(new Date(value))
        ? t('Invalid Date Time Format (DD/MM/YYYY hh:mm)')
        : undefined

    return t('Invalid Date Time Format (DD/MM/YYYY hh:mm)')
  }

export const validateDateTimeMui =
  (t: TFunction) =>
  <Date>(value: Date) => {
    if (!value || !isDate(value)) return undefined
    if (value instanceof Date)
      return !isValid(new Date(value))
        ? t('Invalid Date Time Format (DD/MM/YYYY hh:mm)')
        : undefined

    return t('Invalid Date Time Format (DD/MM/YYYY hh:mm)')
  }

export const validateDateTimeIsBefore =
  <T>(t: TFunction) =>
  (dateToCompare: Date) =>
  (value: T) => {
    let valueDate: Date

    if (typeof value === 'string' || typeof value === 'number') {
      valueDate = new Date(value)
    } else if (isDate(value)) {
      valueDate = value
    } else {
      return undefined
    }

    return isBefore(dateToCompare, valueDate)
      ? t('Date not before {{date}}', { date: formatDate(dateToCompare) })
      : undefined
  }

export const validateDateTimeInFuture =
  <T>(t: TFunction) =>
  (value: T) => {
    let valueDate: Date

    if (typeof value === 'string' || typeof value === 'number') {
      valueDate = new Date(value)
    } else if (isDate(value)) {
      valueDate = value
    } else {
      return undefined
    }

    return isBefore(valueDate, new Date())
      ? t('Selected date is not in the future')
      : undefined
  }

export const validateDateTimeInPast =
  (t: TFunction) => (value: Date | string | number) => {
    if (!value) return undefined

    const valueDate = new Date(value)

    return isAfter(valueDate, new Date())
      ? t('Selected date is not in the past')
      : undefined
  }

export const validateUuidStandardFormat = (t: TFunction) => (value: string) => {
  return value.length !== 36 || value.split('-').length !== 5
    ? t('No valid UUID')
    : undefined
}

export const validateLicensePlate = (t: TFunction) => (value: string) => {
  if (!/^[A-Z]{1,3}-[A-Z]{0,2}\d{1,4}[A-Z]{0,3}$/.test(value)) {
    return t('Invalid license plate format')
  }
  return undefined
}

/*
 *  use to compose multiple validators on one single field
 */
export const composeValidators =
  <T>(validators: (FieldValidator<T> | undefined)[]): FieldValidator<T> =>
  (value, allValues, meta) =>
    validators
      .filter(
        (validator): validator is FieldValidator<T> => validator !== undefined,
      )
      .reduce<
        string | undefined
      >((error, validator) => error ?? validator(value, allValues, meta), undefined)

export const validateMonthYear =
  (t: TFunction) =>
  (value: string): string | undefined => {
    const regex = /^[0-9]{4}-[0-9]{2}$/
    return regex.test(value) ? undefined : t('Invalid Date Format (yyyy-mm)')
  }

export const englishNumber =
  (t: TFunction) =>
  (value: string | number): string | undefined => {
    if (!value) {
      return undefined
    }
    return Number.isNaN(Number(value)) ? t('Invalid Number') : undefined
  }

export const noSpecialChars =
  (t: TFunction) =>
  (value: string): string | undefined => {
    const regex = /[<>":/*~]/
    return regex.test(value)
      ? t('Invalid, special chars <>":/*~ are not allowed ')
      : undefined
  }

export const onlyNumbersAndLetters =
  (t: TFunction) =>
  (value: string): string | undefined => {
    const regex = /^[a-zA-Z0-9 ]*$/
    return regex.test(value)
      ? undefined
      : t('Invalid, only letters and numbers are allowed')
  }
