/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioAddressAutocompleteQueryVariables = Types.Exact<{
  searchTerm: Types.Scalars['String']['input']
  serviceAreaUuid: Types.Scalars['String']['input']
  language: Types.RioLanguage
}>

export type RioAddressAutocompleteQuery = {
  __typename?: 'Query'
  rioAddressAutocomplete: {
    __typename?: 'RioAddressAutocompleteResponse'
    addresses: Array<string>
  }
}

export const RioAddressAutocompleteDocument = gql`
  query RioAddressAutocomplete(
    $searchTerm: String!
    $serviceAreaUuid: String!
    $language: RioLanguage!
  ) {
    rioAddressAutocomplete(
      input: {
        searchTerm: $searchTerm
        serviceAreaUuid: $serviceAreaUuid
        language: $language
      }
    ) {
      addresses
    }
  }
`

/**
 * __useRioAddressAutocompleteQuery__
 *
 * To run a query within a React component, call `useRioAddressAutocompleteQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioAddressAutocompleteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioAddressAutocompleteQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      serviceAreaUuid: // value for 'serviceAreaUuid'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useRioAddressAutocompleteQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioAddressAutocompleteQuery,
    RioAddressAutocompleteQueryVariables
  > &
    (
      | { variables: RioAddressAutocompleteQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioAddressAutocompleteQuery,
    RioAddressAutocompleteQueryVariables
  >(RioAddressAutocompleteDocument, options)
}
export function useRioAddressAutocompleteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioAddressAutocompleteQuery,
    RioAddressAutocompleteQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioAddressAutocompleteQuery,
    RioAddressAutocompleteQueryVariables
  >(RioAddressAutocompleteDocument, options)
}
export function useRioAddressAutocompleteSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioAddressAutocompleteQuery,
        RioAddressAutocompleteQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioAddressAutocompleteQuery,
    RioAddressAutocompleteQueryVariables
  >(RioAddressAutocompleteDocument, options)
}
export type RioAddressAutocompleteQueryHookResult = ReturnType<
  typeof useRioAddressAutocompleteQuery
>
export type RioAddressAutocompleteLazyQueryHookResult = ReturnType<
  typeof useRioAddressAutocompleteLazyQuery
>
export type RioAddressAutocompleteSuspenseQueryHookResult = ReturnType<
  typeof useRioAddressAutocompleteSuspenseQuery
>
export type RioAddressAutocompleteQueryResult = Apollo.QueryResult<
  RioAddressAutocompleteQuery,
  RioAddressAutocompleteQueryVariables
>
