/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioTripQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type RioTripQuery = {
  __typename?: 'Query'
  tripByTripId: { __typename?: 'Trip'; id: string } | null
}

export const RioTripDocument = gql`
  query RioTrip($id: ID!) {
    tripByTripId(id: $id) {
      id
    }
  }
`

/**
 * __useRioTripQuery__
 *
 * To run a query within a React component, call `useRioTripQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioTripQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioTripQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRioTripQuery(
  baseOptions: Apollo.QueryHookOptions<RioTripQuery, RioTripQueryVariables> &
    ({ variables: RioTripQueryVariables; skip?: boolean } | { skip: boolean }),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RioTripQuery, RioTripQueryVariables>(
    RioTripDocument,
    options,
  )
}
export function useRioTripLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioTripQuery,
    RioTripQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RioTripQuery, RioTripQueryVariables>(
    RioTripDocument,
    options,
  )
}
export function useRioTripSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<RioTripQuery, RioTripQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<RioTripQuery, RioTripQueryVariables>(
    RioTripDocument,
    options,
  )
}
export type RioTripQueryHookResult = ReturnType<typeof useRioTripQuery>
export type RioTripLazyQueryHookResult = ReturnType<typeof useRioTripLazyQuery>
export type RioTripSuspenseQueryHookResult = ReturnType<
  typeof useRioTripSuspenseQuery
>
export type RioTripQueryResult = Apollo.QueryResult<
  RioTripQuery,
  RioTripQueryVariables
>
