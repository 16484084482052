import { ENV } from '@backoffice-frontend/environment'

export const REACT_I18NEXT_LANGUAGE_KEY = 'i18nextLng'

export const namespace = `moia.${ENV}`

export const LAST_AUTHENTICATED_USER = `${namespace}.user.last.authenticated.user`
export const AUTHENTICATED_USER_LOCALSTORAGE_KEY = `${namespace}.amplifyAuthMock.authenticatedUsername`
export const SERVICE_WORKER_UPDATE_PENDING = `${namespace}.service.worker.update.pending`
export const BACKOFFICE_LOGIN_METHOD = `${namespace}.backoffice.loginByUsername`
export const BACKOFFICE_USERNAME = `${namespace}.backoffice.username`
export const BACKOFFICE_EMAIL = `${namespace}.backoffice.email`
export const SAML_LOGIN_FLOW = `${namespace}.amplify.saml.flow`
export const SAML_REDIRECT_TO = `${namespace}.amplify.saml.redirectTo`
export const MFA_METHOD_LOCALSTORAGE_KEY = `${namespace}.amplifyAuthMock.mfaMethod`

export const uiNamespace = `${namespace}.ui`
export const NAV_OPEN_FLAG_KEY = `${uiNamespace}.navOpen`
export const FEATURE_TOGGLES_KEY = `${uiNamespace}.featureToggles`
export const DARK_MODE_KEY = `${uiNamespace}.darkMode`
export const APPEARANCE_KEY = `${uiNamespace}.appearance`

export const EMPLOYEE_SEARCH_FILTER = `${uiNamespace}.employee.employeeSearchFilter`
export const EMPLOYEE_SEARCH_SUPERVISOR_FILTER = `${uiNamespace}.employee.supervisorFilter`
export const EMPLOYEE_AREA_LIST = `${uiNamespace}.employee.list.view`
export const EMPLOYEE_MESSAGE_LIST = `${uiNamespace}.employee.message.filter`
export const EMPLOYEE_STATUSES_FILTER = `${uiNamespace}.employee.statuses.filter`
export const EMPLOYEE_QUALIFICATION_FILTER = `${uiNamespace}.employee.qualification.filter`
export const SHIFT_SEARCH_SUPERVISOR_FILTER = `${uiNamespace}.shift.supervisorFilter`

export const FLEET_HUB_ID_KEY = `${namespace}.fleet.hub`

const serviceAreaManagementNamespace = `${namespace}.serviceAreaManagement`
export const SERVICE_AREA_MANAGEMENT_SERVICE_AREA_ID_KEY = `${serviceAreaManagementNamespace}.serviceAreaId`

export const MAP_FILTERS_VISIBLE_KEY = `${namespace}.ui.map.filtersVisible`

export const POOLING_MAP_TOGGLE_TRAFFIC_LAYER_KEY = `${namespace}.ui.map.trafficLayerEnabled`

export const cypressPreferredMfaMethodKey = '__CYPRESS_PREFERRED_MFA_METHOD__'
export const cypressMfaErrorCase = ''
