/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type GlobalServiceAreaUuidQueryVariables = Types.Exact<{
  serviceAreaId: Types.Scalars['ID']['input']
}>

export type GlobalServiceAreaUuidQuery = {
  __typename?: 'Query'
  serviceAreaById: { __typename?: 'ServiceArea'; uuid: string } | null
}

export const GlobalServiceAreaUuidDocument = gql`
  query globalServiceAreaUuid($serviceAreaId: ID!) {
    serviceAreaById(id: $serviceAreaId) {
      uuid
    }
  }
`

/**
 * __useGlobalServiceAreaUuidQuery__
 *
 * To run a query within a React component, call `useGlobalServiceAreaUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalServiceAreaUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalServiceAreaUuidQuery({
 *   variables: {
 *      serviceAreaId: // value for 'serviceAreaId'
 *   },
 * });
 */
export function useGlobalServiceAreaUuidQuery(
  baseOptions: Apollo.QueryHookOptions<
    GlobalServiceAreaUuidQuery,
    GlobalServiceAreaUuidQueryVariables
  > &
    (
      | { variables: GlobalServiceAreaUuidQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    GlobalServiceAreaUuidQuery,
    GlobalServiceAreaUuidQueryVariables
  >(GlobalServiceAreaUuidDocument, options)
}
export function useGlobalServiceAreaUuidLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GlobalServiceAreaUuidQuery,
    GlobalServiceAreaUuidQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    GlobalServiceAreaUuidQuery,
    GlobalServiceAreaUuidQueryVariables
  >(GlobalServiceAreaUuidDocument, options)
}
export function useGlobalServiceAreaUuidSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GlobalServiceAreaUuidQuery,
        GlobalServiceAreaUuidQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    GlobalServiceAreaUuidQuery,
    GlobalServiceAreaUuidQueryVariables
  >(GlobalServiceAreaUuidDocument, options)
}
export type GlobalServiceAreaUuidQueryHookResult = ReturnType<
  typeof useGlobalServiceAreaUuidQuery
>
export type GlobalServiceAreaUuidLazyQueryHookResult = ReturnType<
  typeof useGlobalServiceAreaUuidLazyQuery
>
export type GlobalServiceAreaUuidSuspenseQueryHookResult = ReturnType<
  typeof useGlobalServiceAreaUuidSuspenseQuery
>
export type GlobalServiceAreaUuidQueryResult = Apollo.QueryResult<
  GlobalServiceAreaUuidQuery,
  GlobalServiceAreaUuidQueryVariables
>
