import type { ReactNode } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
  FccAdAlertType,
  TaskNotificationStatus,
  TaskNotificationType,
} from '@backoffice-frontend/graphql'
import { AlertsCommonAreaId } from '../AlertsCommonAreaId'
import { AssignedToMeFilter } from '../const'
import type { AlertFilterTypes } from '../const'

export const useTaskTypeTranslation = () => {
  const { t } = useTranslation(AlertsCommonAreaId)
  const map: Partial<Record<TaskNotificationType | FccAdAlertType, string>> = {
    // AD alerts
    [FccAdAlertType.PassengerUnbuckled]: t('Unbuckled passenger'),
    [FccAdAlertType.PassengerCalling]: t('Incoming Passenger Call'),
    [FccAdAlertType.OperatorCalling]: t('Outgoing Operator Call'),
    [FccAdAlertType.EmergencyStop]: t('Emergency stop'),
    [FccAdAlertType.WaypointAttendance]: t('Waypoint Attendance'),
    // Task notifications
    [TaskNotificationType.SamRoadBlockageReported]: t(
      'New road blockage reported',
    ),
    [TaskNotificationType.StopAccessibilityValidationFailed]: t(
      'Accessibility validation failed',
    ),
    [TaskNotificationType.IvcCameraErrorCameraNotReachable]: t(
      'Camera: Not reachable',
    ),
    [TaskNotificationType.IvcCameraErrorCameraRecordingsNotChecked]: t(
      'Camera: Recordings not checked',
    ),
    [TaskNotificationType.IvcCameraErrorCameraResponseTypeError]: t(
      'Camera: Returns invalid response in HTTP endpoint',
    ),
    [TaskNotificationType.IvcCameraErrorCameraSdCardNotFound]: t(
      'Camera: SD Card not found',
    ),
    [TaskNotificationType.IvcCameraErrorCameraSdCardSizeMismatch]: t(
      'Camera: SD Card size 0MB',
    ),
    [TaskNotificationType.IvcCameraErrorCameraWebserverNotReachable]: t(
      'Camera: Webserver not reachable',
    ),
    [TaskNotificationType.IvcCameraErrorNoMatchingRecordingsFound]: t(
      'Camera: No matching recordings found',
    ),
    [TaskNotificationType.ShiftTimesShiftTypeChangeNotification]:
      t('Shift type change'),
    [TaskNotificationType.IvcCameraErrorNoTimedateFromCamera]: t(
      'Camera: No timedate returned',
    ),
    [TaskNotificationType.IvcCameraErrorNtpNotConfigured]: t(
      'Camera: NTP not configured',
    ),
    [TaskNotificationType.IvcCdisErrorHostUnreachable]: t(
      'CDIS: Host is unreachable',
    ),
    [TaskNotificationType.IvcCdisErrorTimedOut]: t(
      'CDIS: Connection timed out',
    ),
    [TaskNotificationType.IvcCdisNotReachable]: t('CDIS: Not reachable'),
    [TaskNotificationType.IvcCdisNoMapAvailable]: t('CDIS: No map available'),
    [TaskNotificationType.IvcInternetNotReachable]: t(
      'Internet is not reachable',
    ),
    [TaskNotificationType.IvcRouterNotReachable]: t('Router is not reachable'),
    [TaskNotificationType.IvcRouterOffline]: t('Router is offline'),
    [TaskNotificationType.ShiftTimesInvalidBreakNotification]: t(
      'Break correction in GFOS needed',
    ),
    [TaskNotificationType.ShiftTimesMissingBreakEndNotification]: '',
    [TaskNotificationType.ShiftTimesMissingClockEventNotification]: t(
      'Missing automated clock ins or outs in GFOS',
    ),
    [TaskNotificationType.VehicleOutOfServiceAreaNotification]: t(
      'Outside Service Area',
    ),
    [TaskNotificationType.VehicleNoBookingNotification]: t(
      'Without booking more than 1h',
    ),
    [TaskNotificationType.ShiftPoolingOffDepartureNotification]: t(
      'Pooling not activated',
    ),
    [TaskNotificationType.ShiftMissingBreakStartedNotification]: t(
      'Expected “break start” is missing',
    ),
    [TaskNotificationType.ShiftMissingHubCheckinNotification]: t(
      'Driver has not shown up yet',
    ),
    [TaskNotificationType.ShiftMissingHubCheckoutNotification]: t(
      'Driver has not checked out yet',
    ),
    [TaskNotificationType.ShiftDrivingWithoutBreakNotification]: t(
      'Driving without break',
    ),
    [TaskNotificationType.ShiftDeviationNotification]: '',
    [TaskNotificationType.StopActivationFailedNotification]: t(
      'Stop Activation Failed',
    ),
    [TaskNotificationType.StopAreaMissingActiveStopsNotification]: t(
      'Area missing active stops',
    ),
    [TaskNotificationType.StopDownVotedByDrivers]: t(
      'Stop downvoted by Drivers',
    ),
    [TaskNotificationType.StopAccessibilityDownvotedByDrivers]: t(
      'Stop is potentially not accessible',
    ),
    [TaskNotificationType.StopPotentiallyUnreachable]: t(
      'Stop potentially unreachable',
    ),
    [TaskNotificationType.StopValidationFailed]: t('Stop validation failed'),
    [TaskNotificationType.TripPrebookingNoAssignment]: t(
      'Prebooking without assigned vehicle',
    ),
    [TaskNotificationType.VehicleCdisInterruptedNotification]: t(
      'Connection to CDIS is interrupted',
    ),
    [TaskNotificationType.Vehicle_12VBatteryCriticalNotification]: t(
      '12V battery critical',
    ),
    [TaskNotificationType.VehicleChargingErrorNotification]:
      t('Charging error'),
    [TaskNotificationType.VehicleChargingNeededNotification]:
      t('Charging needed'),
    [TaskNotificationType.VehicleNoHubReturnNotification]:
      t('Without hub return'),
    [TaskNotificationType.VehicleWithoutDriverNotification]: t(
      'Vehicle without driver',
    ),
    [TaskNotificationType.VehicleIsStandingNotification]: t(
      'Vehicle is standing',
    ),
    [TaskNotificationType.VehicleLowStateOfChargeNotification]: t('Low SoC'),
    [TaskNotificationType.FcVehicleCabinSafetyCheckRequiredNotification]:
      t('Cabin safety check'),
    [TaskNotificationType.FcVehicleOutOfServiceNotification]: t(
      'Vehicle not bookable',
    ),
    [TaskNotificationType.FcPassengerAuthenticationFallbackRequestNotification]:
      t('Manual passenger check in'),
  }

  return (key: TaskNotificationType | FccAdAlertType): string => map[key] ?? ''
}

export const useTaskInfoTranslation = () => {
  const { t } = useTranslation(AlertsCommonAreaId)
  const map: Partial<Record<TaskNotificationType | FccAdAlertType, ReactNode>> =
    {
      // AD alerts
      [FccAdAlertType.PassengerUnbuckled]: t(
        'A passenger is not buckled while the vehicle is moving. Please click “Take over” and ensure all passengers are buckled up.',
      ),
      // TaskNotifications
      [TaskNotificationType.VehicleOutOfServiceAreaNotification]: (
        <Trans components={{ ul: <ul />, li: <li /> }}>
          {t(
            'This vehicle <ul><li>is opened by a driver or field tester</li><li>is outside the service area for more than 5 minutes</li><li>has no active route assigned</li></ul>Suggested solution <ul><li>Route the vehicle back into the service area</li></ul>Message to driver (VGA)<br /><br />"Bitte kehre schnellstmöglich in die Service Area zurück, damit unsere Kunden Fahrten buchen können. Falls nicht, wird dich ein Hub Center Agent bald kontaktieren."',
          )}
        </Trans>
      ),
      [TaskNotificationType.VehicleNoBookingNotification]: (
        <Trans components={{ ul: <ul />, li: <li /> }}>
          {t(
            'This vehicle <ul><li>is opened by a driver or field tester</li><li>has no trip / route for 60 min</li></ul>Suggested solution <ul><li>Call the vehicle and manually assign a new rebalancing zone</li><li>Alternatively: Wait for automatic assignment of a trip, hub return or rebalancing</li></ul>Message to driver (VGA)<br /><br />"Leider konnten wir in der letzten Stunde keinen passenden Trip für dein Fahrzeug finden. Bitte bleib verfügbar für unsere Kunden. Der nächste Trip kommt bestimmt gleich."',
          )}
        </Trans>
      ),
      [TaskNotificationType.TripPrebookingNoAssignment]: t(
        'The automated matching between the pre-booking request and a vehicle was not possible. Use the button to match the vehicle manually. Expired alerts need to be resolved.',
      ),
      [TaskNotificationType.VehicleNoHubReturnNotification]: (
        <Trans components={{ ul: <ul />, li: <li /> }}>
          {t(
            'This vehicle <ul><li>is active</li><li>is open</li><li>is not opened by service staff</li><li>is outside of hub, IFB & Short Break</li><li>is missing a hub return in the future</li><li>is in field for at least 2 min</li><li>has exceeded 15 min of buffer time for normal hub return or has exceeded 30 min of buffer time for service cancellation</li></ul>Suggested solution <ul><li>Send the vehicle in time back to the hub.</li></ul>',
          )}
        </Trans>
      ),
      [TaskNotificationType.VehicleWithoutDriverNotification]: (
        <Trans components={{ ul: <ul />, li: <li /> }}>
          {t(
            'This vehicle <ul><li>is active</li><li>is not opened by service staff</li><li>is outside of hub, IFB & Short Break</li><li>has ignition on</li></ul>Suggested solution <ul><li>Book the driver to the vehicle via telematics emergency function</li></ul>Message to driver (VGA)<br /><br />"Die Fahrzeugzuweisung ist leider fehlerhaft, bitte melde dich im Service Center."',
          )}
        </Trans>
      ),
      [TaskNotificationType.VehicleIsStandingNotification]: (
        <Trans components={{ ul: <ul />, li: <li /> }}>
          {t(
            'This vehicle <ul><li>is active</li><li>is outside of hub, IFB & Short Break</li><li>didn’t move more than 150 meters in 15 min</li><li>is in field for at least 15 min</li><li>has a trip, hub return or rebalancing dispatched</li><li>is not in a rebalancing zone</li></ul>Suggested solution <ul><li>Clarify with the driver why the vehicle is not moving</li></ul>',
          )}
        </Trans>
      ),
      [TaskNotificationType.VehicleLowStateOfChargeNotification]: (
        <Trans components={{ ul: <ul />, li: <li /> }}>
          {t(
            'This vehicle <ul><li>is active</li><li>is opened by a driver or field tester</li><li>is outside of hub, IFB & Short Break</li><li>has SoC lower than 20% (or 35% for WAVs)</li><li>is not connected to a charging pole</li></ul>Suggested solution <ul><li>Use the “Cancel Service” function and send the vehicle to the nearest hub</li></ul>',
          )}
        </Trans>
      ),
      [TaskNotificationType.FcVehicleCabinSafetyCheckRequiredNotification]: (
        <Trans components={{ br: <br /> }}>
          {t(
            'This vehicle is about to continue its trip.<br /><br />Please click “Start cabin safety check” and confirm the cabin safety in the next step.',
          )}
        </Trans>
      ),
      [TaskNotificationType.FcVehicleOutOfServiceNotification]: (
        <Trans components={{ ul: <ul />, li: <li /> }}>
          {t(
            'This vehicle <ul><li>is active</li><li>is opened by a driver or field tester</li><li>is outside of hub, IFB & Short Break</li><li>is in field for at least 2 min</li><li>is not bookable for more than 10 min</li></ul>Suggested solution <ul><li>Please contact the driver and verify what’s causing the vehicle to be not bookable. If connectivity is lost, restarting the VGA might help.</li></ul>',
          )}
        </Trans>
      ),
      [TaskNotificationType.FcPassengerAuthenticationFallbackRequestNotification]:
        t(
          'The automated passenger check-in failed. Check in passengers manually by following the instructions after pressing “Check in passenger”.',
        ),
      [TaskNotificationType.ShiftDrivingWithoutBreakNotification]: t(
        'Please try at all costs to avoid exceeding the driving time. Check whether the break was not detected. Otherwise contact the driver immediately, perform a service cancellation and make them stop driving safely.',
      ),
      [TaskNotificationType.ShiftMissingHubCheckinNotification]: t(
        'Contact the driver to find out the reason for the delay or absence.',
      ),
    }

  return (key: TaskNotificationType | FccAdAlertType) => map[key] ?? null
}

export const useTaskNotificationStatusLabel = () => {
  const { t } = useTranslation(AlertsCommonAreaId)

  return (key: TaskNotificationStatus) => {
    if (!key) return ''

    const map: Record<TaskNotificationStatus, string> = {
      [TaskNotificationStatus.New]: t('Alert State NEW'),
      [TaskNotificationStatus.InProgress]: t('Alert State IN_PROGRESS'),
      [TaskNotificationStatus.Pending]: t('Alert State PENDING'),
      [TaskNotificationStatus.Solved]: t('Alert State SOLVED'),
    }

    return map[key]
  }
}

export const useTaskTableColumn = () => {
  const { t } = useTranslation(AlertsCommonAreaId)

  return (key: string) => {
    if (!key) return ''

    const map: Record<string, string> = {
      'Drop off stop': t('Column Drop off stop'),
      'Pick up stop': t('Column Pick up stop'),
      'Time left': t('Column Time left'),
      'Vehicle Location': t('Vehicle Location'),
      Action: t('Column Action'),
      Alert: t('Column Alert'),
      Assigned: t('Column Assigned'),
      Created: t('Column Created'),
      Customer: t('Column Customer'),
      Driver: t('Column Driver'),
      Entity: t('Entity'),
      Resolved: t('Column Resolved'),
      Requirement: t('Column Requirement'),
      State: t('Column State'),
      Vehicle: t('Column Vehicle'),
      'Last Occurred': t('Column Last Occurred'),
    }
    return map[key]
  }
}

export const useAlertsFilter = () => {
  const { t } = useTranslation(AlertsCommonAreaId)

  return (key: AlertFilterTypes) => {
    if (!key) return ''

    const map: Record<
      TaskNotificationStatus | typeof AssignedToMeFilter,
      string
    > = {
      [TaskNotificationStatus.New]: t('Alert Filter NEW'),
      [AssignedToMeFilter]: t('Alert Filter MY_IN_PROGRESS'),
      [TaskNotificationStatus.InProgress]: t('Alert Filter IN_PROGRESS'),
      [TaskNotificationStatus.Pending]: t('Alert Filter PENDING'),
      [TaskNotificationStatus.Solved]: t('Alert Filter SOLVED'),
    }
    return map[key]
  }
}
