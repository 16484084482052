/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import {
  AdWaypointPassengersVehicleFragmentDoc,
  AdWaypointPassengersWaypointFragmentDoc,
} from '../../../../../ad-passenger-list/src/lib/AdWaypointPassengers/AdWaypointPassengers.hook'
import {
  GetAlertSeverityTaskNotificationFragmentDoc,
  GetAlertSeverityAdAlertFragmentDoc,
} from '../../../../../alerts/alerts-common/src/lib/utils/getAlertSeverity.hook'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type OperatorPromptVehicleFragment = {
  __typename?: 'Vehicle'
  id: string
  label: number | null
  isWheelchairAccessible: boolean | null
  upcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    adState: Types.FccWaypointState | null
    id: string
    actions: Array<
      | {
          __typename: 'FccWaypointDeliveryAction'
          id: string
          estimatedAt: string
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointHubReturnAction' }
      | {
          __typename: 'FccWaypointPickupAction'
          id: string
          estimatedAt: string
          adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
          trip: { __typename?: 'Trip'; id: string } | null
          customer: {
            __typename?: 'Customer'
            firstName: string | null
            lastName: string | null
            extendedWalkingTime: boolean
            visualImpairment: boolean | null
          } | null
          seats: {
            __typename?: 'FccWaypointSeatInfo'
            seats: number
            wheelchairs: number
            childSeats: number
            boosterSeats: number
          }
        }
      | { __typename?: 'FccWaypointReallocationAction' }
    >
  }> | null
  openTaskNotificationAlerts: {
    __typename?: 'TaskNotificationList'
    total: number
  } | null
  openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
  alerts: {
    __typename?: 'TaskNotificationList'
    alerts: Array<
      | { __typename?: 'HubServiceNotification' }
      | { __typename?: 'IvcTaskNotification' }
      | {
          __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
          id: string
          trip: {
            __typename?: 'Trip'
            id: string
            customer: { __typename?: 'Customer'; id: string } | null
          } | null
        }
      | { __typename?: 'PassengerUnbuckled' }
      | { __typename?: 'RoadBlockageTaskNotification' }
      | { __typename?: 'ShiftDeviationNotification' }
      | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
      | { __typename?: 'StopActivationFailedNotification' }
      | { __typename?: 'StopAreaMissingActiveStopsNotification' }
      | { __typename?: 'StopDownVotedByDrivers' }
      | { __typename?: 'StopPotentiallyUnreachable' }
      | { __typename?: 'StopValidationFailed' }
      | { __typename?: 'TimeManagementNotification' }
      | { __typename?: 'TripPrebookingNoAssignment' }
      | { __typename?: 'VehicleAvailabilityNotification' }
      | { __typename?: 'VehicleIncidentNotification' }
      | { __typename?: 'VehicleSurveillanceNotification' }
      | { __typename?: 'VehicleWaypointAttendance' }
    >
  } | null
}

export type OperatorPromptTask_HubServiceNotification_Fragment = {
  __typename: 'HubServiceNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_IvcTaskNotification_Fragment = {
  __typename: 'IvcTaskNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename: 'PassengerAuthentificationFallbackRequestedNotification'
    id: string
    foreignId: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    notificationType: Types.TaskNotificationType
    taskStatus: Types.TaskNotificationStatus
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type OperatorPromptTask_PassengerUnbuckled_Fragment = {
  __typename: 'PassengerUnbuckled'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_RoadBlockageTaskNotification_Fragment = {
  __typename: 'RoadBlockageTaskNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_ShiftDeviationNotification_Fragment = {
  __typename: 'ShiftDeviationNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_StopAccessibilityDownvotedByDrivers_Fragment = {
  __typename: 'StopAccessibilityDownvotedByDrivers'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_StopActivationFailedNotification_Fragment = {
  __typename: 'StopActivationFailedNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_StopAreaMissingActiveStopsNotification_Fragment =
  {
    __typename: 'StopAreaMissingActiveStopsNotification'
    id: string
    foreignId: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    notificationType: Types.TaskNotificationType
    taskStatus: Types.TaskNotificationStatus
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type OperatorPromptTask_StopDownVotedByDrivers_Fragment = {
  __typename: 'StopDownVotedByDrivers'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_StopPotentiallyUnreachable_Fragment = {
  __typename: 'StopPotentiallyUnreachable'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_StopValidationFailed_Fragment = {
  __typename: 'StopValidationFailed'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_TimeManagementNotification_Fragment = {
  __typename: 'TimeManagementNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_TripPrebookingNoAssignment_Fragment = {
  __typename: 'TripPrebookingNoAssignment'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_VehicleAvailabilityNotification_Fragment = {
  __typename: 'VehicleAvailabilityNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_VehicleIncidentNotification_Fragment = {
  __typename: 'VehicleIncidentNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_VehicleSurveillanceNotification_Fragment = {
  __typename: 'VehicleSurveillanceNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTask_VehicleWaypointAttendance_Fragment = {
  __typename: 'VehicleWaypointAttendance'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptTaskFragment =
  | OperatorPromptTask_HubServiceNotification_Fragment
  | OperatorPromptTask_IvcTaskNotification_Fragment
  | OperatorPromptTask_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | OperatorPromptTask_PassengerUnbuckled_Fragment
  | OperatorPromptTask_RoadBlockageTaskNotification_Fragment
  | OperatorPromptTask_ShiftDeviationNotification_Fragment
  | OperatorPromptTask_StopAccessibilityDownvotedByDrivers_Fragment
  | OperatorPromptTask_StopActivationFailedNotification_Fragment
  | OperatorPromptTask_StopAreaMissingActiveStopsNotification_Fragment
  | OperatorPromptTask_StopDownVotedByDrivers_Fragment
  | OperatorPromptTask_StopPotentiallyUnreachable_Fragment
  | OperatorPromptTask_StopValidationFailed_Fragment
  | OperatorPromptTask_TimeManagementNotification_Fragment
  | OperatorPromptTask_TripPrebookingNoAssignment_Fragment
  | OperatorPromptTask_VehicleAvailabilityNotification_Fragment
  | OperatorPromptTask_VehicleIncidentNotification_Fragment
  | OperatorPromptTask_VehicleSurveillanceNotification_Fragment
  | OperatorPromptTask_VehicleWaypointAttendance_Fragment

export type OperatorPromptAdAlertFragment = {
  __typename: 'FccAdAlert'
  id: string
  createdAt: string
  type: Types.FccAdAlertType
  adAlertStatus: Types.FccAdAlertStatus
  assignee: { __typename?: 'BackofficeUser'; id: string } | null
}

export type OperatorPromptAlert_FccAdAlert_Fragment = {
  __typename: 'FccAdAlert'
  id: string
  createdAt: string
  type: Types.FccAdAlertType
  adAlertStatus: Types.FccAdAlertStatus
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    label: number | null
    isWheelchairAccessible: boolean | null
    upcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      adState: Types.FccWaypointState | null
      id: string
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            id: string
            estimatedAt: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointHubReturnAction' }
        | {
            __typename: 'FccWaypointPickupAction'
            id: string
            estimatedAt: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointReallocationAction' }
      >
    }> | null
    openTaskNotificationAlerts: {
      __typename?: 'TaskNotificationList'
      total: number
    } | null
    openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
    alerts: {
      __typename?: 'TaskNotificationList'
      alerts: Array<
        | { __typename?: 'HubServiceNotification' }
        | { __typename?: 'IvcTaskNotification' }
        | {
            __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
            trip: {
              __typename?: 'Trip'
              id: string
              customer: { __typename?: 'Customer'; id: string } | null
            } | null
          }
        | { __typename?: 'PassengerUnbuckled' }
        | { __typename?: 'RoadBlockageTaskNotification' }
        | { __typename?: 'ShiftDeviationNotification' }
        | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
        | { __typename?: 'StopActivationFailedNotification' }
        | { __typename?: 'StopAreaMissingActiveStopsNotification' }
        | { __typename?: 'StopDownVotedByDrivers' }
        | { __typename?: 'StopPotentiallyUnreachable' }
        | { __typename?: 'StopValidationFailed' }
        | { __typename?: 'TimeManagementNotification' }
        | { __typename?: 'TripPrebookingNoAssignment' }
        | { __typename?: 'VehicleAvailabilityNotification' }
        | { __typename?: 'VehicleIncidentNotification' }
        | { __typename?: 'VehicleSurveillanceNotification' }
        | { __typename?: 'VehicleWaypointAttendance' }
      >
    } | null
  } | null
  assignee: { __typename?: 'BackofficeUser'; id: string } | null
}

export type OperatorPromptAlert_PassengerAuthentificationFallbackRequestedNotification_Fragment =
  {
    __typename: 'PassengerAuthentificationFallbackRequestedNotification'
    id: string
    foreignId: string
    createdAt: string
    severity: Types.TaskNotificationSeverity
    notificationType: Types.TaskNotificationType
    taskStatus: Types.TaskNotificationStatus
    vehicle: {
      __typename?: 'Vehicle'
      id: string
      label: number | null
      isWheelchairAccessible: boolean | null
      upcomingWaypoints: Array<{
        __typename?: 'FccWaypoint'
        adState: Types.FccWaypointState | null
        id: string
        actions: Array<
          | {
              __typename: 'FccWaypointDeliveryAction'
              id: string
              estimatedAt: string
              trip: { __typename?: 'Trip'; id: string } | null
              customer: {
                __typename?: 'Customer'
                firstName: string | null
                lastName: string | null
                extendedWalkingTime: boolean
                visualImpairment: boolean | null
              } | null
              seats: {
                __typename?: 'FccWaypointSeatInfo'
                seats: number
                wheelchairs: number
                childSeats: number
                boosterSeats: number
              }
            }
          | { __typename?: 'FccWaypointHubReturnAction' }
          | {
              __typename: 'FccWaypointPickupAction'
              id: string
              estimatedAt: string
              adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
              trip: { __typename?: 'Trip'; id: string } | null
              customer: {
                __typename?: 'Customer'
                firstName: string | null
                lastName: string | null
                extendedWalkingTime: boolean
                visualImpairment: boolean | null
              } | null
              seats: {
                __typename?: 'FccWaypointSeatInfo'
                seats: number
                wheelchairs: number
                childSeats: number
                boosterSeats: number
              }
            }
          | { __typename?: 'FccWaypointReallocationAction' }
        >
      }> | null
      openTaskNotificationAlerts: {
        __typename?: 'TaskNotificationList'
        total: number
      } | null
      openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
      alerts: {
        __typename?: 'TaskNotificationList'
        alerts: Array<
          | { __typename?: 'HubServiceNotification' }
          | { __typename?: 'IvcTaskNotification' }
          | {
              __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
              id: string
              trip: {
                __typename?: 'Trip'
                id: string
                customer: { __typename?: 'Customer'; id: string } | null
              } | null
            }
          | { __typename?: 'PassengerUnbuckled' }
          | { __typename?: 'RoadBlockageTaskNotification' }
          | { __typename?: 'ShiftDeviationNotification' }
          | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
          | { __typename?: 'StopActivationFailedNotification' }
          | { __typename?: 'StopAreaMissingActiveStopsNotification' }
          | { __typename?: 'StopDownVotedByDrivers' }
          | { __typename?: 'StopPotentiallyUnreachable' }
          | { __typename?: 'StopValidationFailed' }
          | { __typename?: 'TimeManagementNotification' }
          | { __typename?: 'TripPrebookingNoAssignment' }
          | { __typename?: 'VehicleAvailabilityNotification' }
          | { __typename?: 'VehicleIncidentNotification' }
          | { __typename?: 'VehicleSurveillanceNotification' }
          | { __typename?: 'VehicleWaypointAttendance' }
        >
      } | null
    } | null
    assigned: {
      __typename?: 'TaskStateChangeDetails'
      backofficeUserId: string | null
    } | null
  }

export type OperatorPromptAlert_VehicleIncidentNotification_Fragment = {
  __typename: 'VehicleIncidentNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    label: number | null
    isWheelchairAccessible: boolean | null
    upcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      adState: Types.FccWaypointState | null
      id: string
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            id: string
            estimatedAt: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointHubReturnAction' }
        | {
            __typename: 'FccWaypointPickupAction'
            id: string
            estimatedAt: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointReallocationAction' }
      >
    }> | null
    openTaskNotificationAlerts: {
      __typename?: 'TaskNotificationList'
      total: number
    } | null
    openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
    alerts: {
      __typename?: 'TaskNotificationList'
      alerts: Array<
        | { __typename?: 'HubServiceNotification' }
        | { __typename?: 'IvcTaskNotification' }
        | {
            __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
            trip: {
              __typename?: 'Trip'
              id: string
              customer: { __typename?: 'Customer'; id: string } | null
            } | null
          }
        | { __typename?: 'PassengerUnbuckled' }
        | { __typename?: 'RoadBlockageTaskNotification' }
        | { __typename?: 'ShiftDeviationNotification' }
        | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
        | { __typename?: 'StopActivationFailedNotification' }
        | { __typename?: 'StopAreaMissingActiveStopsNotification' }
        | { __typename?: 'StopDownVotedByDrivers' }
        | { __typename?: 'StopPotentiallyUnreachable' }
        | { __typename?: 'StopValidationFailed' }
        | { __typename?: 'TimeManagementNotification' }
        | { __typename?: 'TripPrebookingNoAssignment' }
        | { __typename?: 'VehicleAvailabilityNotification' }
        | { __typename?: 'VehicleIncidentNotification' }
        | { __typename?: 'VehicleSurveillanceNotification' }
        | { __typename?: 'VehicleWaypointAttendance' }
      >
    } | null
  } | null
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptAlert_VehicleSurveillanceNotification_Fragment = {
  __typename: 'VehicleSurveillanceNotification'
  id: string
  foreignId: string
  createdAt: string
  severity: Types.TaskNotificationSeverity
  notificationType: Types.TaskNotificationType
  taskStatus: Types.TaskNotificationStatus
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    label: number | null
    isWheelchairAccessible: boolean | null
    upcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      adState: Types.FccWaypointState | null
      id: string
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            id: string
            estimatedAt: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointHubReturnAction' }
        | {
            __typename: 'FccWaypointPickupAction'
            id: string
            estimatedAt: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointReallocationAction' }
      >
    }> | null
    openTaskNotificationAlerts: {
      __typename?: 'TaskNotificationList'
      total: number
    } | null
    openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
    alerts: {
      __typename?: 'TaskNotificationList'
      alerts: Array<
        | { __typename?: 'HubServiceNotification' }
        | { __typename?: 'IvcTaskNotification' }
        | {
            __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
            trip: {
              __typename?: 'Trip'
              id: string
              customer: { __typename?: 'Customer'; id: string } | null
            } | null
          }
        | { __typename?: 'PassengerUnbuckled' }
        | { __typename?: 'RoadBlockageTaskNotification' }
        | { __typename?: 'ShiftDeviationNotification' }
        | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
        | { __typename?: 'StopActivationFailedNotification' }
        | { __typename?: 'StopAreaMissingActiveStopsNotification' }
        | { __typename?: 'StopDownVotedByDrivers' }
        | { __typename?: 'StopPotentiallyUnreachable' }
        | { __typename?: 'StopValidationFailed' }
        | { __typename?: 'TimeManagementNotification' }
        | { __typename?: 'TripPrebookingNoAssignment' }
        | { __typename?: 'VehicleAvailabilityNotification' }
        | { __typename?: 'VehicleIncidentNotification' }
        | { __typename?: 'VehicleSurveillanceNotification' }
        | { __typename?: 'VehicleWaypointAttendance' }
      >
    } | null
  } | null
  assigned: {
    __typename?: 'TaskStateChangeDetails'
    backofficeUserId: string | null
  } | null
}

export type OperatorPromptAlertFragment =
  | OperatorPromptAlert_FccAdAlert_Fragment
  | OperatorPromptAlert_PassengerAuthentificationFallbackRequestedNotification_Fragment
  | OperatorPromptAlert_VehicleIncidentNotification_Fragment
  | OperatorPromptAlert_VehicleSurveillanceNotification_Fragment

export type OperatorPromptMostUrgentAlertQueryVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
}>

export type OperatorPromptMostUrgentAlertQuery = {
  __typename?: 'Query'
  mostUrgentAlert:
    | {
        __typename: 'FccAdAlert'
        id: string
        createdAt: string
        type: Types.FccAdAlertType
        adAlertStatus: Types.FccAdAlertStatus
        vehicle: {
          __typename?: 'Vehicle'
          id: string
          label: number | null
          isWheelchairAccessible: boolean | null
          upcomingWaypoints: Array<{
            __typename?: 'FccWaypoint'
            adState: Types.FccWaypointState | null
            id: string
            actions: Array<
              | {
                  __typename: 'FccWaypointDeliveryAction'
                  id: string
                  estimatedAt: string
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointHubReturnAction' }
              | {
                  __typename: 'FccWaypointPickupAction'
                  id: string
                  estimatedAt: string
                  adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointReallocationAction' }
            >
          }> | null
          openTaskNotificationAlerts: {
            __typename?: 'TaskNotificationList'
            total: number
          } | null
          openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
          alerts: {
            __typename?: 'TaskNotificationList'
            alerts: Array<
              | { __typename?: 'HubServiceNotification' }
              | { __typename?: 'IvcTaskNotification' }
              | {
                  __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
                  id: string
                  trip: {
                    __typename?: 'Trip'
                    id: string
                    customer: { __typename?: 'Customer'; id: string } | null
                  } | null
                }
              | { __typename?: 'PassengerUnbuckled' }
              | { __typename?: 'RoadBlockageTaskNotification' }
              | { __typename?: 'ShiftDeviationNotification' }
              | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
              | { __typename?: 'StopActivationFailedNotification' }
              | { __typename?: 'StopAreaMissingActiveStopsNotification' }
              | { __typename?: 'StopDownVotedByDrivers' }
              | { __typename?: 'StopPotentiallyUnreachable' }
              | { __typename?: 'StopValidationFailed' }
              | { __typename?: 'TimeManagementNotification' }
              | { __typename?: 'TripPrebookingNoAssignment' }
              | { __typename?: 'VehicleAvailabilityNotification' }
              | { __typename?: 'VehicleIncidentNotification' }
              | { __typename?: 'VehicleSurveillanceNotification' }
              | { __typename?: 'VehicleWaypointAttendance' }
            >
          } | null
        } | null
        assignee: { __typename?: 'BackofficeUser'; id: string } | null
      }
    | {
        __typename: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
        foreignId: string
        createdAt: string
        severity: Types.TaskNotificationSeverity
        notificationType: Types.TaskNotificationType
        taskStatus: Types.TaskNotificationStatus
        vehicle: {
          __typename?: 'Vehicle'
          id: string
          label: number | null
          isWheelchairAccessible: boolean | null
          upcomingWaypoints: Array<{
            __typename?: 'FccWaypoint'
            adState: Types.FccWaypointState | null
            id: string
            actions: Array<
              | {
                  __typename: 'FccWaypointDeliveryAction'
                  id: string
                  estimatedAt: string
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointHubReturnAction' }
              | {
                  __typename: 'FccWaypointPickupAction'
                  id: string
                  estimatedAt: string
                  adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointReallocationAction' }
            >
          }> | null
          openTaskNotificationAlerts: {
            __typename?: 'TaskNotificationList'
            total: number
          } | null
          openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
          alerts: {
            __typename?: 'TaskNotificationList'
            alerts: Array<
              | { __typename?: 'HubServiceNotification' }
              | { __typename?: 'IvcTaskNotification' }
              | {
                  __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
                  id: string
                  trip: {
                    __typename?: 'Trip'
                    id: string
                    customer: { __typename?: 'Customer'; id: string } | null
                  } | null
                }
              | { __typename?: 'PassengerUnbuckled' }
              | { __typename?: 'RoadBlockageTaskNotification' }
              | { __typename?: 'ShiftDeviationNotification' }
              | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
              | { __typename?: 'StopActivationFailedNotification' }
              | { __typename?: 'StopAreaMissingActiveStopsNotification' }
              | { __typename?: 'StopDownVotedByDrivers' }
              | { __typename?: 'StopPotentiallyUnreachable' }
              | { __typename?: 'StopValidationFailed' }
              | { __typename?: 'TimeManagementNotification' }
              | { __typename?: 'TripPrebookingNoAssignment' }
              | { __typename?: 'VehicleAvailabilityNotification' }
              | { __typename?: 'VehicleIncidentNotification' }
              | { __typename?: 'VehicleSurveillanceNotification' }
              | { __typename?: 'VehicleWaypointAttendance' }
            >
          } | null
        } | null
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          backofficeUserId: string | null
        } | null
      }
    | {
        __typename: 'VehicleIncidentNotification'
        id: string
        foreignId: string
        createdAt: string
        severity: Types.TaskNotificationSeverity
        notificationType: Types.TaskNotificationType
        taskStatus: Types.TaskNotificationStatus
        vehicle: {
          __typename?: 'Vehicle'
          id: string
          label: number | null
          isWheelchairAccessible: boolean | null
          upcomingWaypoints: Array<{
            __typename?: 'FccWaypoint'
            adState: Types.FccWaypointState | null
            id: string
            actions: Array<
              | {
                  __typename: 'FccWaypointDeliveryAction'
                  id: string
                  estimatedAt: string
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointHubReturnAction' }
              | {
                  __typename: 'FccWaypointPickupAction'
                  id: string
                  estimatedAt: string
                  adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointReallocationAction' }
            >
          }> | null
          openTaskNotificationAlerts: {
            __typename?: 'TaskNotificationList'
            total: number
          } | null
          openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
          alerts: {
            __typename?: 'TaskNotificationList'
            alerts: Array<
              | { __typename?: 'HubServiceNotification' }
              | { __typename?: 'IvcTaskNotification' }
              | {
                  __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
                  id: string
                  trip: {
                    __typename?: 'Trip'
                    id: string
                    customer: { __typename?: 'Customer'; id: string } | null
                  } | null
                }
              | { __typename?: 'PassengerUnbuckled' }
              | { __typename?: 'RoadBlockageTaskNotification' }
              | { __typename?: 'ShiftDeviationNotification' }
              | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
              | { __typename?: 'StopActivationFailedNotification' }
              | { __typename?: 'StopAreaMissingActiveStopsNotification' }
              | { __typename?: 'StopDownVotedByDrivers' }
              | { __typename?: 'StopPotentiallyUnreachable' }
              | { __typename?: 'StopValidationFailed' }
              | { __typename?: 'TimeManagementNotification' }
              | { __typename?: 'TripPrebookingNoAssignment' }
              | { __typename?: 'VehicleAvailabilityNotification' }
              | { __typename?: 'VehicleIncidentNotification' }
              | { __typename?: 'VehicleSurveillanceNotification' }
              | { __typename?: 'VehicleWaypointAttendance' }
            >
          } | null
        } | null
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          backofficeUserId: string | null
        } | null
      }
    | {
        __typename: 'VehicleSurveillanceNotification'
        id: string
        foreignId: string
        createdAt: string
        severity: Types.TaskNotificationSeverity
        notificationType: Types.TaskNotificationType
        taskStatus: Types.TaskNotificationStatus
        vehicle: {
          __typename?: 'Vehicle'
          id: string
          label: number | null
          isWheelchairAccessible: boolean | null
          upcomingWaypoints: Array<{
            __typename?: 'FccWaypoint'
            adState: Types.FccWaypointState | null
            id: string
            actions: Array<
              | {
                  __typename: 'FccWaypointDeliveryAction'
                  id: string
                  estimatedAt: string
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointHubReturnAction' }
              | {
                  __typename: 'FccWaypointPickupAction'
                  id: string
                  estimatedAt: string
                  adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointReallocationAction' }
            >
          }> | null
          openTaskNotificationAlerts: {
            __typename?: 'TaskNotificationList'
            total: number
          } | null
          openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
          alerts: {
            __typename?: 'TaskNotificationList'
            alerts: Array<
              | { __typename?: 'HubServiceNotification' }
              | { __typename?: 'IvcTaskNotification' }
              | {
                  __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
                  id: string
                  trip: {
                    __typename?: 'Trip'
                    id: string
                    customer: { __typename?: 'Customer'; id: string } | null
                  } | null
                }
              | { __typename?: 'PassengerUnbuckled' }
              | { __typename?: 'RoadBlockageTaskNotification' }
              | { __typename?: 'ShiftDeviationNotification' }
              | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
              | { __typename?: 'StopActivationFailedNotification' }
              | { __typename?: 'StopAreaMissingActiveStopsNotification' }
              | { __typename?: 'StopDownVotedByDrivers' }
              | { __typename?: 'StopPotentiallyUnreachable' }
              | { __typename?: 'StopValidationFailed' }
              | { __typename?: 'TimeManagementNotification' }
              | { __typename?: 'TripPrebookingNoAssignment' }
              | { __typename?: 'VehicleAvailabilityNotification' }
              | { __typename?: 'VehicleIncidentNotification' }
              | { __typename?: 'VehicleSurveillanceNotification' }
              | { __typename?: 'VehicleWaypointAttendance' }
            >
          } | null
        } | null
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          backofficeUserId: string | null
        } | null
      }
    | null
}

export type OperatorPromptTaskByIdQueryVariables = Types.Exact<{
  taskId: Types.Scalars['ID']['input']
}>

export type OperatorPromptTaskByIdQuery = {
  __typename?: 'Query'
  task:
    | { __typename?: 'HubServiceNotification' }
    | { __typename?: 'IvcTaskNotification' }
    | {
        __typename: 'PassengerAuthentificationFallbackRequestedNotification'
        id: string
        foreignId: string
        createdAt: string
        severity: Types.TaskNotificationSeverity
        notificationType: Types.TaskNotificationType
        taskStatus: Types.TaskNotificationStatus
        vehicle: {
          __typename?: 'Vehicle'
          id: string
          label: number | null
          isWheelchairAccessible: boolean | null
          upcomingWaypoints: Array<{
            __typename?: 'FccWaypoint'
            adState: Types.FccWaypointState | null
            id: string
            actions: Array<
              | {
                  __typename: 'FccWaypointDeliveryAction'
                  id: string
                  estimatedAt: string
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointHubReturnAction' }
              | {
                  __typename: 'FccWaypointPickupAction'
                  id: string
                  estimatedAt: string
                  adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointReallocationAction' }
            >
          }> | null
          openTaskNotificationAlerts: {
            __typename?: 'TaskNotificationList'
            total: number
          } | null
          openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
          alerts: {
            __typename?: 'TaskNotificationList'
            alerts: Array<
              | { __typename?: 'HubServiceNotification' }
              | { __typename?: 'IvcTaskNotification' }
              | {
                  __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
                  id: string
                  trip: {
                    __typename?: 'Trip'
                    id: string
                    customer: { __typename?: 'Customer'; id: string } | null
                  } | null
                }
              | { __typename?: 'PassengerUnbuckled' }
              | { __typename?: 'RoadBlockageTaskNotification' }
              | { __typename?: 'ShiftDeviationNotification' }
              | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
              | { __typename?: 'StopActivationFailedNotification' }
              | { __typename?: 'StopAreaMissingActiveStopsNotification' }
              | { __typename?: 'StopDownVotedByDrivers' }
              | { __typename?: 'StopPotentiallyUnreachable' }
              | { __typename?: 'StopValidationFailed' }
              | { __typename?: 'TimeManagementNotification' }
              | { __typename?: 'TripPrebookingNoAssignment' }
              | { __typename?: 'VehicleAvailabilityNotification' }
              | { __typename?: 'VehicleIncidentNotification' }
              | { __typename?: 'VehicleSurveillanceNotification' }
              | { __typename?: 'VehicleWaypointAttendance' }
            >
          } | null
        } | null
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          backofficeUserId: string | null
        } | null
      }
    | { __typename?: 'PassengerUnbuckled' }
    | { __typename?: 'RoadBlockageTaskNotification' }
    | { __typename?: 'ShiftDeviationNotification' }
    | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
    | { __typename?: 'StopActivationFailedNotification' }
    | { __typename?: 'StopAreaMissingActiveStopsNotification' }
    | { __typename?: 'StopDownVotedByDrivers' }
    | { __typename?: 'StopPotentiallyUnreachable' }
    | { __typename?: 'StopValidationFailed' }
    | { __typename?: 'TimeManagementNotification' }
    | { __typename?: 'TripPrebookingNoAssignment' }
    | { __typename?: 'VehicleAvailabilityNotification' }
    | {
        __typename: 'VehicleIncidentNotification'
        id: string
        foreignId: string
        createdAt: string
        severity: Types.TaskNotificationSeverity
        notificationType: Types.TaskNotificationType
        taskStatus: Types.TaskNotificationStatus
        vehicle: {
          __typename?: 'Vehicle'
          id: string
          label: number | null
          isWheelchairAccessible: boolean | null
          upcomingWaypoints: Array<{
            __typename?: 'FccWaypoint'
            adState: Types.FccWaypointState | null
            id: string
            actions: Array<
              | {
                  __typename: 'FccWaypointDeliveryAction'
                  id: string
                  estimatedAt: string
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointHubReturnAction' }
              | {
                  __typename: 'FccWaypointPickupAction'
                  id: string
                  estimatedAt: string
                  adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointReallocationAction' }
            >
          }> | null
          openTaskNotificationAlerts: {
            __typename?: 'TaskNotificationList'
            total: number
          } | null
          openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
          alerts: {
            __typename?: 'TaskNotificationList'
            alerts: Array<
              | { __typename?: 'HubServiceNotification' }
              | { __typename?: 'IvcTaskNotification' }
              | {
                  __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
                  id: string
                  trip: {
                    __typename?: 'Trip'
                    id: string
                    customer: { __typename?: 'Customer'; id: string } | null
                  } | null
                }
              | { __typename?: 'PassengerUnbuckled' }
              | { __typename?: 'RoadBlockageTaskNotification' }
              | { __typename?: 'ShiftDeviationNotification' }
              | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
              | { __typename?: 'StopActivationFailedNotification' }
              | { __typename?: 'StopAreaMissingActiveStopsNotification' }
              | { __typename?: 'StopDownVotedByDrivers' }
              | { __typename?: 'StopPotentiallyUnreachable' }
              | { __typename?: 'StopValidationFailed' }
              | { __typename?: 'TimeManagementNotification' }
              | { __typename?: 'TripPrebookingNoAssignment' }
              | { __typename?: 'VehicleAvailabilityNotification' }
              | { __typename?: 'VehicleIncidentNotification' }
              | { __typename?: 'VehicleSurveillanceNotification' }
              | { __typename?: 'VehicleWaypointAttendance' }
            >
          } | null
        } | null
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          backofficeUserId: string | null
        } | null
      }
    | {
        __typename: 'VehicleSurveillanceNotification'
        id: string
        foreignId: string
        createdAt: string
        severity: Types.TaskNotificationSeverity
        notificationType: Types.TaskNotificationType
        taskStatus: Types.TaskNotificationStatus
        vehicle: {
          __typename?: 'Vehicle'
          id: string
          label: number | null
          isWheelchairAccessible: boolean | null
          upcomingWaypoints: Array<{
            __typename?: 'FccWaypoint'
            adState: Types.FccWaypointState | null
            id: string
            actions: Array<
              | {
                  __typename: 'FccWaypointDeliveryAction'
                  id: string
                  estimatedAt: string
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointHubReturnAction' }
              | {
                  __typename: 'FccWaypointPickupAction'
                  id: string
                  estimatedAt: string
                  adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
                  trip: { __typename?: 'Trip'; id: string } | null
                  customer: {
                    __typename?: 'Customer'
                    firstName: string | null
                    lastName: string | null
                    extendedWalkingTime: boolean
                    visualImpairment: boolean | null
                  } | null
                  seats: {
                    __typename?: 'FccWaypointSeatInfo'
                    seats: number
                    wheelchairs: number
                    childSeats: number
                    boosterSeats: number
                  }
                }
              | { __typename?: 'FccWaypointReallocationAction' }
            >
          }> | null
          openTaskNotificationAlerts: {
            __typename?: 'TaskNotificationList'
            total: number
          } | null
          openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
          alerts: {
            __typename?: 'TaskNotificationList'
            alerts: Array<
              | { __typename?: 'HubServiceNotification' }
              | { __typename?: 'IvcTaskNotification' }
              | {
                  __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
                  id: string
                  trip: {
                    __typename?: 'Trip'
                    id: string
                    customer: { __typename?: 'Customer'; id: string } | null
                  } | null
                }
              | { __typename?: 'PassengerUnbuckled' }
              | { __typename?: 'RoadBlockageTaskNotification' }
              | { __typename?: 'ShiftDeviationNotification' }
              | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
              | { __typename?: 'StopActivationFailedNotification' }
              | { __typename?: 'StopAreaMissingActiveStopsNotification' }
              | { __typename?: 'StopDownVotedByDrivers' }
              | { __typename?: 'StopPotentiallyUnreachable' }
              | { __typename?: 'StopValidationFailed' }
              | { __typename?: 'TimeManagementNotification' }
              | { __typename?: 'TripPrebookingNoAssignment' }
              | { __typename?: 'VehicleAvailabilityNotification' }
              | { __typename?: 'VehicleIncidentNotification' }
              | { __typename?: 'VehicleSurveillanceNotification' }
              | { __typename?: 'VehicleWaypointAttendance' }
            >
          } | null
        } | null
        assigned: {
          __typename?: 'TaskStateChangeDetails'
          backofficeUserId: string | null
        } | null
      }
    | { __typename?: 'VehicleWaypointAttendance' }
    | null
}

export type OperatorPromptByIdAdAlertFragment = {
  __typename: 'FccAdAlert'
  id: string
  createdAt: string
  type: Types.FccAdAlertType
  adAlertStatus: Types.FccAdAlertStatus
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    label: number | null
    isWheelchairAccessible: boolean | null
    upcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      adState: Types.FccWaypointState | null
      id: string
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            id: string
            estimatedAt: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointHubReturnAction' }
        | {
            __typename: 'FccWaypointPickupAction'
            id: string
            estimatedAt: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointReallocationAction' }
      >
    }> | null
    openTaskNotificationAlerts: {
      __typename?: 'TaskNotificationList'
      total: number
    } | null
    openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
    alerts: {
      __typename?: 'TaskNotificationList'
      alerts: Array<
        | { __typename?: 'HubServiceNotification' }
        | { __typename?: 'IvcTaskNotification' }
        | {
            __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
            trip: {
              __typename?: 'Trip'
              id: string
              customer: { __typename?: 'Customer'; id: string } | null
            } | null
          }
        | { __typename?: 'PassengerUnbuckled' }
        | { __typename?: 'RoadBlockageTaskNotification' }
        | { __typename?: 'ShiftDeviationNotification' }
        | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
        | { __typename?: 'StopActivationFailedNotification' }
        | { __typename?: 'StopAreaMissingActiveStopsNotification' }
        | { __typename?: 'StopDownVotedByDrivers' }
        | { __typename?: 'StopPotentiallyUnreachable' }
        | { __typename?: 'StopValidationFailed' }
        | { __typename?: 'TimeManagementNotification' }
        | { __typename?: 'TripPrebookingNoAssignment' }
        | { __typename?: 'VehicleAvailabilityNotification' }
        | { __typename?: 'VehicleIncidentNotification' }
        | { __typename?: 'VehicleSurveillanceNotification' }
        | { __typename?: 'VehicleWaypointAttendance' }
      >
    } | null
  } | null
  assignee: { __typename?: 'BackofficeUser'; id: string } | null
}

export type OperatorPromptAdAlertByIdQueryVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
}>

export type OperatorPromptAdAlertByIdQuery = {
  __typename?: 'Query'
  adAlert: {
    __typename: 'FccAdAlert'
    id: string
    createdAt: string
    type: Types.FccAdAlertType
    adAlertStatus: Types.FccAdAlertStatus
    vehicle: {
      __typename?: 'Vehicle'
      id: string
      label: number | null
      isWheelchairAccessible: boolean | null
      upcomingWaypoints: Array<{
        __typename?: 'FccWaypoint'
        adState: Types.FccWaypointState | null
        id: string
        actions: Array<
          | {
              __typename: 'FccWaypointDeliveryAction'
              id: string
              estimatedAt: string
              trip: { __typename?: 'Trip'; id: string } | null
              customer: {
                __typename?: 'Customer'
                firstName: string | null
                lastName: string | null
                extendedWalkingTime: boolean
                visualImpairment: boolean | null
              } | null
              seats: {
                __typename?: 'FccWaypointSeatInfo'
                seats: number
                wheelchairs: number
                childSeats: number
                boosterSeats: number
              }
            }
          | { __typename?: 'FccWaypointHubReturnAction' }
          | {
              __typename: 'FccWaypointPickupAction'
              id: string
              estimatedAt: string
              adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
              trip: { __typename?: 'Trip'; id: string } | null
              customer: {
                __typename?: 'Customer'
                firstName: string | null
                lastName: string | null
                extendedWalkingTime: boolean
                visualImpairment: boolean | null
              } | null
              seats: {
                __typename?: 'FccWaypointSeatInfo'
                seats: number
                wheelchairs: number
                childSeats: number
                boosterSeats: number
              }
            }
          | { __typename?: 'FccWaypointReallocationAction' }
        >
      }> | null
      openTaskNotificationAlerts: {
        __typename?: 'TaskNotificationList'
        total: number
      } | null
      openAdAlerts: { __typename?: 'FccAdAlerts'; count: number } | null
      alerts: {
        __typename?: 'TaskNotificationList'
        alerts: Array<
          | { __typename?: 'HubServiceNotification' }
          | { __typename?: 'IvcTaskNotification' }
          | {
              __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
              id: string
              trip: {
                __typename?: 'Trip'
                id: string
                customer: { __typename?: 'Customer'; id: string } | null
              } | null
            }
          | { __typename?: 'PassengerUnbuckled' }
          | { __typename?: 'RoadBlockageTaskNotification' }
          | { __typename?: 'ShiftDeviationNotification' }
          | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
          | { __typename?: 'StopActivationFailedNotification' }
          | { __typename?: 'StopAreaMissingActiveStopsNotification' }
          | { __typename?: 'StopDownVotedByDrivers' }
          | { __typename?: 'StopPotentiallyUnreachable' }
          | { __typename?: 'StopValidationFailed' }
          | { __typename?: 'TimeManagementNotification' }
          | { __typename?: 'TripPrebookingNoAssignment' }
          | { __typename?: 'VehicleAvailabilityNotification' }
          | { __typename?: 'VehicleIncidentNotification' }
          | { __typename?: 'VehicleSurveillanceNotification' }
          | { __typename?: 'VehicleWaypointAttendance' }
        >
      } | null
    } | null
    assignee: { __typename?: 'BackofficeUser'; id: string } | null
  } | null
}

export type MarkPromptAsHandledMutationVariables = Types.Exact<{
  serviceAreaUUID: Types.Scalars['ID']['input']
  timestamp: Types.Scalars['DateTime']['input']
}>

export type MarkPromptAsHandledMutation = {
  __typename?: 'Mutation'
  fccMarkPromptAsHandled: {
    __typename?: 'FccOperatorState'
    availability: Types.FccOperatorAvailability
  }
}

export type TakeOverAdAlertMutationVariables = Types.Exact<{
  alertId: Types.Scalars['ID']['input']
}>

export type TakeOverAdAlertMutation = {
  __typename?: 'Mutation'
  takeOverOpenAlert: { __typename?: 'FccAdAlert'; id: string }
}

export const OperatorPromptTaskFragmentDoc = gql`
  fragment OperatorPromptTask on TaskNotification {
    id
    foreignId
    taskStatus: status
    createdAt
    severity
    notificationType
    assigned {
      backofficeUserId
    }
    ...GetAlertSeverityTaskNotification
  }
  ${GetAlertSeverityTaskNotificationFragmentDoc}
`
export const OperatorPromptVehicleFragmentDoc = gql`
  fragment OperatorPromptVehicle on Vehicle {
    id
    label
    isWheelchairAccessible
    ...AdWaypointPassengersVehicle
    ...AdOnBoardPassengersVehicle
    upcomingWaypoints: fccUpcomingWaypoints {
      adState
      ...AdWaypointPassengersWaypoint
    }
    openTaskNotificationAlerts: taskNotifications(
      types: [
        FC_PASSENGER_AUTHENTICATION_FALLBACK_REQUEST_NOTIFICATION
        FC_VEHICLE_CABIN_SAFETY_CHECK_REQUIRED_NOTIFICATION
      ]
      status: [NEW, IN_PROGRESS]
    ) {
      total
    }
    openAdAlerts: fccCurrentAdAlerts {
      count
    }
  }
  ${AdWaypointPassengersVehicleFragmentDoc}
  ${AdOnBoardPassengersVehicleFragmentDoc}
  ${AdWaypointPassengersWaypointFragmentDoc}
`
export const OperatorPromptAdAlertFragmentDoc = gql`
  fragment OperatorPromptAdAlert on FccAdAlert {
    id
    adAlertStatus: status
    createdAt
    type
    assignee {
      id
    }
    ...GetAlertSeverityAdAlert
  }
  ${GetAlertSeverityAdAlertFragmentDoc}
`
export const OperatorPromptAlertFragmentDoc = gql`
  fragment OperatorPromptAlert on FccOperatorAlert {
    ... on VehicleIncidentNotification {
      ...OperatorPromptTask
      vehicle {
        ...OperatorPromptVehicle
      }
    }
    ... on PassengerAuthentificationFallbackRequestedNotification {
      ...OperatorPromptTask
      vehicle {
        ...OperatorPromptVehicle
      }
    }
    ... on VehicleSurveillanceNotification {
      ...OperatorPromptTask
      vehicle {
        ...OperatorPromptVehicle
      }
    }
    ... on FccAdAlert {
      ...OperatorPromptAdAlert
      vehicle {
        ...OperatorPromptVehicle
      }
    }
  }
  ${OperatorPromptTaskFragmentDoc}
  ${OperatorPromptVehicleFragmentDoc}
  ${OperatorPromptAdAlertFragmentDoc}
`
export const OperatorPromptByIdAdAlertFragmentDoc = gql`
  fragment OperatorPromptByIdAdAlert on FccAdAlert {
    ...OperatorPromptAdAlert
    vehicle {
      ...OperatorPromptVehicle
    }
  }
  ${OperatorPromptAdAlertFragmentDoc}
  ${OperatorPromptVehicleFragmentDoc}
`
export const OperatorPromptMostUrgentAlertDocument = gql`
  query OperatorPromptMostUrgentAlert($serviceAreaUUID: ID!) {
    mostUrgentAlert: fccMostUrgentAlert(serviceAreaUUID: $serviceAreaUUID) {
      ...OperatorPromptAlert
    }
  }
  ${OperatorPromptAlertFragmentDoc}
`

/**
 * __useOperatorPromptMostUrgentAlertQuery__
 *
 * To run a query within a React component, call `useOperatorPromptMostUrgentAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorPromptMostUrgentAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorPromptMostUrgentAlertQuery({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *   },
 * });
 */
export function useOperatorPromptMostUrgentAlertQuery(
  baseOptions: Apollo.QueryHookOptions<
    OperatorPromptMostUrgentAlertQuery,
    OperatorPromptMostUrgentAlertQueryVariables
  > &
    (
      | {
          variables: OperatorPromptMostUrgentAlertQueryVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OperatorPromptMostUrgentAlertQuery,
    OperatorPromptMostUrgentAlertQueryVariables
  >(OperatorPromptMostUrgentAlertDocument, options)
}
export function useOperatorPromptMostUrgentAlertLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatorPromptMostUrgentAlertQuery,
    OperatorPromptMostUrgentAlertQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OperatorPromptMostUrgentAlertQuery,
    OperatorPromptMostUrgentAlertQueryVariables
  >(OperatorPromptMostUrgentAlertDocument, options)
}
export function useOperatorPromptMostUrgentAlertSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OperatorPromptMostUrgentAlertQuery,
        OperatorPromptMostUrgentAlertQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OperatorPromptMostUrgentAlertQuery,
    OperatorPromptMostUrgentAlertQueryVariables
  >(OperatorPromptMostUrgentAlertDocument, options)
}
export type OperatorPromptMostUrgentAlertQueryHookResult = ReturnType<
  typeof useOperatorPromptMostUrgentAlertQuery
>
export type OperatorPromptMostUrgentAlertLazyQueryHookResult = ReturnType<
  typeof useOperatorPromptMostUrgentAlertLazyQuery
>
export type OperatorPromptMostUrgentAlertSuspenseQueryHookResult = ReturnType<
  typeof useOperatorPromptMostUrgentAlertSuspenseQuery
>
export type OperatorPromptMostUrgentAlertQueryResult = Apollo.QueryResult<
  OperatorPromptMostUrgentAlertQuery,
  OperatorPromptMostUrgentAlertQueryVariables
>
export const OperatorPromptTaskByIdDocument = gql`
  query OperatorPromptTaskById($taskId: ID!) {
    task: taskNotificationById(id: $taskId) {
      ...OperatorPromptAlert
    }
  }
  ${OperatorPromptAlertFragmentDoc}
`

/**
 * __useOperatorPromptTaskByIdQuery__
 *
 * To run a query within a React component, call `useOperatorPromptTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorPromptTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorPromptTaskByIdQuery({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useOperatorPromptTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    OperatorPromptTaskByIdQuery,
    OperatorPromptTaskByIdQueryVariables
  > &
    (
      | { variables: OperatorPromptTaskByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OperatorPromptTaskByIdQuery,
    OperatorPromptTaskByIdQueryVariables
  >(OperatorPromptTaskByIdDocument, options)
}
export function useOperatorPromptTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatorPromptTaskByIdQuery,
    OperatorPromptTaskByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OperatorPromptTaskByIdQuery,
    OperatorPromptTaskByIdQueryVariables
  >(OperatorPromptTaskByIdDocument, options)
}
export function useOperatorPromptTaskByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OperatorPromptTaskByIdQuery,
        OperatorPromptTaskByIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OperatorPromptTaskByIdQuery,
    OperatorPromptTaskByIdQueryVariables
  >(OperatorPromptTaskByIdDocument, options)
}
export type OperatorPromptTaskByIdQueryHookResult = ReturnType<
  typeof useOperatorPromptTaskByIdQuery
>
export type OperatorPromptTaskByIdLazyQueryHookResult = ReturnType<
  typeof useOperatorPromptTaskByIdLazyQuery
>
export type OperatorPromptTaskByIdSuspenseQueryHookResult = ReturnType<
  typeof useOperatorPromptTaskByIdSuspenseQuery
>
export type OperatorPromptTaskByIdQueryResult = Apollo.QueryResult<
  OperatorPromptTaskByIdQuery,
  OperatorPromptTaskByIdQueryVariables
>
export const OperatorPromptAdAlertByIdDocument = gql`
  query OperatorPromptAdAlertById($alertId: ID!) {
    adAlert: fccAdAlertById(id: $alertId) {
      ...OperatorPromptByIdAdAlert
    }
  }
  ${OperatorPromptByIdAdAlertFragmentDoc}
`

/**
 * __useOperatorPromptAdAlertByIdQuery__
 *
 * To run a query within a React component, call `useOperatorPromptAdAlertByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperatorPromptAdAlertByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperatorPromptAdAlertByIdQuery({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useOperatorPromptAdAlertByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    OperatorPromptAdAlertByIdQuery,
    OperatorPromptAdAlertByIdQueryVariables
  > &
    (
      | { variables: OperatorPromptAdAlertByIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    OperatorPromptAdAlertByIdQuery,
    OperatorPromptAdAlertByIdQueryVariables
  >(OperatorPromptAdAlertByIdDocument, options)
}
export function useOperatorPromptAdAlertByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    OperatorPromptAdAlertByIdQuery,
    OperatorPromptAdAlertByIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    OperatorPromptAdAlertByIdQuery,
    OperatorPromptAdAlertByIdQueryVariables
  >(OperatorPromptAdAlertByIdDocument, options)
}
export function useOperatorPromptAdAlertByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        OperatorPromptAdAlertByIdQuery,
        OperatorPromptAdAlertByIdQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    OperatorPromptAdAlertByIdQuery,
    OperatorPromptAdAlertByIdQueryVariables
  >(OperatorPromptAdAlertByIdDocument, options)
}
export type OperatorPromptAdAlertByIdQueryHookResult = ReturnType<
  typeof useOperatorPromptAdAlertByIdQuery
>
export type OperatorPromptAdAlertByIdLazyQueryHookResult = ReturnType<
  typeof useOperatorPromptAdAlertByIdLazyQuery
>
export type OperatorPromptAdAlertByIdSuspenseQueryHookResult = ReturnType<
  typeof useOperatorPromptAdAlertByIdSuspenseQuery
>
export type OperatorPromptAdAlertByIdQueryResult = Apollo.QueryResult<
  OperatorPromptAdAlertByIdQuery,
  OperatorPromptAdAlertByIdQueryVariables
>
export const MarkPromptAsHandledDocument = gql`
  mutation MarkPromptAsHandled($serviceAreaUUID: ID!, $timestamp: DateTime!) {
    fccMarkPromptAsHandled(
      input: { serviceAreaUUID: $serviceAreaUUID, timestamp: $timestamp }
    ) {
      availability
    }
  }
`
export type MarkPromptAsHandledMutationFn = Apollo.MutationFunction<
  MarkPromptAsHandledMutation,
  MarkPromptAsHandledMutationVariables
>

/**
 * __useMarkPromptAsHandledMutation__
 *
 * To run a mutation, you first call `useMarkPromptAsHandledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkPromptAsHandledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markPromptAsHandledMutation, { data, loading, error }] = useMarkPromptAsHandledMutation({
 *   variables: {
 *      serviceAreaUUID: // value for 'serviceAreaUUID'
 *      timestamp: // value for 'timestamp'
 *   },
 * });
 */
export function useMarkPromptAsHandledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkPromptAsHandledMutation,
    MarkPromptAsHandledMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    MarkPromptAsHandledMutation,
    MarkPromptAsHandledMutationVariables
  >(MarkPromptAsHandledDocument, options)
}
export type MarkPromptAsHandledMutationHookResult = ReturnType<
  typeof useMarkPromptAsHandledMutation
>
export type MarkPromptAsHandledMutationResult =
  Apollo.MutationResult<MarkPromptAsHandledMutation>
export type MarkPromptAsHandledMutationOptions = Apollo.BaseMutationOptions<
  MarkPromptAsHandledMutation,
  MarkPromptAsHandledMutationVariables
>
export const TakeOverAdAlertDocument = gql`
  mutation TakeOverAdAlert($alertId: ID!) {
    takeOverOpenAlert(id: $alertId) {
      id
    }
  }
`
export type TakeOverAdAlertMutationFn = Apollo.MutationFunction<
  TakeOverAdAlertMutation,
  TakeOverAdAlertMutationVariables
>

/**
 * __useTakeOverAdAlertMutation__
 *
 * To run a mutation, you first call `useTakeOverAdAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeOverAdAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeOverAdAlertMutation, { data, loading, error }] = useTakeOverAdAlertMutation({
 *   variables: {
 *      alertId: // value for 'alertId'
 *   },
 * });
 */
export function useTakeOverAdAlertMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TakeOverAdAlertMutation,
    TakeOverAdAlertMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    TakeOverAdAlertMutation,
    TakeOverAdAlertMutationVariables
  >(TakeOverAdAlertDocument, options)
}
export type TakeOverAdAlertMutationHookResult = ReturnType<
  typeof useTakeOverAdAlertMutation
>
export type TakeOverAdAlertMutationResult =
  Apollo.MutationResult<TakeOverAdAlertMutation>
export type TakeOverAdAlertMutationOptions = Apollo.BaseMutationOptions<
  TakeOverAdAlertMutation,
  TakeOverAdAlertMutationVariables
>
