// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { BoumRight } from '@moia-dev/moia-token-claims'
import {
  VirtualAdVehiclesAreaId,
  VirtualAdVehiclesAreaRoutes,
  VirtualAdVehiclesAreaDataTestIds,
} from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { VirtualAdVehiclesAreaNavigation } from './VirtualAdVehiclesAreaNavigation'

const LazyVirtualAdVehiclesComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./VirtualAdVehiclesArea')),
)

const VirtualAdVehiclesComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyVirtualAdVehiclesComponent />
  </SuspenseLoadingWrapper>
)

export const VirtualAdVehiclesArea = {
  id: VirtualAdVehiclesAreaId,
  dataTestIds: VirtualAdVehiclesAreaDataTestIds,
  requiredRight: BoumRight.AREA_AD_FLEET_MAP,
  NavigationComponent: VirtualAdVehiclesAreaNavigation,
  route: {
    path: VirtualAdVehiclesAreaRoutes.route,
    component: VirtualAdVehiclesComponent,
  },
}
