import '@geoman-io/leaflet-geoman-free'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import L from 'leaflet'
// @ts-expect-error there are no type definitions for the lethargy library
import { Lethargy } from 'lethargy'
import { useCallback, useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { CustomMarkerTypes } from '@backoffice-frontend/common'
import { getMoiaMapMarkerIcon } from './MoiaMapUtils'

// constructor(stability?: number, sensitivity?: number, tolerance?: number)
const lethargy = new Lethargy(7, 50, 0.05)
const isInertialScroll = (e: WheelEvent) => lethargy.check(e) === false
/**
 * Control of the map zoom events that continue to occur if you swipe on the surface of a Magic Mouse, Trackpad,
 * and some other devices, and then release the finger. That's like giving a regular scroll wheel a push and letting it spin.
 * As long as the (virtual) wheel is spinning, zoom events continue to occur. This is called inertial scrolling (on the Mac).
 * https://github.com/Leaflet/Leaflet/issues/4410
 * https://github.com/pacificclimate/plan2adapt-v2/issues/144
 */
// @ts-expect-error TS is not picking the additional leaflet type definition
L.Map.ScrollWheelZoom.prototype._onWheelScroll = function scroll(e) {
  L.DomEvent.stop(e)
  if (lethargy.check(e) !== false) {
    if (isInertialScroll(e)) return
    this._delta += L.DomEvent.getWheelDelta(e)
    this._lastMousePos = this._map.mouseEventToContainerPoint(e)
    this._performZoom()
  }
}

// @ts-expect-error - TS is not picking the additional leaflet type definition from geoman-leaflet
L.PM.initialize()

export const MoiaLeafletMap = ({
  drawPolygon = false,
  drawMarker = false,
}: {
  drawPolygon?: boolean
  drawMarker?: boolean
}) => {
  const map = useMap()

  const configureLeafletPm = useCallback(() => {
    if (!map.pm) {
      return
    }

    map.pm.addControls({
      position: 'topleft',
      drawMarker,
      drawPolygon,
      drawCircle: false,
      drawCircleMarker: false,
      drawPolyline: false,
      drawRectangle: false,
      editMode: false,
      dragMode: false,
      cutPolygon: false,
      removalMode: false,
      rotateMode: false,
      drawText: false,
    })

    // Customizing settings for the options added
    if (drawMarker) {
      map.pm.enableDraw('Marker', {
        tooltips: false,
        markerStyle: {
          icon: getMoiaMapMarkerIcon(
            CustomMarkerTypes.CUSTOMER_DISPLAY_LOCATION,
          ),
        },
      })
      map.pm.disableDraw('Marker')
    }
  }, [drawMarker, drawPolygon, map.pm])

  useEffect(() => {
    configureLeafletPm()
  }, [configureLeafletPm])

  return null
}
