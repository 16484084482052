// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { LazyAdAlertCardsTranslations } from '@backoffice-frontend/ad-alert-cards'
import { LazyAdOperatorCallTranslations } from '@backoffice-frontend/ad-operator-call'
import { LazyAdOperatorDrawerTranslations } from '@backoffice-frontend/ad-operator-drawer'
import { LazyAdOperatorModalsTranslations } from '@backoffice-frontend/ad-operator-modals'
import { LazyAdOperatorPromptsTranslations } from '@backoffice-frontend/ad-operator-prompts'
import { LazyAdOperatorVideoTranslations } from '@backoffice-frontend/ad-operator-video'
import { LazyAdPassengerListTranslations } from '@backoffice-frontend/ad-passenger-list'
import { LazyAdPoolingToggleTranslations } from '@backoffice-frontend/ad-pooling-toggle'
import { LazyAdTelematicsCardsTranslations } from '@backoffice-frontend/ad-telematics-cards'
import { LazyAlertCardsTranslations } from '@backoffice-frontend/alert-cards'
import { LazyAlertCardsCommonTranslations } from '@backoffice-frontend/alert-cards-common'
import { LazyAlertDrawerTranslations } from '@backoffice-frontend/alert-drawer'
import { LazyAlertsCommonTranslations } from '@backoffice-frontend/alerts-common'
import { LazyAreaComponentsTranslations } from '@backoffice-frontend/area-components'
import { LazyAuthenticatedAppViewTranslations } from '@backoffice-frontend/authenticated-app-view'
import { LazyBackofficeStatusTranslations } from '@backoffice-frontend/backoffice-status'
import { LazyBackofficeUpdatesTranslations } from '@backoffice-frontend/backoffice-updates'
import { LazyCustomerComponentsTranslations } from '@backoffice-frontend/customer-components'
import { LazyEmployeeAutocompleteFieldTranslations } from '@backoffice-frontend/employee-autocomplete-field'
import { LazyEmployeeIdentityTranslations } from '@backoffice-frontend/employee-identity'
import { LazyFeatureToggleTranslations } from '@backoffice-frontend/feature-toggle'
import { LazyFleetMapsCommonTranslations } from '@backoffice-frontend/fleet-maps-common'
import { LazyFormsTranslations } from '@backoffice-frontend/forms'
import { LazyLeafletMapCommonTranslations } from '@backoffice-frontend/leaflet-map-common'
import { LazyLoginViewTranslations } from '@backoffice-frontend/login-view'
import { LazyOperatorCallTranslations } from '@backoffice-frontend/operator-call'
import { LazyPatternsTranslations } from '@backoffice-frontend/patterns'
import { LazyPaymentComponentsTranslations } from '@backoffice-frontend/payment-components'
import { LazyReportIoLibTranslations } from '@backoffice-frontend/report-io-lib'
import { LazyServiceAreaPickerTranslations } from '@backoffice-frontend/service-area-picker'
import { LazyServiceCancellationTranslations } from '@backoffice-frontend/service-cancellation'
import { LazyServiceMetricsTranslations } from '@backoffice-frontend/service-metrics'
import { LazyShiftsEmployeeTranslations } from '@backoffice-frontend/shifts-employee'
import { LazyTablesTranslations } from '@backoffice-frontend/tables'
import { LazyTripComponentsTranslations } from '@backoffice-frontend/trip-components'
import { LazyUserManagementUiTranslations } from '@backoffice-frontend/user-management-ui'
import { LazyValidatorsTranslations } from '@backoffice-frontend/validators'
import { LazyVehicleChecksTranslations } from '@backoffice-frontend/vehicle-checks'
import { LazyVehicleCommonTranslations } from '@backoffice-frontend/vehicle-common'
import { LazyVehicleMissionTranslations } from '@backoffice-frontend/vehicle-mission'

export const IncludeSharedLibTranslations = () => {
  return (
    <>
      <LazyServiceAreaPickerTranslations />
      <LazyAreaComponentsTranslations />
      <LazyAdOperatorCallTranslations />
      <LazyAdOperatorVideoTranslations />
      <LazyServiceMetricsTranslations />
      <LazyOperatorCallTranslations />
      <LazyAdOperatorDrawerTranslations />
      <LazyAdOperatorModalsTranslations />
      <LazyAdOperatorPromptsTranslations />
      <LazyAdPassengerListTranslations />
      <LazyAdPoolingToggleTranslations />
      <LazyAdTelematicsCardsTranslations />
      <LazyAlertCardsTranslations />
      <LazyAlertCardsCommonTranslations />
      <LazyAdAlertCardsTranslations />
      <LazyAlertDrawerTranslations />
      <LazyFeatureToggleTranslations />
      <LazyValidatorsTranslations />
      <LazyFleetMapsCommonTranslations />
      <LazyLoginViewTranslations />
      <LazyAuthenticatedAppViewTranslations />
      <LazyPatternsTranslations />
      <LazyFormsTranslations />
      <LazyTablesTranslations />
      <LazyLeafletMapCommonTranslations />
      <LazyVehicleCommonTranslations />
      <LazyServiceCancellationTranslations />
      <LazyUserManagementUiTranslations />
      <LazyBackofficeUpdatesTranslations />
      <LazyBackofficeStatusTranslations />
      <LazyEmployeeIdentityTranslations />
      <LazyShiftsEmployeeTranslations />
      <LazyAlertsCommonTranslations />
      <LazyCustomerComponentsTranslations />
      <LazyPaymentComponentsTranslations />
      <LazyVehicleMissionTranslations />
      <LazyTripComponentsTranslations />
      <LazyEmployeeAutocompleteFieldTranslations />
      <LazyReportIoLibTranslations />
      <LazyVehicleChecksTranslations />
    </>
  )
}
