/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import { AdOnBoardPassengersVehicleFragmentDoc } from '../../../../../ad-passenger-list/src/lib/AdOnBoardPassengers/AdOnBoardPassengers.hook'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'
import {
  AlertVehicleMapVehicleFragmentDoc,
  AlertVehicleMapWaypointFragmentDoc,
} from '../AlertVehicleMap/AlertVehicleMap.hook'
import { AlertsModalSkeletonVehicleFragmentDoc } from '../components/AlertsModalSkeleton/AlertsModalSkeleton.hook'

const defaultOptions = {} as const
export type CallModalQueryVariables = Types.Exact<{
  vehicleId: Types.Scalars['ID']['input']
  taskNotificationTypes:
    | Array<Types.TaskNotificationType>
    | Types.TaskNotificationType
  taskNotificationStatus:
    | Array<Types.TaskNotificationStatus>
    | Types.TaskNotificationStatus
}>

export type CallModalQuery = {
  __typename?: 'Query'
  vehicle: {
    __typename?: 'Vehicle'
    id: string
    isWheelchairAccessible: boolean | null
    isSelfDriving: boolean
    label: number | null
    upcomingWaypoints: Array<{
      __typename?: 'FccWaypoint'
      actions: Array<
        | {
            __typename: 'FccWaypointDeliveryAction'
            estimatedAt: string
            id: string
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
        | {
            __typename: 'FccWaypointPickupAction'
            estimatedAt: string
            id: string
            adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
            trip: { __typename?: 'Trip'; id: string } | null
            customer: {
              __typename?: 'Customer'
              firstName: string | null
              lastName: string | null
              extendedWalkingTime: boolean
              visualImpairment: boolean | null
            } | null
            seats: {
              __typename?: 'FccWaypointSeatInfo'
              seats: number
              wheelchairs: number
              childSeats: number
              boosterSeats: number
            }
          }
        | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
      >
      stop: {
        __typename?: 'Stop'
        routingLocation: {
          __typename?: 'Location'
          latitude: number
          longitude: number
        }
      } | null
    }> | null
    latestLocation: {
      __typename?: 'VehicleLocation'
      heading: number | null
      location: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      } | null
    } | null
    latestADTelemetry: {
      __typename?: 'ADTelemetry'
      rightSlidingDoorOpen: {
        __typename?: 'ADTelemetryBooleanWithTimestamp'
        value: boolean
        timestamp: string
      } | null
      speed: {
        __typename?: 'ADTelemetryFloatWithTimestamp'
        value: number
        timestamp: string
      } | null
    } | null
    taskNotifications: {
      __typename?: 'TaskNotificationList'
      tasks: Array<
        | {
            __typename: 'HubServiceNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'IvcTaskNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'PassengerAuthentificationFallbackRequestedNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'PassengerUnbuckled'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'RoadBlockageTaskNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'ShiftDeviationNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopAccessibilityDownvotedByDrivers'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopActivationFailedNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopAreaMissingActiveStopsNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopDownVotedByDrivers'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopPotentiallyUnreachable'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'StopValidationFailed'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'TimeManagementNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'TripPrebookingNoAssignment'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleAvailabilityNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleIncidentNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleSurveillanceNotification'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
        | {
            __typename: 'VehicleWaypointAttendance'
            id: string
            createdAt: string
            severity: Types.TaskNotificationSeverity
            type: Types.TaskNotificationType
          }
      >
    } | null
    fccCurrentAdAlerts: {
      __typename?: 'FccAdAlerts'
      alerts: Array<{
        __typename: 'FccAdAlert'
        id: string
        createdAt: string
        type: Types.FccAdAlertType
      }>
    } | null
    pooling: { __typename?: 'Pooling'; enabled: boolean } | null
  }
}

export const CallModalDocument = gql`
  query CallModal(
    $vehicleId: ID!
    $taskNotificationTypes: [TaskNotificationType!]!
    $taskNotificationStatus: [TaskNotificationStatus!]!
  ) {
    vehicle: vehicleByID(id: $vehicleId) {
      ...AlertsModalSkeletonVehicle
      ...AdOnBoardPassengersVehicle
      ...AlertVehicleMapVehicle
    }
  }
  ${AlertsModalSkeletonVehicleFragmentDoc}
  ${AdOnBoardPassengersVehicleFragmentDoc}
  ${AlertVehicleMapVehicleFragmentDoc}
`

/**
 * __useCallModalQuery__
 *
 * To run a query within a React component, call `useCallModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCallModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCallModalQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      taskNotificationTypes: // value for 'taskNotificationTypes'
 *      taskNotificationStatus: // value for 'taskNotificationStatus'
 *   },
 * });
 */
export function useCallModalQuery(
  baseOptions: Apollo.QueryHookOptions<
    CallModalQuery,
    CallModalQueryVariables
  > &
    (
      | { variables: CallModalQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<CallModalQuery, CallModalQueryVariables>(
    CallModalDocument,
    options,
  )
}
export function useCallModalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CallModalQuery,
    CallModalQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<CallModalQuery, CallModalQueryVariables>(
    CallModalDocument,
    options,
  )
}
export function useCallModalSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<CallModalQuery, CallModalQueryVariables>,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<CallModalQuery, CallModalQueryVariables>(
    CallModalDocument,
    options,
  )
}
export type CallModalQueryHookResult = ReturnType<typeof useCallModalQuery>
export type CallModalLazyQueryHookResult = ReturnType<
  typeof useCallModalLazyQuery
>
export type CallModalSuspenseQueryHookResult = ReturnType<
  typeof useCallModalSuspenseQuery
>
export type CallModalQueryResult = Apollo.QueryResult<
  CallModalQuery,
  CallModalQueryVariables
>
