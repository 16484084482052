import { useTranslation } from 'react-i18next'
import { AlertsAreaId } from '@backoffice-frontend/alerts'
import { AlertsTestingAreaId } from '@backoffice-frontend/alerts-testing'
import {
  AccidentsReportsAreaId,
  AccountingAreaId,
  AdConfigurationAreaId,
  AdFleetMapAreaId,
  CapacityPlanningAreaId,
  CouponAreaId,
  CustomerAreaId,
  CustomerFeatureAllowlistingAreaId,
  DisruptionsAreaId,
  DriverTippingAreaId,
  DsBossAreaId,
  EmployeeAreaId,
  EmployeeTimeManagementAreaId,
  FleetMaintenanceAreaId,
  FleetManagementTestingAreaId,
  FleetMapAreaId,
  HubsAreaId,
  PassengerAppAreaId,
  PatternLibraryAreaId,
  PricingAreaId,
  SafetyDriverInterfaceAreaId,
  ServiceAreasAreaId,
  ServiceHoursAreaId,
  ShiftsAreaId,
  ShiftsCreationAreaId,
  StopNetworkAlertsAreaId,
  StopNetworkAreaId,
  TripAreaId,
  VehicleAlertsAreaId,
  VehicleAreaId,
  VehicleGroupAreaId,
  VirtualAdVehiclesAreaId,
} from '@backoffice-frontend/common'
import { UserManagementAreaId } from '@backoffice-frontend/user-management'
import { AuthenticatedAppViewAreaId } from '../../AuthenticatedAppViewAreaId'

export const useGetAreaTranslation = () => {
  const { t } = useTranslation(AuthenticatedAppViewAreaId)

  const getAreaTranslation = (id: string) => {
    switch (id) {
      case UserManagementAreaId:
        return t('User management')
      case AlertsAreaId:
        return t('HSC Alerts')
      case CapacityPlanningAreaId:
        return t('Capacity Planner')
      case EmployeeAreaId:
        return t('Employee')
      case ShiftsAreaId:
        return t('Shifts')
      case DriverTippingAreaId:
        return t('Driver Tipping')
      case EmployeeTimeManagementAreaId:
        return t('Time Management')
      case VehicleAlertsAreaId:
        return t('Vehicle Alerts')
      case FleetMaintenanceAreaId:
        return t('Fleet Maintenance')
      case VehicleAreaId:
        return t('Vehicles')
      case FleetMapAreaId:
        return t('Fleet Map')
      case AdFleetMapAreaId:
        return t('AD Fleet Map')
      case VehicleGroupAreaId:
        return t('Vehicle Configuration')
      case StopNetworkAreaId:
        return t('Stop Network')
      case StopNetworkAlertsAreaId:
        return t('Stop Network Alerts')
      case HubsAreaId:
        return t('Locations')
      case ServiceAreasAreaId:
        return t('Service Areas')
      case ServiceHoursAreaId:
        return t('Service Hours')
      case CustomerAreaId:
        return t('Customers')
      case TripAreaId:
        return t('Trip')
      case CouponAreaId:
        return t('Coupons')
      case AdConfigurationAreaId:
        return t('Vehicle advertisement manager')
      case AccountingAreaId:
        return t('Accounting')
      case PassengerAppAreaId:
        return t('Passenger App')
      case PricingAreaId:
        return t('Pricing')
      case AlertsTestingAreaId:
        return t('Alerts Testing')
      case PatternLibraryAreaId:
        return t('Pattern Library')
      case ShiftsCreationAreaId:
        return t('Shift management')
      case FleetManagementTestingAreaId:
        return t('Vehicle Testing')
      case VirtualAdVehiclesAreaId:
        return t('Virtual AD Vehicles')
      case DsBossAreaId:
        return t('Demand & Supply')
      case CustomerFeatureAllowlistingAreaId:
        return t('Feature Management')
      case AccidentsReportsAreaId:
        return t('Accidents')
      case SafetyDriverInterfaceAreaId:
        return t('Safety Driver Interface')
      case DisruptionsAreaId:
        return t('Disruptions')
      default:
        return id
    }
  }

  return { getAreaTranslation }
}
