/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeStatusDeleteMediaByFileNameMutationVariables =
  Types.Exact<{
    input: Types.BackofficeUpdateGetMediaFilePresignedUrlInput
  }>

export type BackofficeStatusDeleteMediaByFileNameMutation = {
  __typename?: 'Mutation'
  backofficeStatusDeleteMediaByFileName: {
    __typename?: 'BackofficeStatusDeleteMediaByFileNameResponse'
    success: boolean
  }
}

export const BackofficeStatusDeleteMediaByFileNameDocument = gql`
  mutation backofficeStatusDeleteMediaByFileName(
    $input: BackofficeUpdateGetMediaFilePresignedUrlInput!
  ) {
    backofficeStatusDeleteMediaByFileName(input: $input) {
      success
    }
  }
`
export type BackofficeStatusDeleteMediaByFileNameMutationFn =
  Apollo.MutationFunction<
    BackofficeStatusDeleteMediaByFileNameMutation,
    BackofficeStatusDeleteMediaByFileNameMutationVariables
  >

/**
 * __useBackofficeStatusDeleteMediaByFileNameMutation__
 *
 * To run a mutation, you first call `useBackofficeStatusDeleteMediaByFileNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusDeleteMediaByFileNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [backofficeStatusDeleteMediaByFileNameMutation, { data, loading, error }] = useBackofficeStatusDeleteMediaByFileNameMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBackofficeStatusDeleteMediaByFileNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    BackofficeStatusDeleteMediaByFileNameMutation,
    BackofficeStatusDeleteMediaByFileNameMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    BackofficeStatusDeleteMediaByFileNameMutation,
    BackofficeStatusDeleteMediaByFileNameMutationVariables
  >(BackofficeStatusDeleteMediaByFileNameDocument, options)
}
export type BackofficeStatusDeleteMediaByFileNameMutationHookResult =
  ReturnType<typeof useBackofficeStatusDeleteMediaByFileNameMutation>
export type BackofficeStatusDeleteMediaByFileNameMutationResult =
  Apollo.MutationResult<BackofficeStatusDeleteMediaByFileNameMutation>
export type BackofficeStatusDeleteMediaByFileNameMutationOptions =
  Apollo.BaseMutationOptions<
    BackofficeStatusDeleteMediaByFileNameMutation,
    BackofficeStatusDeleteMediaByFileNameMutationVariables
  >
