/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type UpdateCustomerMutationVariables = Types.Exact<{
  input: Types.UpdateCustomerInput
}>

export type UpdateCustomerMutation = {
  __typename?: 'Mutation'
  updateCustomer: {
    __typename?: 'UpdateCustomerResponse'
    data: {
      __typename?: 'UpdateCustomerResponseData'
      customer: {
        __typename?: 'Customer'
        id: string
        firstName: string | null
        lastName: string | null
        email: string | null
        severelyDisabled: {
          __typename?: 'SeverelyDisabled'
          validUntil: any | null
          eligibility: Types.Eligibility | null
        } | null
      }
    } | null
    errors: Array<{
      __typename?: 'UpdateCustomerResponseError'
      message: string
      path: Array<string>
    }> | null
  }
}

export const UpdateCustomerDocument = gql`
  mutation updateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      data {
        customer {
          id
          firstName
          lastName
          email
          severelyDisabled {
            validUntil
            eligibility
          }
        }
      }
      errors {
        message
        path
      }
    }
  }
`
export type UpdateCustomerMutationFn = Apollo.MutationFunction<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateCustomerMutation,
    UpdateCustomerMutationVariables
  >(UpdateCustomerDocument, options)
}
export type UpdateCustomerMutationHookResult = ReturnType<
  typeof useUpdateCustomerMutation
>
export type UpdateCustomerMutationResult =
  Apollo.MutationResult<UpdateCustomerMutation>
export type UpdateCustomerMutationOptions = Apollo.BaseMutationOptions<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>
