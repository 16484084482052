/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeStatusGetLastVisitQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeStatusGetLastVisitQuery = {
  __typename?: 'Query'
  backofficeStatusGetLastVisit: {
    __typename?: 'BackofficeStatusesLastVisitResponse'
    email: string
    lastVisitDate: string
  }
}

export const BackofficeStatusGetLastVisitDocument = gql`
  query backofficeStatusGetLastVisit {
    backofficeStatusGetLastVisit {
      email
      lastVisitDate
    }
  }
`

/**
 * __useBackofficeStatusGetLastVisitQuery__
 *
 * To run a query within a React component, call `useBackofficeStatusGetLastVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeStatusGetLastVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeStatusGetLastVisitQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeStatusGetLastVisitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BackofficeStatusGetLastVisitQuery,
    BackofficeStatusGetLastVisitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeStatusGetLastVisitQuery,
    BackofficeStatusGetLastVisitQueryVariables
  >(BackofficeStatusGetLastVisitDocument, options)
}
export function useBackofficeStatusGetLastVisitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeStatusGetLastVisitQuery,
    BackofficeStatusGetLastVisitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeStatusGetLastVisitQuery,
    BackofficeStatusGetLastVisitQueryVariables
  >(BackofficeStatusGetLastVisitDocument, options)
}
export function useBackofficeStatusGetLastVisitSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeStatusGetLastVisitQuery,
        BackofficeStatusGetLastVisitQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeStatusGetLastVisitQuery,
    BackofficeStatusGetLastVisitQueryVariables
  >(BackofficeStatusGetLastVisitDocument, options)
}
export type BackofficeStatusGetLastVisitQueryHookResult = ReturnType<
  typeof useBackofficeStatusGetLastVisitQuery
>
export type BackofficeStatusGetLastVisitLazyQueryHookResult = ReturnType<
  typeof useBackofficeStatusGetLastVisitLazyQuery
>
export type BackofficeStatusGetLastVisitSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeStatusGetLastVisitSuspenseQuery
>
export type BackofficeStatusGetLastVisitQueryResult = Apollo.QueryResult<
  BackofficeStatusGetLastVisitQuery,
  BackofficeStatusGetLastVisitQueryVariables
>
