/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type PassengerCardCustomerFragment = {
  __typename?: 'Customer'
  firstName: string | null
  lastName: string | null
  extendedWalkingTime: boolean
  visualImpairment: boolean | null
}

export type PassengerCardSeatInfoFragment = {
  __typename?: 'FccWaypointSeatInfo'
  seats: number
  wheelchairs: number
  childSeats: number
  boosterSeats: number
}

export type ConfirmManualNoShowMutationVariables = Types.Exact<{
  tripId: Types.Scalars['ID']['input']
}>

export type ConfirmManualNoShowMutation = {
  __typename?: 'Mutation'
  fccConfirmManualNoShow: any | null
}

export type ConfirmManualCheckInMutationVariables = Types.Exact<{
  tripId: Types.Scalars['ID']['input']
}>

export type ConfirmManualCheckInMutation = {
  __typename?: 'Mutation'
  fccManuallyCheckInPassenger: any | null
}

export type PassengerAuthenticationCardStateSubscriptionSubscriptionVariables =
  Types.Exact<{
    tripId: Types.Scalars['ID']['input']
  }>

export type PassengerAuthenticationCardStateSubscriptionSubscription = {
  __typename?: 'Subscription'
  fccPassengerAuthenticationState: {
    __typename?: 'FccPassengerAuthentication'
    tripId: string
    state: Types.FccPassengerCheckInStatus | null
    authenticationError: Types.FccPassengerAuthenticationError | null
  }
}

export const PassengerCardCustomerFragmentDoc = gql`
  fragment PassengerCardCustomer on Customer {
    firstName
    lastName
    extendedWalkingTime
    visualImpairment
  }
`
export const PassengerCardSeatInfoFragmentDoc = gql`
  fragment PassengerCardSeatInfo on FccWaypointSeatInfo {
    seats
    wheelchairs
    childSeats
    boosterSeats
  }
`
export const ConfirmManualNoShowDocument = gql`
  mutation confirmManualNoShow($tripId: ID!) {
    fccConfirmManualNoShow(tripId: $tripId)
  }
`
export type ConfirmManualNoShowMutationFn = Apollo.MutationFunction<
  ConfirmManualNoShowMutation,
  ConfirmManualNoShowMutationVariables
>

/**
 * __useConfirmManualNoShowMutation__
 *
 * To run a mutation, you first call `useConfirmManualNoShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmManualNoShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmManualNoShowMutation, { data, loading, error }] = useConfirmManualNoShowMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useConfirmManualNoShowMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmManualNoShowMutation,
    ConfirmManualNoShowMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmManualNoShowMutation,
    ConfirmManualNoShowMutationVariables
  >(ConfirmManualNoShowDocument, options)
}
export type ConfirmManualNoShowMutationHookResult = ReturnType<
  typeof useConfirmManualNoShowMutation
>
export type ConfirmManualNoShowMutationResult =
  Apollo.MutationResult<ConfirmManualNoShowMutation>
export type ConfirmManualNoShowMutationOptions = Apollo.BaseMutationOptions<
  ConfirmManualNoShowMutation,
  ConfirmManualNoShowMutationVariables
>
export const ConfirmManualCheckInDocument = gql`
  mutation confirmManualCheckIn($tripId: ID!) {
    fccManuallyCheckInPassenger(tripId: $tripId)
  }
`
export type ConfirmManualCheckInMutationFn = Apollo.MutationFunction<
  ConfirmManualCheckInMutation,
  ConfirmManualCheckInMutationVariables
>

/**
 * __useConfirmManualCheckInMutation__
 *
 * To run a mutation, you first call `useConfirmManualCheckInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmManualCheckInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmManualCheckInMutation, { data, loading, error }] = useConfirmManualCheckInMutation({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function useConfirmManualCheckInMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmManualCheckInMutation,
    ConfirmManualCheckInMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ConfirmManualCheckInMutation,
    ConfirmManualCheckInMutationVariables
  >(ConfirmManualCheckInDocument, options)
}
export type ConfirmManualCheckInMutationHookResult = ReturnType<
  typeof useConfirmManualCheckInMutation
>
export type ConfirmManualCheckInMutationResult =
  Apollo.MutationResult<ConfirmManualCheckInMutation>
export type ConfirmManualCheckInMutationOptions = Apollo.BaseMutationOptions<
  ConfirmManualCheckInMutation,
  ConfirmManualCheckInMutationVariables
>
export const PassengerAuthenticationCardStateSubscriptionDocument = gql`
  subscription passengerAuthenticationCardStateSubscription($tripId: ID!) {
    fccPassengerAuthenticationState(tripId: $tripId) {
      tripId
      state
      authenticationError
    }
  }
`

/**
 * __usePassengerAuthenticationCardStateSubscriptionSubscription__
 *
 * To run a query within a React component, call `usePassengerAuthenticationCardStateSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePassengerAuthenticationCardStateSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePassengerAuthenticationCardStateSubscriptionSubscription({
 *   variables: {
 *      tripId: // value for 'tripId'
 *   },
 * });
 */
export function usePassengerAuthenticationCardStateSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    PassengerAuthenticationCardStateSubscriptionSubscription,
    PassengerAuthenticationCardStateSubscriptionSubscriptionVariables
  > &
    (
      | {
          variables: PassengerAuthenticationCardStateSubscriptionSubscriptionVariables
          skip?: boolean
        }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    PassengerAuthenticationCardStateSubscriptionSubscription,
    PassengerAuthenticationCardStateSubscriptionSubscriptionVariables
  >(PassengerAuthenticationCardStateSubscriptionDocument, options)
}
export type PassengerAuthenticationCardStateSubscriptionSubscriptionHookResult =
  ReturnType<typeof usePassengerAuthenticationCardStateSubscriptionSubscription>
export type PassengerAuthenticationCardStateSubscriptionSubscriptionResult =
  Apollo.SubscriptionResult<PassengerAuthenticationCardStateSubscriptionSubscription>
