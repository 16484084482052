import { Link } from 'react-router-dom'
import { WelcomeAreaRoutes } from '@backoffice-frontend/common'
import { MoiaLogo } from '@backoffice-frontend/patterns'

type NavigationDrawerLogoProps = {
  open: boolean
}

export const NavigationDrawerLogo = ({ open }: NavigationDrawerLogoProps) => (
  <div
    css={theme => ({
      alignContent: 'center',
      display: 'grid',
      justifyContent: 'center',
      minHeight: theme.headerHeight,
    })}
  >
    <Link
      css={{
        display: 'block',
        transition: 'width 200ms',
      }}
      to={WelcomeAreaRoutes.route}
    >
      <MoiaLogo
        css={theme => ({
          fill: theme.semantic.ColorContentDefault,
          ...(open
            ? {
                height: '28px',
                width: '126px',
              }
            : {
                height: '11px',
                width: '48px',
              }),
        })}
      />
    </Link>
  </div>
)
