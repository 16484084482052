import amplifyAuth, { CognitoUser } from '@aws-amplify/auth'
import type {
  CognitoUserPool,
  CognitoUserSession,
  CognitoIdToken,
  CookieStorage,
} from 'amazon-cognito-identity-js'
import { jwtDecode } from 'jwt-decode'
import store from 'store'
import { Claims } from '@moia-dev/moia-token-claims'
import { Environment, ENV } from '@backoffice-frontend/environment'
import { MfaMethods, Routes } from '../const'
import {
  BACKOFFICE_EMAIL,
  BACKOFFICE_USERNAME,
  DARK_MODE_KEY,
  LAST_AUTHENTICATED_USER,
  MAP_FILTERS_VISIBLE_KEY,
  NAV_OPEN_FLAG_KEY,
  POOLING_MAP_TOGGLE_TRAFFIC_LAYER_KEY,
  REACT_I18NEXT_LANGUAGE_KEY,
  SAML_LOGIN_FLOW,
  SAML_REDIRECT_TO,
  SERVICE_AREA_MANAGEMENT_SERVICE_AREA_ID_KEY,
} from '../const/config'
import * as amplifyAuthMock from './amplifyAuthMock'

export const resetPersistedUserSpecificValues = (currentUser: string) => {
  const lastUser = store.get(LAST_AUTHENTICATED_USER)
  store.set(LAST_AUTHENTICATED_USER, currentUser)

  const currentUserIsDifferent = lastUser && currentUser !== lastUser
  if (currentUserIsDifferent) {
    store.remove(SERVICE_AREA_MANAGEMENT_SERVICE_AREA_ID_KEY)
    store.remove(MAP_FILTERS_VISIBLE_KEY)
    store.remove(POOLING_MAP_TOGGLE_TRAFFIC_LAYER_KEY)
    store.remove(DARK_MODE_KEY)
    store.remove(NAV_OPEN_FLAG_KEY)
    store.remove(REACT_I18NEXT_LANGUAGE_KEY)
  }
}

export const setHasOktaSamlError = () => {
  store.set(SAML_LOGIN_FLOW, 'ERROR')
}

const setIsOktaSamlOngoing = (state: string) => {
  store.set(SAML_LOGIN_FLOW, state)
}

export const setOktaSamlRedirectTo = (redirectTo: string | null) => {
  store.set(SAML_REDIRECT_TO, redirectTo)
}
export const getOktaSamlRedirectTo = () => {
  return store.get(SAML_REDIRECT_TO)
}
export const getIsOktaSamlOngoing = () => {
  // typecast is fine here as we only set the value in this file
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  return store.get(SAML_LOGIN_FLOW) as '' | 'ONGOING' | 'ERROR'
}
// NOTE: set both variables only on configuration time, this needs to fail
//  fast if something is messed up in the initialization order of the application
let auth: typeof amplifyAuth

// please add a comment here or fix the issue
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type UserAttributes = Record<string, any>
export type LoginCredentials = { username: string; password: string }

export declare class MOIACognitoUser extends CognitoUser {
  public Session: string

  public authenticationFlowType: string

  public challengeParam: Record<string, string>

  public keyPrefix: string

  public pool: CognitoUserPool

  public preferredMFA: MfaMethods

  public signInUserSession: string | null

  public storage: CookieStorage

  public userDataKey: string

  public username: string
}
const getRedirectBase = (path = '') => {
  const { host, protocol } = window.location
  return `${protocol}//${host}/${path}`
}
export type CognitoConfig = {
  region: string
  userPoolId: string
  userPoolWebClientId: string
  domain: string
}
export const configureAmplify = (config: CognitoConfig): typeof amplifyAuth => {
  const { region, userPoolId, userPoolWebClientId, domain } = config
  auth =
    ENV === Environment.local || ENV === Environment.test
      ? // ignore types on the mock
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
        (amplifyAuthMock as any)
      : amplifyAuth
  auth.configure({
    region,
    userPoolId,
    userPoolWebClientId,

    oauth: {
      domain,
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: getRedirectBase('login/'),
      redirectSignOut: getRedirectBase(),
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  })

  return auth
}

export const changePassword = (
  oldPassword: string,
  newPassword: string,
): Promise<'SUCCESS'> =>
  auth
    .currentAuthenticatedUser()
    .then(user => auth.changePassword(user, oldPassword, newPassword))

const getCurrentAuthenticatedUser = async () => auth.currentAuthenticatedUser()

export const setUpTOTP = async (): Promise<string> => {
  const user = await getCurrentAuthenticatedUser()
  return auth.setupTOTP(user)
}

export const getUserPreferredMFA = async (): Promise<string> => {
  const user = await getCurrentAuthenticatedUser()
  return auth.getPreferredMFA(user)
}

export const verifyTotpToken = async (
  challengeAnswer: string,
): Promise<string> => {
  const user = await getCurrentAuthenticatedUser()
  // please add a comment here or fix the issue
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const verifyRes = (await auth.verifyTotpToken(user, challengeAnswer)) as {
    Status?: string
  }
  if (verifyRes.Status === 'SUCCESS') {
    return auth.setPreferredMFA(user, MfaMethods.TOTP)
  }
  return 'FALSE'
}

let challengedUser: MOIACognitoUser | undefined

export const isOktaUser = async () => {
  const info = await auth.currentUserInfo()
  return info.username.startsWith('Okta_')
}
export const hasNoMFA = async () => {
  const mfaMethod = await getUserPreferredMFA()
  const oktaUser = await isOktaUser()
  const claims = await getClaims()
  if (oktaUser) {
    return false
  }
  return !claims.boum.isSystemUser() && mfaMethod === MfaMethods.NOMFA
}

export const setChallengedUser = (user: MOIACognitoUser | undefined): void => {
  challengedUser = user
}

export const login = async (
  credentials: LoginCredentials,
): Promise<MOIACognitoUser> => {
  const { username, password } = credentials

  setIsOktaSamlOngoing('')
  const user = await auth.signIn(username.trim(), password)
  if (user?.challengeName === 'NEW_PASSWORD_REQUIRED') {
    setChallengedUser(user)
  }
  return user
}

export const confirmSignIn = async ({
  code,
  user,
}: {
  code: string
  user: string
}): Promise<MOIACognitoUser> =>
  auth.confirmSignIn(user, code, MfaMethods.SOFTWARE_TOKEN_MFA)

export const pathnameRedirectSearchKey = 'next'
const createLogoutRedirectUrl = () => {
  const { pathname, hash, search } = window.location
  const redirectUrl = `${pathname}${search}${hash}`
  return redirectUrl
}

export const cleanUpLocalStorage = () => {
  store.each((_, key) => {
    if (!(key.startsWith(`moia.`) || key === REACT_I18NEXT_LANGUAGE_KEY)) {
      store.remove(key)
    }
  })
  store.remove(BACKOFFICE_EMAIL)
}

export const logout = async (): Promise<void> => {
  console.info('starting logout')
  if (
    !(
      window.location.pathname === Routes.Auth.Login.url ||
      window.location.pathname === '/'
    )
  ) {
    console.info('updating route', createLogoutRedirectUrl())
    setOktaSamlRedirectTo(createLogoutRedirectUrl())
  }
  setIsOktaSamlOngoing('')
  store.set(BACKOFFICE_USERNAME, '')
  cleanUpLocalStorage()
  await auth.signOut()

  // saml logout will trigger a redirect to /
  // the regular cognito logout won't do that so we need to trigger it manually
  // but only if we are not on the root or login page already else we will end up in a redirection cycle
  // as AuthContext.ts will attempt a logout if the user is not authenticated as well
  if (
    !(
      window.location.pathname === Routes.Auth.Login.url ||
      window.location.pathname === '/'
    )
  ) {
    window.location.pathname = '/'
  }
}

const onAuthenticationError = async (error: Error) => {
  setIsOktaSamlOngoing('')
  console.error('onAuthenticationError:', error)

  return logout()
}

const isCloseToExpiration = (session: CognitoUserSession) => {
  const token = session.getIdToken()
  const tokenExpirationTimestamp = token.getExpiration()
  const nowTimestamp = Date.now() / 1000 // converting to seconds

  const secondsUntilExpiration = tokenExpirationTimestamp - nowTimestamp
  const tokenIsCloseToExpiration = secondsUntilExpiration < 30

  return tokenIsCloseToExpiration
}

const refreshToken = async (session: CognitoUserSession) => {
  const refreshToken = session.getRefreshToken()
  const user = await auth.currentAuthenticatedUser()

  return new Promise<CognitoIdToken>((resolve, reject) =>
    user.refreshSession(
      refreshToken,
      (error: Error, refreshedSession: CognitoUserSession) => {
        if (error) throw reject(error)
        else resolve(refreshedSession.getIdToken())
      },
    ),
  )
}

export type BOFToken = {
  readonly token: string
  readonly claims: Claims
}

export async function getJwtString(): Promise<string> {
  return (await getXBOFToken()).token
}

export async function getClaims(): Promise<Claims> {
  return (await getXBOFToken()).claims
}

export function getXBOFToken(): Promise<BOFToken> {
  return new Promise((rs, rj) => {
    auth
      .currentSession()
      .then(session => {
        // The `currentSession` automatically refreshes the token when it expires, but **not**
        // when the token is close to expiration. This has lead to issues before where tokens would
        // expire during a request which resulted in `401` errors. To prevent this we are manually
        // refreshing the token when it is close (30 seconds) to expiration.
        if (isCloseToExpiration(session)) {
          refreshToken(session).then(token =>
            rs({
              token: token.getJwtToken(),
              claims: Claims.fromWireClaims(jwtDecode(token.getJwtToken()), {
                defaultBOUM: {},
                defaultCognito: {},
                defaultEmployee: {},
                defaultOpenID: {},
              }),
            }),
          )
        } else {
          rs({
            token: session.getIdToken().getJwtToken(),
            claims: Claims.fromWireClaims(
              jwtDecode(session.getIdToken().getJwtToken()),
              {
                defaultBOUM: {},
                defaultCognito: {},
                defaultEmployee: {},
                defaultOpenID: {},
              },
            ),
          })
        }
      })
      .catch(e => {
        void onAuthenticationError(e)
        rj(e)
      })
  })
}

export const refreshClaims = async (): Promise<Claims | undefined> => {
  const session = await auth.currentSession()
  const refresh = session.getRefreshToken()
  const currentUser = await auth.currentAuthenticatedUser()
  return new Promise<Claims>((resolve, reject) => {
    currentUser.refreshSession(
      refresh,
      (_error: Error, cognitoUserSession: CognitoUserSession) => {
        if (cognitoUserSession) {
          try {
            const claims = Claims.fromWireClaims(
              jwtDecode(cognitoUserSession.getIdToken().getJwtToken()),
              {
                defaultBOUM: {},
                defaultCognito: {},
                defaultEmployee: {},
                defaultOpenID: {},
              },
            )
            resolve(claims)
          } catch (e) {
            reject(e)
          }
        } else {
          reject(new Error('could not refresh id token'))
        }
      },
    )
  })
}

export const getUsername = async (): Promise<string | undefined> => {
  try {
    const claims = await getClaims()
    const username = claims.boum.backofficeUsername()
    store.set(BACKOFFICE_USERNAME, username)
    return username
  } catch (e) {
    // typecast unkown to Error
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    void onAuthenticationError(e as Error)
    return undefined
  }
}

export const getCachedEmail = async () => {
  const cachedEmail = store.get(BACKOFFICE_EMAIL)
  if (cachedEmail) {
    return cachedEmail
  }

  const email = await getUserEmail()
  return email
}

const getUserEmail = async (): Promise<string | undefined> => {
  try {
    const claims = await getClaims()
    const email = claims.cognito.email()
    store.set(BACKOFFICE_EMAIL, email)
    return email
  } catch (e) {
    // typecast unkown to Error
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    void onAuthenticationError(e as Error)
    return undefined
  }
}

// needs to be a then promise as async await will break the current mocking setup :/
export const isAuthenticated = (): Promise<MOIACognitoUser | null> => {
  return auth
    .currentUserPoolUser()
    .then((user: MOIACognitoUser | null) => {
      setIsOktaSamlOngoing('')
      return user
    })
    .catch(error => {
      console.error('onAuthenticationError:', error)
      return null
    })
}

export const challengePassword = (newPassword: string): Promise<void> => {
  if (challengedUser) {
    const requiredAttributes = {
      // please add a comment here or fix the issue
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @typescript-eslint/no-explicit-any
      ...(challengedUser as any).challengeParam.userAttributes,
    }
    delete requiredAttributes.email_verified
    delete requiredAttributes.phone_number_verified
    return auth
      .completeNewPassword(challengedUser, newPassword, requiredAttributes)
      .then(() => {
        setChallengedUser(undefined)
      })
  }
  return Promise.reject(new Error('no challenged user'))
}

export const forgotPassword = (username: string): Promise<void> =>
  auth.forgotPassword(username)

export const confirmForgotPassword = (
  username: string,
  code: string,
  newPassword: string,
) => auth.forgotPasswordSubmit(username, code, newPassword)

export const loginWithOkta = (): Promise<unknown> => {
  if (ENV === Environment.local || ENV === Environment.test) {
    alert(
      "You are trying to login with Okta on a local or test environment. This is currently not supported. Either login without Okta or use e.g. setEnvironment('integration') in the browser's console to switch to a different environment.",
    )
    return Promise.resolve()
  }

  setIsOktaSamlOngoing('ONGOING')
  // @ts-expect-error amplify types don't allow custom providers
  return auth.federatedSignIn({ provider: 'Okta' })
}
