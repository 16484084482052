/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type BackofficeUpdateGetLastVisitQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type BackofficeUpdateGetLastVisitQuery = {
  __typename?: 'Query'
  backofficeUpdateGetLastVisit: {
    __typename?: 'BackofficeUpdatesLastVisitResponse'
    email: string
    lastVisitDate: string
  }
}

export const BackofficeUpdateGetLastVisitDocument = gql`
  query backofficeUpdateGetLastVisit {
    backofficeUpdateGetLastVisit {
      email
      lastVisitDate
    }
  }
`

/**
 * __useBackofficeUpdateGetLastVisitQuery__
 *
 * To run a query within a React component, call `useBackofficeUpdateGetLastVisitQuery` and pass it any options that fit your needs.
 * When your component renders, `useBackofficeUpdateGetLastVisitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBackofficeUpdateGetLastVisitQuery({
 *   variables: {
 *   },
 * });
 */
export function useBackofficeUpdateGetLastVisitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    BackofficeUpdateGetLastVisitQuery,
    BackofficeUpdateGetLastVisitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    BackofficeUpdateGetLastVisitQuery,
    BackofficeUpdateGetLastVisitQueryVariables
  >(BackofficeUpdateGetLastVisitDocument, options)
}
export function useBackofficeUpdateGetLastVisitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    BackofficeUpdateGetLastVisitQuery,
    BackofficeUpdateGetLastVisitQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    BackofficeUpdateGetLastVisitQuery,
    BackofficeUpdateGetLastVisitQueryVariables
  >(BackofficeUpdateGetLastVisitDocument, options)
}
export function useBackofficeUpdateGetLastVisitSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        BackofficeUpdateGetLastVisitQuery,
        BackofficeUpdateGetLastVisitQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    BackofficeUpdateGetLastVisitQuery,
    BackofficeUpdateGetLastVisitQueryVariables
  >(BackofficeUpdateGetLastVisitDocument, options)
}
export type BackofficeUpdateGetLastVisitQueryHookResult = ReturnType<
  typeof useBackofficeUpdateGetLastVisitQuery
>
export type BackofficeUpdateGetLastVisitLazyQueryHookResult = ReturnType<
  typeof useBackofficeUpdateGetLastVisitLazyQuery
>
export type BackofficeUpdateGetLastVisitSuspenseQueryHookResult = ReturnType<
  typeof useBackofficeUpdateGetLastVisitSuspenseQuery
>
export type BackofficeUpdateGetLastVisitQueryResult = Apollo.QueryResult<
  BackofficeUpdateGetLastVisitQuery,
  BackofficeUpdateGetLastVisitQueryVariables
>
