/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioEmployeeQueryVariables = Types.Exact<{
  employeeId: Types.Scalars['ID']['input']
}>

export type RioEmployeeQuery = {
  __typename?: 'Query'
  employee: {
    __typename?: 'Employee'
    firstName: string | null
    id: string
    lastName: string | null
    serviceAreaId: string | null
    hub: { __typename?: 'Hub'; id: string } | null
  }
}

export const RioEmployeeDocument = gql`
  query RioEmployee($employeeId: ID!) {
    employee(id: $employeeId) {
      firstName
      id
      lastName
      hub {
        id
      }
      serviceAreaId
    }
  }
`

/**
 * __useRioEmployeeQuery__
 *
 * To run a query within a React component, call `useRioEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioEmployeeQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *   },
 * });
 */
export function useRioEmployeeQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioEmployeeQuery,
    RioEmployeeQueryVariables
  > &
    (
      | { variables: RioEmployeeQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<RioEmployeeQuery, RioEmployeeQueryVariables>(
    RioEmployeeDocument,
    options,
  )
}
export function useRioEmployeeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioEmployeeQuery,
    RioEmployeeQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<RioEmployeeQuery, RioEmployeeQueryVariables>(
    RioEmployeeDocument,
    options,
  )
}
export function useRioEmployeeSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioEmployeeQuery,
        RioEmployeeQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<RioEmployeeQuery, RioEmployeeQueryVariables>(
    RioEmployeeDocument,
    options,
  )
}
export type RioEmployeeQueryHookResult = ReturnType<typeof useRioEmployeeQuery>
export type RioEmployeeLazyQueryHookResult = ReturnType<
  typeof useRioEmployeeLazyQuery
>
export type RioEmployeeSuspenseQueryHookResult = ReturnType<
  typeof useRioEmployeeSuspenseQuery
>
export type RioEmployeeQueryResult = Apollo.QueryResult<
  RioEmployeeQuery,
  RioEmployeeQueryVariables
>
