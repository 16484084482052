import type { CognitoUser } from 'amazon-cognito-identity-js'
import store from 'store/dist/store.modern'
import { Claims } from '@moia-dev/moia-token-claims'
import {
  AUTHENTICATED_USER_LOCALSTORAGE_KEY,
  MFA_METHOD_LOCALSTORAGE_KEY,
  cypressMfaErrorCase,
  cypressPreferredMfaMethodKey,
} from '../const/config'
import { MfaErrorStates, MfaMethods } from '../const/enums'
import { GroupToRights } from './staticGroupsToRightsMock'

const mockUserId = (username: string) => `123-${username}`

const getUsernameFromEmail = (email: string) => email.split('@')[0]

const getGroupFromEmailOrUsername = (
  emailOrUsername: string,
): string | undefined => {
  const regex = /(\+\w*_*)/
  const result = emailOrUsername.match(regex)
  return result?.at(0)?.replace('+', '')
}

export const getUserForGroup = (emailOrUsername: string) => {
  const groupKey = getGroupFromEmailOrUsername(emailOrUsername)
  return groupKey
    ? {
        groups: [groupKey],
        password: '!1234QWer',
        rights: GroupToRights[groupKey],
        username: `backoffice-dev+${groupKey}`,
        email: `backoffice-dev+${groupKey}@moia.io`,
        userId: mockUserId(`backoffice-dev+${groupKey}`),
      }
    : undefined
}

class MockUser {
  constructor(
    public username: string,
    public email: string,
    public preferredMFA = store.get(MFA_METHOD_LOCALSTORAGE_KEY),
  ) {}

  getUsername = () => this.username

  getEmail = () => this.email

  getUserId = () => mockUserId(this.username)

  // please add a comment here or fix the issue
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refreshSession = (_error: Error, callback: any) => {
    const session = new MockUserSession()
    callback(null, session)
  }
}

const restoreAuthenticatedUser = () => {
  try {
    const email = store.get(AUTHENTICATED_USER_LOCALSTORAGE_KEY)
    const username = getUsernameFromEmail(email)
    return email ? new MockUser(username, email) : null
  } catch {
    return null
  }
}

class MockUserPool {
  constructor(
    public id: string | null,
    public clientId: string | null,
  ) {}
}

const getMockUser = (emailOrUsername: string) => {
  const user = getUserForGroup(emailOrUsername)
  const newUser = {
    groups: [],
    password: '!1234QWer',
    rights: [],
    username: 'backoffice-dev+new',
    userId: mockUserId('backoffice-dev+new'),
    email: 'backoffice-dev+new@moia.io',
  }
  return user ? user : newUser
}

let authenticatedUser = restoreAuthenticatedUser()

class MockUserSession {
  getRefreshToken = () => Promise.resolve('mock refresh token')

  getIdToken = () => ({
    getExpiration: () => new Date(Date.now() + 20 * 1000),
    getJwtToken: () => {
      if (!authenticatedUser) {
        return Promise.reject('No current user')
      }

      const username = authenticatedUser.getUsername()
      const user = getMockUser(username)

      const claims = Claims.fromSimpleClaims({
        defaultBOUM: {
          backofficeUsername: username,
          userId: user.userId,
          isSystemUser: false,
          userRights: user.rights,
          // temporary tenant for mocking
          tenants: ['moia', 'switchh'],
          customerTenants: ['moia', 'switchh', 'mobli'],
          operatorTenants: ['moia'],
          // Cannot import from fixtures because it will trigger an nx circular
          // dependency. Maybe create a new nx lib?
          serviceAreas: [
            // hamburg service area UUID
            'f89ee35c-10ff-4d3b-959e-07d2e7637119',
            // hanover service area UUID
            '3321dbea-f00b-4998-a3e1-a6c4b5898387',
            // miami service area UUID
            '6b17caac-df17-4130-8bce-4d62ff5ad518',
          ],
        },
        defaultCognito: {
          email: `${username}@moia.io`,
        },
        defaultEmployee: {},
        defaultOpenID: {},
      }).toJSON()

      const header = { alg: 'RS256', fakeHeader: true }
      const encodedClaims = btoa(JSON.stringify(claims))
      const encodedHeader = btoa(JSON.stringify(header))
      const encodedSignature = btoa('fakeSignature')
      const fakeJwt = `${encodedHeader}.${encodedClaims}.${encodedSignature}`

      return fakeJwt
    },
  })
}

export const configure = ({
  userPoolId,
  userPoolWebClientId,
}: {
  userPoolId: string
  userPoolWebClientId: string
}): void => {
  new MockUserPool(userPoolId, userPoolWebClientId)
}

export const getPreferredMFA = (user: MockUser): string => user.preferredMFA

export const setPreferredMFA = async (): Promise<string> => {
  authenticatedUser!.preferredMFA = MfaMethods.SOFTWARE_TOKEN_MFA
  return Promise.resolve('SUCCESS')
}

export const verifyTotpToken = async (): Promise<{ Status: string }> =>
  Promise.resolve({ Status: 'SUCCESS' })

export const setupTOTP = (): Promise<string> => Promise.resolve('123456')

export const signIn = (email: string): Promise<MockUser> => {
  if (!email) {
    return Promise.reject('Username cannot be empty')
  }
  if (email === 'disabled') {
    return Promise.reject({ message: 'User is disabled.' })
  }

  const username = getUsernameFromEmail(email)
  authenticatedUser = new MockUser(username, email)

  const preferredMfaMethod = store.get(cypressPreferredMfaMethodKey)
  if (preferredMfaMethod) {
    authenticatedUser.preferredMFA = preferredMfaMethod
  }

  store.set(AUTHENTICATED_USER_LOCALSTORAGE_KEY, email)
  return Promise.resolve(authenticatedUser)
}

export const confirmSignIn = (): Promise<MockUser | null> => {
  const errorCase = store.get(cypressMfaErrorCase)
  if (errorCase === MfaErrorStates.NotAuthorizedException) {
    return Promise.reject({ code: MfaErrorStates.NotAuthorizedException })
  }
  if (errorCase === MfaErrorStates.CodeMismatchException) {
    return Promise.reject({ code: MfaErrorStates.CodeMismatchException })
  }

  return Promise.resolve(authenticatedUser)
}

export const currentUserPoolUser = (): Promise<MockUser> => {
  if (!authenticatedUser) {
    return Promise.reject('No current user')
  }

  return Promise.resolve(authenticatedUser)
}
export const currentUserInfo = (): Promise<MockUser> => {
  if (!authenticatedUser) {
    return Promise.reject('No current user')
  }

  return Promise.resolve(authenticatedUser)
}

export const currentAuthenticatedUser = (): Promise<MockUser> => {
  if (!authenticatedUser) {
    return Promise.reject('not authenticated')
  }
  return currentUserPoolUser().then(user => ({
    ...user,
    attributes: {
      email_verified: true,
      email: user.getUsername(),
    },
  }))
}

const COGNITO_PASS_REGEXP =
  // please add a comment here or fix the issue
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{6,99}$/
export const changePassword = (
  _user: CognitoUser,
  _oldPass: string,
  newPass: string,
): Promise<string> => {
  if (!authenticatedUser) {
    return Promise.reject('Failed to get the session because the user is empty')
  }

  if (!COGNITO_PASS_REGEXP.test(newPass)) {
    return Promise.reject({
      message: 'any',
      code: 'InvalidPasswordException',
    })
  }

  return Promise.resolve('SUCCESS')
}

export const currentSession = (): Promise<MockUserSession> => {
  return currentUserPoolUser().then(() => new MockUserSession())
}

export const signOut = (): Promise<void> => {
  authenticatedUser = null
  store.remove(AUTHENTICATED_USER_LOCALSTORAGE_KEY)
  return Promise.resolve()
}

export const completeNewPassword = (
  user: string,
  password: string,
): Promise<string> => {
  if (!password) {
    return Promise.reject('Password cannot be empty')
  }

  return Promise.resolve(user)
}

export const forgotPassword = (username: string): Promise<void> => {
  if (!username) {
    return Promise.reject('Username cannot be empty')
  }

  return Promise.resolve()
}

export const forgotPasswordSubmit = (
  username: string,
  code: string,
  newPassword: string,
): Promise<MockUser> => {
  if (!username) {
    return Promise.reject('Username cannot be empty')
  }
  if (!code) {
    return Promise.reject('Code cannot be empty')
  }
  if (!newPassword) {
    return Promise.reject('Password cannot be empty')
  }
  if (newPassword === 'LimitExceededException') {
    return Promise.reject({ code: 'LimitExceededException' })
  }
  if (newPassword === 'CodeMismatchException') {
    return Promise.reject({ code: 'CodeMismatchException' })
  }
  if (newPassword === 'fails') {
    return Promise.reject({ code: 'fails' })
  }
  if (newPassword === 'InvalidPasswordException') {
    return Promise.reject({ code: 'InvalidPasswordException' })
  }

  const mockUnauthenticatedUser = new MockUser(username, '')
  return Promise.resolve(mockUnauthenticatedUser)
}
