/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type RioVehicleDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input']
}>

export type RioVehicleDetailsQuery = {
  __typename?: 'Query'
  vehicleByID: {
    __typename?: 'Vehicle'
    serviceAreaUUID: string
    label: number | null
    latestLocation: {
      __typename?: 'VehicleLocation'
      location: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      } | null
    } | null
    lastVehicleInteraction: {
      __typename?: 'VehicleInteraction'
      timestamp: string
      type: Types.VehicleInteractionType
      employee: {
        __typename?: 'Employee'
        id: string
        firstName: string | null
        lastName: string | null
      } | null
    } | null
  }
}

export const RioVehicleDetailsDocument = gql`
  query RioVehicleDetails($id: ID!) {
    vehicleByID(id: $id) {
      latestLocation {
        location {
          latitude
          longitude
        }
      }
      lastVehicleInteraction {
        timestamp
        type
        employee {
          id
          firstName
          lastName
        }
      }
      serviceAreaUUID
      label
    }
  }
`

/**
 * __useRioVehicleDetailsQuery__
 *
 * To run a query within a React component, call `useRioVehicleDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRioVehicleDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRioVehicleDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRioVehicleDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    RioVehicleDetailsQuery,
    RioVehicleDetailsQueryVariables
  > &
    (
      | { variables: RioVehicleDetailsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    RioVehicleDetailsQuery,
    RioVehicleDetailsQueryVariables
  >(RioVehicleDetailsDocument, options)
}
export function useRioVehicleDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RioVehicleDetailsQuery,
    RioVehicleDetailsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    RioVehicleDetailsQuery,
    RioVehicleDetailsQueryVariables
  >(RioVehicleDetailsDocument, options)
}
export function useRioVehicleDetailsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        RioVehicleDetailsQuery,
        RioVehicleDetailsQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    RioVehicleDetailsQuery,
    RioVehicleDetailsQueryVariables
  >(RioVehicleDetailsDocument, options)
}
export type RioVehicleDetailsQueryHookResult = ReturnType<
  typeof useRioVehicleDetailsQuery
>
export type RioVehicleDetailsLazyQueryHookResult = ReturnType<
  typeof useRioVehicleDetailsLazyQuery
>
export type RioVehicleDetailsSuspenseQueryHookResult = ReturnType<
  typeof useRioVehicleDetailsSuspenseQuery
>
export type RioVehicleDetailsQueryResult = Apollo.QueryResult<
  RioVehicleDetailsQuery,
  RioVehicleDetailsQueryVariables
>
