export enum Features {
  // please keep this one at all times, is used for testing
  // and should stay even if no feature toggle is used at the moment
  FEATURE_TOGGLE_TEST = 'FEATURE_TOGGLE_TEST',
  // normal Features go here:
  SUBSCRIPTION_SUPPORT = 'SUBSCRIPTION_SUPPORT',
  GRAPHQL_GDPR_SELF_ASSESSMENT = 'GRAPHQL_GDPR_SELF_ASSESSMENT',
  VEHICLE_STANDING_ALERT = 'VEHICLE_STANDING_ALERT',
  PIE_CHART = 'PIE_CHART',
  TURN_RESTRICTIONS = 'TURN_RESTRICTIONS',
  NEXT_GEN_SAM_TABLES = 'NEXT_GEN_SAM_TABLES',
  VEHICLE_CHECKS_REPORTS = 'VEHICLE_CHECKS_REPORTS',
  MUNICH_DEMO = 'MUNICH_DEMO',
  HAMBURG_KPI_DASHBOARD = 'HAMBURG_KPI_DASHBOARD',
  DSBOSS_SCENARIOS_TABLE_PACE = 'DSBOSS_SCENARIOS_TABLE_PACE',
  EMERGENCY_DISABLE_SERVICE = 'EMERGENCY_DISABLE_SERVICE',
} // DONT DELETE - template injection feature
