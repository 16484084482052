/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'
import { GetPassengersWaypointFragmentDoc } from '../utils/passenger-filters.hook'

export type AdWaypointPassengersVehicleFragment = {
  __typename?: 'Vehicle'
  alerts: {
    __typename?: 'TaskNotificationList'
    alerts: Array<
      | { __typename?: 'HubServiceNotification' }
      | { __typename?: 'IvcTaskNotification' }
      | {
          __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
          id: string
          trip: {
            __typename?: 'Trip'
            id: string
            customer: { __typename?: 'Customer'; id: string } | null
          } | null
        }
      | { __typename?: 'PassengerUnbuckled' }
      | { __typename?: 'RoadBlockageTaskNotification' }
      | { __typename?: 'ShiftDeviationNotification' }
      | { __typename?: 'StopAccessibilityDownvotedByDrivers' }
      | { __typename?: 'StopActivationFailedNotification' }
      | { __typename?: 'StopAreaMissingActiveStopsNotification' }
      | { __typename?: 'StopDownVotedByDrivers' }
      | { __typename?: 'StopPotentiallyUnreachable' }
      | { __typename?: 'StopValidationFailed' }
      | { __typename?: 'TimeManagementNotification' }
      | { __typename?: 'TripPrebookingNoAssignment' }
      | { __typename?: 'VehicleAvailabilityNotification' }
      | { __typename?: 'VehicleIncidentNotification' }
      | { __typename?: 'VehicleSurveillanceNotification' }
      | { __typename?: 'VehicleWaypointAttendance' }
    >
  } | null
}

export type AdWaypointPassengersWaypointFragment = {
  __typename?: 'FccWaypoint'
  id: string
  actions: Array<
    | {
        __typename: 'FccWaypointDeliveryAction'
        id: string
        estimatedAt: string
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename?: 'FccWaypointHubReturnAction' }
    | {
        __typename: 'FccWaypointPickupAction'
        id: string
        estimatedAt: string
        adPassengerAuthenticationState: Types.FccPassengerAuthenticationState | null
        trip: { __typename?: 'Trip'; id: string } | null
        customer: {
          __typename?: 'Customer'
          firstName: string | null
          lastName: string | null
          extendedWalkingTime: boolean
          visualImpairment: boolean | null
        } | null
        seats: {
          __typename?: 'FccWaypointSeatInfo'
          seats: number
          wheelchairs: number
          childSeats: number
          boosterSeats: number
        }
      }
    | { __typename?: 'FccWaypointReallocationAction' }
  >
}

export type AdWaypointPassengersAuthenticationAlertFragment = {
  __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
  id: string
  trip: {
    __typename?: 'Trip'
    id: string
    customer: { __typename?: 'Customer'; id: string } | null
  } | null
}

export const AdWaypointPassengersAuthenticationAlertFragmentDoc = gql`
  fragment AdWaypointPassengersAuthenticationAlert on PassengerAuthentificationFallbackRequestedNotification {
    id
    trip {
      id
      customer {
        id
      }
    }
  }
`
export const AdWaypointPassengersVehicleFragmentDoc = gql`
  fragment AdWaypointPassengersVehicle on Vehicle {
    alerts: taskNotifications(
      status: [NEW, IN_PROGRESS, PENDING]
      types: FC_PASSENGER_AUTHENTICATION_FALLBACK_REQUEST_NOTIFICATION
    ) {
      alerts: tasks {
        ... on PassengerAuthentificationFallbackRequestedNotification {
          ...AdWaypointPassengersAuthenticationAlert
        }
      }
    }
  }
  ${AdWaypointPassengersAuthenticationAlertFragmentDoc}
`
export const AdWaypointPassengersWaypointFragmentDoc = gql`
  fragment AdWaypointPassengersWaypoint on FccWaypoint {
    id
    ...GetPassengersWaypoint
  }
  ${GetPassengersWaypointFragmentDoc}
`
