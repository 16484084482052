/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type QueryRefereesQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input']
}>

export type QueryRefereesQuery = {
  __typename?: 'Query'
  referees: Array<{
    __typename?: 'RefereeInfo'
    referralStatus: Types.ReferralStatus
    redeemedOn: string
    rewardSentOn: string | null
    customer: {
      __typename?: 'Customer'
      id: string
      firstName: string | null
      lastName: string | null
    }
  }>
}

export const QueryRefereesDocument = gql`
  query queryReferees($id: String!) {
    referees(customerId: $id) {
      customer {
        id
        firstName
        lastName
      }
      referralStatus
      redeemedOn
      rewardSentOn
    }
  }
`

/**
 * __useQueryRefereesQuery__
 *
 * To run a query within a React component, call `useQueryRefereesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueryRefereesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueryRefereesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQueryRefereesQuery(
  baseOptions: Apollo.QueryHookOptions<
    QueryRefereesQuery,
    QueryRefereesQueryVariables
  > &
    (
      | { variables: QueryRefereesQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<QueryRefereesQuery, QueryRefereesQueryVariables>(
    QueryRefereesDocument,
    options,
  )
}
export function useQueryRefereesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    QueryRefereesQuery,
    QueryRefereesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<QueryRefereesQuery, QueryRefereesQueryVariables>(
    QueryRefereesDocument,
    options,
  )
}
export function useQueryRefereesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        QueryRefereesQuery,
        QueryRefereesQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    QueryRefereesQuery,
    QueryRefereesQueryVariables
  >(QueryRefereesDocument, options)
}
export type QueryRefereesQueryHookResult = ReturnType<
  typeof useQueryRefereesQuery
>
export type QueryRefereesLazyQueryHookResult = ReturnType<
  typeof useQueryRefereesLazyQuery
>
export type QueryRefereesSuspenseQueryHookResult = ReturnType<
  typeof useQueryRefereesSuspenseQuery
>
export type QueryRefereesQueryResult = Apollo.QueryResult<
  QueryRefereesQuery,
  QueryRefereesQueryVariables
>
