import {
  BoxProvider,
  SdsProvider,
  VehicleEngineType,
  VehicleGroupType,
  VehicleInteractionType,
  VehicleState,
  VehicleStateChangeReason,
} from '@backoffice-frontend/graphql'

// GraphQL
export const inFieldVehicle = {
  changeReasonFreeText: 'update de-fleeting date for all plutos',
  climateControlState: 'OFF',
  currentlyLocatedInHub: null,
  currentlyOpen: false,
  deFleetingDate: '2026-06-30T00:00:00Z',
  engineType: 'ELECTRIC',
  firstRegistrationDate: '2019-03-19T23:00:00Z',
  hardwareConfiguration: {
    cdisBox: {
      imei: '456',
      imsi: '8282',
      macAddr: 'cdis-mac',
      serialNumber: 'cdis-serial',
    },
    boxPc: {
      __typename: 'BoxPc',
      macAddr: '08:39:4C:9D:BD:BB',
      cpuId: '2345654',
    },
  },
  homeHub: {
    id: 'hub-hamburg-1',
    name: 'Hub Hamburg 1',
    city: 'Hamburg',
    contact: 'Brooke Weimann',
    displayName: 'Hub HH 1',
    geometry:
      '{"foo":"k>)aQrqtn(","bar":"$:0&r_*XH_","bike":"GR3a;>.`in","a":1828,"b":75014,"name":"|^f<;Jj5*\'","prop":46923}',
    phoneNumber: '1-229-411-5006 x92922',
    serviceAreaUUID: 'f89ee35c-10ff-4d3b-959e-07d2e7637119',
    postcode: '95141-8833',
    streetAndNumber: '1693 Khalid Route Suite 718',
    location: {
      __typename: 'Location',
      latitude: 53.5506341,
      longitude: 9.9867308,
    },
    totalReturningVehicles: null,
    fixedSetupTimes: null,
    changeDescription: null,
    area: null,
    capacityConfiguration: null,
    areaCapacityConfiguration: null,
    inFieldBreakLocation: null,
  },
  id: '8c5665ec-aca5-463e-bbcc-977d3e5d0379',
  ignition: {
    __typename: 'Ignition',
    updatedAt: '2020-07-02T14:22:07.26Z',
    ignitionState: 'ON',
  },
  inFleetingDate: '2019-03-19T23:00:00Z',
  interiorTemperature: 17,
  label: 16,
  lastBoxState: {
    __typename: 'TelematicBoxState',
    timestamp: '2021-05-06T11:42:56.093Z',
    mileage: 59021.3,
    ignition: 'OFF',
    centralLock: 'UNLOCKED',
    doors: 'CLOSED',
    keyFob: 'INSERTED',
    windows: 'ALL_CLOSED',
    location: {
      __typename: 'Location',
      latitude: 0,
      longitude: 0,
    },
    locationTimestamp: '2021-05-06T11:42:56.07Z',
    highVoltageBatteryLevel: 80,
    highVoltageBatteryHealth: null,
    powerPlug: 'DISCONNECTED',
    batteryVoltage: 12.6,
    cruisingRange: 250,
    interiorTemperature: 17,
    targetTemperature: 21,
    climateControlActive: false,
    connectedState: 'CONNECTED_STATE_UNKNOWN',
    chargingMode: 'OFF',
    batteryEnergy: null,
  },
  lastVehicleInteraction: {
    employee: {
      id: 'employee-id-kjellski',
      firstName: 'Kjell',
      lastName: 'Otto',
      email: 'otto.kjell@gmail.com',
      status: 'ACTIVE',
      address: [Object],
      dateOfBirth: '2023-04-01T19:16:38.332Z',
      birthCity: 'Port Myraport',
      birthCountry: 'CX',
      confirmed: true,
      contractor: '-"?Z.Y5ZDx',
      blocked: false,
      suspended: false,
      emailVerified: false,
      entryDate: '2022-10-31T12:02:19.598Z',
      exitDate: '2023-07-07T19:35:01.058Z',
      externalId: 'moia_opsco_de/sap-Kjell',
      gender: 'FEMALE',
      hubDefaultId: 'hub-hamburg-1',
      hub: [Object],
      nationality: 'Norfolk Island',
      serviceAreaId: 'f89ee35c-10ff-4d3b-959e-07d2e7637119',
      mobile: '+4916099460466',
      mobileVerified: false,
      supervisor: 'y6\\EHZYM"L',
      wavQualification: true,
      profilePictureURL: 'someImage',
      profilePictureTimestamp: '2023-07-07T08:47:04.829Z',
    },
    timestamp: '2021-04-22T07:02:26.383Z',
    type: VehicleInteractionType.Close,
    hardwareKeyUsed: true,
    id: 'some-interaction-id',
    isBackofficeInteraction: true,
  },
  lastVehicleStateChange: {
    __typename: 'VehicleStateChange',
    vehicleStateChangeReason: VehicleStateChangeReason.ServiceMoiaVwAction,
    changeReasonFreeText: 'update de-fleeting date for all plutos',
    createdBy: 'System',
    createdAt: '2021-05-06T11:43:12.624Z',
  },
  latestLocation: {
    location: {
      latitude: 0,
      longitude: 0,
    },
    locationSource: 'TELEMATIC',
  },
  licensePlate: 'HH-MK1234',
  nextMainInspectionDate: '2020-03-24T23:00:00Z',
  openedBy: null,
  scheduledStateChange: null,
  scheduledStateChanges: null,
  serviceAreaUUID: 'f89ee35c-10ff-4d3b-959e-07d2e7637119',
  seatConfiguration: {
    __typename: 'VehicleSeatConfiguration',
    available: 0,
    reserved: 6,
    childSeatSpecs: [
      {
        isBoosterSeat: true,
        isChildSeat: true,
        isAvailable: true,
        seatNumber: 1,
      },
    ],
    availableWheelchairSeats: 1,
    total: 6,
  },
  state: VehicleState.Active,
  isSelfDriving: false,
  vehicleGroup: {
    id: 'pluto_0',
    groupType: VehicleGroupType.Pluto,
    boxProvider: BoxProvider.Cdis,
    engineType: VehicleEngineType.Electric,
    displayName: 'Pluto MOIA 1 Child Seat',
    tenant: 'moia',
    seats: 6,
    wheelchairSeats: 1,
    sdsProvider: SdsProvider.None,
    isWheelchairAccessible: true,
    childSeatSpecs: [
      {
        isAvailable: true,
        isBoosterSeat: true,
        isChildSeat: true,
        seatNumber: 2,
      },
    ],
    serviceAreaUUIDs: ['service-area-id'],
  },
  vehicleStateChangeReason: VehicleStateChangeReason.ServiceMoiaVwAction,
  vin: 'SUPERMOIACART1234',
}
