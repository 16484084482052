/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */
/* eslint-disable */
export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never }
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never
    }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  CronExpression: { input: any; output: any }
  /** ISO-8601 Date */
  Date: { input: string; output: string }
  /** ISO-8601 DateTime */
  DateTime: { input: string; output: string }
  /** Display names */
  DisplayNames: {
    input: Partial<Record<'de' | 'en', string>>
    output: Partial<Record<'de' | 'en', string>>
  }
  /** ISO-8601 time duration */
  Duration: { input: string; output: string }
  /** GeoJSON as a JSON string */
  GeoJSONSerializedObject: { input: any; output: any }
  /** An instantaneous point on the time-line measured in seconds after 1970-01-01T00:00:00 */
  Instant: { input: any; output: any }
  /** A local date string (i.e., with no associated timezone) in YYYY-MM-DD format, e.g. 2020-01-01 */
  LocalDateTime: { input: any; output: any }
  /** ISO-8601 Date and time with offset (2011-12-03T10:15:30+01:00) */
  OffsetDateTime: { input: any; output: any }
  /** Percentage in the range 0.0 - 100.0. However, the value may exceed the specified range. */
  Percentage: { input: any; output: any }
  RefundSuccess: { input: any; output: any }
  /** Represented as an ISO8601 Time, e.g. 03:02:01 - https://en.wikipedia.org/wiki/ISO_8601#Times */
  TimeOfDay: { input: string; output: string }
  /** TimeZone represents a time zone offset, and also figures out daylight savings. */
  TimeZone: { input: string; output: string }
  /** Moia internal Trip or external, e.g. HVV */
  TripTenant: { input: any; output: any }
  /** A V4 UUID. */
  UUID: { input: string; output: string }
  /** Represented as no value being returned - https://the-guild.dev/graphql/scalars/docs/scalars/void */
  Void: { input: any; output: any }
  /** Represented as an ISO8601 Year-month, e.g. 2024-02 - https://en.wikipedia.org/wiki/ISO_8601#Calendar_dates */
  YearMonth: { input: string; output: string }
}

export enum AdConnectivityState {
  Connected = 'CONNECTED',
  Interrupted = 'INTERRUPTED',
}

export type AdConnectivityStateWithTimestamp = {
  __typename?: 'ADConnectivityStateWithTimestamp'
  connectivityState: AdConnectivityState
  timestamp: Scalars['DateTime']['output']
}

export type AdTelemetry = {
  __typename?: 'ADTelemetry'
  cabinConnectivityState: Maybe<AdConnectivityStateWithTimestamp>
  centralLockLocked: Maybe<AdTelemetryBooleanWithTimestamp>
  /** Energy consumed in Watts per second */
  consumedEnergy: Maybe<AdTelemetryFloatWithTimestamp>
  /** @deprecated Use rightSlidingDoorOpen instead */
  doorsOpen: Maybe<AdTelemetryBooleanWithTimestamp>
  highVoltBatterySOCPercentage: Maybe<AdTelemetryFloatWithTimestamp>
  ignitionOn: Maybe<AdTelemetryBooleanWithTimestamp>
  interiorTemperatureCelsius: Maybe<AdTelemetryFloatWithTimestamp>
  location: Maybe<LocationWithTimestamp>
  missionConnectivityState: Maybe<AdConnectivityStateWithTimestamp>
  remainingCruisingRangeKilometer: Maybe<AdTelemetryFloatWithTimestamp>
  rightSlidingDoorOpen: Maybe<AdTelemetryBooleanWithTimestamp>
  sdsMode: Maybe<SdsModeWithTimestamp>
  signalConnectivityState: Maybe<AdConnectivityStateWithTimestamp>
  /** Current speed of vehicle in meters per second */
  speed: Maybe<AdTelemetryFloatWithTimestamp>
}

export type AdTelemetryBooleanWithTimestamp = {
  __typename?: 'ADTelemetryBooleanWithTimestamp'
  /** DateTime when the value changed the last time. */
  timestamp: Scalars['DateTime']['output']
  value: Scalars['Boolean']['output']
}

export type AdTelemetryFloatWithTimestamp = {
  __typename?: 'ADTelemetryFloatWithTimestamp'
  /** DateTime when the value changed the last time. */
  timestamp: Scalars['DateTime']['output']
  value: Scalars['Float']['output']
}

export type Action = {
  __typename?: 'Action'
  automated: Scalars['Boolean']['output']
  condition: Maybe<ActionCondition>
  created: Scalars['DateTime']['output']
  description: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  modified: Scalars['DateTime']['output']
  modifiedBy: Scalars['String']['output']
  reason: ActionReason
  scheduleStatus: ActionScheduleStatus
  timeRange: TimeRange
  type: ActionType
}

export enum ActionCondition {
  AllStopDeactivationsExpired = 'AllStopDeactivationsExpired',
}

export enum ActionEntityType {
  Area = 'Area',
  ServiceArea = 'ServiceArea',
  Stop = 'Stop',
}

export type ActionInput = {
  actionType: ActionType
  condition: InputMaybe<ActionCondition>
  description: InputMaybe<Scalars['String']['input']>
  entityId: Scalars['String']['input']
  entityType: ActionEntityType
  reason: InputMaybe<ActionReason>
  timeRange: TimeRangeInput
}

export enum ActionReason {
  ActivationPeriod = 'ActivationPeriod',
  AuthorityCheckPending = 'AuthorityCheckPending',
  BadStopVoting = 'BadStopVoting',
  Complaint = 'Complaint',
  ConstructionSite = 'ConstructionSite',
  Demo = 'Demo',
  IndividualEvent = 'IndividualEvent',
  OutOfServiceArea = 'OutOfServiceArea',
  RecurringEvent = 'RecurringEvent',
  Stvo = 'STVO',
  Unreachable = 'Unreachable',
}

export enum ActionScheduleStatus {
  Expired = 'Expired',
  ExpiredRecurring = 'ExpiredRecurring',
  Ongoing = 'Ongoing',
  OngoingRecurring = 'OngoingRecurring',
  Unknown = 'Unknown',
  Upcoming = 'Upcoming',
  UpcomingRecurring = 'UpcomingRecurring',
}

export enum ActionType {
  EntityDeletion = 'EntityDeletion',
  ServiceAreaUpdate = 'ServiceAreaUpdate',
  StopDeactivation = 'StopDeactivation',
}

export type ActivateClimateControlInput = {
  vehicleID: Scalars['ID']['input']
}

export type ActivateClimateControlResponse = {
  __typename?: 'ActivateClimateControlResponse'
  vehicle: Vehicle
}

export type ActivateVehicleInput = {
  vehicleId: Scalars['ID']['input']
}

/** Deprecated: to be replaced by OpenVehicleInput, OpenVehicleForBackofficeUserInput, CloseVehicleInput, CloseVehicleForBackofficeUserInput */
export type AdminVehicleInteractionInput = {
  interactionType: VehicleInteractionType
  isBatchInteraction: InputMaybe<Scalars['Boolean']['input']>
  reason: InputMaybe<InteractionReason>
  reasonFreeText: InputMaybe<Scalars['String']['input']>
  token: InputMaybe<Scalars['String']['input']>
  vehicleID: Scalars['ID']['input']
}

export type AggregatedVehicleHubArrivalsAndDeparturesResponse = {
  __typename?: 'AggregatedVehicleHubArrivalsAndDeparturesResponse'
  from: Scalars['DateTime']['output']
  hubArrivalsOrDeparturesByType: Array<HubArrivalsOrDeparturesByType>
  to: Scalars['DateTime']['output']
}

export type ApplePay = {
  __typename?: 'ApplePay'
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  _: Maybe<Scalars['Boolean']['output']>
}

export type ApproveCustomPriceConfigInput = {
  id: Scalars['ID']['input']
  version: Scalars['Int']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type ApproveCustomPriceConfigResponse = {
  __typename?: 'ApproveCustomPriceConfigResponse'
  config: CustomPriceConfigResponse
}

export type ApproveDefaultPriceConfigInput = {
  id: Scalars['ID']['input']
  version: Scalars['Int']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type ApproveDefaultPriceConfigResponse = {
  __typename?: 'ApproveDefaultPriceConfigResponse'
  config: DefaultPriceConfigResponse
}

/** Input object for archiving a pricing polygon in the polygon library */
export type ArchivePriceConfigPolygonInput = {
  id: Scalars['ID']['input']
}

export type ArchivePriceConfigPolygonResponse = {
  __typename?: 'ArchivePriceConfigPolygonResponse'
  polygon: PriceConfigPolygon
}

export type ArchiveVehicleInput = {
  vehicleId: Scalars['ID']['input']
}

export type Area = {
  __typename?: 'Area'
  actions: Array<Action>
  areaCapacityConfiguration: Maybe<AreaCapacityConfiguration>
  /** Schedule-based configuration. Null if not yet configured. */
  capacityConfiguration: Maybe<Array<HubCapacity>>
  changeDescription: Scalars['String']['output']
  created: Scalars['DateTime']['output']
  description: Maybe<Scalars['String']['output']>
  details: Maybe<AreaDetails>
  /** GeoJSON geometry object */
  geometry: Scalars['String']['output']
  hasOverlappingActions: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  keepOutZone: Maybe<Scalars['Boolean']['output']>
  metadata: Array<MetadataString>
  modified: Scalars['DateTime']['output']
  modifiedBy: Scalars['String']['output']
  name: Maybe<Scalars['String']['output']>
  serviceArea: ServiceArea
  status: Maybe<AreaStatus>
  /**
   * Number of vehicles returning to this hub in the specified interval.
   * Defaults to the next 30 minutes.
   */
  totalReturningVehicles: Maybe<Scalars['Int']['output']>
  type: AreaType
}

export type AreaTotalReturningVehiclesArgs = {
  interval: InputMaybe<DateTimeIntervalInput>
}

export type AreaCapacityConfiguration = {
  __typename?: 'AreaCapacityConfiguration'
  acPowerChargingCapacity: Scalars['Int']['output']
  highPowerChargingCapacity: Scalars['Int']['output']
  highPowerChargingCapacityCritical: Scalars['Int']['output']
  highPowerChargingCapacityMax: Scalars['Int']['output']
  maxBreaks: Scalars['Int']['output']
  parkingCapacity: Scalars['Int']['output']
  plannedBreaksAllowed: Scalars['Boolean']['output']
  shortBreaksAllowed: Scalars['Boolean']['output']
}

export type AreaCapacityConfigurationInput = {
  acPowerChargingCapacity: Scalars['Int']['input']
  changeDescription: InputMaybe<Scalars['String']['input']>
  highPowerChargingCapacity: Scalars['Int']['input']
  highPowerChargingCapacityCritical: Scalars['Int']['input']
  highPowerChargingCapacityMax: Scalars['Int']['input']
  maxBreaks: InputMaybe<Scalars['Int']['input']>
  parkingCapacity: Scalars['Int']['input']
  plannedBreaksAllowed: InputMaybe<Scalars['Boolean']['input']>
  shortBreaksAllowed: InputMaybe<Scalars['Boolean']['input']>
}

export type AreaDetails =
  | CooperationDetails
  | HubDetails
  | InFieldBreakDetails
  | ShortBreakDetails

export type AreaFilterInput = {
  keepOutZone: InputMaybe<Scalars['Boolean']['input']>
  status: InputMaybe<Array<SearchAreaStatus>>
  type: InputMaybe<Array<SearchAreaType>>
}

export type AreaInput = {
  changeDescription: InputMaybe<Scalars['String']['input']>
  description: InputMaybe<Scalars['String']['input']>
  /** GeoJSON geometry object */
  geometry: Scalars['String']['input']
  keepOutZone: InputMaybe<Scalars['Boolean']['input']>
  metadata: Array<MetadataStringInput>
  name: InputMaybe<Scalars['String']['input']>
  navigationStopId: InputMaybe<Scalars['String']['input']>
  type: AreaType
}

export type AreaLogRecord = ShiftStateLogRecord & {
  __typename?: 'AreaLogRecord'
  area: Maybe<Area>
  eventTime: Scalars['DateTime']['output']
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
  vehicleId: Scalars['ID']['output']
}

export enum AreaStatus {
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
}

export enum AreaType {
  Ad = 'AD',
  ConstructionSite = 'ConstructionSite',
  Cooperation = 'Cooperation',
  Event = 'Event',
  Funding = 'Funding',
  Hub = 'Hub',
  InFieldBreak = 'InFieldBreak',
  Other = 'Other',
  Scouting = 'Scouting',
  ShortBreak = 'ShortBreak',
}

export type AreaUtilization = {
  __typename?: 'AreaUtilization'
  area: Area
  utilization: Scalars['Int']['output']
}

export type AssignCdisBoxToVehicleInput = {
  fleetVehicleId: Scalars['ID']['input']
  imei: Scalars['String']['input']
}

export type AssignTabletToVehicleInput = {
  /** Set this to true if the tablet should be put in development MDM organization group (shouldn't get a prd/int MDM profile) */
  developmentMode: InputMaybe<Scalars['Boolean']['input']>
  imei: Scalars['ID']['input']
  /** Set this to true if the tablet is a simulated tablet (not connected to the MDM) */
  isSimulated: InputMaybe<Scalars['Boolean']['input']>
  phoneNumber: InputMaybe<Scalars['String']['input']>
  vehicleId: Scalars['ID']['input']
}

/** A Cdis box that has reported for a vehicle and whose connection has been approved */
export type AssignedCdisBox = {
  __typename?: 'AssignedCdisBox'
  /** A unique serial number for identifying devices (International Mobile Equipment Identity) */
  imei: Scalars['String']['output']
  /** Timestamp at which the box was last updated */
  updatedAt: Scalars['Date']['output']
  /** A unique serial number for identifying vehicles (Vehicle Identification Number) */
  vin: Scalars['String']['output']
}

export type AvailableVehiclesResponse = {
  __typename?: 'AvailableVehiclesResponse'
  vehicles: Array<Vehicle>
  vehiclesArePrioritized: Scalars['Boolean']['output']
}

export type BackofficeStatus = {
  __typename?: 'BackofficeStatus'
  creationDate: Scalars['String']['output']
  id: Scalars['ID']['output']
  notes: Scalars['String']['output']
  title: Scalars['String']['output']
  type: BackofficeStatusType
}

export type BackofficeStatusCreate = {
  notes: Scalars['String']['input']
  title: Scalars['String']['input']
  type: BackofficeStatusType
}

export type BackofficeStatusDelete = {
  id: Scalars['ID']['input']
}

export type BackofficeStatusDeleteMediaByFileNameResponse = {
  __typename?: 'BackofficeStatusDeleteMediaByFileNameResponse'
  success: Scalars['Boolean']['output']
}

export type BackofficeStatusDeleteResponse = {
  __typename?: 'BackofficeStatusDeleteResponse'
  success: Scalars['Boolean']['output']
}

export enum BackofficeStatusType {
  InReview = 'IN_REVIEW',
  Open = 'OPEN',
  Resolved = 'RESOLVED',
}

export type BackofficeStatusUpdate = {
  creationDate: Scalars['String']['input']
  id: Scalars['ID']['input']
  notes: Scalars['String']['input']
  title: Scalars['String']['input']
  type: BackofficeStatusType
}

export type BackofficeStatusesLastVisitResponse = {
  __typename?: 'BackofficeStatusesLastVisitResponse'
  email: Scalars['String']['output']
  lastVisitDate: Scalars['String']['output']
}

export type BackofficeStatusesResponse = {
  __typename?: 'BackofficeStatusesResponse'
  statuses: Array<Maybe<BackofficeStatus>>
  total: Scalars['Int']['output']
}

export type BackofficeTimedUser = {
  __typename?: 'BackofficeTimedUser'
  expires: Maybe<Scalars['DateTime']['output']>
  user: BackofficeUserIdentity
}

export type BackofficeUpdate = {
  __typename?: 'BackofficeUpdate'
  creationDate: Scalars['String']['output']
  id: Scalars['ID']['output']
  images: Maybe<Array<Maybe<Scalars['String']['output']>>>
  notesDe: Scalars['String']['output']
  notesEn: Scalars['String']['output']
  rights: Array<Maybe<Scalars['String']['output']>>
  titleDe: Scalars['String']['output']
  titleEn: Scalars['String']['output']
  type: BackofficeUpdateType
  videoDe: Maybe<Scalars['String']['output']>
  videoEn: Maybe<Scalars['String']['output']>
}

export type BackofficeUpdateByRightsResponse = {
  __typename?: 'BackofficeUpdateByRightsResponse'
  total: Scalars['Int']['output']
  updates: Array<Maybe<BackofficeUpdate>>
}

export type BackofficeUpdateCreate = {
  images: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  notesDe: Scalars['String']['input']
  notesEn: Scalars['String']['input']
  rights: Array<InputMaybe<Scalars['String']['input']>>
  titleDe: Scalars['String']['input']
  titleEn: Scalars['String']['input']
  type: BackofficeUpdateType
  videoDe: InputMaybe<Scalars['String']['input']>
  videoEn: InputMaybe<Scalars['String']['input']>
}

export type BackofficeUpdateDelete = {
  id: Scalars['ID']['input']
}

export type BackofficeUpdateDeleteResponse = {
  __typename?: 'BackofficeUpdateDeleteResponse'
  success: Scalars['Boolean']['output']
}

export type BackofficeUpdateFilePresignedResponse = {
  __typename?: 'BackofficeUpdateFilePresignedResponse'
  fileName: Scalars['String']['output']
  preSignedUrl: Scalars['String']['output']
}

export type BackofficeUpdateFilePresignedUrlInput = {
  contentType: Scalars['String']['input']
  fileName: Scalars['String']['input']
}

export type BackofficeUpdateGetMediaFilePresignedUrlInput = {
  fileName: Scalars['String']['input']
}

export enum BackofficeUpdateType {
  Bug = 'BUG',
  Improvement = 'IMPROVEMENT',
  NewFeature = 'NEW_FEATURE',
  Tip = 'TIP',
}

export type BackofficeUpdateUpdate = {
  creationDate: Scalars['String']['input']
  id: Scalars['ID']['input']
  images: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  notesDe: Scalars['String']['input']
  notesEn: Scalars['String']['input']
  rights: Array<InputMaybe<Scalars['String']['input']>>
  titleDe: Scalars['String']['input']
  titleEn: Scalars['String']['input']
  type: BackofficeUpdateType
  videoDe: InputMaybe<Scalars['String']['input']>
  videoEn: InputMaybe<Scalars['String']['input']>
}

export type BackofficeUpdatesLastVisitResponse = {
  __typename?: 'BackofficeUpdatesLastVisitResponse'
  email: Scalars['String']['output']
  lastVisitDate: Scalars['String']['output']
}

export type BackofficeUser = IBackofficeUserIdentity & {
  __typename?: 'BackofficeUser'
  cognitoUsername: Scalars['String']['output']
  createDate: Scalars['DateTime']['output']
  critical: Scalars['Boolean']['output']
  customerTenants: Array<Scalars['String']['output']>
  email: Scalars['String']['output']
  enabled: Scalars['Boolean']['output']
  firstName: Scalars['String']['output']
  fleets: Array<Scalars['String']['output']>
  gdpr: Scalars['Boolean']['output']
  groupsApprover: Array<Scalars['String']['output']>
  id: Scalars['ID']['output']
  lastLoginDate: Maybe<Scalars['String']['output']>
  lastModifiedDate: Scalars['DateTime']['output']
  lastName: Scalars['String']['output']
  markedForDeletion: Maybe<Scalars['String']['output']>
  mfaActivated: Scalars['Boolean']['output']
  operatorTenants: Array<Scalars['String']['output']>
  ridepoolingServiceTenants: Array<Scalars['String']['output']>
  status: Scalars['String']['output']
  systemUser: Scalars['Boolean']['output']
  timedGroups: Array<Maybe<BackofficeUserTimeGroup>>
  timedServiceAreas: Array<Maybe<BackofficeUserTimeArea>>
  userTenants: Array<Scalars['String']['output']>
  username: Scalars['String']['output']
}

export type BackofficeUserIdentity = IBackofficeUserIdentity & {
  __typename?: 'BackofficeUserIdentity'
  email: Maybe<Scalars['String']['output']>
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
  username: Maybe<Scalars['String']['output']>
}

export type BackofficeUserManagementAddUserToGroupInput = {
  expires: InputMaybe<Scalars['String']['input']>
  groupName: Scalars['String']['input']
  notes: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type BackofficeUserManagementCreateBackofficeGroupInput = {
  DEDescription: Scalars['String']['input']
  ENDescription: Scalars['String']['input']
  name: Scalars['String']['input']
  notes: Scalars['String']['input']
  rights: InputMaybe<Array<Scalars['String']['input']>>
}

export type BackofficeUserManagementCreateBackofficeUserInput = {
  notes: Scalars['String']['input']
  user: BackofficeUserWithoutId
}

export type BackofficeUserManagementDeleteBackofficeGroupInput = {
  name: Scalars['String']['input']
  notes: Scalars['String']['input']
}

export type BackofficeUserManagementDeleteBackofficeUserInput = {
  id: Scalars['ID']['input']
  notes: Scalars['String']['input']
}

export type BackofficeUserManagementDisableBackofficeUserInput = {
  id: Scalars['ID']['input']
}

export type BackofficeUserManagementEnableBackofficeUserInput = {
  id: Scalars['ID']['input']
}

export type BackofficeUserManagementFleet = {
  __typename?: 'BackofficeUserManagementFleet'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  operatorTenant: Scalars['String']['output']
}

export type BackofficeUserManagementFleetShort = {
  __typename?: 'BackofficeUserManagementFleetShort'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type BackofficeUserManagementGroup = {
  __typename?: 'BackofficeUserManagementGroup'
  DEDescription: Maybe<Scalars['String']['output']>
  ENDescription: Maybe<Scalars['String']['output']>
  critical: Scalars['Boolean']['output']
  gdpr: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  rights: Maybe<Array<Scalars['String']['output']>>
}

export type BackofficeUserManagementGroupExtended = {
  __typename?: 'BackofficeUserManagementGroupExtended'
  DEDescription: Maybe<Scalars['String']['output']>
  ENDescription: Maybe<Scalars['String']['output']>
  approvers: Array<Maybe<BackofficeUserIdentity>>
  critical: Scalars['Boolean']['output']
  gdpr: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  rights: Maybe<Array<Scalars['String']['output']>>
  users: Array<Maybe<BackofficeTimedUser>>
}

export type BackofficeUserManagementGroupHistory = {
  __typename?: 'BackofficeUserManagementGroupHistory'
  action: Scalars['String']['output']
  affectedAttribute: Scalars['String']['output']
  author: Scalars['String']['output']
  description: Scalars['String']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  notes: Scalars['String']['output']
  timestamp: Scalars['String']['output']
}

export type BackofficeUserManagementLogoutBackofficeUserInput = {
  id: Scalars['ID']['input']
}

export type BackofficeUserManagementRemoveUserFromGroupInput = {
  expires: InputMaybe<Scalars['DateTime']['input']>
  groupName: Scalars['String']['input']
  notes: Scalars['String']['input']
  userId: Scalars['ID']['input']
}

export type BackofficeUserManagementResetMfaBackofficeUserInput = {
  id: Scalars['ID']['input']
}

export type BackofficeUserManagementSwapGroupsInput = {
  groupName: InputMaybe<Scalars['String']['input']>
  groupsList: InputMaybe<Array<Scalars['String']['input']>>
}

export type BackofficeUserManagementUpdateBackofficeGroupInput = {
  DEDescription: Scalars['String']['input']
  ENDescription: Scalars['String']['input']
  name: Scalars['String']['input']
  notes: Scalars['String']['input']
  rights: InputMaybe<Array<Scalars['String']['input']>>
}

export type BackofficeUserManagementUpdateBackofficeUserInput = {
  notes: Scalars['String']['input']
  user: BackofficeUserWithId
}

export type BackofficeUserManagementUserFormDetails = {
  __typename?: 'BackofficeUserManagementUserFormDetails'
  approvers: Array<BackofficeUserManagementGroup>
  customerTenants: Array<Scalars['String']['output']>
  fleets: Array<BackofficeUserManagementFleet>
  groups: Array<BackofficeUserManagementGroup>
  operatorTenants: Array<Scalars['String']['output']>
  ridepoolingServiceTenants: Array<Scalars['String']['output']>
  serviceAreaDetails: Array<Maybe<ServiceArea>>
  serviceAreas: Array<Scalars['String']['output']>
  userTenants: Array<BackofficeUserManagementUserTenant>
}

export type BackofficeUserManagementUserHistory = {
  __typename?: 'BackofficeUserManagementUserHistory'
  action: Scalars['String']['output']
  affectedAttribute: Scalars['String']['output']
  author: Scalars['String']['output']
  description: Scalars['String']['output']
  email: Scalars['String']['output']
  id: Scalars['ID']['output']
  notes: Scalars['String']['output']
  timestamp: Scalars['String']['output']
  userName: Scalars['String']['output']
  userTenants: Maybe<Array<BackofficeUserManagementUserTenant>>
}

export type BackofficeUserManagementUserTenant = {
  __typename?: 'BackofficeUserManagementUserTenant'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type BackofficeUserManagementUsersHistoryInput = {
  endDate: InputMaybe<Scalars['DateTime']['input']>
  startDate: InputMaybe<Scalars['DateTime']['input']>
  tenants: InputMaybe<Array<Scalars['String']['input']>>
}

export type BackofficeUserTimeArea = {
  __typename?: 'BackofficeUserTimeArea'
  expires: Maybe<Scalars['DateTime']['output']>
  serviceArea: Scalars['String']['output']
}

export type BackofficeUserTimeAreaInput = {
  expires: InputMaybe<Scalars['DateTime']['input']>
  serviceArea: Scalars['String']['input']
}

export type BackofficeUserTimeGroup = {
  __typename?: 'BackofficeUserTimeGroup'
  expires: Maybe<Scalars['DateTime']['output']>
  groupName: Scalars['String']['output']
}

export type BackofficeUserTimeGroupInput = {
  expires: InputMaybe<Scalars['DateTime']['input']>
  groupName: Scalars['String']['input']
}

export type BackofficeUserWithId = {
  customerTenants: Array<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  fleets: Array<Scalars['String']['input']>
  groupsApprover: Array<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName: Scalars['String']['input']
  operatorTenants: Array<Scalars['String']['input']>
  ridepoolingServiceTenants: Array<Scalars['String']['input']>
  systemUser: Scalars['Boolean']['input']
  timedGroups: Array<InputMaybe<BackofficeUserTimeGroupInput>>
  timedServiceAreas: Array<InputMaybe<BackofficeUserTimeAreaInput>>
  userTenants: Array<Scalars['String']['input']>
  username: Scalars['String']['input']
}

export type BackofficeUserWithoutId = {
  customerTenants: Array<Scalars['String']['input']>
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  fleets: Array<Scalars['String']['input']>
  groupsApprover: Array<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  operatorTenants: Array<Scalars['String']['input']>
  ridepoolingServiceTenants: Array<Scalars['String']['input']>
  systemUser: Scalars['Boolean']['input']
  timedGroups: Array<InputMaybe<BackofficeUserTimeGroupInput>>
  timedServiceAreas: Array<InputMaybe<BackofficeUserTimeAreaInput>>
  userTenants: Array<Scalars['String']['input']>
  username: Scalars['String']['input']
}

export type BoundingBoxFilterInput = {
  maxLat: Scalars['Float']['input']
  maxLon: Scalars['Float']['input']
  minLat: Scalars['Float']['input']
  minLon: Scalars['Float']['input']
}

export type BoxPc = {
  __typename?: 'BoxPc'
  artifactName: Maybe<Scalars['String']['output']>
  cpuId: Scalars['ID']['output']
  greengrassDeviceId: Scalars['String']['output']
  greengrassProvisioningState: Maybe<GreengrassProvisioningState>
  macAddr: Scalars['String']['output']
  provisioningState: ProvisioningState
}

export type BoxPcReplacedChange = FleetEntityChange & {
  __typename?: 'BoxPcReplacedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: BoxPcReplacedChangeFields
}

export type BoxPcReplacedChangeFields = VehicleChangeFields & {
  __typename?: 'BoxPcReplacedChangeFields'
  boxPcNew: Maybe<BoxPc>
  boxPcOld: Maybe<BoxPc>
  vehicleID: Scalars['ID']['output']
}

export type BoxPcWipedChange = FleetEntityChange & {
  __typename?: 'BoxPcWipedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: BoxPcWipedChangeFields
}

export type BoxPcWipedChangeFields = VehicleChangeFields & {
  __typename?: 'BoxPcWipedChangeFields'
  boxPcWiped: Maybe<BoxPc>
  vehicleID: Scalars['ID']['output']
}

export enum BoxProvider {
  Cdis = 'CDIS',
  NoProvider = 'NO_PROVIDER',
}

export enum CdisError {
  ErrorBatteryLevelLow = 'ERROR_BATTERY_LEVEL_LOW',
  ErrorBonnetOpen = 'ERROR_BONNET_OPEN',
  ErrorCanBusActive = 'ERROR_CAN_BUS_ACTIVE',
  ErrorClamp_15On = 'ERROR_CLAMP_15_ON',
  ErrorClampSOn = 'ERROR_CLAMP_S_ON',
  ErrorClimateControlTimeout = 'ERROR_CLIMATE_CONTROL_TIMEOUT',
  ErrorConvertibleRoofOpen = 'ERROR_CONVERTIBLE_ROOF_OPEN',
  ErrorEngineOn = 'ERROR_ENGINE_ON',
  ErrorFrontDriverSideDoorOpen = 'ERROR_FRONT_DRIVER_SIDE_DOOR_OPEN',
  ErrorFrontDriverSideWindowOpen = 'ERROR_FRONT_DRIVER_SIDE_WINDOW_OPEN',
  ErrorFrontPassengerSideDoorOpen = 'ERROR_FRONT_PASSENGER_SIDE_DOOR_OPEN',
  ErrorFrontPassengerSideWindowOpen = 'ERROR_FRONT_PASSENGER_SIDE_WINDOW_OPEN',
  ErrorLightsOn = 'ERROR_LIGHTS_ON',
  ErrorMissingChargingCable = 'ERROR_MISSING_CHARGING_CABLE',
  ErrorMissingRfid1 = 'ERROR_MISSING_RFID1',
  ErrorNoCdisBoxAssigned = 'ERROR_NO_CDIS_BOX_ASSIGNED',
  ErrorRearDriverSideDoorOpen = 'ERROR_REAR_DRIVER_SIDE_DOOR_OPEN',
  ErrorRearDriverSideWindowOpen = 'ERROR_REAR_DRIVER_SIDE_WINDOW_OPEN',
  ErrorRearPassengerSideDoorOpen = 'ERROR_REAR_PASSENGER_SIDE_DOOR_OPEN',
  ErrorRearPassengerSideWindowOpen = 'ERROR_REAR_PASSENGER_SIDE_WINDOW_OPEN',
  ErrorRequestTooOld = 'ERROR_REQUEST_TOO_OLD',
  ErrorShutdownTimeOutOfRange = 'ERROR_SHUTDOWN_TIME_OUT_OF_RANGE',
  ErrorSunRoofOpen = 'ERROR_SUN_ROOF_OPEN',
  ErrorTimeoutWaitingForCentralLocking = 'ERROR_TIMEOUT_WAITING_FOR_CENTRAL_LOCKING',
  ErrorTrunkOpen = 'ERROR_TRUNK_OPEN',
  ErrorUnknown = 'ERROR_UNKNOWN',
  ErrorVehicleIsMoving = 'ERROR_VEHICLE_IS_MOVING',
  None = 'NONE',
}

export type CabinVideoStreamCredentials = {
  __typename?: 'CabinVideoStreamCredentials'
  accessKeyId: Scalars['String']['output']
  channelName: Scalars['String']['output']
  secretAccessKey: Scalars['String']['output']
  sessionToken: Scalars['String']['output']
  validUntil: Scalars['DateTime']['output']
}

export type Camera = {
  __typename?: 'Camera'
  macAddr: Scalars['ID']['output']
  serialNumber: Maybe<Scalars['String']['output']>
}

export type CameraReplacedChange = FleetEntityChange & {
  __typename?: 'CameraReplacedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: CameraReplacedChangeFields
}

export type CameraReplacedChangeFields = VehicleChangeFields & {
  __typename?: 'CameraReplacedChangeFields'
  cameraNew: Maybe<Camera>
  cameraOld: Maybe<Camera>
  vehicleID: Scalars['ID']['output']
}

export enum CampaignConfigPurpose {
  B2BMarketingCampaign = 'B2B_MARKETING_CAMPAIGN',
  B2CMarketingCampaign = 'B2C_MARKETING_CAMPAIGN',
  Barter = 'BARTER',
  Experiment = 'EXPERIMENT',
  Pricing = 'PRICING',
}

export enum CancellationType {
  CustomerCanceled = 'CUSTOMER_CANCELED',
  CustomerNotShown = 'CUSTOMER_NOT_SHOWN',
  None = 'NONE',
  VehicleServiceAbortion = 'VEHICLE_SERVICE_ABORTION',
}

export type CardData = {
  __typename?: 'CardData'
  cardType: Maybe<Scalars['String']['output']>
  last4Digits: Maybe<Scalars['String']['output']>
}

export type CdisBox = {
  __typename?: 'CdisBox'
  imei: Scalars['ID']['output']
  imsi: Scalars['String']['output']
  macAddr: Scalars['String']['output']
  serialNumber: Scalars['String']['output']
}

export type CdisBoxReplacedChange = FleetEntityChange & {
  __typename?: 'CdisBoxReplacedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: CdisBoxReplacedChangeFields
}

export type CdisBoxReplacedChangeFields = VehicleChangeFields & {
  __typename?: 'CdisBoxReplacedChangeFields'
  cdisBoxNew: Maybe<CdisBox>
  cdisBoxOld: Maybe<CdisBox>
  vehicleID: Scalars['ID']['output']
}

export enum CentralLockState {
  Locked = 'LOCKED',
  Unlocked = 'UNLOCKED',
}

export enum ChargingMode {
  Ac = 'AC',
  Dc = 'DC',
  Off = 'OFF',
}

export type ChildAge = {
  __typename?: 'ChildAge'
  max: Scalars['Int']['output']
  min: Scalars['Int']['output']
}

export type ChildAgeInput = {
  max: Scalars['Int']['input']
  min: Scalars['Int']['input']
}

export type ChildSeatSpec = {
  __typename?: 'ChildSeatSpec'
  isAvailable: Scalars['Boolean']['output']
  isBoosterSeat: Scalars['Boolean']['output']
  isChildSeat: Scalars['Boolean']['output']
  seatNumber: Scalars['Int']['output']
}

export type ChildSeatSpecInput = {
  isAvailable: Scalars['Boolean']['input']
  isBoosterSeat: Scalars['Boolean']['input']
  isChildSeat: Scalars['Boolean']['input']
  seatNumber: Scalars['Int']['input']
}

export type ChildrenPricing = {
  __typename?: 'ChildrenPricing'
  accompaniedChildrenFree: Scalars['Boolean']['output']
  accompaniedChildrenFreeDisplayText: Maybe<Array<PriceConfigDisplayText>>
  unaccompaniedChildrenDiscount: Scalars['Float']['output']
  unaccompaniedChildrenDiscountDisplayText: Maybe<Array<PriceConfigDisplayText>>
}

export type ChildrenPricingInput = {
  accompaniedChildrenFree: Scalars['Boolean']['input']
  accompaniedChildrenFreeDisplayText: InputMaybe<
    Array<PriceConfigDisplayTextInput>
  >
  unaccompaniedChildrenDiscount: Scalars['Float']['input']
  unaccompaniedChildrenDiscountDisplayText: InputMaybe<
    Array<PriceConfigDisplayTextInput>
  >
}

export enum ClimateControlState {
  Cooling = 'COOLING',
  Heating = 'HEATING',
  Off = 'OFF',
}

export type CloseVehicleByBackofficeUserInput = {
  isBatchInteraction: Scalars['Boolean']['input']
  vehicleID: Scalars['ID']['input']
}

/** The CLOSE interaction looks up the matching OPEN interaction and takes all data from there */
export type CloseVehicleInput = {
  vehicleID: Scalars['ID']['input']
}

export type ClosestHubResponse = {
  __typename?: 'ClosestHubResponse'
  hub: Hub
}

export type ConfigureVehicleSeatsInput = {
  /** A list of indices of child seat configs, that are available for this vehicle */
  availableChildSeats: Array<Scalars['Int']['input']>
  /** The number of 'normal' seats that are available for this vehicle */
  numberOfSeats: Scalars['Int']['input']
  /** The number of wheelchair seats that are available for this vehicle */
  numberOfWheelchairSeats: Scalars['Int']['input']
}

export type ConfirmCabinSafetyInput = {
  vehicleId: Scalars['ID']['input']
}

export enum ConnectedState {
  ConnectedInterrupted = 'CONNECTED_INTERRUPTED',
  ConnectedOffline = 'CONNECTED_OFFLINE',
  ConnectedOnline = 'CONNECTED_ONLINE',
  ConnectedStateUnknown = 'CONNECTED_STATE_UNKNOWN',
}

export type CooperationDetails = {
  __typename?: 'CooperationDetails'
  stop: Maybe<NavigationStop>
}

export enum CountryCode {
  Ac = 'AC',
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  An = 'AN',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bu = 'BU',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cp = 'CP',
  Cr = 'CR',
  Cs = 'CS',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dg = 'DG',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ea = 'EA',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Eu = 'EU',
  Ez = 'EZ',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Fx = 'FX',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Ic = 'IC',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nt = 'NT',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sf = 'SF',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Su = 'SU',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Ta = 'TA',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tp = 'TP',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Uk = 'UK',
  Um = 'UM',
  Undefined = 'UNDEFINED',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Xk = 'XK',
  Ye = 'YE',
  Yt = 'YT',
  Yu = 'YU',
  Za = 'ZA',
  Zm = 'ZM',
  Zr = 'ZR',
  Zw = 'ZW',
}

export type CreateCustomPriceConfigInput = {
  childrenPricing: ChildrenPricingInput
  classicServiceClass: ServiceClassParametersInput
  conditions: InputMaybe<CustomPriceConfigConditionsInput>
  couponsAllowed: Scalars['Boolean']['input']
  displayText: Array<CustomPriceConfigDisplayTextInput>
  dynamicPricing: InputMaybe<DynamicPricingInput>
  expressServiceClass: ServiceClassParametersInput
  expressServiceClassSurcharge: MoneyInput
  name: Scalars['String']['input']
  parameters: PriceConfigParametersInput
  priority: Scalars['Int']['input']
  purpose: CustomPriceConfigPurpose
  serviceAreaID: Scalars['ID']['input']
  validFrom: Scalars['DateTime']['input']
  validUntil: Scalars['DateTime']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type CreateCustomPriceConfigResponse = {
  __typename?: 'CreateCustomPriceConfigResponse'
  config: CustomPriceConfigResponse
}

export type CreateDefaultPriceConfigInput = {
  childrenPricing: ChildrenPricingInput
  classicServiceClass: ServiceClassParametersInput
  dynamicPricing: InputMaybe<DynamicPricingInput>
  expressServiceClass: ServiceClassParametersInput
  expressServiceClassSurcharge: MoneyInput
  name: Scalars['String']['input']
  parameters: PriceConfigParametersInput
  serviceAreaID: Scalars['ID']['input']
  validFrom: Scalars['DateTime']['input']
}

/**
 * Possible Errors:
 * 1. Server Errors, e.g.:
 * errors: [
 * {
 * message: "failed to generate uuid",
 * path: [ "input"],
 * }
 * ]
 * 2. Not found, e.g.:
 * errors: [
 * {
 * message: "price config not found",
 * path: [ "deleteDefaultPriceConfig"],
 * }
 * ]
 * 3. Unauthorized, e.g.:
 * errors: [
 * {
 * message: "operation unauthorized",
 * path": ["createDefaultPriceConfig"]
 * }
 * ]
 * 4. Validation Errors, e.g.:
 * ...
 */
export type CreateDefaultPriceConfigResponse = {
  __typename?: 'CreateDefaultPriceConfigResponse'
  config: DefaultPriceConfigResponse
}

/** Input object for creating a pricing polygon in the polygon library */
export type CreatePriceConfigPolygonInput = {
  locations: Array<LocationInput>
  name: Scalars['String']['input']
  serviceAreaID: Scalars['ID']['input']
}

export type CreatePriceConfigPolygonResponse = {
  __typename?: 'CreatePriceConfigPolygonResponse'
  polygon: PriceConfigPolygon
}

export type CreatePricingCampaignResponse = {
  __typename?: 'CreatePricingCampaignResponse'
  campaign: PricingCampaign
}

export type CreateVehicleInput = {
  serviceAreaUUID: Scalars['String']['input']
  tenant: Scalars['String']['input']
  vin: Scalars['String']['input']
}

export type CreateWorkOrdersInput = {
  serviceAreaUUID: Scalars['ID']['input']
  serviceItem: WorkOrderServiceItem
  status: WorkOrderStatus
  vehicleIDs: Array<Scalars['ID']['input']>
}

export type CreditCard = {
  __typename?: 'CreditCard'
  last4Digits: Scalars['String']['output']
  provider: Scalars['String']['output']
}

export enum Criticality {
  Accident = 'ACCIDENT',
  CriticalIiIncident = 'CRITICAL_II_INCIDENT',
  CriticalIIncident = 'CRITICAL_I_INCIDENT',
  MajorIiIncident = 'MAJOR_II_INCIDENT',
  MajorIDynIncident = 'MAJOR_I_DYN_INCIDENT',
  MajorIIncident = 'MAJOR_I_INCIDENT',
  MinorIncident = 'MINOR_INCIDENT',
  NoIncident = 'NO_INCIDENT',
  ReversibleIncident = 'REVERSIBLE_INCIDENT',
  SporadicIncident = 'SPORADIC_INCIDENT',
  UncontrollableIrrelevantIncident = 'UNCONTROLLABLE_IRRELEVANT_INCIDENT',
}

export enum CurrencyCode {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Bov = 'BOV',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Bwp = 'BWP',
  Byn = 'BYN',
  Byr = 'BYR',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clf = 'CLF',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Cou = 'COU',
  Crc = 'CRC',
  Cuc = 'CUC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Ltl = 'LTL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mru = 'MRU',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Mxv = 'MXV',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nio = 'NIO',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rur = 'RUR',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Ssp = 'SSP',
  Std = 'STD',
  Stn = 'STN',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmt = 'TMT',
  Tnd = 'TND',
  Top = 'TOP',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Undefined = 'UNDEFINED',
  Usd = 'USD',
  Usn = 'USN',
  Uss = 'USS',
  Uyi = 'UYI',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Vef = 'VEF',
  Ves = 'VES',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xaf = 'XAF',
  Xag = 'XAG',
  Xau = 'XAU',
  Xba = 'XBA',
  Xbb = 'XBB',
  Xbc = 'XBC',
  Xbd = 'XBD',
  Xcd = 'XCD',
  Xdr = 'XDR',
  Xof = 'XOF',
  Xpd = 'XPD',
  Xpf = 'XPF',
  Xpt = 'XPT',
  Xsu = 'XSU',
  Xts = 'XTS',
  Xua = 'XUA',
  Xxx = 'XXX',
  Yer = 'YER',
  Zar = 'ZAR',
  Zmw = 'ZMW',
  Zwl = 'ZWL',
}

export type CurrentlyDrivenRouteResponse = {
  __typename?: 'CurrentlyDrivenRouteResponse'
  hasActiveRoute: Scalars['Boolean']['output']
  vehicleId: Scalars['ID']['output']
  vehicleRoute: Maybe<VehicleRoute>
}

export type CursorListResponse = {
  /** Identifier for the first item that would be in the next response page */
  nextCursor: Maybe<Scalars['ID']['output']>
  /** Total items in the set that can be paginated over. Can be emtpy to indicate the total is unknown */
  total: Maybe<Scalars['Int']['output']>
}

export type CursorPaginationInput = {
  /** This is not implemented yet, needs to be treated as offset for now, e.g. 0, 50, etc */
  cursor: InputMaybe<Scalars['ID']['input']>
  limit: Scalars['Int']['input']
}

export type CustomConfigOrderByInput = {
  validFrom: InputMaybe<Sort>
  validUntil: InputMaybe<Sort>
}

export type CustomPriceConfigConditions = {
  __typename?: 'CustomPriceConfigConditions'
  passengerCountCondition: Maybe<CustomPriceConfigPassengerCountCondition>
  polygonLocationConditions: Maybe<
    Array<Maybe<CustomPriceConfigPolygonLocationCondition>>
  >
  serviceClassCondition: Maybe<CustomPriceConfigServiceClassCondition>
  testGroupCondition: Maybe<CustomPriceConfigTestGroupCondition>
  timeCondition: Maybe<CustomPriceConfigTimeCondition>
}

export type CustomPriceConfigConditionsInput = {
  passengerCountCondition: InputMaybe<CustomPriceConfigPassengerCountConditionInput>
  polygonLocationConditions: InputMaybe<
    Array<InputMaybe<CustomPriceConfigPolygonLocationConditionInput>>
  >
  serviceClassCondition: InputMaybe<CustomPriceConfigServiceClassConditionInput>
  testGroupCondition: InputMaybe<CustomPriceConfigTestGroupConditionInput>
  timeCondition: InputMaybe<CustomPriceConfigTimeConditionInput>
}

export type CustomPriceConfigDisplayText = {
  __typename?: 'CustomPriceConfigDisplayText'
  header: Maybe<Scalars['String']['output']>
  locale: Scalars['String']['output']
  text: Maybe<Scalars['String']['output']>
}

export type CustomPriceConfigDisplayTextInput = {
  header: InputMaybe<Scalars['String']['input']>
  locale: Scalars['String']['input']
  text: InputMaybe<Scalars['String']['input']>
}

export type CustomPriceConfigPassengerCountCondition = {
  __typename?: 'CustomPriceConfigPassengerCountCondition'
  passengerCount: Scalars['Int']['output']
}

export type CustomPriceConfigPassengerCountConditionInput = {
  passengerCount: Scalars['Int']['input']
}

export type CustomPriceConfigPolygonLocationCondition = {
  __typename?: 'CustomPriceConfigPolygonLocationCondition'
  action: LocationActionCondition
  polygon: PriceConditionPolygon
}

export type CustomPriceConfigPolygonLocationConditionInput = {
  action: LocationActionCondition
  polygon: PriceConfigPolygonInput
}

export enum CustomPriceConfigPurpose {
  B2BMarketingCampaign = 'B2B_MARKETING_CAMPAIGN',
  B2CMarketingCampaign = 'B2C_MARKETING_CAMPAIGN',
  Barter = 'BARTER',
  Experiment = 'EXPERIMENT',
  Legacy = 'LEGACY',
  Pricing = 'PRICING',
}

export type CustomPriceConfigResponse = {
  __typename?: 'CustomPriceConfigResponse'
  changeLog: Array<PriceConfigChangeLogEntry>
  childrenPricing: ChildrenPricing
  classicServiceClass: Maybe<ServiceClassParameters>
  conditions: Maybe<CustomPriceConfigConditions>
  couponsAllowed: Scalars['Boolean']['output']
  displayText: Array<CustomPriceConfigDisplayText>
  dynamicPricing: Maybe<DynamicPricing>
  expressServiceClass: Maybe<ServiceClassParameters>
  expressServiceClassSurcharge: Money
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parameters: PriceConfigParameters
  possibleStateActions: Array<PriceConfigStateAction>
  priority: Scalars['Int']['output']
  purpose: CustomPriceConfigPurpose
  serviceAreaID: Scalars['ID']['output']
  state: PriceConfigState
  validFrom: Scalars['DateTime']['output']
  validUntil: Scalars['DateTime']['output']
  version: Scalars['Int']['output']
}

export enum CustomPriceConfigServiceClass {
  Classic = 'CLASSIC',
  Express = 'EXPRESS',
}

export type CustomPriceConfigServiceClassCondition = {
  __typename?: 'CustomPriceConfigServiceClassCondition'
  serviceClass: CustomPriceConfigServiceClass
}

export type CustomPriceConfigServiceClassConditionInput = {
  serviceClass: CustomPriceConfigServiceClass
}

export type CustomPriceConfigTestGroupCondition = {
  __typename?: 'CustomPriceConfigTestGroupCondition'
  group: Maybe<Scalars['String']['output']>
}

export type CustomPriceConfigTestGroupConditionInput = {
  /** test group to be member of to match condition */
  group: InputMaybe<Scalars['String']['input']>
}

export type CustomPriceConfigTimeCondition = {
  __typename?: 'CustomPriceConfigTimeCondition'
  days: Array<DayOfWeek>
  timeSlots: Array<PriceConfigTimeSlot>
}

export type CustomPriceConfigTimeConditionInput = {
  days: Array<DayOfWeek>
  timeSlots: InputMaybe<Array<PriceConfigTimeSlotInput>>
}

export type CustomPriceConfigsResponse = ListResponse & {
  __typename?: 'CustomPriceConfigsResponse'
  customPriceConfigs: Array<CustomPriceConfigResponse>
  total: Scalars['Int']['output']
}

/** The data that is gathered for a customer */
export type Customer = {
  __typename?: 'Customer'
  /** The optional sign up IP address of the customer */
  createdIpAddress: Maybe<Scalars['String']['output']>
  /** Creation date of the customer profile (ISO-8601 Date and time with offset) */
  creationDate: Maybe<Scalars['OffsetDateTime']['output']>
  /** Indicates if the customer enabled the marketing permissions in the past */
  disqualifiedFromMarketingPermissionCoupon: Maybe<Scalars['Boolean']['output']>
  /** Email address of the customer */
  email: Maybe<Scalars['String']['output']>
  /** The email verified flag for the customer */
  emailVerified: Scalars['Boolean']['output']
  /** The extended walking time flag for the customer */
  extendedWalkingTime: Scalars['Boolean']['output']
  /** The first name of the customer */
  firstName: Maybe<Scalars['String']['output']>
  /** HVV  Partnership valid until  date (ISO-8601 Date and time with offset) */
  hvvPartnershipValidUntil: Maybe<Scalars['OffsetDateTime']['output']>
  /** Unique identifier of the customer created by cognito */
  id: Scalars['ID']['output']
  /** The status of the user in cognito. If 'false' the user can not sign in. */
  isEnabled: Scalars['Boolean']['output']
  /** Last modified date of the customer data (ISO-8601 Date and time with offset) */
  lastModifiedDate: Maybe<Scalars['OffsetDateTime']['output']>
  /** The last name of the customer */
  lastName: Maybe<Scalars['String']['output']>
  /** The customers locale/language (BCP 47, based on customers mobile device language settings) */
  locale: Scalars['String']['output']
  /** Marketing permissions of the customer */
  marketingPermissions: Maybe<MarketingPermissions>
  /** The phone number of the customer. */
  phoneNumber: Maybe<Scalars['String']['output']>
  /** The phone verified flag for the customer */
  phoneVerified: Scalars['Boolean']['output']
  /** The accessibility flag for severely disabled customers */
  severelyDisabled: Maybe<SeverelyDisabled>
  /** Tenant identifier of the customer */
  tenant: Scalars['String']['output']
  /** Confirmed specific versions of the terms and conditions */
  termsAndConditions: Maybe<TermsAndConditions>
  /** The visual impairment flag for the customer */
  visualImpairment: Maybe<Scalars['Boolean']['output']>
  /** The accessibility flag for the wheelchair of the customer */
  wheelchairAccess: Scalars['Boolean']['output']
  /** Zip-code of the customers location at the time of confirming marketing permissions */
  zipCode: Maybe<Scalars['String']['output']>
}

export type CustomerResponse = {
  __typename?: 'CustomerResponse'
  customer: Maybe<Customer>
}

/** The field of the customer profile to find customer */
export enum CustomerSearchField {
  /** Search by email */
  Email = 'EMAIL',
  /** Search by firstname */
  FirstName = 'FIRST_NAME',
  /** Search by lastname */
  LastName = 'LAST_NAME',
  /** Search by phone number */
  PhoneNumber = 'PHONE_NUMBER',
  /** Unspecified search by all fields */
  Unspecified = 'UNSPECIFIED',
}

export type CustomerSearchInput = {
  field: CustomerSearchField
  text: Scalars['String']['input']
}

export type CustomerSearchResponse = {
  __typename?: 'CustomerSearchResponse'
  customers: Array<Customer>
  totalHits: Scalars['Int']['output']
}

export type CustomersResponse = {
  __typename?: 'CustomersResponse'
  customers: Array<Maybe<Customer>>
  total: Scalars['Int']['output']
}

export type Damage = {
  __typename?: 'Damage'
  description: Maybe<Scalars['String']['output']>
  solved: Scalars['Boolean']['output']
  vehiclePartId: Scalars['ID']['output']
}

export type DamageImage = {
  __typename?: 'DamageImage'
  id: Scalars['ID']['output']
  thumbnailUrl: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type DamageImageInput = {
  id: Scalars['ID']['input']
  thumbnailUrl: InputMaybe<Scalars['String']['input']>
  url: Scalars['String']['input']
}

export type DamageInput = {
  description: InputMaybe<Scalars['String']['input']>
  vehiclePartId: Scalars['ID']['input']
}

export type DamageReport = {
  __typename?: 'DamageReport'
  actualRepairCost: Maybe<Money>
  createdAt: Scalars['DateTime']['output']
  damages: Array<Damage>
  employee: Maybe<Employee>
  estimatedRepairCost: Maybe<Money>
  id: Scalars['ID']['output']
  images: Maybe<Array<DamageImage>>
  ownFault: Scalars['Boolean']['output']
  personalInjury: Scalars['Boolean']['output']
  state: DamageReportState
  updatedAt: Scalars['DateTime']['output']
  updatedByUser: Maybe<BackofficeUser>
  vehicle: Maybe<Vehicle>
}

export type DamageReportInput = {
  actualRepairCost: InputMaybe<MoneyInput>
  damages: Array<DamageInput>
  employeeID: InputMaybe<Scalars['ID']['input']>
  estimatedRepairCost: InputMaybe<MoneyInput>
  ownFault: InputMaybe<Scalars['Boolean']['input']>
  personalInjury: InputMaybe<Scalars['Boolean']['input']>
  vehicleId: Scalars['ID']['input']
}

/** In the end we will only support one enum for supported languages for vehicle checks and damage reports */
export enum DamageReportLanguage {
  De = 'DE',
  En = 'EN',
}

export type DamageReportList = CursorListResponse & {
  __typename?: 'DamageReportList'
  damageReports: Array<DamageReport>
  nextCursor: Maybe<Scalars['ID']['output']>
  total: Scalars['Int']['output']
}

export type DamageReportSearch = {
  fromDate: InputMaybe<Scalars['DateTime']['input']>
  pagination: InputMaybe<CursorPaginationInput>
  serviceAreaUUID: Scalars['ID']['input']
  state: DamageReportState
  toDate: InputMaybe<Scalars['DateTime']['input']>
}

export enum DamageReportState {
  Approved = 'APPROVED',
  AwaitingApproval = 'AWAITING_APPROVAL',
  Deleted = 'DELETED',
  Solved = 'SOLVED',
}

export type DamageReportUpdateInput = {
  actualRepairCost: InputMaybe<MoneyInput>
  damages: Array<DamageUpdateInput>
  employeeID: Scalars['ID']['input']
  estimatedRepairCost: InputMaybe<MoneyInput>
  id: Scalars['ID']['input']
  images: Array<DamageImageInput>
  ownFault: Scalars['Boolean']['input']
  personalInjury: Scalars['Boolean']['input']
  state: DamageReportState
}

export type DamageUpdateInput = {
  description: InputMaybe<Scalars['String']['input']>
  solved: Scalars['Boolean']['input']
  vehiclePartId: Scalars['ID']['input']
}

export type DateTimeInterval = {
  __typename?: 'DateTimeInterval'
  from: Scalars['DateTime']['output']
  to: Scalars['DateTime']['output']
}

export type DateTimeIntervalInput = {
  from: InputMaybe<Scalars['DateTime']['input']>
  to: InputMaybe<Scalars['DateTime']['input']>
}

export enum DayOfWeek {
  Friday = 'FRIDAY',
  Monday = 'MONDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
  Thursday = 'THURSDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
}

export type DeactivateVehicleInput = {
  changeReasonFreeText: InputMaybe<Scalars['String']['input']>
  /** Date and time when the vehicle is expected to be activated again, basically a downtime forecast. */
  expectedActivationAt: InputMaybe<Scalars['DateTime']['input']>
  vehicleId: Scalars['ID']['input']
  vehicleStateChangeReason: InputMaybe<VehicleStateChangeReason>
}

export enum Deactivation {
  Any = 'ANY',
  Current = 'CURRENT',
  Planned = 'PLANNED',
}

export type DefaultPriceConfigResponse = {
  __typename?: 'DefaultPriceConfigResponse'
  changeLog: Array<PriceConfigChangeLogEntry>
  childrenPricing: ChildrenPricing
  classicServiceClass: Maybe<ServiceClassParameters>
  dynamicPricing: Maybe<DynamicPricing>
  expressServiceClass: Maybe<ServiceClassParameters>
  expressServiceClassSurcharge: Money
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  parameters: PriceConfigParameters
  possibleStateActions: Array<PriceConfigStateAction>
  serviceAreaID: Scalars['ID']['output']
  state: PriceConfigState
  validFrom: Scalars['DateTime']['output']
  version: Scalars['Int']['output']
}

export type DefaultPriceConfigsResponse = ListResponse & {
  __typename?: 'DefaultPriceConfigsResponse'
  defaultPriceConfigs: Array<DefaultPriceConfigResponse>
  total: Scalars['Int']['output']
}

export type DeleteCustomPriceConfigInput = {
  id: Scalars['ID']['input']
  version: Scalars['Int']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type DeleteCustomPriceConfigResponse = {
  __typename?: 'DeleteCustomPriceConfigResponse'
  config: CustomPriceConfigResponse
}

export type DeleteDefaultPriceConfigInput = {
  id: Scalars['ID']['input']
  version: Scalars['Int']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type DeleteDefaultPriceConfigResponse = {
  __typename?: 'DeleteDefaultPriceConfigResponse'
  config: DefaultPriceConfigResponse
}

export type DetailsDto = {
  __typename?: 'DetailsDto'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type DisableCustomerInput = {
  id: Scalars['ID']['input']
}

export type DisableCustomerResponse = {
  __typename?: 'DisableCustomerResponse'
  success: Scalars['Boolean']['output']
}

export type DisapproveCustomPriceConfigInput = {
  id: Scalars['ID']['input']
  version: Scalars['Int']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type DisapproveCustomPriceConfigResponse = {
  __typename?: 'DisapproveCustomPriceConfigResponse'
  config: CustomPriceConfigResponse
}

export type DisapproveDefaultPriceConfigInput = {
  id: Scalars['ID']['input']
  version: Scalars['Int']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type DisapproveDefaultPriceConfigResponse = {
  __typename?: 'DisapproveDefaultPriceConfigResponse'
  config: DefaultPriceConfigResponse
}

export enum DoorState {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type DsBossCreateOrUpdate = {
  description: InputMaybe<Scalars['String']['input']>
  isFinal: Scalars['Boolean']['input']
  iteration: Scalars['String']['input']
  monthlyParameters: InputMaybe<Array<InputMaybe<DsBossMonthlyParameterInput>>>
  predictionEndTimestampUtc: Scalars['DateTime']['input']
  predictionStartTimestampUtc: Scalars['DateTime']['input']
  process: DsBossProcess
  scenarioId: InputMaybe<Scalars['ID']['input']>
  scenarioName: Scalars['String']['input']
  serviceAreaId: Scalars['ID']['input']
  timeDependentParameters: InputMaybe<
    Array<InputMaybe<DsBossTimeDependentParameterInput>>
  >
  trainingEndTimestampUtc: Scalars['DateTime']['input']
  trainingStartTimestampUtc: Scalars['DateTime']['input']
  useSciFactors: InputMaybe<Scalars['Boolean']['input']>
}

export type DsBossCreateOrUpdateResponse = {
  __typename?: 'DsBossCreateOrUpdateResponse'
  scenarioId: Scalars['ID']['output']
  status: DsBossCreateOrUpdateStatus
}

export enum DsBossCreateOrUpdateStatus {
  Created = 'Created',
  Error = 'Error',
  ParallelRun = 'ParallelRun',
  SavedFinalOutput = 'SavedFinalOutput',
  Updated = 'Updated',
}

export type DsBossDelete = {
  iteration: Scalars['String']['input']
  scenarioId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
}

export type DsBossDeleteResponse = {
  __typename?: 'DsBossDeleteResponse'
  status: DsBossDeleteStatus
}

export enum DsBossDeleteStatus {
  Deleted = 'DELETED',
  Error = 'ERROR',
  MarkedFinal = 'MARKED_FINAL',
  StillRunning = 'STILL_RUNNING',
}

export enum DsBossFileType {
  MonthlyAggregatesExcel = 'MONTHLY_AGGREGATES_EXCEL',
  PlanningDfParquet = 'PLANNING_DF_PARQUET',
}

export type DsBossGetBasedOnScenarioId = {
  dsBossFileType: InputMaybe<DsBossFileType>
  iteration: Scalars['String']['input']
  scenarioId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
}

export type DsBossGetBasedOnServiceAreaId = {
  serviceAreaId: Scalars['ID']['input']
}

export type DsBossGetFileResponse = {
  __typename?: 'DsBossGetFileResponse'
  dsBossFileType: DsBossFileType
  file: Maybe<Scalars['String']['output']>
  status: DsBossStatus
  statusMessage: Maybe<Scalars['String']['output']>
}

export type DsBossMonthlyParameter = {
  __typename?: 'DsBossMonthlyParameter'
  description: Maybe<Scalars['String']['output']>
  month: Scalars['Int']['output']
  parameterName: DsBossMonthlyParameterName
  value: Scalars['Float']['output']
  year: Scalars['Int']['output']
}

export type DsBossMonthlyParameterInput = {
  description: InputMaybe<Scalars['String']['input']>
  month: Scalars['Int']['input']
  parameterName: DsBossMonthlyParameterName
  value: Scalars['Float']['input']
  year: Scalars['Int']['input']
}

export enum DsBossMonthlyParameterName {
  MonthlyExpectedSupply = 'MONTHLY_EXPECTED_SUPPLY',
  MonthlySupplyConstraint = 'MONTHLY_SUPPLY_CONSTRAINT',
}

export enum DsBossProcess {
  Budget = 'BUDGET',
  Rmp = 'RMP',
}

export type DsBossScenarioDetails = {
  __typename?: 'DsBossScenarioDetails'
  creationTimestampUtc: Scalars['DateTime']['output']
  description: Maybe<Scalars['String']['output']>
  isFinal: Scalars['Boolean']['output']
  iteration: Scalars['String']['output']
  lastEditedUtc: Scalars['DateTime']['output']
  monthlyParameters: Maybe<Array<Maybe<DsBossMonthlyParameter>>>
  predictionEndTimestampUtc: Scalars['DateTime']['output']
  predictionStartTimestampUtc: Scalars['DateTime']['output']
  process: DsBossProcess
  scenarioId: Scalars['ID']['output']
  scenarioName: Scalars['String']['output']
  serviceAreaId: Scalars['ID']['output']
  status: DsBossStatus
  timeDependentParameters: Maybe<Array<Maybe<DsBossTimeDependentParameter>>>
  trainingEndTimestampUtc: Scalars['DateTime']['output']
  trainingStartTimestampUtc: Scalars['DateTime']['output']
  useSciFactors: Maybe<Scalars['Boolean']['output']>
}

export type DsBossScenarioDetailsResponse = {
  __typename?: 'DsBossScenarioDetailsResponse'
  scenarioDetails: Maybe<DsBossScenarioDetails>
}

export type DsBossScenarioShortDescription = {
  __typename?: 'DsBossScenarioShortDescription'
  creationTimestampUtc: Scalars['DateTime']['output']
  isFinal: Scalars['Boolean']['output']
  iteration: Scalars['String']['output']
  lastEditedUtc: Scalars['DateTime']['output']
  predictionEndTimestampUtc: Scalars['DateTime']['output']
  predictionStartTimestampUtc: Scalars['DateTime']['output']
  scenarioId: Scalars['String']['output']
  scenarioName: Scalars['String']['output']
  serviceAreaId: Scalars['ID']['output']
}

export type DsBossScenariosResponse = ListResponse & {
  __typename?: 'DsBossScenariosResponse'
  missingValues: Maybe<Scalars['Boolean']['output']>
  scenarios: Maybe<Array<Maybe<DsBossScenarioShortDescription>>>
  total: Scalars['Int']['output']
}

export enum DsBossStatus {
  Error = 'ERROR',
  Finished = 'FINISHED',
  Running = 'RUNNING',
}

export type DsBossTimeDependentParameter = {
  __typename?: 'DsBossTimeDependentParameter'
  description: Maybe<Scalars['String']['output']>
  endTimestampUtc: Scalars['DateTime']['output']
  parameterName: DsBossTimeDependentParameterName
  startTimestampUtc: Scalars['DateTime']['output']
  value: Scalars['Float']['output']
}

export type DsBossTimeDependentParameterInput = {
  description: InputMaybe<Scalars['String']['input']>
  endTimestampUtc: Scalars['DateTime']['input']
  parameterName: DsBossTimeDependentParameterName
  startTimestampUtc: Scalars['DateTime']['input']
  value: Scalars['Float']['input']
}

export enum DsBossTimeDependentParameterName {
  DemandScalingFactor = 'DEMAND_SCALING_FACTOR',
  MaxVehicles = 'MAX_VEHICLES',
  MinVehicles = 'MIN_VEHICLES',
  PassengersScalingFactor = 'PASSENGERS_SCALING_FACTOR',
  PlannedVrhScalingFactor = 'PLANNED_VRH_SCALING_FACTOR',
  TargetAcceptanceRate = 'TARGET_ACCEPTANCE_RATE',
  TargetPrice = 'TARGET_PRICE',
  TravelTimeScalingFactor = 'TRAVEL_TIME_SCALING_FACTOR',
}

export type DsBossUpdateBasedOnScenarioId = {
  iteration: Scalars['String']['input']
  scenarioId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
}

export type DynamicPricing = {
  __typename?: 'DynamicPricing'
  lowerBoundDeviationToDistanceBased: Maybe<Scalars['Float']['output']>
  /** @deprecated Use `lowerBoundDeviationToDistanceBased` and `upperBoundDeviationToDistanceBased`. */
  maxDeviationToDistanceBased: Maybe<Scalars['Float']['output']>
  minPrice: Money
  upperBoundDeviationToDistanceBased: Maybe<Scalars['Float']['output']>
}

export type DynamicPricingInput = {
  lowerBoundDeviationToDistanceBased: InputMaybe<Scalars['Float']['input']>
  maxDeviationToDistanceBased: InputMaybe<Scalars['Float']['input']>
  minPrice: MoneyInput
  upperBoundDeviationToDistanceBased: InputMaybe<Scalars['Float']['input']>
}

export type EmsLicense = {
  __typename?: 'EMSLicense'
  issuedBy: Maybe<Scalars['String']['output']>
  licenseNumber: Maybe<Scalars['String']['output']>
  typeOfLicense: Maybe<Scalars['String']['output']>
  validFrom: Maybe<Scalars['Date']['output']>
  validUntil: Maybe<Scalars['Date']['output']>
}

export type EditCurrentStateChangeInput = {
  changeReasonFreeText: InputMaybe<Scalars['String']['input']>
  expectedActivationAt: InputMaybe<Scalars['DateTime']['input']>
  vehicleID: Scalars['ID']['input']
  vehicleStateChangeReason: VehicleStateChangeReason
}

export enum Eligibility {
  /** Only caretaker is eligible for travel */
  EligibilityCaretaker = 'ELIGIBILITY_CARETAKER',
  /** Only customer is eligible for travel */
  EligibilityCustomer = 'ELIGIBILITY_CUSTOMER',
  /** Customer and caretaker are eligible for travel */
  EligibilityCustomerPlusCaretaker = 'ELIGIBILITY_CUSTOMER_PLUS_CARETAKER',
  /** Invalid/unknown eligibility */
  EligibilityInvalid = 'ELIGIBILITY_INVALID',
}

/** Deprecated: to be replaced by OpenVehicleInput, OpenVehicleForBackofficeUserInput, CloseVehicleInput, CloseVehicleForBackofficeUserInput */
export type EmergencyVehicleInteractionInput = {
  interactionType: VehicleInteractionType
  reason: InputMaybe<InteractionReason>
  reasonFreeText: InputMaybe<Scalars['String']['input']>
  token: InputMaybe<Scalars['String']['input']>
  vehicleID: Scalars['ID']['input']
}

export type Employee = {
  __typename?: 'Employee'
  address: Maybe<EmployeeAddress>
  birthCountry: Maybe<Scalars['String']['output']>
  /** If set, contains the timestamp when this driver is to be blocked automatically. */
  blockScheduledAt: Maybe<Scalars['DateTime']['output']>
  blocked: Scalars['Boolean']['output']
  /** Reason for suspension/block. Not set if driver is not suspended. */
  blockedReason: Maybe<Scalars['String']['output']>
  confirmed: Scalars['Boolean']['output']
  contractor: Maybe<Scalars['String']['output']>
  contracts: Maybe<Array<EmployeeContract>>
  dateOfBirth: Maybe<Scalars['Date']['output']>
  deputySupervisorUser: Maybe<BackofficeUser>
  driverLicense: Maybe<EmsLicense>
  email: Maybe<Scalars['String']['output']>
  emailVerified: Scalars['Boolean']['output']
  emergencyContact: EmployeeEmergencyContact
  employeeVehicleInteraction: EmployeeVehicleInteraction
  entryDate: Maybe<Scalars['Date']['output']>
  exitDate: Maybe<Scalars['Date']['output']>
  externalId: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  hub: Maybe<Hub>
  hubStartDate: Maybe<Scalars['Date']['output']>
  id: Scalars['ID']['output']
  lastName: Maybe<Scalars['String']['output']>
  mobile: Maybe<Scalars['String']['output']>
  mobileVerified: Scalars['Boolean']['output']
  nationality: Maybe<Scalars['String']['output']>
  onboardingEnddate: Maybe<Scalars['Date']['output']>
  passengerTransportLicense: Maybe<EmsLicense>
  profilePictureTimestamp: Maybe<Scalars['String']['output']>
  /** URL to profile picture for this employee. Generates a pre-signed URL that is valid for 60 seconds. */
  profilePictureURL: Maybe<Scalars['String']['output']>
  roles: Array<Role>
  sdoQualification: Scalars['Boolean']['output']
  serviceAreaId: Maybe<Scalars['ID']['output']>
  status: EmployeeStatus
  supervisorUser: Maybe<BackofficeUser>
  wavQualification: Scalars['Boolean']['output']
}

export type EmployeeAbsence = {
  __typename?: 'EmployeeAbsence'
  key: Maybe<EmployeeAbsenceKey>
}

export enum EmployeeAbsenceKey {
  AbsentUnknown = 'ABSENT_UNKNOWN',
  CancellationCertificate = 'CANCELLATION_CERTIFICATE',
  CancellationNoCertificate = 'CANCELLATION_NO_CERTIFICATE',
  ChildSick = 'CHILD_SICK',
  Cure = 'CURE',
  EducationalLeave = 'EDUCATIONAL_LEAVE',
  MaternityLeave = 'MATERNITY_LEAVE',
  PaidLeave = 'PAID_LEAVE',
  ParentalLeave = 'PARENTAL_LEAVE',
  Quarantine = 'QUARANTINE',
  Sabbatical = 'SABBATICAL',
  SickCertificate = 'SICK_CERTIFICATE',
  SickNoCertificate = 'SICK_NO_CERTIFICATE',
  SpecialLeave = 'SPECIAL_LEAVE',
  UnpaidLeave = 'UNPAID_LEAVE',
  Vacation = 'VACATION',
  VacationPrioritized = 'VACATION_PRIORITIZED',
  WorkAccident = 'WORK_ACCIDENT',
}

export type EmployeeAddress = {
  __typename?: 'EmployeeAddress'
  city: Maybe<Scalars['String']['output']>
  countryCode: Maybe<Scalars['String']['output']>
  state: Maybe<Scalars['String']['output']>
  /** Street Name with House Number */
  streetAddress: Maybe<Scalars['String']['output']>
  zipCode: Maybe<Scalars['String']['output']>
}

export type EmployeeAuthDataChangeValueInput = {
  employeeId: Scalars['ID']['input']
  newValue: Scalars['String']['input']
  type: EmployeeAuthDataType
}

export type EmployeeAuthDataResendCodeInput = {
  accessToken: Scalars['String']['input']
  employeeId: Scalars['ID']['input']
  type: EmployeeAuthDataType
}

export type EmployeeAuthDataResendCodeResponse = {
  __typename?: 'EmployeeAuthDataResendCodeResponse'
  success: Scalars['Boolean']['output']
}

export enum EmployeeAuthDataType {
  Email = 'EMAIL',
  Mobile = 'MOBILE',
}

export type EmployeeAuthDataVerifyCodeInput = {
  accessToken: Scalars['String']['input']
  code: Scalars['String']['input']
  employeeId: Scalars['ID']['input']
  type: EmployeeAuthDataType
}

export type EmployeeBlockInput = {
  /** List of employee IDs to block */
  employees: Array<Scalars['ID']['input']>
  /** Blocking note */
  note: InputMaybe<Scalars['String']['input']>
  /** Reason for blocking */
  reason: Scalars['String']['input']
}

export type EmployeeBlockResponse = {
  __typename?: 'EmployeeBlockResponse'
  blockedEmployees: Array<Employee>
}

export type EmployeeContract = {
  __typename?: 'EmployeeContract'
  hours: Maybe<Scalars['Float']['output']>
  start: Maybe<Scalars['Date']['output']>
}

export type EmployeeCreateNoteFileGetUrlResponse = {
  __typename?: 'EmployeeCreateNoteFileGetUrlResponse'
  preSignedViewUrl: Maybe<Scalars['String']['output']>
}

export type EmployeeCreateNoteInput = {
  category: EmployeeNoteCategory
  employeeId: Scalars['ID']['input']
  files: Array<EmployeeNoteFileInput>
  note: InputMaybe<Scalars['String']['input']>
  personalNote: InputMaybe<Scalars['String']['input']>
  subject: Scalars['String']['input']
}

export type EmployeeCreateNoteResponse = {
  __typename?: 'EmployeeCreateNoteResponse'
  note: EmployeeNote
}

export type EmployeeCreateNotesInput = {
  category: EmployeeNoteCategory
  employeeIds: Array<Scalars['ID']['input']>
  note: InputMaybe<Scalars['String']['input']>
  personalNote: InputMaybe<Scalars['String']['input']>
  subject: Scalars['String']['input']
}

export type EmployeeCreateNotesResponse = {
  __typename?: 'EmployeeCreateNotesResponse'
  notes: Array<EmployeeNote>
}

export type EmployeeCreateScheduledBlockInput = {
  /** List of employee IDs to block */
  employees: Array<Scalars['ID']['input']>
  /** Blocking note */
  note: InputMaybe<Scalars['String']['input']>
  /** Reason for blocking */
  reason: Scalars['String']['input']
  /** Scheduled Blocking time-point */
  scheduledAt: Scalars['DateTime']['input']
}

export type EmployeeDeleteMessagesInput = {
  employeeId: Scalars['ID']['input']
  messageIds: Array<Scalars['ID']['input']>
}

export type EmployeeDeleteMessagesResponse = {
  __typename?: 'EmployeeDeleteMessagesResponse'
  deletedMessageIds: Array<Scalars['ID']['output']>
}

export type EmployeeDeleteNotesInput = {
  employeeId: Scalars['ID']['input']
  noteIds: Array<Scalars['ID']['input']>
}

export type EmployeeDeleteNotesResponse = {
  __typename?: 'EmployeeDeleteNotesResponse'
  deletedIds: Array<Scalars['ID']['output']>
  success: Scalars['Boolean']['output']
}

export type EmployeeDeleteProfileInput = {
  employeeId: Scalars['ID']['input']
}

export type EmployeeDeleteProfileResponse = {
  __typename?: 'EmployeeDeleteProfileResponse'
  deleted: Scalars['Boolean']['output']
  employeeId: Scalars['ID']['output']
}

export type EmployeeDeleteScheduledBlockInput = {
  blockId: Scalars['ID']['input']
}

export type EmployeeDeleteScheduledBlockResponse = {
  __typename?: 'EmployeeDeleteScheduledBlockResponse'
  deleted: Scalars['Boolean']['output']
}

export type EmployeeEmergencyContact = {
  __typename?: 'EmployeeEmergencyContact'
  filled: Maybe<Scalars['Boolean']['output']>
  name: Maybe<Scalars['String']['output']>
  phone: Maybe<Scalars['String']['output']>
}

export type EmployeeGetScheduledBlocksResponse = {
  __typename?: 'EmployeeGetScheduledBlocksResponse'
  scheduledBlocks: Array<EmployeeScheduledBlock>
}

export type EmployeeLogResponse = {
  __typename?: 'EmployeeLogResponse'
  log: Array<ShiftStateLogRecord>
}

export type EmployeeMessage = {
  __typename?: 'EmployeeMessage'
  acknowledgedAt: Maybe<Scalars['DateTime']['output']>
  acknowledgement: EmployeeMessageAcknowledgement
  body: Scalars['String']['output']
  category: EmployeeMessageCategory
  copySentAsMail: Scalars['Boolean']['output']
  copySentAsSms: Scalars['Boolean']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  read: Scalars['Boolean']['output']
  /** If null, the message was sent by the system. */
  senderUser: Maybe<BackofficeUser>
  subject: Maybe<Scalars['String']['output']>
  url: Maybe<Scalars['String']['output']>
}

export enum EmployeeMessageAcknowledgement {
  Done = 'DONE',
  NotRequired = 'NOT_REQUIRED',
  Required = 'REQUIRED',
}

export enum EmployeeMessageCategory {
  General = 'GENERAL',
  Personal = 'PERSONAL',
  ReleaseNotes = 'RELEASE_NOTES',
  System = 'SYSTEM',
  Voucher = 'VOUCHER',
}

export type EmployeeMessageResponse = {
  __typename?: 'EmployeeMessageResponse'
  message: EmployeeMessage
}

export type EmployeeMessagesResponse = {
  __typename?: 'EmployeeMessagesResponse'
  messages: Array<EmployeeMessage>
}

export type EmployeeNote = {
  __typename?: 'EmployeeNote'
  category: EmployeeNoteCategory
  createdAt: Scalars['DateTime']['output']
  createdByUser: Maybe<BackofficeUser>
  employeeId: Scalars['ID']['output']
  files: Array<EmployeeNoteFile>
  lastModifiedAt: Maybe<Scalars['DateTime']['output']>
  lastModifiedByUser: Maybe<BackofficeUser>
  note: Maybe<Scalars['String']['output']>
  noteId: Scalars['ID']['output']
  personalNote: Maybe<Scalars['String']['output']>
  subject: Scalars['String']['output']
}

export enum EmployeeNoteCategory {
  DriverBlocking = 'DRIVER_BLOCKING',
  DriverTraining = 'DRIVER_TRAINING',
  EmployeeConversation = 'EMPLOYEE_CONVERSATION',
  OnboardingMap = 'ONBOARDING_MAP',
  Other = 'OTHER',
  PersonalMatter = 'PERSONAL_MATTER',
}

export type EmployeeNoteCreateFileGetUrlInput = {
  employeeId: Scalars['ID']['input']
  fileName: Scalars['String']['input']
  noteId: Scalars['ID']['input']
}

export type EmployeeNoteFile = {
  __typename?: 'EmployeeNoteFile'
  isPersonalFile: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  noteId: Maybe<Scalars['ID']['output']>
}

export type EmployeeNoteFileInput = {
  name: Scalars['String']['input']
}

export type EmployeeNoteFilePreSignedUrlInput = {
  employeeId: Scalars['ID']['input']
  fileName: Scalars['String']['input']
  isPersonalFile: InputMaybe<Scalars['Boolean']['input']>
}

export type EmployeeNoteFilePreSignedUrlResponse = {
  __typename?: 'EmployeeNoteFilePreSignedUrlResponse'
  employeeId: Scalars['ID']['output']
  name: Scalars['String']['output']
  /** pre-signed url to upload file */
  preSignedUrl: Scalars['String']['output']
}

export type EmployeeQueryNotesInput = {
  /** List of note categories to filter for. If not set or empty, categories will not be filtered. */
  categories: InputMaybe<Array<EmployeeNoteCategory>>
  dateRange: InputMaybe<DateTimeIntervalInput>
  employeeId: Scalars['ID']['input']
  /** Lets users search through the subject, title, and public note content. */
  search: InputMaybe<Scalars['String']['input']>
  /**
   * Year of note to filter for. If not set, notes will not be filtered by year.
   * @deprecated Use dateRange instead
   */
  year: InputMaybe<Scalars['Int']['input']>
}

export type EmployeeQueryNotesResponse = {
  __typename?: 'EmployeeQueryNotesResponse'
  notes: Array<EmployeeNote>
}

export type EmployeeResendEmailVerificationInput = {
  employeeId: Scalars['ID']['input']
}

export type EmployeeResendEmailVerificationResponse = {
  __typename?: 'EmployeeResendEmailVerificationResponse'
  success: Scalars['Boolean']['output']
}

export type EmployeeResetPasswordInput = {
  employeeId: Scalars['ID']['input']
}

export type EmployeeResetPasswordResponse = {
  __typename?: 'EmployeeResetPasswordResponse'
  temporaryPassword: Scalars['String']['output']
}

export type EmployeeScheduledBlock = {
  __typename?: 'EmployeeScheduledBlock'
  employees: Array<Employee>
  id: Scalars['ID']['output']
  note: Maybe<Scalars['String']['output']>
  reason: Scalars['String']['output']
  scheduledAt: Scalars['DateTime']['output']
}

export type EmployeeSetEmergencyContactInput = {
  name: Scalars['String']['input']
  phone: Scalars['String']['input']
}

export enum EmployeeStatus {
  Active = 'ACTIVE',
  Deactivated = 'DEACTIVATED',
  Hired = 'HIRED',
  Pending = 'PENDING',
}

export type EmployeeTimesheet = {
  __typename?: 'EmployeeTimesheet'
  absence: Maybe<EmployeeAbsence>
  day: Scalars['Date']['output']
  extraTimes: Array<EmployeeTimesheetExtraTime>
  lastUpdatedAt: Scalars['DateTime']['output']
  workTimes: Array<EmployeeTimesheetPosition>
}

export type EmployeeTimesheetExtraTime = {
  __typename?: 'EmployeeTimesheetExtraTime'
  netDuration: Scalars['Duration']['output']
  postingKey: Scalars['String']['output']
  shift: Maybe<Shift>
}

export type EmployeeTimesheetPosition = {
  __typename?: 'EmployeeTimesheetPosition'
  end: Maybe<Scalars['DateTime']['output']>
  /** Net duration for this work period. May differ from (end - start) due to extra break rules applied by GFOS */
  netDuration: Maybe<Scalars['Duration']['output']>
  shift: Maybe<Shift>
  start: Maybe<Scalars['DateTime']['output']>
}

export type EmployeeTipsResponse = {
  __typename?: 'EmployeeTipsResponse'
  monthlyTips: Array<EmployeeTipsSum>
}

export type EmployeeTipsSum = {
  __typename?: 'EmployeeTipsSum'
  amount: Money
  period: Scalars['String']['output']
}

export type EmployeeUnblockInput = {
  /** List of employee IDs to unblock */
  employees: Array<Scalars['ID']['input']>
  /** Unblocking note */
  note: InputMaybe<Scalars['String']['input']>
  /** Reason for unblocking */
  reason: Scalars['String']['input']
}

export type EmployeeUnblockResponse = {
  __typename?: 'EmployeeUnblockResponse'
  unblockedEmployees: Array<Employee>
}

export type EmployeeUpdateContactDataInput = {
  email: InputMaybe<Scalars['String']['input']>
  mobile: InputMaybe<Scalars['String']['input']>
}

export type EmployeeUpdateNoteInput = {
  category: EmployeeNoteCategory
  employeeId: Scalars['ID']['input']
  files: Array<EmployeeNoteFileInput>
  note: InputMaybe<Scalars['String']['input']>
  noteId: Scalars['ID']['input']
  personalNote: InputMaybe<Scalars['String']['input']>
  subject: Scalars['String']['input']
}

export type EmployeeUpdateNoteResponse = {
  __typename?: 'EmployeeUpdateNoteResponse'
  note: EmployeeNote
}

export type EmployeeUpdateProfileDataInput = {
  contactData: InputMaybe<EmployeeUpdateContactDataInput>
  id: Scalars['ID']['input']
  qualifications: InputMaybe<EmployeeUpdateQualificationsInput>
  supervisors: InputMaybe<EmployeeUpdateSupervisorsInput>
}

export type EmployeeUpdateQualificationsInput = {
  sdoQualification: InputMaybe<Scalars['Boolean']['input']>
  wavQualification: InputMaybe<Scalars['Boolean']['input']>
}

export type EmployeeUpdateScheduledBlockInput = {
  /** List of employee IDs to block */
  employees: Array<Scalars['ID']['input']>
  /** Blocking note */
  note: InputMaybe<Scalars['String']['input']>
  /** Reason for blocking */
  reason: Scalars['String']['input']
  /** Scheduled block ID */
  scheduleBlockId: Scalars['ID']['input']
  /** Scheduled block time-point */
  scheduledAt: Scalars['DateTime']['input']
}

export type EmployeeUpdateSupervisorsInput = {
  deputySupervisorId: InputMaybe<Scalars['ID']['input']>
  supervisorFirstName: InputMaybe<Scalars['String']['input']>
  supervisorId: InputMaybe<Scalars['ID']['input']>
  supervisorLastName: InputMaybe<Scalars['String']['input']>
}

export type EmployeeVehicleInteraction = {
  __typename?: 'EmployeeVehicleInteraction'
  status: EmployeeVehicleInteractionStatus
  token: Maybe<Scalars['String']['output']>
}

export enum EmployeeVehicleInteractionStatus {
  EmployeeInactive = 'EMPLOYEE_INACTIVE',
  EmployeeSuspended = 'EMPLOYEE_SUSPENDED',
  HasOpenVehicle = 'HAS_OPEN_VEHICLE',
  NotCheckedIn = 'NOT_CHECKED_IN',
  NoShift = 'NO_SHIFT',
  Ok = 'OK',
  /** @deprecated Status SDO_SHIFT is unused and will be removd */
  SdoShift = 'SDO_SHIFT',
}

export type EmployeeZendeskSsoResponse = {
  __typename?: 'EmployeeZendeskSSOResponse'
  /** jwt authorized url redirecting back to the originally requested resource */
  returnTo: Scalars['String']['output']
}

export type Employees = {
  __typename?: 'Employees'
  employees: Array<Employee>
  total: Scalars['Int']['output']
  unconfirmed: Scalars['Int']['output']
}

export type EmployeesFilterInput = {
  blocked: InputMaybe<Scalars['Boolean']['input']>
  fulltext: InputMaybe<Scalars['String']['input']>
  hubId: InputMaybe<Scalars['ID']['input']>
  roles: InputMaybe<Array<Role>>
  sdoQualification: InputMaybe<Scalars['Boolean']['input']>
  serviceAreaId: InputMaybe<Scalars['ID']['input']>
  statuses: InputMaybe<Array<EmployeeStatus>>
  /** Filter by supervisor id, if set to empty string, will return all employees without supervisor */
  supervisorUserId: InputMaybe<Scalars['ID']['input']>
  wavQualification: InputMaybe<Scalars['Boolean']['input']>
}

export type EnableCustomerInput = {
  id: Scalars['ID']['input']
}

export type EnableCustomerResponse = {
  __typename?: 'EnableCustomerResponse'
  success: Scalars['Boolean']['output']
}

export enum EventItemType {
  Action = 'ACTION',
  Area = 'AREA',
  Roadblockage = 'ROADBLOCKAGE',
  RoadblockageAdditionalSegments = 'ROADBLOCKAGE_ADDITIONAL_SEGMENTS',
  Servicearea = 'SERVICEAREA',
  Stop = 'STOP',
  Turnrestriction = 'TURNRESTRICTION',
}

export enum ExecutionState {
  Active = 'ACTIVE',
  Done = 'DONE',
  Missed = 'MISSED',
  Upcoming = 'UPCOMING',
}

export type FccAdAlert = {
  __typename?: 'FccAdAlert'
  assignee: Maybe<BackofficeUser>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: FccAdAlertStatus
  statusChanges: Array<FccAdAlertStatusChange>
  type: FccAdAlertType
  updatedAt: Scalars['DateTime']['output']
  vehicle: Maybe<Vehicle>
}

export enum FccAdAlertStatus {
  Assigned = 'ASSIGNED',
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type FccAdAlertStatusChange = {
  __typename?: 'FccAdAlertStatusChange'
  bySystem: Scalars['Boolean']['output']
  byUser: Maybe<BackofficeUser>
  date: Scalars['DateTime']['output']
  from: Maybe<FccAdAlertStatus>
  to: FccAdAlertStatus
}

export enum FccAdAlertType {
  CabinSafetyCheck = 'CABIN_SAFETY_CHECK',
  EmergencyStop = 'EMERGENCY_STOP',
  MissingPassengerAuthentication = 'MISSING_PASSENGER_AUTHENTICATION',
  OperatorCalling = 'OPERATOR_CALLING',
  PassengerCalling = 'PASSENGER_CALLING',
  PassengerUnbuckled = 'PASSENGER_UNBUCKLED',
  ServiceCancellationOngoing = 'SERVICE_CANCELLATION_ONGOING',
  WaypointAttendance = 'WAYPOINT_ATTENDANCE',
}

export type FccAdAlerts = {
  __typename?: 'FccAdAlerts'
  alerts: Array<FccAdAlert>
  count: Scalars['Int']['output']
}

export type FccAdConnectivity = {
  __typename?: 'FccAdConnectivity'
  connectivityState: FccAdConnectivityState
  timestamp: Scalars['DateTime']['output']
}

export enum FccAdConnectivityState {
  Connected = 'CONNECTED',
  Interrupted = 'INTERRUPTED',
}

export type FccAuditLogEntry = {
  __typename?: 'FccAuditLogEntry'
  category: FccAuditLogEventCategory
  id: Scalars['ID']['output']
  incidentId: Maybe<Scalars['ID']['output']>
  initiationDetails: FccInitiationDetails
  location: Maybe<LocationWithTimestamp>
  type: FccAuditLogEventType
  vehicle: Maybe<Vehicle>
}

export enum FccAuditLogEventCategory {
  CabinSafetyCheck = 'CABIN_SAFETY_CHECK',
  EmergencyStopIncident = 'EMERGENCY_STOP_INCIDENT',
  PassengerAuthenticationFallback = 'PASSENGER_AUTHENTICATION_FALLBACK',
  PassengerUnbuckledIncident = 'PASSENGER_UNBUCKLED_INCIDENT',
  ServiceCancellation = 'SERVICE_CANCELLATION',
  VehicleInteraction = 'VEHICLE_INTERACTION',
  VideoStream = 'VIDEO_STREAM',
}

export enum FccAuditLogEventType {
  CabinSafetyCheckRequired = 'CABIN_SAFETY_CHECK_REQUIRED',
  DoorActuationClosedRequested = 'DOOR_ACTUATION_CLOSED_REQUESTED',
  DoorActuationOpenRequested = 'DOOR_ACTUATION_OPEN_REQUESTED',
  IncidentHandlingRequired = 'INCIDENT_HANDLING_REQUIRED',
  IncidentResolved = 'INCIDENT_RESOLVED',
  OperatorClosedDoor = 'OPERATOR_CLOSED_DOOR',
  OperatorFinishedCabinSafetyCheck = 'OPERATOR_FINISHED_CABIN_SAFETY_CHECK',
  OperatorHandledIncidentCancelTrips = 'OPERATOR_HANDLED_INCIDENT_CANCEL_TRIPS',
  OperatorHandledIncidentContinueTrips = 'OPERATOR_HANDLED_INCIDENT_CONTINUE_TRIPS',
  OperatorHandledPassengerAuthenticationSuccessful = 'OPERATOR_HANDLED_PASSENGER_AUTHENTICATION_SUCCESSFUL',
  OperatorProlongedVideostream = 'OPERATOR_PROLONGED_VIDEOSTREAM',
  OperatorStartedCabinSafetyCheck = 'OPERATOR_STARTED_CABIN_SAFETY_CHECK',
  OperatorStartedIncidentHandling = 'OPERATOR_STARTED_INCIDENT_HANDLING',
  OperatorStartedVideostream = 'OPERATOR_STARTED_VIDEOSTREAM',
  OperatorStoppedIncidentHandling = 'OPERATOR_STOPPED_INCIDENT_HANDLING',
  PassengerAuthenticationRequired = 'PASSENGER_AUTHENTICATION_REQUIRED',
  PoolingOffRequested = 'POOLING_OFF_REQUESTED',
  PoolingOnRequested = 'POOLING_ON_REQUESTED',
  ServiceCancellationFailed = 'SERVICE_CANCELLATION_FAILED',
  ServiceCancellationStateCompleted = 'SERVICE_CANCELLATION_STATE_COMPLETED',
  ServiceCancellationStateConfirmed = 'SERVICE_CANCELLATION_STATE_CONFIRMED',
  ServiceCancellationTimeout = 'SERVICE_CANCELLATION_TIMEOUT',
}

export type FccAuditLogInput = {
  /** DateTimes are exclusive */
  dateTimeRange: InputMaybe<DateTimeIntervalInput>
  pagination: InputMaybe<CursorPaginationInput>
  vehicleId: Scalars['ID']['input']
}

export type FccAuditLogList = CursorListResponse & {
  __typename?: 'FccAuditLogList'
  logs: Array<FccAuditLogEntry>
  nextCursor: Maybe<Scalars['ID']['output']>
  total: Maybe<Scalars['Int']['output']>
}

export enum FccCabinSafetyCheckState {
  CabinReady = 'CABIN_READY',
  DoorClosed = 'DOOR_CLOSED',
  PendingCabinReadyCheck = 'PENDING_CABIN_READY_CHECK',
  PendingCloseDoor = 'PENDING_CLOSE_DOOR',
}

export type FccCancelUpcomingTripInput = {
  tripId: Scalars['ID']['input']
  vehicleId: Scalars['ID']['input']
}

export enum FccCancellationReason {
  Accident = 'ACCIDENT',
  Cleaning = 'CLEANING',
  Other = 'OTHER',
  SicknessOfDriver = 'SICKNESS_OF_DRIVER',
  SpecialBreak = 'SPECIAL_BREAK',
  Training = 'TRAINING',
  UnreachableStop = 'UNREACHABLE_STOP',
  VehicleError = 'VEHICLE_ERROR',
  WrongBreakDetection = 'WRONG_BREAK_DETECTION',
  WrongHubReturn = 'WRONG_HUB_RETURN',
}

export type FccConfirmCancelAllTripsInput = {
  vehicleId: Scalars['ID']['input']
}

export type FccContinueTripsInput = {
  incidentId: Scalars['ID']['input']
  vehicleId: Scalars['ID']['input']
}

export enum FccDoorActuationError {
  /** The door state did not switch to the desired state in time */
  DoorStateMismatch = 'DOOR_STATE_MISMATCH',
  SlidingDoorDeactivated = 'SLIDING_DOOR_DEACTIVATED',
  /** No response was received in time */
  TtlExceeded = 'TTL_EXCEEDED',
  UnknownError = 'UNKNOWN_ERROR',
  VehicleMoving = 'VEHICLE_MOVING',
  VehicleNotParked = 'VEHICLE_NOT_PARKED',
}

export type FccDoorActuationNotPossibleStatus = {
  __typename?: 'FccDoorActuationNotPossibleStatus'
  vehicleId: Scalars['ID']['output']
}

export type FccDoorActuationPossibleStatus = {
  __typename?: 'FccDoorActuationPossibleStatus'
  vehicleId: Scalars['ID']['output']
}

export type FccDoorActuationRequestFailureStatus = {
  __typename?: 'FccDoorActuationRequestFailureStatus'
  errors: Array<FccDoorActuationError>
  vehicleId: Scalars['ID']['output']
}

export type FccDoorActuationRequestSuccessStatus = {
  __typename?: 'FccDoorActuationRequestSuccessStatus'
  vehicleId: Scalars['ID']['output']
}

export type FccDoorActuationStatus =
  | FccDoorActuationNotPossibleStatus
  | FccDoorActuationPossibleStatus
  | FccDoorActuationRequestFailureStatus
  | FccDoorActuationRequestSuccessStatus

export enum FccDoorState {
  Ajar = 'AJAR',
  Closed = 'CLOSED',
  Closing = 'CLOSING',
  Open = 'OPEN',
  Opening = 'OPENING',
}

export type FccDoorStatus = {
  __typename?: 'FccDoorStatus'
  doorState: FccDoorState
  updatedAt: Scalars['DateTime']['output']
}

export enum FccFleetMapType {
  Ad = 'AD',
  NonAd = 'NON_AD',
}

export type FccFleetShutdownMessageInput = {
  message: Scalars['String']['input']
  serviceAreaUuid: Scalars['ID']['input']
}

export type FccFleetShutdownRequestInput = {
  serviceAreaUuid: Scalars['ID']['input']
}

export type FccGroupedAdAlerts = {
  __typename?: 'FccGroupedAdAlerts'
  assignedToMe: FccAdAlerts
  assignedToOthers: FccAdAlerts
  open: FccAdAlerts
}

export type FccInitiateCancelAllTripsInput = {
  incidentId: InputMaybe<Scalars['ID']['input']>
  vehicleId: Scalars['ID']['input']
}

export type FccInitiationDetails = {
  __typename?: 'FccInitiationDetails'
  at: Scalars['DateTime']['output']
  bySystem: Scalars['Boolean']['output']
  username: Maybe<Scalars['String']['output']>
}

export type FccMarkPromptAsHandledInput = {
  serviceAreaUUID: Scalars['ID']['input']
  timestamp: Scalars['DateTime']['input']
}

export type FccOperatorAlert =
  | FccAdAlert
  | PassengerAuthentificationFallbackRequestedNotification
  | VehicleIncidentNotification
  | VehicleSurveillanceNotification

export enum FccOperatorAvailability {
  Available = 'AVAILABLE',
  Break = 'BREAK',
  /** Can not be set directly but is derived implicitly by active call or task assignment */
  Busy = 'BUSY',
  Offline = 'OFFLINE',
}

export type FccOperatorAvailabilityCountInServiceArea = {
  __typename?: 'FccOperatorAvailabilityCountInServiceArea'
  available: Scalars['Int']['output']
  break: Scalars['Int']['output']
  busy: Scalars['Int']['output']
}

export type FccOperatorIncidentResolutionInput = {
  incidentForeignId: Scalars['ID']['input']
  resolutionType: FccOperatorIncidentResolutionType
  vehicleId: Scalars['ID']['input']
}

export enum FccOperatorIncidentResolutionType {
  CancelAllTrips = 'CANCEL_ALL_TRIPS',
  ContinueTrip = 'CONTINUE_TRIP',
}

export type FccOperatorState = {
  __typename?: 'FccOperatorState'
  availability: FccOperatorAvailability
  availableSince: Maybe<Scalars['DateTime']['output']>
}

export type FccPassengerAuthentication = {
  __typename?: 'FccPassengerAuthentication'
  authenticationError: Maybe<FccPassengerAuthenticationError>
  state: Maybe<FccPassengerCheckInStatus>
  tripId: Scalars['ID']['output']
}

export enum FccPassengerAuthenticationError {
  Failed = 'FAILED',
  Timeout = 'TIMEOUT',
}

export enum FccPassengerAuthenticationState {
  Confirmed = 'CONFIRMED',
  InProgress = 'IN_PROGRESS',
  ManualNoShowPending = 'MANUAL_NO_SHOW_PENDING',
  ManualNoShowRequested = 'MANUAL_NO_SHOW_REQUESTED',
  NoShowTriggered = 'NO_SHOW_TRIGGERED',
}

export enum FccPassengerCheckInStatus {
  Confirmed = 'CONFIRMED',
  InProgress = 'IN_PROGRESS',
  ManualNoShowPending = 'MANUAL_NO_SHOW_PENDING',
  ManualNoShowRequested = 'MANUAL_NO_SHOW_REQUESTED',
  NoShowTriggered = 'NO_SHOW_TRIGGERED',
}

export type FccPrepareOperatorUserInput = {
  fleetMapType: FccFleetMapType
}

export type FccProvideTripCancellationReasonInput = {
  reasonDescription: Scalars['String']['input']
  tripId: Scalars['ID']['input']
}

export type FccRequestDoorActuationInput = {
  doorState: FccDoorState
  vehicleId: Scalars['ID']['input']
}

export type FccRequestOperatorCallInput = {
  /** Will be set when call is triggered by an already existing alert. Whithout the id a new operator call alert will be triggered. */
  causedByAlertId: InputMaybe<Scalars['ID']['input']>
  vehicleId: Scalars['ID']['input']
}

export type FccRouteLocation = {
  __typename?: 'FccRouteLocation'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
}

export enum FccSdsMission {
  AtStop = 'AT_STOP',
  EmergencyInProgress = 'EMERGENCY_IN_PROGRESS',
  EmergencyParked = 'EMERGENCY_PARKED',
  Idle = 'IDLE',
  Invalid = 'INVALID',
  OnDuty = 'ON_DUTY',
  SafetyCheck = 'SAFETY_CHECK',
}

export enum FccSdsMode {
  Disengaged = 'DISENGAGED',
  Engaged = 'ENGAGED',
  Fault = 'FAULT',
  InCalibration = 'IN_CALIBRATION',
  NotAuthorized = 'NOT_AUTHORIZED',
  Transportation = 'TRANSPORTATION',
}

export enum FccSdsState {
  Disengaged = 'DISENGAGED',
  Exceptional = 'EXCEPTIONAL',
  Operational = 'OPERATIONAL',
  Teleoperation = 'TELEOPERATION',
}

export type FccSeat = {
  __typename?: 'FccSeat'
  occupancy: FccSeatOccupancy
  seatbelt: FccSeatbelt
}

export type FccSeatLayout = {
  __typename?: 'FccSeatLayout'
  cockpitLeft: FccSeat
  cockpitRight: FccSeat
  secondRowLeft: FccSeat
  secondRowMiddle: FccSeat
  secondRowRight: FccSeat
  thirdRowLeft: FccSeat
  thirdRowMiddle: FccSeat
  thirdRowRight: FccSeat
}

export enum FccSeatOccupancy {
  NotInstalled = 'NOT_INSTALLED',
  Occupied = 'OCCUPIED',
  Unoccupied = 'UNOCCUPIED',
}

export type FccSeatStatus = {
  __typename?: 'FccSeatStatus'
  cockpitLeft: FccSeatStatusType
  cockpitRight: FccSeatStatusType
  secondRowLeft: FccSeatStatusType
  secondRowMiddle: FccSeatStatusType
  secondRowRight: FccSeatStatusType
  thirdRowLeft: FccSeatStatusType
  thirdRowMiddle: FccSeatStatusType
  thirdRowRight: FccSeatStatusType
}

export enum FccSeatStatusType {
  Buckled = 'BUCKLED',
  NotInstalled = 'NOT_INSTALLED',
  NotOccupied = 'NOT_OCCUPIED',
  Unbuckled = 'UNBUCKLED',
}

export enum FccSeatbelt {
  Fastened = 'FASTENED',
  NotInstalled = 'NOT_INSTALLED',
  Unfastened = 'UNFASTENED',
}

export type FccServiceCancellationError = {
  __typename?: 'FccServiceCancellationError'
  code: FccServiceCancellationErrorCode
}

export enum FccServiceCancellationErrorCode {
  Failed = 'FAILED',
  Timeout = 'TIMEOUT',
}

export type FccServiceCancellationRequestInput = {
  deactivateVehicleOnHubReturn: Scalars['Boolean']['input']
  otherReasonDescription: InputMaybe<Scalars['String']['input']>
  reason: FccCancellationReason
  returnToHubId: InputMaybe<Scalars['ID']['input']>
  vehicleId: Scalars['ID']['input']
}

export type FccServiceCancellationState = {
  __typename?: 'FccServiceCancellationState'
  cancellationError: Maybe<FccServiceCancellationError>
  state: Maybe<FccServiceCancellationStateEnum>
  vehicleId: Scalars['ID']['output']
}

export enum FccServiceCancellationStateEnum {
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Initiated = 'INITIATED',
  Processed = 'PROCESSED',
}

export type FccSetOperatorAvailabilityInput = {
  availability: FccOperatorAvailability
  serviceAreaUUID: Scalars['ID']['input']
}

export enum FccUpdateAdPoolingStatusError {
  GeneralError = 'GENERAL_ERROR',
  /** Communication to upstream service failed. */
  UpstreamServiceError = 'UPSTREAM_SERVICE_ERROR',
  /** The target vehicle is not an AD vehicle. */
  VehicleNotAd = 'VEHICLE_NOT_AD',
}

export type FccUpdateAdPoolingStatusFailure = {
  __typename?: 'FccUpdateAdPoolingStatusFailure'
  error: FccUpdateAdPoolingStatusError
  message: Maybe<Scalars['String']['output']>
  vehicleId: Scalars['ID']['output']
}

export type FccUpdateAdPoolingStatusInput = {
  poolingEnabled: Scalars['Boolean']['input']
  vehicleId: Scalars['ID']['input']
}

export type FccUpdateAdPoolingStatusStatus =
  | FccUpdateAdPoolingStatusFailure
  | FccUpdateAdPoolingStatusSuccess

export type FccUpdateAdPoolingStatusSuccess = {
  __typename?: 'FccUpdateAdPoolingStatusSuccess'
  vehicleId: Scalars['ID']['output']
}

export type FccVehicleLocation = {
  __typename?: 'FccVehicleLocation'
  /** meters per second */
  speed: Scalars['Float']['output']
  vehicleId: Scalars['String']['output']
}

export type FccVehicleRoute = {
  __typename?: 'FccVehicleRoute'
  /**
   * encodedPath is the lossy compressed representation of the route path.
   * It is encoded using '@here/flexpolyline' and should be decoded using the same library.
   */
  encodedPath: Maybe<Scalars['String']['output']>
  updatedAt: Scalars['DateTime']['output']
  vehicleId: Scalars['ID']['output']
}

export type FccVehicleTelemetry = {
  __typename?: 'FccVehicleTelemetry'
  cabinConnectivity: Maybe<FccAdConnectivity>
  centralLockLocked: Maybe<Scalars['Boolean']['output']>
  consumedEnergy: Maybe<Scalars['Float']['output']>
  /** @deprecated Use rightSlidingDoorOpen instead */
  doorsOpen: Maybe<Scalars['Boolean']['output']>
  ignitionOn: Maybe<Scalars['Boolean']['output']>
  interiorTemperatureCelsius: Maybe<Scalars['Float']['output']>
  missionConnectivity: Maybe<FccAdConnectivity>
  remainingCruisingRangeKilometer: Maybe<Scalars['Float']['output']>
  rightSlidingDoor: Maybe<FccDoorStatus>
  rightSlidingDoorOpen: Maybe<Scalars['Boolean']['output']>
  sdsMission: Maybe<FccSdsMission>
  sdsMode: Maybe<FccSdsMode>
  sdsState: Maybe<FccSdsState>
  seatStatus: Maybe<FccSeatStatus>
  signalConnectivity: Maybe<FccAdConnectivity>
  stateOfChargePercentage: Maybe<Scalars['Float']['output']>
  vehicleId: Scalars['String']['output']
}

export type FccWaypoint = {
  __typename?: 'FccWaypoint'
  /** Actions of this waypoint */
  actions: Array<FccWaypointAction>
  /** For AD Vehicles the state of the waypoint */
  adState: Maybe<FccWaypointState>
  /** For AD Vehicles the timestamp when the state of the waypoint was updated */
  adStateUpdatedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  /** The stop at this waypoint */
  stop: Maybe<Stop>
  /** The vehicle, whose mission this waypoint is part of */
  vehicle: Maybe<Vehicle>
}

export type FccWaypointAction =
  | FccWaypointDeliveryAction
  | FccWaypointHubReturnAction
  | FccWaypointPickupAction
  | FccWaypointReallocationAction

export type FccWaypointDeliveryAction = {
  __typename?: 'FccWaypointDeliveryAction'
  customer: Maybe<Customer>
  estimatedAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  passengers: FccWaypointPassengerInfo
  promisedTimeRange: DateTimeInterval
  seats: FccWaypointSeatInfo
  trip: Maybe<Trip>
}

export type FccWaypointHubReturnAction = {
  __typename?: 'FccWaypointHubReturnAction'
  area: Maybe<Area>
  estimatedAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isPlannedHubReturn: Scalars['Boolean']['output']
  promisedTimeRange: DateTimeInterval
  reason: FccWaypointHubReturnReason
}

export enum FccWaypointHubReturnReason {
  DriverBreak = 'DRIVER_BREAK',
  OnDemand = 'ON_DEMAND',
  ShiftEnd = 'SHIFT_END',
  ShortBreak = 'SHORT_BREAK',
}

export type FccWaypointPassengerInfo = {
  __typename?: 'FccWaypointPassengerInfo'
  adults: Scalars['Int']['output']
  children: Scalars['Int']['output']
}

export type FccWaypointPickupAction = {
  __typename?: 'FccWaypointPickupAction'
  adPassengerAuthenticationState: Maybe<FccPassengerAuthenticationState>
  customer: Maybe<Customer>
  estimatedAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  notShownUpAt: Maybe<Scalars['DateTime']['output']>
  passengers: FccWaypointPassengerInfo
  promisedTimeRange: DateTimeInterval
  seats: FccWaypointSeatInfo
  trip: Maybe<Trip>
}

export type FccWaypointReallocationAction = {
  __typename?: 'FccWaypointReallocationAction'
  estimatedAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  promisedTimeRange: DateTimeInterval
  radius: Scalars['Int']['output']
}

export type FccWaypointSeatInfo = {
  __typename?: 'FccWaypointSeatInfo'
  boosterSeats: Scalars['Int']['output']
  childSeats: Scalars['Int']['output']
  prams: Scalars['Int']['output']
  seats: Scalars['Int']['output']
  wheelchairs: Scalars['Int']['output']
}

export enum FccWaypointState {
  Changed = 'CHANGED',
  Completed = 'COMPLETED',
  Reached = 'REACHED',
}

export type Fee = {
  __typename?: 'Fee'
  feeAmount: Money
  feeType: Scalars['String']['output']
  invoiceId: Scalars['ID']['output']
  isRefundable: Scalars['Boolean']['output']
  status: Scalars['String']['output']
}

/** The data that is gathered for a trip feedback */
export type Feedback = {
  __typename?: 'Feedback'
  /** The categories the customer wants to give feedback for (optional) */
  categories: Maybe<Array<FeedbackCategory>>
  /** When feedback was created */
  createdAt: Scalars['DateTime']['output']
  /** ID of the customer */
  customerId: Scalars['ID']['output']
  /** The actual feedback message (optional) */
  message: Maybe<Scalars['String']['output']>
  /** Whether the feedback is positive or negative (thumbs up/down) */
  positive: Scalars['Boolean']['output']
  /** Tenant */
  tenant: Scalars['String']['output']
  /** ID of the trip */
  tripId: Scalars['ID']['output']
  /** Trip reason for a trip (optional) */
  tripReason: Maybe<FeedbackTripReason>
  /** When feedback was updated */
  updatedAt: Scalars['DateTime']['output']
}

export type FeedbackCategory = {
  __typename?: 'FeedbackCategory'
  /** ID of the category */
  id: Scalars['ID']['output']
  /** Category labels for different languages */
  labels: Array<FeedbackCategoryLabel>
  /** Category name */
  name: Maybe<FeedbackCategoryName>
}

export type FeedbackCategoryLabel = {
  __typename?: 'FeedbackCategoryLabel'
  /** Label of the category in the language from the languageKey */
  label: Scalars['String']['output']
  /** Language of the label */
  languageKey: FeedbackCategoryLanguageKey
}

export enum FeedbackCategoryLanguageKey {
  De = 'DE',
  En = 'EN',
}

export enum FeedbackCategoryName {
  App = 'App',
  DriverPerformance = 'DriverPerformance',
  Other = 'Other',
  Shuttle = 'Shuttle',
  Stops = 'Stops',
}

export type FeedbackResponse = {
  __typename?: 'FeedbackResponse'
  feedback: Maybe<Feedback>
}

export type FeedbackTripReason = {
  __typename?: 'FeedbackTripReason'
  /** Trip reason description in case reasons contains OTHER (optional) */
  message: Maybe<Scalars['String']['output']>
  /** Trip reasons */
  reasons: Array<FeedbackTripReasonName>
}

export enum FeedbackTripReasonName {
  BusinessTrip = 'BUSINESS_TRIP',
  Commuting = 'COMMUTING',
  Errands = 'ERRANDS',
  FamilyTime = 'FAMILY_TIME',
  GoingToStationAirport = 'GOING_TO_STATION_AIRPORT',
  Invalid = 'INVALID',
  Leisure = 'LEISURE',
  Other = 'OTHER',
  Tourism = 'TOURISM',
}

export type FleetEntityChange = {
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: VehicleChangeFields
}

export type FleetMapVehicles = {
  __typename?: 'FleetMapVehicles'
  vehicles: Array<Vehicle>
}

export type FleetMapVehiclesInput = {
  isSelfDriving: InputMaybe<Scalars['Boolean']['input']>
  serviceAreaUUID: Scalars['String']['input']
}

export type FleetProcess = {
  /** Readable unique identifier */
  case: Scalars['ID']['output']
  finishedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  serviceAreaUUID: Scalars['ID']['output']
  startedAt: Scalars['DateTime']['output']
  state: FleetProcessState
  type: FleetProcessType
  updatedAt: Maybe<Scalars['DateTime']['output']>
  vehicle: Vehicle
}

export enum FleetProcessState {
  Active = 'ACTIVE',
  Complete = 'COMPLETE',
}

export type FleetProcessStateTransitionPrecondition = {
  __typename?: 'FleetProcessStateTransitionPrecondition'
  fulfilled: Scalars['Boolean']['output']
  key: Scalars['String']['output']
}

export type FleetProcessTransitionResponse = {
  __typename?: 'FleetProcessTransitionResponse'
  /** In case of success=false the missing preconditions are contained here */
  missingPreconditions: Array<FleetProcessStateTransitionPrecondition>
  process: FleetProcess
  transitionedSuccessfully: Scalars['Boolean']['output']
}

export enum FleetProcessType {
  MainInspection = 'MAIN_INSPECTION',
  MileageBasedInspection = 'MILEAGE_BASED_INSPECTION',
  VehicleInspectionCard = 'VEHICLE_INSPECTION_CARD',
}

/** Inclusive FloatRange, e.g. min <= x <= max */
export type FloatRangeInput = {
  max: Scalars['Float']['input']
  min: Scalars['Float']['input']
}

export type GeoJson = {
  __typename?: 'GeoJson'
  features: Array<GeoJsonFeature>
  type: GeoJsonType
}

export type GeoJsonFeature = {
  __typename?: 'GeoJsonFeature'
  geometry: GeoJsonGeometryPolygon
  properties: GeoJsonProperty
  type: GeoJsonFeatureType
}

export type GeoJsonFeatureInput = {
  geometry: GeoJsonGeometryInput
  type: Scalars['String']['input']
}

export enum GeoJsonFeatureType {
  Feature = 'Feature',
}

export type GeoJsonGeometryInput = {
  coordinates: Array<Array<Scalars['Float']['input']>>
  type: Scalars['String']['input']
}

export type GeoJsonGeometryPolygon = {
  __typename?: 'GeoJsonGeometryPolygon'
  /** Arrays of coordinates, first longitude, second latitude */
  coordinates: Array<Array<Array<Scalars['Float']['output']>>>
  type: GeoJsonGeometryType
}

export enum GeoJsonGeometryType {
  Polygon = 'Polygon',
}

export type GeoJsonProperty = {
  __typename?: 'GeoJsonProperty'
  hub: Maybe<Scalars['String']['output']>
}

export enum GeoJsonType {
  FeatureCollection = 'FeatureCollection',
}

export type Geometry = {
  geoJson: Scalars['String']['output']
}

export type GetIncidentsResponse = {
  __typename?: 'GetIncidentsResponse'
  description: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  resolved: Scalars['Boolean']['output']
  severity: Severity
  title: Scalars['String']['output']
}

export type GooglePay = {
  __typename?: 'GooglePay'
  /** Fake field because GraphQL does not support empty objects. Do not query, use __typename instead. */
  _: Maybe<Scalars['Boolean']['output']>
}

export type GrantPermissionCloseDoorInput = {
  vehicleId: Scalars['ID']['input']
}

export enum Granularity {
  Day = 'DAY',
  Month = 'MONTH',
}

export enum GreengrassProvisioningState {
  CertPending = 'CERT_PENDING',
  DeploymentPending = 'DEPLOYMENT_PENDING',
  Draft = 'DRAFT',
  Failed = 'FAILED',
  Success = 'SUCCESS',
}

export type HardwareConfiguration = {
  __typename?: 'HardwareConfiguration'
  boxPc: BoxPc
  camera: Camera
  cdisBox: CdisBox
  keyRfid: Scalars['String']['output']
  router: Router
  tablet: Maybe<Tablet>
}

export type Heatmap = {
  __typename?: 'Heatmap'
  createdAt: Scalars['DateTime']['output']
  /** format for each element is [lat, lon, value] */
  data: Array<Array<Scalars['Float']['output']>>
  /** space between points in the raster (meters) */
  rasterSize: Scalars['Int']['output']
  stats: HeatmapStats
}

export type HeatmapStats = {
  __typename?: 'HeatmapStats'
  avg: Scalars['Float']['output']
  max: Scalars['Float']['output']
  min: Scalars['Float']['output']
  p10: Scalars['Float']['output']
  p20: Scalars['Float']['output']
  p30: Scalars['Float']['output']
  p40: Scalars['Float']['output']
  p50: Scalars['Float']['output']
  p60: Scalars['Float']['output']
  p70: Scalars['Float']['output']
  p80: Scalars['Float']['output']
  p90: Scalars['Float']['output']
}

export type HistoryFieldChange = {
  __typename?: 'HistoryFieldChange'
  fieldName: Scalars['String']['output']
  from: Maybe<Scalars['String']['output']>
  to: Maybe<Scalars['String']['output']>
}

export type HistoryItem = {
  __typename?: 'HistoryItem'
  /** user who changed the action which affected this item */
  actionChangedBy: Maybe<Scalars['String']['output']>
  changedAt: Scalars['DateTime']['output']
  changedBy: Scalars['String']['output']
  changes: Array<HistoryFieldChange>
  eventItemId: Scalars['String']['output']
  eventItemType: EventItemType
  serviceAreaId: Scalars['String']['output']
}

export type HistoryItems = {
  __typename?: 'HistoryItems'
  items: Array<HistoryItem>
  nextCursor: Maybe<Scalars['String']['output']>
}

export type Hub = {
  __typename?: 'Hub'
  /** @deprecated Fleet legacy field, replace with geometry */
  area: Maybe<GeoJson>
  areaCapacityConfiguration: Maybe<AreaCapacityConfiguration>
  /**
   * Schedule-based configuration. Null if not yet configured.
   * @deprecated Use the same field on the Area type
   */
  capacityConfiguration: Maybe<Array<HubCapacity>>
  changeDescription: Maybe<Scalars['String']['output']>
  city: Scalars['String']['output']
  contact: Scalars['String']['output']
  displayName: Scalars['String']['output']
  /** The hub's geometry as a GeoJSON string */
  geometry: Scalars['String']['output']
  id: Scalars['ID']['output']
  /** @deprecated Use Area.type */
  inFieldBreakLocation: Maybe<Scalars['Boolean']['output']>
  location: Location
  name: Scalars['String']['output']
  phoneNumber: Scalars['String']['output']
  postcode: Scalars['String']['output']
  serviceAreaUUID: Scalars['ID']['output']
  streetAndNumber: Scalars['String']['output']
  /**
   * Number of vehicles returning to this hub in the specified interval.
   * Defaults to the next 30 minutes.
   * @deprecated Use the same field on the Area type
   */
  totalReturningVehicles: Maybe<Scalars['Int']['output']>
}

export type HubTotalReturningVehiclesArgs = {
  interval: InputMaybe<DateTimeIntervalInput>
}

export enum HubArrivalOrDepartureType {
  BreakEndDeparture = 'BREAK_END_DEPARTURE',
  BreakStartArrival = 'BREAK_START_ARRIVAL',
  ShiftEndArrival = 'SHIFT_END_ARRIVAL',
  ShiftStartDeparture = 'SHIFT_START_DEPARTURE',
}

export type HubArrivalsOrDeparturesByType = {
  __typename?: 'HubArrivalsOrDeparturesByType'
  total: Scalars['Int']['output']
  type: HubArrivalOrDepartureType
}

export type HubCapacity = {
  __typename?: 'HubCapacity'
  /** AC slow-charging */
  acChargingCapacity: Scalars['Int']['output']
  /** DC fast-charging */
  highPowerChargingCapacity: Scalars['Int']['output']
  /** Total non-charging capacity */
  parkingCapacity: Scalars['Int']['output']
  schedule: Scalars['CronExpression']['output']
}

export type HubDataInput = {
  changeDescription: InputMaybe<Scalars['String']['input']>
  city: Scalars['String']['input']
  contact: Scalars['String']['input']
  description: InputMaybe<Scalars['String']['input']>
  displayName: Scalars['String']['input']
  /** The hub's geometry as a GeoJSON string */
  geometry: InputMaybe<Scalars['String']['input']>
  location: LocationInput
  name: Scalars['String']['input']
  phoneNumber: Scalars['String']['input']
  postcode: Scalars['String']['input']
  serviceAreaUUID: Scalars['ID']['input']
  streetAndNumber: Scalars['String']['input']
}

export type HubDetails = {
  __typename?: 'HubDetails'
  city: Scalars['String']['output']
  contact: Scalars['String']['output']
  displayName: Scalars['String']['output']
  hubStop: Maybe<NavigationStop>
  phoneNumber: Scalars['String']['output']
  postcode: Scalars['String']['output']
  streetAndNumber: Scalars['String']['output']
}

export type HubLogRecordInput = {
  employeeId: Scalars['ID']['input']
  hubId: Scalars['ID']['input']
}

export type HubReturn = {
  __typename?: 'HubReturn'
  area: Maybe<Area>
  hub: Maybe<Hub>
  isInFieldBreak: Scalars['Boolean']['output']
  scheduledArrival: Scalars['DateTime']['output']
  type: HubReturnType
}

export enum HubReturnReason {
  DriverBreak = 'DRIVER_BREAK',
  OnDemand = 'ON_DEMAND',
  ShiftEnd = 'SHIFT_END',
  ShortBreak = 'SHORT_BREAK',
}

export enum HubReturnType {
  BreakStart = 'BREAK_START',
  ShiftEnd = 'SHIFT_END',
}

export type HubServiceNotification = TaskNotification & {
  __typename?: 'HubServiceNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  employee: Maybe<Employee>
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  shift: Maybe<Shift>
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export type HubsResponse = {
  __typename?: 'HubsResponse'
  hubs: Array<Hub>
  total: Scalars['Int']['output']
}

export type IBackofficeUserIdentity = {
  firstName: Scalars['String']['output']
  id: Scalars['ID']['output']
  lastName: Scalars['String']['output']
}

export type Ignition = {
  __typename?: 'Ignition'
  ignitionState: Maybe<IgnitionState>
  /** Last time the ignition switched state */
  updatedAt: Maybe<Scalars['DateTime']['output']>
}

export enum IgnitionState {
  Off = 'OFF',
  On = 'ON',
}

export type InFieldBreakDetails = {
  __typename?: 'InFieldBreakDetails'
  stop: Maybe<NavigationStop>
}

export enum InactiveReasonCategory {
  AuthorityApprovalPending = 'AUTHORITY_APPROVAL_PENDING',
  Other = 'OTHER',
  OutOfServiceArea = 'OUT_OF_SERVICE_AREA',
}

export enum IncidentTypeIds {
  AirbagSystemError = 'AIRBAG_SYSTEM_ERROR',
  AutomaticEmergencyBrakeAebOff = 'AUTOMATIC_EMERGENCY_BRAKE_AEB_OFF',
  AutomaticEmergencyBrakeEscOff = 'AUTOMATIC_EMERGENCY_BRAKE_ESC_OFF',
  AutomaticEmergencyBrakeNoSensorView = 'AUTOMATIC_EMERGENCY_BRAKE_NO_SENSOR_VIEW',
  AutomaticEmergencyBrakeSystemError = 'AUTOMATIC_EMERGENCY_BRAKE_SYSTEM_ERROR',
  BatteryManagementChargingStatusOfHvChargingError = 'BATTERY_MANAGEMENT_CHARGING_STATUS_OF_HV_CHARGING_ERROR',
  BatteryManagementChargingStatusOfLvBatteryError = 'BATTERY_MANAGEMENT_CHARGING_STATUS_OF_LV_BATTERY_ERROR',
  BatteryManagementDcdcConverterDefective = 'BATTERY_MANAGEMENT_DCDC_CONVERTER_DEFECTIVE',
  BatteryManagementGeneratorDefective = 'BATTERY_MANAGEMENT_GENERATOR_DEFECTIVE',
  BatteryManagementRequestForBatteryService = 'BATTERY_MANAGEMENT_REQUEST_FOR_BATTERY_SERVICE',
  BodyControlModuleBrakeFluidBelowMinThreshold = 'BODY_CONTROL_MODULE_BRAKE_FLUID_BELOW_MIN_THRESHOLD',
  BodyControlModuleBrakePadBelowMinThreshold = 'BODY_CONTROL_MODULE_BRAKE_PAD_BELOW_MIN_THRESHOLD',
  BodyControlModuleCoolingFluidBelowMinThreshold = 'BODY_CONTROL_MODULE_COOLING_FLUID_BELOW_MIN_THRESHOLD',
  BodyControlModuleHoodOpenHightVel = 'BODY_CONTROL_MODULE_HOOD_OPEN_HIGHT_VEL',
  BodyControlModuleHoodOpenLowVel = 'BODY_CONTROL_MODULE_HOOD_OPEN_LOW_VEL',
  BodyControlModuleWasherFluidBelowMinThreshold = 'BODY_CONTROL_MODULE_WASHER_FLUID_BELOW_MIN_THRESHOLD',
  ButtonSr2Jammed = 'BUTTON_SR2_JAMMED',
  ButtonSr3Jammed = 'BUTTON_SR3_JAMMED',
  ChargingStatusLow = 'CHARGING_STATUS_LOW',
  ChildProtectionErrorDefect = 'CHILD_PROTECTION_ERROR_DEFECT',
  ClampControlEmergencyShutdownTextInInstrumentCluster = 'CLAMP_CONTROL_EMERGENCY_SHUTDOWN_TEXT_IN_INSTRUMENT_CLUSTER',
  ClampControlWorkshopTextInInstrumentCluster = 'CLAMP_CONTROL_WORKSHOP_TEXT_IN_INSTRUMENT_CLUSTER',
  ClimateSensorExternalTempSensorError = 'CLIMATE_SENSOR_EXTERNAL_TEMP_SENSOR_ERROR',
  ClimateSensorStatusExternalTempFault = 'CLIMATE_SENSOR_STATUS_EXTERNAL_TEMP_FAULT',
  CollisionWObjectLateralImpactDetected = 'COLLISION_W_OBJECT_LATERAL_IMPACT_DETECTED',
  CollisionWObjectLevel_1 = 'COLLISION_W_OBJECT_LEVEL_1',
  CollisionWObjectLevel_2 = 'COLLISION_W_OBJECT_LEVEL_2',
  CoolingSdsComputerMainThermomanagementCoolantTemperatureWarningSafetyDriverAboveThreshold = 'COOLING_SDS_COMPUTER_MAIN_THERMOMANAGEMENT_COOLANT_TEMPERATURE_WARNING_SAFETY_DRIVER_ABOVE_THRESHOLD',
  CoolingSdsComputerMainThermomanagementCoolantTemperatureWarningSafetyDriverTooHigh = 'COOLING_SDS_COMPUTER_MAIN_THERMOMANAGEMENT_COOLANT_TEMPERATURE_WARNING_SAFETY_DRIVER_TOO_HIGH',
  CoPilotDetectionErrorInSeatOccupancyMat = 'CO_PILOT_DETECTION_ERROR_IN_SEAT_OCCUPANCY_MAT',
  DiagnosisAutonomousDrivingAvsAuthAvsAuthorityOvertakeSecondarySdsComputerError = 'DIAGNOSIS_AUTONOMOUS_DRIVING_AVS_AUTH_AVS_AUTHORITY_OVERTAKE_SECONDARY_SDS_COMPUTER_ERROR',
  DiagnosisAutonomousDrivingAvsErrorAvsAuthorityOvertakeSecondarySdsComputerError = 'DIAGNOSIS_AUTONOMOUS_DRIVING_AVS_ERROR_AVS_AUTHORITY_OVERTAKE_SECONDARY_SDS_COMPUTER_ERROR',
  DiagnosisAutonomousDrivingCarOvertakeErrorCavsAndAvsError = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_CAVS_AND_AVS_ERROR',
  DiagnosisAutonomousDrivingCarOvertakeErrorCavsAndAvsErrorHigh = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_CAVS_AND_AVS_ERROR_HIGH',
  DiagnosisAutonomousDrivingCarOvertakeErrorCavsAndAvsErrorLow = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_CAVS_AND_AVS_ERROR_LOW',
  DiagnosisAutonomousDrivingCarOvertakeErrorCavsAndAvsErrorMedium = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_CAVS_AND_AVS_ERROR_MEDIUM',
  DiagnosisAutonomousDrivingCarOvertakeErrorCavsAndAvsUnplausibleStatAuthority = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_CAVS_AND_AVS_UNPLAUSIBLE_STAT_AUTHORITY',
  DiagnosisAutonomousDrivingCarOvertakeErrorInterfaceDrivingStatusDtm = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_INTERFACE_DRIVING_STATUS_DTM',
  DiagnosisAutonomousDrivingCarOvertakeErrorMrc_2A = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_MRC_2A',
  DiagnosisAutonomousDrivingCarOvertakeErrorMrcEmergencyBrakingImpactSuspected = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_MRC_EMERGENCY_BRAKING_IMPACT_SUSPECTED',
  DiagnosisAutonomousDrivingCarOvertakeErrorMrcImpactDetected = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_MRC_IMPACT_DETECTED',
  DiagnosisAutonomousDrivingCarOvertakeErrorMrcPullover = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_MRC_PULLOVER',
  DiagnosisAutonomousDrivingCarOvertakeErrorMrcStop = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAR_OVERTAKE_ERROR_MRC_STOP',
  DiagnosisAutonomousDrivingCavsAuthCavsAuthorityOvertakeSecondarySdsComputerError = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAVS_AUTH_CAVS_AUTHORITY_OVERTAKE_SECONDARY_SDS_COMPUTER_ERROR',
  DiagnosisAutonomousDrivingCavsErrorCavsAuthorityOvertakePrimarySdsComputerError = 'DIAGNOSIS_AUTONOMOUS_DRIVING_CAVS_ERROR_CAVS_AUTHORITY_OVERTAKE_PRIMARY_SDS_COMPUTER_ERROR',
  DimmingErrorKl_58XdDisplayAndInformationLighting = 'DIMMING_ERROR_KL_58XD_DISPLAY_AND_INFORMATION_LIGHTING',
  DimmingErrorKl_58XsSwitchSearchLights = 'DIMMING_ERROR_KL_58XS_SWITCH_SEARCH_LIGHTS',
  DimmingErrorKl_58XtTimerTriggeredSearchLightsEGStartStopButton = 'DIMMING_ERROR_KL_58XT_TIMER_TRIGGERED_SEARCH_LIGHTS_E_G_START_STOP_BUTTON',
  DoorLockDefect = 'DOOR_LOCK_DEFECT',
  DoorStatusError = 'DOOR_STATUS_ERROR',
  ElectronicBrakeBoosterFailure_2NdActuator = 'ELECTRONIC_BRAKE_BOOSTER_FAILURE_2ND_ACTUATOR',
  ElectronicBrakeBoosterFailureEbbWithoutHydraulicBb = 'ELECTRONIC_BRAKE_BOOSTER_FAILURE_EBB_WITHOUT_HYDRAULIC_BB',
  ElectronicBrakeBoosterFailureStandstillManagement = 'ELECTRONIC_BRAKE_BOOSTER_FAILURE_STANDSTILL_MANAGEMENT',
  ElectronicBrakeBoosterReductionOfBrakeBoost = 'ELECTRONIC_BRAKE_BOOSTER_REDUCTION_OF_BRAKE_BOOST',
  ElectronicParkBrakeFailureStartUpAssistant = 'ELECTRONIC_PARK_BRAKE_FAILURE_START_UP_ASSISTANT',
  ElectronicParkBrakeFaultSlopeHoldingAbility = 'ELECTRONIC_PARK_BRAKE_FAULT_SLOPE_HOLDING_ABILITY',
  ElectronicParkBrakeInclinationOfSlopeTooHigh = 'ELECTRONIC_PARK_BRAKE_INCLINATION_OF_SLOPE_TOO_HIGH',
  ElectronicParkBrakeSystemError = 'ELECTRONIC_PARK_BRAKE_SYSTEM_ERROR',
  ElectronicPowerControlFaultService = 'ELECTRONIC_POWER_CONTROL_FAULT_SERVICE',
  ElectronicPowerControlFaultVehicleStop = 'ELECTRONIC_POWER_CONTROL_FAULT_VEHICLE_STOP',
  ElectronicPowerControlPowerRestriction = 'ELECTRONIC_POWER_CONTROL_POWER_RESTRICTION',
  ElectronicStabilityProgramAbsLamp = 'ELECTRONIC_STABILITY_PROGRAM_ABS_LAMP',
  ElectronicStabilityProgramActiveTractionControl = 'ELECTRONIC_STABILITY_PROGRAM_ACTIVE_TRACTION_CONTROL',
  ElectronicStabilityProgramElectricalErrorWOEffectOnBrakeForceDistribution = 'ELECTRONIC_STABILITY_PROGRAM_ELECTRICAL_ERROR_W_O_EFFECT_ON_BRAKE_FORCE_DISTRIBUTION',
  ElectronicStabilityProgramErrorElectronicBrakeForceDistribution = 'ELECTRONIC_STABILITY_PROGRAM_ERROR_ELECTRONIC_BRAKE_FORCE_DISTRIBUTION',
  ElectronicStabilityProgramErrorLateralAcceleration = 'ELECTRONIC_STABILITY_PROGRAM_ERROR_LATERAL_ACCELERATION',
  ElectronicStabilityProgramErrorLongitudinalAcceleration = 'ELECTRONIC_STABILITY_PROGRAM_ERROR_LONGITUDINAL_ACCELERATION',
  ElectronicStabilityProgramErrorQbitLateralAcceleration = 'ELECTRONIC_STABILITY_PROGRAM_ERROR_QBIT_LATERAL_ACCELERATION',
  ElectronicStabilityProgramErrorQbitLongitudinalAcceleration = 'ELECTRONIC_STABILITY_PROGRAM_ERROR_QBIT_LONGITUDINAL_ACCELERATION',
  ElectronicStabilityProgramErrorVelocitySignal = 'ELECTRONIC_STABILITY_PROGRAM_ERROR_VELOCITY_SIGNAL',
  ElectronicStabilityProgramErrorWheelTickSignal = 'ELECTRONIC_STABILITY_PROGRAM_ERROR_WHEEL_TICK_SIGNAL',
  ElectronicStabilityProgramEscFaultTextInInstrumentCluster = 'ELECTRONIC_STABILITY_PROGRAM_ESC_FAULT_TEXT_IN_INSTRUMENT_CLUSTER',
  ElectronicStabilityProgramEscFaultTextInInstrumentClusterRed = 'ELECTRONIC_STABILITY_PROGRAM_ESC_FAULT_TEXT_IN_INSTRUMENT_CLUSTER_RED',
  ElectronicStabilityProgramEscLampOn = 'ELECTRONIC_STABILITY_PROGRAM_ESC_LAMP_ON',
  ElectronicStabilityProgramEscLampShortBlink = 'ELECTRONIC_STABILITY_PROGRAM_ESC_LAMP_SHORT_BLINK',
  ElectronicStabilityProgramUndervoltageVelocitySignal = 'ELECTRONIC_STABILITY_PROGRAM_UNDERVOLTAGE_VELOCITY_SIGNAL',
  EmergencyStopButtonPressed = 'EMERGENCY_STOP_BUTTON_PRESSED',
  EventDataRecorderEdrAdDataLoggerCrashAutomaticTriggerDssd = 'EVENT_DATA_RECORDER_EDR_AD_DATA_LOGGER_CRASH_AUTOMATIC_TRIGGER_DSSD',
  EventDataRecorderEdrAdDataLoggerCrashAutomaticTriggerEdr = 'EVENT_DATA_RECORDER_EDR_AD_DATA_LOGGER_CRASH_AUTOMATIC_TRIGGER_EDR',
  ESoundDefectiveError = 'E_SOUND_DEFECTIVE_ERROR',
  FrontLightsAllFrontLightsLeftRightDefect = 'FRONT_LIGHTS_ALL_FRONT_LIGHTS_LEFT_RIGHT_DEFECT',
  FrontLightsAutomaticHighBeamAssistDefect = 'FRONT_LIGHTS_AUTOMATIC_HIGH_BEAM_ASSIST_DEFECT',
  FrontLightsAutomaticHighBeamAssistSensorBlocked = 'FRONT_LIGHTS_AUTOMATIC_HIGH_BEAM_ASSIST_SENSOR_BLOCKED',
  FrontLightsCombinatedLightDefect = 'FRONT_LIGHTS_COMBINATED_LIGHT_DEFECT',
  FrontLightsDaytimeRunningLightLeftRightDefect = 'FRONT_LIGHTS_DAYTIME_RUNNING_LIGHT_LEFT_RIGHT_DEFECT',
  FrontLightsFogLightLeftRightDefect = 'FRONT_LIGHTS_FOG_LIGHT_LEFT_RIGHT_DEFECT',
  FrontLightsHeadLightRangeControlError = 'FRONT_LIGHTS_HEAD_LIGHT_RANGE_CONTROL_ERROR',
  FrontLightsHighBeamLeftRightDefectDay = 'FRONT_LIGHTS_HIGH_BEAM_LEFT_RIGHT_DEFECT_DAY',
  FrontLightsHighBeamLeftRightDefectNight = 'FRONT_LIGHTS_HIGH_BEAM_LEFT_RIGHT_DEFECT_NIGHT',
  FrontLightsIndicatorLeftRightDefect = 'FRONT_LIGHTS_INDICATOR_LEFT_RIGHT_DEFECT',
  FrontLightsIndicatorLeftRightSideDefect = 'FRONT_LIGHTS_INDICATOR_LEFT_RIGHT_SIDE_DEFECT',
  FrontLightsLowBeamLeftRightDefectDay = 'FRONT_LIGHTS_LOW_BEAM_LEFT_RIGHT_DEFECT_DAY',
  FrontLightsLowBeamLeftRightDefectNight = 'FRONT_LIGHTS_LOW_BEAM_LEFT_RIGHT_DEFECT_NIGHT',
  FrontLightsParkingLightLeftRightDefect = 'FRONT_LIGHTS_PARKING_LIGHT_LEFT_RIGHT_DEFECT',
  FrontLightsTurningLightLeftRightDefect = 'FRONT_LIGHTS_TURNING_LIGHT_LEFT_RIGHT_DEFECT',
  HvacBlowerError = 'HVAC_BLOWER_ERROR',
  ImmobilizerEmergencyModeLimpHomeModeActivated = 'IMMOBILIZER_EMERGENCY_MODE_LIMP_HOME_MODE_ACTIVATED',
  ImmobilizerKeyAuthenticationFailed = 'IMMOBILIZER_KEY_AUTHENTICATION_FAILED',
  ImmobilizerKeyNotFound = 'IMMOBILIZER_KEY_NOT_FOUND',
  IncidentHandlerWithoutFunctionFunctionIhDegradedOrErrorMode = 'INCIDENT_HANDLER_WITHOUT_FUNCTION_FUNCTION_IH_DEGRADED_OR_ERROR_MODE',
  InteriorMonitoringMrc_2ACriticalIncidentWithDriveInterruptionMrc2A = 'INTERIOR_MONITORING_MRC_2A_CRITICAL_INCIDENT_WITH_DRIVE_INTERRUPTION_MRC2A',
  InteriorMonitoringMrc_2BCriticalIncidentWithDriveInterruptionMrc2B = 'INTERIOR_MONITORING_MRC_2B_CRITICAL_INCIDENT_WITH_DRIVE_INTERRUPTION_MRC2B',
  KeylessEntryKeylessEntryDefective = 'KEYLESS_ENTRY_KEYLESS_ENTRY_DEFECTIVE',
  NoncriticalInteriorMonitoringIncidentServesForStorage = 'NONCRITICAL_INTERIOR_MONITORING_INCIDENT_SERVES_FOR_STORAGE',
  NvemDragoon1Dra1ErrorMode = 'NVEM_DRAGOON1_DRA1_ERROR_MODE',
  NvemDragoon2Dra2ErrorMode = 'NVEM_DRAGOON2_DRA2_ERROR_MODE',
  NvemDragoon3Dra3ErrorMode = 'NVEM_DRAGOON3_DRA3_ERROR_MODE',
  OnlineRemoteUpdateUpdateFailed = 'ONLINE_REMOTE_UPDATE_UPDATE_FAILED',
  OnlineRemoteUpdateUpdateIncomplete = 'ONLINE_REMOTE_UPDATE_UPDATE_INCOMPLETE',
  OverheatWarningHotDriveSlowly = 'OVERHEAT_WARNING_HOT_DRIVE_SLOWLY',
  OverheatWarningHotMaxVelocityLimited = 'OVERHEAT_WARNING_HOT_MAX_VELOCITY_LIMITED',
  PowertrainCoordinatorBrakesOverheated_1 = 'POWERTRAIN_COORDINATOR_BRAKES_OVERHEATED_1',
  PowertrainCoordinatorBrakesOverheated_2 = 'POWERTRAIN_COORDINATOR_BRAKES_OVERHEATED_2',
  PreCrashCurrentlyRestricted = 'PRE_CRASH_CURRENTLY_RESTRICTED',
  PreCrashCurrentlyRestrictedEscOff = 'PRE_CRASH_CURRENTLY_RESTRICTED_ESC_OFF',
  PreCrashDemoMode = 'PRE_CRASH_DEMO_MODE',
  PreCrashEscOff = 'PRE_CRASH_ESC_OFF',
  PreCrashNoSensorView = 'PRE_CRASH_NO_SENSOR_VIEW',
  PreCrashSystemError = 'PRE_CRASH_SYSTEM_ERROR',
  PreCrashSystemOff = 'PRE_CRASH_SYSTEM_OFF',
  RainSensorDefect = 'RAIN_SENSOR_DEFECT',
  RainVisibilityIncident = 'RAIN_VISIBILITY_INCIDENT',
  RangeRangeError = 'RANGE_RANGE_ERROR',
  RearLightsAllBrakeLightsLeftRightDefect = 'REAR_LIGHTS_ALL_BRAKE_LIGHTS_LEFT_RIGHT_DEFECT',
  RearLightsFogLightLeftRightMiddleDefect = 'REAR_LIGHTS_FOG_LIGHT_LEFT_RIGHT_MIDDLE_DEFECT',
  RearLightsIndicatorLeftRightDefect = 'REAR_LIGHTS_INDICATOR_LEFT_RIGHT_DEFECT',
  RearLightsMin_1BrakeLightLeftRightMiddleDefect = 'REAR_LIGHTS_MIN_1_BRAKE_LIGHT_LEFT_RIGHT_MIDDLE_DEFECT',
  RearLightsMin_1TailLightLeftRightMiddleDefect = 'REAR_LIGHTS_MIN_1_TAIL_LIGHT_LEFT_RIGHT_MIDDLE_DEFECT',
  RearLightsNumberPlateLightDefect = 'REAR_LIGHTS_NUMBER_PLATE_LIGHT_DEFECT',
  RearLightsRearLightsControlDefect = 'REAR_LIGHTS_REAR_LIGHTS_CONTROL_DEFECT',
  RearLightsReversingLightLeftRightMiddleDefect = 'REAR_LIGHTS_REVERSING_LIGHT_LEFT_RIGHT_MIDDLE_DEFECT',
  ReducingElectricalDrivePowerBelowGbbThreshold = 'REDUCING_ELECTRICAL_DRIVE_POWER_BELOW_GBB_THRESHOLD',
  ReducingElectricalDrivePowerBelowMinThreshold = 'REDUCING_ELECTRICAL_DRIVE_POWER_BELOW_MIN_THRESHOLD',
  ReducingElectricalDrivePowerNoDriveThrust = 'REDUCING_ELECTRICAL_DRIVE_POWER_NO_DRIVE_THRUST',
  SafetyDriverDetectionErrorInSeatOccupancyMat = 'SAFETY_DRIVER_DETECTION_ERROR_IN_SEAT_OCCUPANCY_MAT',
  SafetyDriverInterventionAcceleratorIncident = 'SAFETY_DRIVER_INTERVENTION_ACCELERATOR_INCIDENT',
  SafetyDriverInterventionBrakeIncident = 'SAFETY_DRIVER_INTERVENTION_BRAKE_INCIDENT',
  SafetyDriverInterventionSteeringIncident = 'SAFETY_DRIVER_INTERVENTION_STEERING_INCIDENT',
  SeatBeltPass_1_2NotBuckled = 'SEAT_BELT_PASS_1_2_NOT_BUCKLED',
  SeatBeltPass_1_2NotBuckledSdsEngaged = 'SEAT_BELT_PASS_1_2_NOT_BUCKLED_SDS_ENGAGED',
  SeatBeltPass_1_3NotBuckled = 'SEAT_BELT_PASS_1_3_NOT_BUCKLED',
  SeatBeltPass_1_3NotBuckledSdsEngaged = 'SEAT_BELT_PASS_1_3_NOT_BUCKLED_SDS_ENGAGED',
  SeatBeltPass_1_4NotBuckled = 'SEAT_BELT_PASS_1_4_NOT_BUCKLED',
  SeatBeltPass_1_4NotBuckledSdsEngaged = 'SEAT_BELT_PASS_1_4_NOT_BUCKLED_SDS_ENGAGED',
  SeatBeltPass_2_2NotBuckled = 'SEAT_BELT_PASS_2_2_NOT_BUCKLED',
  SeatBeltPass_2_2NotBuckledSdsEngaged = 'SEAT_BELT_PASS_2_2_NOT_BUCKLED_SDS_ENGAGED',
  SeatBeltPass_2_3NotBuckled = 'SEAT_BELT_PASS_2_3_NOT_BUCKLED',
  SeatBeltPass_2_3NotBuckledSdsEngaged = 'SEAT_BELT_PASS_2_3_NOT_BUCKLED_SDS_ENGAGED',
  SeatBeltPass_2_4NotBuckled = 'SEAT_BELT_PASS_2_4_NOT_BUCKLED',
  SeatBeltPass_2_4NotBuckledSdsEngaged = 'SEAT_BELT_PASS_2_4_NOT_BUCKLED_SDS_ENGAGED',
  SeatBeltPass_3_2NotBuckled = 'SEAT_BELT_PASS_3_2_NOT_BUCKLED',
  SeatBeltPass_3_2NotBuckledSdsEngaged = 'SEAT_BELT_PASS_3_2_NOT_BUCKLED_SDS_ENGAGED',
  SeatBeltPass_3_3NotBuckled = 'SEAT_BELT_PASS_3_3_NOT_BUCKLED',
  SeatBeltPass_3_3NotBuckledSdsEngaged = 'SEAT_BELT_PASS_3_3_NOT_BUCKLED_SDS_ENGAGED',
  SeatBeltPass_3_4NotBuckled = 'SEAT_BELT_PASS_3_4_NOT_BUCKLED',
  SeatBeltPass_3_4NotBuckledSdsEngaged = 'SEAT_BELT_PASS_3_4_NOT_BUCKLED_SDS_ENGAGED',
  ServiceCallActivationSr2NotificationOfServiceCallRequirementForStorageInTheEdr = 'SERVICE_CALL_ACTIVATION_SR2_NOTIFICATION_OF_SERVICE_CALL_REQUIREMENT_FOR_STORAGE_IN_THE_EDR',
  ServiceCallActivationSr3NotificationOfServiceCallRequirementForStorageInTheEdr = 'SERVICE_CALL_ACTIVATION_SR3_NOTIFICATION_OF_SERVICE_CALL_REQUIREMENT_FOR_STORAGE_IN_THE_EDR',
  SlidingDoor = 'SLIDING_DOOR',
  SlidingDoorAntiPinchSituation = 'SLIDING_DOOR_ANTI_PINCH_SITUATION',
  SlidingDoorCustomerIntervention = 'SLIDING_DOOR_CUSTOMER_INTERVENTION',
  SlidingDoorDriverTakeover = 'SLIDING_DOOR_DRIVER_TAKEOVER',
  SlidingDoorNotFunctioning = 'SLIDING_DOOR_NOT_FUNCTIONING',
  SlidingDoorPlayProtectionActive = 'SLIDING_DOOR_PLAY_PROTECTION_ACTIVE',
  SteeringAuthorityFailure = 'STEERING_AUTHORITY_FAILURE',
  SteeringColumnLockRequestMoveSteeringWheel = 'STEERING_COLUMN_LOCK_REQUEST_MOVE_STEERING_WHEEL',
  SteeringColumnLockRequestSclWorkshop = 'STEERING_COLUMN_LOCK_REQUEST_SCL_WORKSHOP',
  SteeringQfkError = 'STEERING_QFK_ERROR',
  SteeringWheelHeaterError = 'STEERING_WHEEL_HEATER_ERROR',
  TimeMasterError = 'TIME_MASTER_ERROR',
  TirePressureMonitoringSystemAllTiresWarning_1 = 'TIRE_PRESSURE_MONITORING_SYSTEM_ALL_TIRES_WARNING_1',
  TirePressureMonitoringSystemAllTiresWarning_2 = 'TIRE_PRESSURE_MONITORING_SYSTEM_ALL_TIRES_WARNING_2',
  TirePressureMonitoringSystemAllTiresWarning_3 = 'TIRE_PRESSURE_MONITORING_SYSTEM_ALL_TIRES_WARNING_3',
  TirePressureMonitoringSystemFrontRearLeftRightTireWarning_1 = 'TIRE_PRESSURE_MONITORING_SYSTEM_FRONT_REAR_LEFT_RIGHT_TIRE_WARNING_1',
  TirePressureMonitoringSystemFrontRearLeftRightTireWarning_2 = 'TIRE_PRESSURE_MONITORING_SYSTEM_FRONT_REAR_LEFT_RIGHT_TIRE_WARNING_2',
  TirePressureMonitoringSystemFrontRearLeftRightTireWarning_3 = 'TIRE_PRESSURE_MONITORING_SYSTEM_FRONT_REAR_LEFT_RIGHT_TIRE_WARNING_3',
  TirePressureMonitoringSystemSystemError = 'TIRE_PRESSURE_MONITORING_SYSTEM_SYSTEM_ERROR',
  VssErrorAcceleration = 'VSS_ERROR_ACCELERATION',
  VssErrorGear = 'VSS_ERROR_GEAR',
  VssErrorPrimaryBrake = 'VSS_ERROR_PRIMARY_BRAKE',
  VssErrorPrimarySteering = 'VSS_ERROR_PRIMARY_STEERING',
  VssErrorRvs = 'VSS_ERROR_RVS',
  VssErrorSecondarySteering = 'VSS_ERROR_SECONDARY_STEERING',
}

export enum Incidentstatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export type InsertHubLogCheckedInRecordResponse = {
  __typename?: 'InsertHubLogCheckedInRecordResponse'
  record: ShiftStateHubLogRecord
}

export type InsertHubLogCheckedOutRecordResponse = {
  __typename?: 'InsertHubLogCheckedOutRecordResponse'
  record: ShiftStateHubLogRecord
}

export enum InspectionDoneFilter {
  InspectionProtocolMissing = 'INSPECTION_PROTOCOL_MISSING',
  InvoiceMissing = 'INVOICE_MISSING',
  Last_6Months = 'LAST_6_MONTHS',
  Last_30Days = 'LAST_30_DAYS',
  SinceStartOfLastYear = 'SINCE_START_OF_LAST_YEAR',
  SinceStartOfThisYear = 'SINCE_START_OF_THIS_YEAR',
}

export type InspectionDoneProcessesSearch = {
  doneFilter: InspectionDoneFilter
  label: InputMaybe<Scalars['Int']['input']>
  serviceAreaUUID: Scalars['String']['input']
}

export type InspectionDueProcessesSearch = {
  /** days for main inspections, km for mileage-based inspections / VICs */
  dueWithin: InputMaybe<Scalars['Int']['input']>
  label: InputMaybe<Scalars['Int']['input']>
  serviceAreaUUID: Scalars['String']['input']
}

export type InspectionProgressProcessesSearch = {
  label: InputMaybe<Scalars['Int']['input']>
  serviceAreaUUID: Scalars['String']['input']
}

export enum InteractionReason {
  AppProblems = 'APP_PROBLEMS',
  Charging = 'CHARGING',
  Cleaning = 'CLEANING',
  ForgotSomething = 'FORGOT_SOMETHING',
  Maintenance = 'MAINTENANCE',
  Other = 'OTHER',
  Reparking = 'REPARKING',
  TelemetryProblems = 'TELEMETRY_PROBLEMS',
}

export type IvcTaskNotification = TaskNotification & {
  __typename?: 'IvcTaskNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  timestamp: Scalars['DateTime']['output']
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export enum KeyFobState {
  Empty = 'EMPTY',
  Inserted = 'INSERTED',
}

export type KeyRfidReplacedChange = FleetEntityChange & {
  __typename?: 'KeyRfidReplacedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: KeyRfidReplacedChangeFields
}

export type KeyRfidReplacedChangeFields = VehicleChangeFields & {
  __typename?: 'KeyRfidReplacedChangeFields'
  keyRfidNew: Maybe<Scalars['String']['output']>
  keyRfidOld: Maybe<Scalars['String']['output']>
  vehicleID: Scalars['ID']['output']
}

export enum Language {
  De = 'DE',
  En = 'EN',
}

export enum LanguageCode {
  Aa = 'aa',
  Ab = 'ab',
  Ae = 'ae',
  Af = 'af',
  Ak = 'ak',
  Am = 'am',
  An = 'an',
  Ar = 'ar',
  As = 'as',
  Av = 'av',
  Ay = 'ay',
  Az = 'az',
  Ba = 'ba',
  Be = 'be',
  Bg = 'bg',
  Bh = 'bh',
  Bi = 'bi',
  Bm = 'bm',
  Bn = 'bn',
  Bo = 'bo',
  Br = 'br',
  Bs = 'bs',
  Ca = 'ca',
  Ce = 'ce',
  Ch = 'ch',
  Co = 'co',
  Cr = 'cr',
  Cs = 'cs',
  Cu = 'cu',
  Cv = 'cv',
  Cy = 'cy',
  Da = 'da',
  De = 'de',
  Dv = 'dv',
  Dz = 'dz',
  Ee = 'ee',
  El = 'el',
  En = 'en',
  Eo = 'eo',
  Es = 'es',
  Et = 'et',
  Eu = 'eu',
  Fa = 'fa',
  Ff = 'ff',
  Fi = 'fi',
  Fj = 'fj',
  Fo = 'fo',
  Fr = 'fr',
  Fy = 'fy',
  Ga = 'ga',
  Gd = 'gd',
  Gl = 'gl',
  Gn = 'gn',
  Gu = 'gu',
  Gv = 'gv',
  Ha = 'ha',
  He = 'he',
  Hi = 'hi',
  Ho = 'ho',
  Hr = 'hr',
  Ht = 'ht',
  Hu = 'hu',
  Hy = 'hy',
  Hz = 'hz',
  Ia = 'ia',
  Id = 'id',
  Ie = 'ie',
  Ig = 'ig',
  Ii = 'ii',
  Ik = 'ik',
  Io = 'io',
  Is = 'is',
  It = 'it',
  Iu = 'iu',
  Ja = 'ja',
  Jv = 'jv',
  Ka = 'ka',
  Kg = 'kg',
  Ki = 'ki',
  Kj = 'kj',
  Kk = 'kk',
  Kl = 'kl',
  Km = 'km',
  Kn = 'kn',
  Ko = 'ko',
  Kr = 'kr',
  Ks = 'ks',
  Ku = 'ku',
  Kv = 'kv',
  Kw = 'kw',
  Ky = 'ky',
  La = 'la',
  Lb = 'lb',
  Lg = 'lg',
  Li = 'li',
  Ln = 'ln',
  Lo = 'lo',
  Lt = 'lt',
  Lu = 'lu',
  Lv = 'lv',
  Mg = 'mg',
  Mh = 'mh',
  Mi = 'mi',
  Mk = 'mk',
  Ml = 'ml',
  Mn = 'mn',
  Mr = 'mr',
  Ms = 'ms',
  Mt = 'mt',
  My = 'my',
  Na = 'na',
  Nb = 'nb',
  Nd = 'nd',
  Ne = 'ne',
  Ng = 'ng',
  Nl = 'nl',
  Nn = 'nn',
  No = 'no',
  Nr = 'nr',
  Nv = 'nv',
  Ny = 'ny',
  Oc = 'oc',
  Oj = 'oj',
  Om = 'om',
  Or = 'or',
  Os = 'os',
  Pa = 'pa',
  Pi = 'pi',
  Pl = 'pl',
  Ps = 'ps',
  Pt = 'pt',
  Qu = 'qu',
  Rm = 'rm',
  Rn = 'rn',
  Ro = 'ro',
  Ru = 'ru',
  Rw = 'rw',
  Sa = 'sa',
  Sc = 'sc',
  Sd = 'sd',
  Se = 'se',
  Sg = 'sg',
  Si = 'si',
  Sk = 'sk',
  Sl = 'sl',
  Sm = 'sm',
  Sn = 'sn',
  So = 'so',
  Sq = 'sq',
  Sr = 'sr',
  Ss = 'ss',
  St = 'st',
  Su = 'su',
  Sv = 'sv',
  Sw = 'sw',
  Ta = 'ta',
  Te = 'te',
  Tg = 'tg',
  Th = 'th',
  Ti = 'ti',
  Tk = 'tk',
  Tl = 'tl',
  Tn = 'tn',
  To = 'to',
  Tr = 'tr',
  Ts = 'ts',
  Tt = 'tt',
  Tw = 'tw',
  Ty = 'ty',
  Ug = 'ug',
  Uk = 'uk',
  Undefined = 'undefined',
  Ur = 'ur',
  Uz = 'uz',
  Ve = 've',
  Vi = 'vi',
  Vo = 'vo',
  Wa = 'wa',
  Wo = 'wo',
  Xh = 'xh',
  Yi = 'yi',
  Yo = 'yo',
  Za = 'za',
  Zh = 'zh',
  Zu = 'zu',
}

export type LatestHubLogRecordResponse = {
  __typename?: 'LatestHubLogRecordResponse'
  record: ShiftStateHubLogRecord
}

export type ListResponse = {
  /** Total items in the set that can be paginated over */
  total: Scalars['Int']['output']
}

/** Location is a common type */
export type Location = {
  __typename?: 'Location'
  latitude: Scalars['Float']['output']
  longitude: Scalars['Float']['output']
}

export enum LocationActionCondition {
  /** Only Destination location is considered when location condition is evaluated */
  Destination = 'DESTINATION',
  /** Only Origin location is considered when location condition is evaluated */
  Origin = 'ORIGIN',
  /**
   * Both Origin and Destination locations are considered when location condition is evaluated.
   * I.e. Condition is true when either Origin or Destination location match the condition.
   */
  OriginAndDestination = 'ORIGIN_AND_DESTINATION',
}

export type LocationAttributes = {
  __typename?: 'LocationAttributes'
  area: Shape
  bottomRight: Location
  city: Scalars['String']['output']
  country: CountryCode
  defaultLocation: Location
  geometry: Scalars['String']['output']
  timezone: Scalars['TimeZone']['output']
  topLeft: Location
}

export type LocationAttributesCreationInput = {
  area: GeoJsonFeatureInput
  bottomRight: LocationInput
  city: Scalars['String']['input']
  country: CountryCode
  defaultLocation: LocationInput
  timezone: Scalars['TimeZone']['input']
  topLeft: LocationInput
}

export type LocationAttributesUpdateInput = {
  area: GeoJsonFeatureInput
  bottomRight: LocationInput
  defaultLocation: LocationInput
  timezone: Scalars['TimeZone']['input']
  topLeft: LocationInput
}

export enum LocationConditionType {
  /** Only Destination location is considered when location condition is evaluated */
  Destination = 'DESTINATION',
  /** Only Origin location is considered when location condition is evaluated */
  Origin = 'ORIGIN',
  /**
   * Both Origin and Destination locations are considered when location condition is evaluated.
   * I.e. Condition is true when either Origin or Destination location match the condition.
   */
  OriginAndDestination = 'ORIGIN_AND_DESTINATION',
}

export type LocationInput = {
  latitude: Scalars['Float']['input']
  longitude: Scalars['Float']['input']
}

export enum LocationSourceType {
  Telematic = 'TELEMATIC',
  Vip = 'VIP',
}

export type LocationWithTimestamp = {
  __typename?: 'LocationWithTimestamp'
  location: Maybe<Location>
  timestamp: Maybe<Scalars['DateTime']['output']>
}

export enum LogRecordShiftType {
  NonPooling = 'NON_POOLING',
  PlutoPooling = 'PLUTO_POOLING',
  SafetyDriverOperation = 'SAFETY_DRIVER_OPERATION',
  WavPooling = 'WAV_POOLING',
}

export type MainInspectionDocumentRequest = {
  documentID: Scalars['ID']['input']
  documentType: MainInspectionProcessDocumentType
  fileName: Scalars['String']['input']
  processID: Scalars['ID']['input']
  /** Gross cost (only applicable for invoice) */
  totalGrossCost: InputMaybe<MoneyInput>
  /** Net cost (only applicable for invoice) */
  totalNetCost: InputMaybe<MoneyInput>
  url: Scalars['String']['input']
}

export type MainInspectionProcess = FleetProcess & {
  __typename?: 'MainInspectionProcess'
  /** Appointment date for the next inspection. Must be before dueDate. */
  appointmentDate: Maybe<Scalars['DateTime']['output']>
  /** Readable unique identifier */
  case: Scalars['ID']['output']
  documents: Array<MainInspectionProcessDocument>
  /** The date that the next inspection is due */
  dueDate: Scalars['DateTime']['output']
  finishedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  /** The associated scheduled inactivation of the vehicle for this inspection */
  inactivationScheduler: Maybe<ScheduledVehicleStateChange>
  inspectionState: MainInspectionProcessState
  nextPossibleTransitions: Array<MainInspectionProcessPossibleTransition>
  serviceAreaUUID: Scalars['ID']['output']
  startedAt: Scalars['DateTime']['output']
  state: FleetProcessState
  testFacility: Maybe<MainInspectionTestFacility>
  transitions: Array<MainInspectionProcessTransition>
  type: FleetProcessType
  updatedAt: Maybe<Scalars['DateTime']['output']>
  vehicle: Vehicle
  /** Has the vehicle been activated after the inspection */
  vehicleActivatedAt: Maybe<Scalars['DateTime']['output']>
}

export type MainInspectionProcessDocument = {
  __typename?: 'MainInspectionProcessDocument'
  documentID: Scalars['ID']['output']
  documentType: MainInspectionProcessDocumentType
  /** Original filename with extension */
  fileName: Scalars['String']['output']
  /** Gross cost (only applicable for invoice) */
  totalGrossCost: Maybe<Money>
  /** Net cost (only applicable for invoice) */
  totalNetCost: Maybe<Money>
  uploadedAt: Scalars['DateTime']['output']
  url: Scalars['String']['output']
}

export enum MainInspectionProcessDocumentType {
  Invoice = 'INVOICE',
  MainInspectionProtocol = 'MAIN_INSPECTION_PROTOCOL',
}

export type MainInspectionProcessPossibleTransition = {
  __typename?: 'MainInspectionProcessPossibleTransition'
  preconditions: Array<FleetProcessStateTransitionPrecondition>
  state: MainInspectionProcessState
}

export enum MainInspectionProcessState {
  /** Invoice uploaded */
  Done = 'DONE',
  /** The next main inspection is within the next 3 month */
  Due = 'DUE',
  /** An inspection appointment is fixed, and required data is entered */
  InProgress = 'IN_PROGRESS',
  /** Main inspection is confirmed to be done */
  MainInspectionDone = 'MAIN_INSPECTION_DONE',
  /** Main inspection protocol is uploaded */
  WaitingForInvoice = 'WAITING_FOR_INVOICE',
}

export type MainInspectionProcessTransition = {
  __typename?: 'MainInspectionProcessTransition'
  initiatedAt: Scalars['DateTime']['output']
  /** The username of the user who initiated the transition */
  initiatedBy: Scalars['String']['output']
  toState: MainInspectionProcessState
}

export type MainInspectionProcessTransitionRequest = {
  nextState: MainInspectionProcessState
  processId: Scalars['ID']['input']
}

export type MainInspectionTestFacility = {
  __typename?: 'MainInspectionTestFacility'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  serviceAreaUUID: Scalars['ID']['output']
}

/** Marketing permissions of the customer */
export type MarketingPermissions = {
  __typename?: 'MarketingPermissions'
  /** Advertisement flag */
  advertisement: Scalars['Boolean']['output']
  /** Email flag */
  email: Scalars['Boolean']['output']
  /** Last date when the customer was asked for marketing permissions (ISO-8601 Date and time with offset) */
  lastAskedForMarketingPermissionsAt: Maybe<Scalars['OffsetDateTime']['output']>
  /** Last modification date when the customer changed its permissions (ISO-8601 Date and time with offset) */
  lastModified: Maybe<Scalars['OffsetDateTime']['output']>
  /** Push notifications flag */
  pushNotification: Scalars['Boolean']['output']
  /** Text messages flag */
  textMessage: Scalars['Boolean']['output']
  /** The latest version the customer applied changes to */
  version: Scalars['String']['output']
}

export type MetadataString = {
  __typename?: 'MetadataString'
  key: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type MetadataStringInput = {
  key: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type MileageBasedInspectionDocumentRequest = {
  documentID: Scalars['ID']['input']
  documentType: MileageBasedInspectionProcessDocumentType
  fileName: Scalars['String']['input']
  processID: Scalars['ID']['input']
  /** Gross cost (only applicable for invoice) */
  totalGrossCost: InputMaybe<MoneyInput>
  /** Net cost (only applicable for invoice) */
  totalNetCost: InputMaybe<MoneyInput>
  url: Scalars['String']['input']
}

export type MileageBasedInspectionProcess = FleetProcess & {
  __typename?: 'MileageBasedInspectionProcess'
  /** Date for the vehicle pickup. */
  appointmentDate: Maybe<Scalars['DateTime']['output']>
  /** Readable unique identifier */
  case: Scalars['ID']['output']
  documents: Array<MileageBasedInspectionProcessDocument>
  finishedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  /** The associated scheduled inactivation of the vehicle for this inspection. Must be before appointmentDate. */
  inactivationScheduler: Maybe<ScheduledVehicleStateChange>
  /** Mileage interval this process refers to. E.g. 30k, 60k, 90k */
  inspectionMileage: Maybe<Scalars['Int']['output']>
  inspectionState: MileageBasedInspectionProcessState
  /** The mileage at the point in time the inspection was marked as done */
  mileageAtInspectionDone: Maybe<Scalars['Float']['output']>
  mileageAtStart: Scalars['Float']['output']
  nextPossibleTransitions: Array<MileageBasedInspectionProcessPossibleTransition>
  /** UUID of hub at which the vehicle will be picked up */
  pickupHub: Maybe<Hub>
  serviceAreaUUID: Scalars['ID']['output']
  startedAt: Scalars['DateTime']['output']
  state: FleetProcessState
  testFacility: Maybe<MileageBasedInspectionTestFacility>
  transitions: Array<MileageBasedInspectionProcessTransition>
  type: FleetProcessType
  updatedAt: Maybe<Scalars['DateTime']['output']>
  vehicle: Vehicle
  /** Has the vehicle been activated after the inspection */
  vehicleActivatedAt: Maybe<Scalars['DateTime']['output']>
}

export type MileageBasedInspectionProcessDocument = {
  __typename?: 'MileageBasedInspectionProcessDocument'
  documentID: Scalars['ID']['output']
  documentType: MileageBasedInspectionProcessDocumentType
  /** Original filename with extension */
  fileName: Scalars['String']['output']
  /** Gross cost (only applicable for invoice) */
  totalGrossCost: Maybe<Money>
  /** Net cost (only applicable for invoice) */
  totalNetCost: Maybe<Money>
  uploadedAt: Scalars['DateTime']['output']
  url: Scalars['String']['output']
}

export enum MileageBasedInspectionProcessDocumentType {
  ComplaintProtocol = 'COMPLAINT_PROTOCOL',
  Invoice = 'INVOICE',
  MileageBasedInspectionProtocol = 'MILEAGE_BASED_INSPECTION_PROTOCOL',
}

export type MileageBasedInspectionProcessPossibleTransition = {
  __typename?: 'MileageBasedInspectionProcessPossibleTransition'
  preconditions: Array<FleetProcessStateTransitionPrecondition>
  state: MileageBasedInspectionProcessState
}

export enum MileageBasedInspectionProcessState {
  /** Everything is done */
  Done = 'DONE',
  /** The next inspection within x km */
  Due = 'DUE',
  /** The vehicle is waiting to get inactive */
  InProgress = 'IN_PROGRESS',
  /** The inspection is done, a manual confirmation to move to next state is needed */
  MileageBasedInspectionDone = 'MILEAGE_BASED_INSPECTION_DONE',
  /** The inspection is done but the invoice is still missing */
  WaitingForInvoice = 'WAITING_FOR_INVOICE',
  /** The vehicle is waiting to get picked up */
  WaitingForPickup = 'WAITING_FOR_PICKUP',
  /** The vehicle is waiting to get returned */
  WaitingForReturn = 'WAITING_FOR_RETURN',
}

export type MileageBasedInspectionProcessTransition = {
  __typename?: 'MileageBasedInspectionProcessTransition'
  initiatedAt: Scalars['DateTime']['output']
  /** The username of the user who initiated the transition */
  initiatedBy: Scalars['String']['output']
  toState: MileageBasedInspectionProcessState
}

export type MileageBasedInspectionProcessTransitionRequest = {
  nextState: MileageBasedInspectionProcessState
  processId: Scalars['ID']['input']
}

export type MileageBasedInspectionTestFacility = {
  __typename?: 'MileageBasedInspectionTestFacility'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  serviceAreaUUID: Scalars['ID']['output']
}

export type Money = {
  __typename?: 'Money'
  /** Amount in hundredths (e.g. cents), € 1.50 = 150 */
  amount: Scalars['Int']['output']
  /** Represented as an ISO 4217 currency designator, e.g. EUR */
  currency: Scalars['String']['output']
}

export type MoneyInput = {
  /** Amount in hundredths (e.g. cents), € 1.50 = 150 */
  amount: Scalars['Int']['input']
  /** Represented as an ISO 4217 currency designator, e.g. EUR */
  currency: Scalars['String']['input']
}

export type MultiPolygon = Geometry & {
  __typename?: 'MultiPolygon'
  geoJson: Scalars['String']['output']
  polygons: Array<Shape>
}

export type Mutation = {
  __typename?: 'Mutation'
  /** Only usable by backoffice. Acknowledge an unchecked VehicleCheckAnswer */
  acknowledgeVehicleCheckAnswer: VehicleCheck
  /** Activates the climate control of a vehicle. Triggers a call to the telematic box. The target temperature is a fixed value on the telematic box. */
  activateClimateControl: ActivateClimateControlResponse
  activateEmergencyStopButtonIncident: Maybe<SimulatedAdVehicleIncident>
  activateSimulatedADVehicleIncident: Maybe<SimulatedAdVehicleIncident>
  /** Put a vehicle into the ACTIVE state. A lot of validation is required for this. */
  activateVehicle: StateChangeResponse
  addRoadBlockageActivation: RoadBlockageActivation
  addTurnRestrictionActivation: TurnRestrictionActivation
  approveCustomPriceConfig: ApproveCustomPriceConfigResponse
  approveDefaultPriceConfig: ApproveDefaultPriceConfigResponse
  archivePriceConfigPolygon: ArchivePriceConfigPolygonResponse
  /** Archive a vehicle. This can only be done if the previous state was INACTIVE with the reason De-Fleeting. */
  archiveVehicle: StateChangeResponse
  /** Assigns a CDIS box to a vehicle */
  assignCdisBoxToVehicle: AssignedCdisBox
  assignTabletToVehicle: HardwareConfiguration
  backofficeStatusCreate: BackofficeStatus
  backofficeStatusDelete: BackofficeStatusDeleteResponse
  backofficeStatusDeleteMediaByFileName: BackofficeStatusDeleteMediaByFileNameResponse
  backofficeStatusSetLastVisit: BackofficeStatusesLastVisitResponse
  backofficeStatusUpdate: BackofficeStatus
  backofficeUpdateCreate: BackofficeUpdate
  backofficeUpdateDelete: BackofficeUpdateDeleteResponse
  backofficeUpdateSetLastVisit: BackofficeUpdatesLastVisitResponse
  backofficeUpdateUpdate: BackofficeUpdate
  backofficeUpdatesGetMediaPresignedUrlByFileName: Maybe<BackofficeUpdateFilePresignedResponse>
  backofficeUpdatesGetPresignedUrl: BackofficeUpdateFilePresignedResponse
  backofficeUserManagementAddUserToGroup: BackofficeUser
  backofficeUserManagementCreateBackofficeGroup: BackofficeUserManagementGroup
  backofficeUserManagementCreateBackofficeUser: BackofficeUser
  backofficeUserManagementDeleteBackofficeGroup: Scalars['String']['output']
  backofficeUserManagementDeleteBackofficeUser: Scalars['String']['output']
  backofficeUserManagementDisableBackofficeUser: Scalars['String']['output']
  backofficeUserManagementEnableBackofficeUser: Scalars['String']['output']
  backofficeUserManagementLogoutBackofficeUser: Scalars['ID']['output']
  backofficeUserManagementRemoveUserFromGroup: BackofficeUser
  backofficeUserManagementResetMfaBackofficeUser: Scalars['String']['output']
  backofficeUserManagementSwapGroups: BackofficeUser
  backofficeUserManagementUpdateBackofficeGroup: BackofficeUserManagementGroup
  backofficeUserManagementUpdateBackofficeUser: BackofficeUser
  /** Vehicle CLOSE interaction to be used via backoffice by driver managers. */
  closeVehicleByBackofficeUser: VehicleInteractionResponse
  /** Vehicle CLOSE interaction to be used by app for drivers and service staff */
  closeVehicleByEmployee: VehicleInteractionResponse
  /** Vehicle OPEN interaction to be used via backoffice by driver managers in case of an emergency */
  closeVehicleForEmployeeInEmergency: VehicleInteractionResponse
  configureVehicleSeats: Scalars['Boolean']['output']
  confirmCabinSafety: Maybe<Scalars['Void']['output']>
  createAction: Action
  createArea: Area
  createCustomPriceConfig: CreateCustomPriceConfigResponse
  createDamageReport: DamageReport
  createDefaultPriceConfig: CreateDefaultPriceConfigResponse
  createHub: Hub
  createPriceConfigPolygon: CreatePriceConfigPolygonResponse
  createPricingCampaign: CreatePricingCampaignResponse
  createRoadBlockage: RoadBlockage
  /** Creates a new Service Area */
  createServiceArea: ServiceArea
  /** Creates a new Stop */
  createStop: Maybe<Stop>
  createTurnRestriction: TurnRestriction
  createVehicle: VehicleResponse
  createVehicleCheck: VehicleCheck
  createVehicleGroup: VehicleGroupResponse
  /** Creates a vehicle list, into which an arbitrary number of vehicles can be logically grouped */
  createVehicleList: VehicleListResponse
  createWorkOrders: Scalars['Boolean']['output']
  deactivateSimulatedADVehicleIncident: Maybe<SimulatedAdVehicleIncident>
  deactivateVehicle: StateChangeResponse
  deleteAction: Maybe<Action>
  deleteArea: Maybe<Area>
  deleteCustomPriceConfig: DeleteCustomPriceConfigResponse
  deleteDefaultPriceConfig: DeleteDefaultPriceConfigResponse
  deleteRoadBlockage: Maybe<RoadBlockage>
  /** Deletes a scheduled state change, see above */
  deleteScheduleVehicleStateChange: ScheduleVehicleStateDeletionResponse
  deleteServiceBreak: Scalars['ID']['output']
  /** Deletes a Stop */
  deleteStop: Maybe<Stop>
  deleteTurnRestriction: Maybe<TurnRestriction>
  /** Delete a vehicle by id. Only vehicles in state DRAFT can be deleted */
  deleteVehicle: Scalars['Boolean']['output']
  deleteVehicleGroup: Scalars['Boolean']['output']
  deleteVehicleList: Scalars['Boolean']['output']
  deleteWorkOrder: Scalars['Boolean']['output']
  disableCustomer: DisableCustomerResponse
  disapproveCustomPriceConfig: DisapproveCustomPriceConfigResponse
  disapproveDefaultPriceConfig: DisapproveDefaultPriceConfigResponse
  dsBossCreateOrUpdateScenario: DsBossCreateOrUpdateResponse
  dsBossDeleteScenario: DsBossDeleteResponse
  dsBossSaveFinalOutputToDataset: DsBossCreateOrUpdateResponse
  /** Edit the last/current state change, no scheduled one */
  editCurrentStateChange: Scalars['Boolean']['output']
  employeeAuthDataChangeValue: Employee
  employeeAuthDataResendCode: EmployeeAuthDataResendCodeResponse
  employeeAuthDataVerifyCode: Employee
  employeeBlock: EmployeeBlockResponse
  /** Mutation for Operations App to clear driver emergency contact details */
  employeeClearEmergencyContact: Employee
  employeeCreateNote: EmployeeCreateNoteResponse
  employeeCreateNotes: EmployeeCreateNotesResponse
  employeeCreateScheduledBlock: EmployeeScheduledBlock
  /** Deletes all messages with the supplied IDs, to be used by Operations App */
  employeeDeleteMessages: EmployeeDeleteMessagesResponse
  employeeDeleteNotes: EmployeeDeleteNotesResponse
  employeeDeleteProfile: EmployeeDeleteProfileResponse
  employeeDeleteScheduledBlock: EmployeeDeleteScheduledBlockResponse
  employeeNoteCreateFileGetUrl: EmployeeCreateNoteFileGetUrlResponse
  employeeNoteCreatePreSignedUrl: EmployeeNoteFilePreSignedUrlResponse
  employeeResendEmailVerification: EmployeeResendEmailVerificationResponse
  employeeResetPassword: EmployeeResetPasswordResponse
  /** Mutation for Operations App to update driver emergency contact details */
  employeeSetEmergencyContact: Employee
  employeeUnblock: EmployeeUnblockResponse
  employeeUpdateNote: EmployeeUpdateNoteResponse
  employeeUpdateProfileData: Employee
  employeeUpdateScheduledBlock: EmployeeScheduledBlock
  enableCustomer: EnableCustomerResponse
  fccBroadcastFleetShutdownMessage: Maybe<Scalars['Void']['output']>
  fccCancelUpcomingTrip: Maybe<Scalars['Void']['output']>
  fccConfirmCancelAllTrips: Maybe<Scalars['Void']['output']>
  fccConfirmManualNoShow: Maybe<Scalars['Void']['output']>
  fccContinueTrips: Maybe<Scalars['Void']['output']>
  fccInitiateCancelAllTrips: Maybe<Scalars['Void']['output']>
  fccManuallyCheckInPassenger: Maybe<Scalars['Void']['output']>
  fccMarkPromptAsHandled: FccOperatorState
  fccPrepareOperatorUser: Maybe<Scalars['Void']['output']>
  fccProvideTripCancellationReason: Maybe<Scalars['Void']['output']>
  fccRequestDoorActuation: Maybe<Scalars['Void']['output']>
  fccRequestFleetShutdown: Maybe<Scalars['Void']['output']>
  fccRequestOperatorCall: Maybe<Scalars['Void']['output']>
  fccRequestServiceCancellation: Maybe<Scalars['Void']['output']>
  fccResolveIncident: Maybe<Scalars['Void']['output']>
  fccSetOperatorAvailability: FccOperatorState
  fccUpdateAdPoolingStatus: FccUpdateAdPoolingStatusStatus
  /** Refund the payment for a fee */
  feeRefundByTripId: Maybe<Scalars['RefundSuccess']['output']>
  /** Flash (activate blinker/indicator) of CDIS vehicle (only possible for vehicles that aren't open) */
  flashVehicle: Scalars['Boolean']['output']
  grantPermissionCloseDoor: Maybe<Scalars['Void']['output']>
  insertHubCheckedInLogRecord: Maybe<InsertHubLogCheckedInRecordResponse>
  insertHubCheckedOutLogRecord: Maybe<InsertHubLogCheckedOutRecordResponse>
  /** mark message as acknowledged, return value indicates success of operation */
  markEmployeeMessageAsAcknowledged: Maybe<EmployeeMessageResponse>
  /** mark message as read, return value indicates success of operation */
  markEmployeeMessageAsRead: Maybe<EmployeeMessageResponse>
  /** Vehicle OPEN interaction to be used by app for drivers and service staff */
  openVehicleByEmployee: VehicleInteractionResponse
  /** To be used by backoffice users opening a vehicle for themselves */
  openVehicleForBackofficeUser: VehicleInteractionResponse
  /** Vehicle OPEN interaction to be used via backoffice by driver managers */
  openVehicleForEmployee: VehicleInteractionResponse
  /** Vehicle OPEN interaction to be used via backoffice by driver managers in case of an emergency */
  openVehicleForEmployeeInEmergency: VehicleInteractionResponse
  prolongCabinVideoStream: Maybe<Scalars['Void']['output']>
  /** Triggers a call to the telematic box, which would also wake it up if it was asleep. */
  refreshVehicleTelematics: Scalars['Boolean']['output']
  /** Refund the payment for a trip */
  refundByTripId: Maybe<Scalars['RefundSuccess']['output']>
  removeRoadBlockageActivation: Scalars['Boolean']['output']
  removeTurnRestrictionActivation: Scalars['Boolean']['output']
  requestCabinVideoStreamCredentials: CabinVideoStreamCredentials
  /** Resets the seats of a vehicle to that of the vehicle configuration, hence removing any custom overrides */
  resetVehicleSeats: Maybe<Scalars['Boolean']['output']>
  rioDeleteReport: RioDeleteReportResponse
  rioSetReportStatus: RioSetReportStatusResponse
  rioStoreListViewConfig: RioStoreListViewConfigResponse
  rioStoreReport: RioStoreReportResponse
  rioStoreTemplate: RioStoreTemplateResponse
  /** Schedules a vehicle state change in the future to make sure the vehicle isn't in the field, for example for an upcoming event, inspections, hub transfer, etc */
  scheduleVehicleStateChange: ScheduleVehicleStateChangeResponse
  sendEmployeeMessage: SendEmployeeMessageResponse
  /** send a message to all active employees of a search result */
  sendMassMessage: SendMassMessageResponse
  setShiftComment: ShiftComment
  setShiftEmployeeStatus: Maybe<ShiftEmployeeStatus>
  setTaskNotificationPending: TaskNotification
  /** Defines the vehicles (via ID) in a previously created vehicle list */
  setVehiclesInList: Scalars['Boolean']['output']
  shiftApplyEmail: ShiftApplyEmailResponse
  shiftCapacityPlanningCreate: ShiftCapacityPlanning
  shiftCapacityPlanningDelete: Scalars['Boolean']['output']
  shiftCapacityPlanningUpdateStatus: ShiftCapacityPlanning
  /** confirm shift related task notifications */
  shiftConfirmTaskNotification: ShiftConfirmTaskNotificationResponse
  shiftDeletePlannedHubReturnSection: ShiftDeletePlannedHubReturnSectionResponse
  shiftLostAndFoundEmail: ShiftLostAndFoundResponse
  shiftOverrideScheduledHubReturn: ShiftScheduledHubReturn
  /** updates shift type (WAV, SDO, or Pluto) for a given shift */
  shiftOverrideType: ShiftOverrideTypeResponse
  /** Sets an absence for a shift. When null is passed, the previous absence is removed. */
  shiftSetAbsence: Shift
  shiftSetPlannedHubReturnSection: ShiftSetPlannedHubReturnSectionResponse
  shiftsCreateActivity: ShiftsActivity
  shiftsDeleteActivity: ShiftsActivity
  shiftsUpdateActivity: ShiftsActivity
  /** Simulate incoming "cabin ready check requested" event from Vehicle Cabin Surveillance. Only available for testing on DEV & INT. */
  simulateCabinReadyCheckRequestedEvent: Maybe<Scalars['Void']['output']>
  /** Simulate incoming "close door requested" event from Vehicle Cabin Surveillance. Only available for testing on DEV & INT. */
  simulateCloseDoorRequestedEvent: Maybe<Scalars['Void']['output']>
  /** Simulate incoming door actuation response from Vehicle Cabin Surveillance. Only available for testing on DEV & INT. */
  simulateRemoteDoorActuationResponseEvent: Maybe<Scalars['Void']['output']>
  takeOverOpenAlert: FccAdAlert
  transitionMainInspectionProcessToNextState: FleetProcessTransitionResponse
  transitionMileageBasedInspectionProcessToNextState: FleetProcessTransitionResponse
  transitionVICProcessToNextState: FleetProcessTransitionResponse
  triggerArrivalEvent: Maybe<Scalars['Boolean']['output']>
  triggerDepartureEvent: Maybe<Scalars['Boolean']['output']>
  triggerSimulatedVehicleEvent: Maybe<Scalars['Boolean']['output']>
  /** Trigger a new task. Only available for testing on DEV & INT. */
  triggerTaskNotification: Scalars['Boolean']['output']
  unassignAlert: FccAdAlert
  /** Unassigns a CDIS box from a vehicle */
  unassignCdisBoxFromVehicle: Scalars['Boolean']['output']
  unassignTabletFromVehicle: HardwareConfiguration
  updateAction: Action
  updateArea: Area
  updateAreaCapacity: Area
  updateCustomPriceConfig: UpdateCustomPriceConfigResponse
  updateCustomer: UpdateCustomerResponse
  /** Only usable by backoffice */
  updateDamageReport: DamageReport
  updateDefaultPriceConfig: UpdateDefaultPriceConfigResponse
  /** returns a pre-signed profile picture URL (valid for 60s) for picture uploads or clears the stored profile picture */
  updateEmployeePicture: UpdateEmployeeProfilePictureResponse
  updateHub: Hub
  updateMainInspectionDocumentCosts: Scalars['Boolean']['output']
  updateMainInspectionProcessData: MainInspectionProcess
  updateMileageBasedInspectionDocumentCosts: Scalars['Boolean']['output']
  updateMileageBasedInspectionProcessData: MileageBasedInspectionProcess
  updateRoadBlockage: RoadBlockage
  updateRoadBlockageActivation: RoadBlockageActivation
  /** Updates a Service Area */
  updateServiceArea: ServiceArea
  updateSimulatedADVehicle: Maybe<SimulatedAdVehicle>
  updateSimulatedADVehicleSignalConnectivity: Maybe<SimulatedAdVehicleSignalConnectivity>
  updateSimulatedVehicle: Maybe<SimulatedVehicle>
  /** Updates a Stop */
  updateStop: Maybe<Stop>
  updateTaskNotificationNote: TaskNotification
  updateTaskNotificationStatus: TaskNotification
  updateTaskNotificationVehicleId: TaskNotification
  updateTurnRestriction: TurnRestriction
  updateTurnRestrictionActivation: TurnRestrictionActivation
  updateVICProcessData: VicProcess
  /** Update the base data of a vehicle. */
  updateVehicle: VehicleResponse
  /** Update a vehicle configuration (formerly known as vehicle group) */
  updateVehicleGroup: VehicleGroupResponse
  /** Update a vehicle list. Vehicle lists are used for marketing campaigns, events, etc */
  updateVehicleList: VehicleListResponse
  /** Update whether a vehicle is prioritized or not. Prioritized vehicles are shown on top of the available vehicles list in the employee app. */
  updateVehiclePrioritization: Scalars['Boolean']['output']
  /** Will disable or enable rebalancing for provided vehicle. */
  updateVehicleRebalancing: Vehicle
  updateWorkOrder: WorkOrder
  wipeBoxPc: BoxPc
}

export type MutationAcknowledgeVehicleCheckAnswerArgs = {
  questionKey: Scalars['String']['input']
  vehicleCheckID: Scalars['ID']['input']
}

export type MutationActivateClimateControlArgs = {
  input: ActivateClimateControlInput
}

export type MutationActivateEmergencyStopButtonIncidentArgs = {
  fleetVehicleId: Scalars['ID']['input']
  sendToEcuClient: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationActivateSimulatedAdVehicleIncidentArgs = {
  input: UpdateSimulatedAdVehicleIncidentsInput
  sendToEcuClient: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationActivateVehicleArgs = {
  input: ActivateVehicleInput
}

export type MutationAddRoadBlockageActivationArgs = {
  input: RoadBlockageActivationInput
}

export type MutationAddTurnRestrictionActivationArgs = {
  input: TurnRestrictionActivationInput
}

export type MutationApproveCustomPriceConfigArgs = {
  input: ApproveCustomPriceConfigInput
}

export type MutationApproveDefaultPriceConfigArgs = {
  input: ApproveDefaultPriceConfigInput
}

export type MutationArchivePriceConfigPolygonArgs = {
  input: ArchivePriceConfigPolygonInput
}

export type MutationArchiveVehicleArgs = {
  input: ArchiveVehicleInput
}

export type MutationAssignCdisBoxToVehicleArgs = {
  input: AssignCdisBoxToVehicleInput
}

export type MutationAssignTabletToVehicleArgs = {
  input: AssignTabletToVehicleInput
}

export type MutationBackofficeStatusCreateArgs = {
  input: BackofficeStatusCreate
}

export type MutationBackofficeStatusDeleteArgs = {
  input: BackofficeStatusDelete
}

export type MutationBackofficeStatusDeleteMediaByFileNameArgs = {
  input: BackofficeUpdateGetMediaFilePresignedUrlInput
}

export type MutationBackofficeStatusUpdateArgs = {
  input: BackofficeStatusUpdate
}

export type MutationBackofficeUpdateCreateArgs = {
  input: BackofficeUpdateCreate
}

export type MutationBackofficeUpdateDeleteArgs = {
  input: BackofficeUpdateDelete
}

export type MutationBackofficeUpdateUpdateArgs = {
  input: BackofficeUpdateUpdate
}

export type MutationBackofficeUpdatesGetMediaPresignedUrlByFileNameArgs = {
  input: InputMaybe<BackofficeUpdateGetMediaFilePresignedUrlInput>
}

export type MutationBackofficeUpdatesGetPresignedUrlArgs = {
  input: InputMaybe<BackofficeUpdateFilePresignedUrlInput>
}

export type MutationBackofficeUserManagementAddUserToGroupArgs = {
  input: BackofficeUserManagementAddUserToGroupInput
}

export type MutationBackofficeUserManagementCreateBackofficeGroupArgs = {
  input: BackofficeUserManagementCreateBackofficeGroupInput
}

export type MutationBackofficeUserManagementCreateBackofficeUserArgs = {
  input: BackofficeUserManagementCreateBackofficeUserInput
}

export type MutationBackofficeUserManagementDeleteBackofficeGroupArgs = {
  input: BackofficeUserManagementDeleteBackofficeGroupInput
}

export type MutationBackofficeUserManagementDeleteBackofficeUserArgs = {
  input: BackofficeUserManagementDeleteBackofficeUserInput
}

export type MutationBackofficeUserManagementDisableBackofficeUserArgs = {
  input: BackofficeUserManagementDisableBackofficeUserInput
}

export type MutationBackofficeUserManagementEnableBackofficeUserArgs = {
  input: BackofficeUserManagementEnableBackofficeUserInput
}

export type MutationBackofficeUserManagementLogoutBackofficeUserArgs = {
  input: BackofficeUserManagementLogoutBackofficeUserInput
}

export type MutationBackofficeUserManagementRemoveUserFromGroupArgs = {
  input: BackofficeUserManagementRemoveUserFromGroupInput
}

export type MutationBackofficeUserManagementResetMfaBackofficeUserArgs = {
  input: BackofficeUserManagementResetMfaBackofficeUserInput
}

export type MutationBackofficeUserManagementSwapGroupsArgs = {
  input: BackofficeUserManagementSwapGroupsInput
}

export type MutationBackofficeUserManagementUpdateBackofficeGroupArgs = {
  input: BackofficeUserManagementUpdateBackofficeGroupInput
}

export type MutationBackofficeUserManagementUpdateBackofficeUserArgs = {
  input: BackofficeUserManagementUpdateBackofficeUserInput
}

export type MutationCloseVehicleByBackofficeUserArgs = {
  input: CloseVehicleByBackofficeUserInput
}

export type MutationCloseVehicleByEmployeeArgs = {
  input: CloseVehicleInput
}

export type MutationCloseVehicleForEmployeeInEmergencyArgs = {
  input: CloseVehicleInput
}

export type MutationConfigureVehicleSeatsArgs = {
  input: ConfigureVehicleSeatsInput
  vehicleID: Scalars['ID']['input']
}

export type MutationConfirmCabinSafetyArgs = {
  input: ConfirmCabinSafetyInput
}

export type MutationCreateActionArgs = {
  input: ActionInput
  serviceAreaID: Scalars['ID']['input']
}

export type MutationCreateAreaArgs = {
  input: AreaInput
  serviceAreaId: Scalars['String']['input']
}

export type MutationCreateCustomPriceConfigArgs = {
  input: CreateCustomPriceConfigInput
}

export type MutationCreateDamageReportArgs = {
  damageReport: DamageReportInput
}

export type MutationCreateDefaultPriceConfigArgs = {
  input: CreateDefaultPriceConfigInput
}

export type MutationCreateHubArgs = {
  input: HubDataInput
}

export type MutationCreatePriceConfigPolygonArgs = {
  input: CreatePriceConfigPolygonInput
}

export type MutationCreatePricingCampaignArgs = {
  input: PricingCampaignInput
}

export type MutationCreateRoadBlockageArgs = {
  roadBlockageInput: RoadBlockageInput
  serviceAreaId: Scalars['ID']['input']
}

export type MutationCreateServiceAreaArgs = {
  input: ServiceAreaCreationInput
}

export type MutationCreateStopArgs = {
  input: StopInput
  serviceAreaId: Scalars['String']['input']
}

export type MutationCreateTurnRestrictionArgs = {
  serviceAreaId: Scalars['ID']['input']
  turnRestrictionInput: TurnRestrictionInput
}

export type MutationCreateVehicleArgs = {
  input: CreateVehicleInput
}

export type MutationCreateVehicleCheckArgs = {
  vehicleCheck: VehicleCheckInput
}

export type MutationCreateVehicleGroupArgs = {
  input: VehicleGroupConfigurationInput
}

export type MutationCreateVehicleListArgs = {
  input: VehicleListInput
}

export type MutationCreateWorkOrdersArgs = {
  input: CreateWorkOrdersInput
}

export type MutationDeactivateSimulatedAdVehicleIncidentArgs = {
  fleetVehicleId: Scalars['ID']['input']
  sendToEcuClient: InputMaybe<Scalars['Boolean']['input']>
  type: IncidentTypeIds
}

export type MutationDeactivateVehicleArgs = {
  input: DeactivateVehicleInput
}

export type MutationDeleteActionArgs = {
  actionID: Scalars['ID']['input']
  serviceAreaID: Scalars['ID']['input']
}

export type MutationDeleteAreaArgs = {
  areaId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
}

export type MutationDeleteCustomPriceConfigArgs = {
  input: DeleteCustomPriceConfigInput
}

export type MutationDeleteDefaultPriceConfigArgs = {
  input: DeleteDefaultPriceConfigInput
}

export type MutationDeleteRoadBlockageArgs = {
  roadBlockageId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
}

export type MutationDeleteScheduleVehicleStateChangeArgs = {
  input: ScheduledVehicleStateChangeDeleteInput
}

export type MutationDeleteServiceBreakArgs = {
  vehicleId: Scalars['ID']['input']
}

export type MutationDeleteStopArgs = {
  serviceAreaId: Scalars['String']['input']
  stopId: Scalars['String']['input']
}

export type MutationDeleteTurnRestrictionArgs = {
  serviceAreaId: Scalars['ID']['input']
  turnRestrictionId: Scalars['ID']['input']
}

export type MutationDeleteVehicleArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteVehicleGroupArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteVehicleListArgs = {
  id: Scalars['ID']['input']
}

export type MutationDeleteWorkOrderArgs = {
  workOrderID: Scalars['ID']['input']
}

export type MutationDisableCustomerArgs = {
  input: DisableCustomerInput
}

export type MutationDisapproveCustomPriceConfigArgs = {
  input: DisapproveCustomPriceConfigInput
}

export type MutationDisapproveDefaultPriceConfigArgs = {
  input: DisapproveDefaultPriceConfigInput
}

export type MutationDsBossCreateOrUpdateScenarioArgs = {
  input: DsBossCreateOrUpdate
}

export type MutationDsBossDeleteScenarioArgs = {
  input: DsBossDelete
}

export type MutationDsBossSaveFinalOutputToDatasetArgs = {
  input: DsBossUpdateBasedOnScenarioId
}

export type MutationEditCurrentStateChangeArgs = {
  input: EditCurrentStateChangeInput
}

export type MutationEmployeeAuthDataChangeValueArgs = {
  input: EmployeeAuthDataChangeValueInput
}

export type MutationEmployeeAuthDataResendCodeArgs = {
  input: EmployeeAuthDataResendCodeInput
}

export type MutationEmployeeAuthDataVerifyCodeArgs = {
  input: EmployeeAuthDataVerifyCodeInput
}

export type MutationEmployeeBlockArgs = {
  input: EmployeeBlockInput
}

export type MutationEmployeeCreateNoteArgs = {
  input: EmployeeCreateNoteInput
}

export type MutationEmployeeCreateNotesArgs = {
  input: EmployeeCreateNotesInput
}

export type MutationEmployeeCreateScheduledBlockArgs = {
  input: EmployeeCreateScheduledBlockInput
}

export type MutationEmployeeDeleteMessagesArgs = {
  input: EmployeeDeleteMessagesInput
}

export type MutationEmployeeDeleteNotesArgs = {
  input: EmployeeDeleteNotesInput
}

export type MutationEmployeeDeleteProfileArgs = {
  input: EmployeeDeleteProfileInput
}

export type MutationEmployeeDeleteScheduledBlockArgs = {
  input: EmployeeDeleteScheduledBlockInput
}

export type MutationEmployeeNoteCreateFileGetUrlArgs = {
  input: EmployeeNoteCreateFileGetUrlInput
}

export type MutationEmployeeNoteCreatePreSignedUrlArgs = {
  input: EmployeeNoteFilePreSignedUrlInput
}

export type MutationEmployeeResendEmailVerificationArgs = {
  input: EmployeeResendEmailVerificationInput
}

export type MutationEmployeeResetPasswordArgs = {
  input: EmployeeResetPasswordInput
}

export type MutationEmployeeSetEmergencyContactArgs = {
  input: EmployeeSetEmergencyContactInput
}

export type MutationEmployeeUnblockArgs = {
  input: EmployeeUnblockInput
}

export type MutationEmployeeUpdateNoteArgs = {
  input: EmployeeUpdateNoteInput
}

export type MutationEmployeeUpdateProfileDataArgs = {
  input: EmployeeUpdateProfileDataInput
}

export type MutationEmployeeUpdateScheduledBlockArgs = {
  input: EmployeeUpdateScheduledBlockInput
}

export type MutationEnableCustomerArgs = {
  input: EnableCustomerInput
}

export type MutationFccBroadcastFleetShutdownMessageArgs = {
  input: FccFleetShutdownMessageInput
}

export type MutationFccCancelUpcomingTripArgs = {
  input: FccCancelUpcomingTripInput
}

export type MutationFccConfirmCancelAllTripsArgs = {
  input: FccConfirmCancelAllTripsInput
}

export type MutationFccConfirmManualNoShowArgs = {
  tripId: Scalars['ID']['input']
}

export type MutationFccContinueTripsArgs = {
  input: FccContinueTripsInput
}

export type MutationFccInitiateCancelAllTripsArgs = {
  input: FccInitiateCancelAllTripsInput
}

export type MutationFccManuallyCheckInPassengerArgs = {
  tripId: Scalars['ID']['input']
}

export type MutationFccMarkPromptAsHandledArgs = {
  input: FccMarkPromptAsHandledInput
}

export type MutationFccPrepareOperatorUserArgs = {
  input: FccPrepareOperatorUserInput
}

export type MutationFccProvideTripCancellationReasonArgs = {
  input: FccProvideTripCancellationReasonInput
}

export type MutationFccRequestDoorActuationArgs = {
  input: FccRequestDoorActuationInput
}

export type MutationFccRequestFleetShutdownArgs = {
  input: FccFleetShutdownRequestInput
}

export type MutationFccRequestOperatorCallArgs = {
  input: FccRequestOperatorCallInput
}

export type MutationFccRequestServiceCancellationArgs = {
  input: FccServiceCancellationRequestInput
}

export type MutationFccResolveIncidentArgs = {
  input: FccOperatorIncidentResolutionInput
}

export type MutationFccSetOperatorAvailabilityArgs = {
  input: FccSetOperatorAvailabilityInput
}

export type MutationFccUpdateAdPoolingStatusArgs = {
  input: FccUpdateAdPoolingStatusInput
}

export type MutationFeeRefundByTripIdArgs = {
  id: Scalars['ID']['input']
}

export type MutationFlashVehicleArgs = {
  vehicleID: Scalars['ID']['input']
}

export type MutationGrantPermissionCloseDoorArgs = {
  input: GrantPermissionCloseDoorInput
}

export type MutationInsertHubCheckedInLogRecordArgs = {
  input: HubLogRecordInput
}

export type MutationInsertHubCheckedOutLogRecordArgs = {
  input: HubLogRecordInput
}

export type MutationMarkEmployeeMessageAsAcknowledgedArgs = {
  employeeId: Scalars['ID']['input']
  messageId: Scalars['ID']['input']
}

export type MutationMarkEmployeeMessageAsReadArgs = {
  employeeId: Scalars['ID']['input']
  messageId: Scalars['ID']['input']
}

export type MutationOpenVehicleByEmployeeArgs = {
  input: OpenVehicleEmployeeInput
}

export type MutationOpenVehicleForBackofficeUserArgs = {
  input: OpenVehicleBackofficeSelfUserInput
}

export type MutationOpenVehicleForEmployeeArgs = {
  input: OpenVehicleBackofficeInput
}

export type MutationOpenVehicleForEmployeeInEmergencyArgs = {
  input: OpenVehicleBackofficeInput
}

export type MutationProlongCabinVideoStreamArgs = {
  input: ProlongCabinVideoStreamInput
}

export type MutationRefreshVehicleTelematicsArgs = {
  vehicleID: Scalars['ID']['input']
}

export type MutationRefundByTripIdArgs = {
  id: Scalars['ID']['input']
}

export type MutationRemoveRoadBlockageActivationArgs = {
  roadBlockageActivationID: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
}

export type MutationRemoveTurnRestrictionActivationArgs = {
  serviceAreaId: Scalars['ID']['input']
  turnRestrictionActivationId: Scalars['ID']['input']
}

export type MutationRequestCabinVideoStreamCredentialsArgs = {
  request: RequestCabinVideoStreamCredentials
}

export type MutationResetVehicleSeatsArgs = {
  vehicleID: Scalars['ID']['input']
}

export type MutationRioDeleteReportArgs = {
  input: RioDeleteReportInput
}

export type MutationRioSetReportStatusArgs = {
  input: RioSetReportStatusInput
}

export type MutationRioStoreListViewConfigArgs = {
  input: RioStoreListViewConfigInput
}

export type MutationRioStoreReportArgs = {
  input: RioStoreReportInput
}

export type MutationRioStoreTemplateArgs = {
  input: RioStoreTemplateInput
}

export type MutationScheduleVehicleStateChangeArgs = {
  input: ScheduleVehicleStateChangeInput
}

export type MutationSendEmployeeMessageArgs = {
  input: SendEmployeeMessageInput
}

export type MutationSendMassMessageArgs = {
  input: SendMassMessageInput
}

export type MutationSetShiftCommentArgs = {
  input: ShiftCommentInput
}

export type MutationSetShiftEmployeeStatusArgs = {
  input: ShiftEmployeeStatusInput
}

export type MutationSetTaskNotificationPendingArgs = {
  id: Scalars['ID']['input']
  until: InputMaybe<Scalars['DateTime']['input']>
  version: InputMaybe<Scalars['Int']['input']>
}

export type MutationSetVehiclesInListArgs = {
  listID: Scalars['ID']['input']
  vehicleIDs: Array<Scalars['ID']['input']>
}

export type MutationShiftApplyEmailArgs = {
  input: ShiftApplyEmailInput
}

export type MutationShiftCapacityPlanningCreateArgs = {
  input: ShiftCapacityPlanningCreateInput
}

export type MutationShiftCapacityPlanningDeleteArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type MutationShiftCapacityPlanningUpdateStatusArgs = {
  input: ShiftCapacityPlanningUpdateStatusInput
}

export type MutationShiftConfirmTaskNotificationArgs = {
  input: ShiftConfirmTaskNotificationInput
}

export type MutationShiftDeletePlannedHubReturnSectionArgs = {
  input: ShiftDeletePlannedHubReturnSectionInput
}

export type MutationShiftLostAndFoundEmailArgs = {
  input: ShiftLostAndFoundEmailInput
}

export type MutationShiftOverrideScheduledHubReturnArgs = {
  input: ShiftOverrideScheduledHubReturnInput
}

export type MutationShiftOverrideTypeArgs = {
  input: ShiftOverrideTypeInput
}

export type MutationShiftSetAbsenceArgs = {
  absenceType: InputMaybe<EmployeeAbsenceKey>
  shiftId: Scalars['ID']['input']
}

export type MutationShiftSetPlannedHubReturnSectionArgs = {
  input: ShiftSetPlannedHubReturnSectionInput
}

export type MutationShiftsCreateActivityArgs = {
  input: ShiftsCreateActivityInput
}

export type MutationShiftsDeleteActivityArgs = {
  activityId: Scalars['ID']['input']
}

export type MutationShiftsUpdateActivityArgs = {
  input: ShiftsUpdateActivityInput
}

export type MutationSimulateCabinReadyCheckRequestedEventArgs = {
  input: SimulateEventInput
}

export type MutationSimulateCloseDoorRequestedEventArgs = {
  input: SimulateEventInput
}

export type MutationSimulateRemoteDoorActuationResponseEventArgs = {
  input: SimulateDoorActuationResponseInput
}

export type MutationTakeOverOpenAlertArgs = {
  id: Scalars['ID']['input']
}

export type MutationTransitionMainInspectionProcessToNextStateArgs = {
  input: MainInspectionProcessTransitionRequest
}

export type MutationTransitionMileageBasedInspectionProcessToNextStateArgs = {
  input: MileageBasedInspectionProcessTransitionRequest
}

export type MutationTransitionVicProcessToNextStateArgs = {
  input: VicProcessTransitionRequest
}

export type MutationTriggerArrivalEventArgs = {
  hubID: Scalars['ID']['input']
  vehicleID: Scalars['ID']['input']
}

export type MutationTriggerDepartureEventArgs = {
  vehicleID: Scalars['ID']['input']
}

export type MutationTriggerSimulatedVehicleEventArgs = {
  eventType: SimulatedVehicleEventType
  vehicleID: Scalars['ID']['input']
}

export type MutationTriggerTaskNotificationArgs = {
  input: TriggerTaskNotificationInput
}

export type MutationUnassignAlertArgs = {
  id: Scalars['ID']['input']
}

export type MutationUnassignCdisBoxFromVehicleArgs = {
  input: UnassignCdisBoxFromVehicleInput
}

export type MutationUnassignTabletFromVehicleArgs = {
  input: UnassignTabletFromVehicleInput
}

export type MutationUpdateActionArgs = {
  actionID: Scalars['ID']['input']
  input: ActionInput
  serviceAreaID: Scalars['ID']['input']
}

export type MutationUpdateAreaArgs = {
  areaId: Scalars['String']['input']
  input: AreaInput
  serviceAreaId: Scalars['String']['input']
}

export type MutationUpdateAreaCapacityArgs = {
  areaId: Scalars['String']['input']
  input: InputMaybe<AreaCapacityConfigurationInput>
  serviceAreaId: Scalars['String']['input']
}

export type MutationUpdateCustomPriceConfigArgs = {
  input: UpdateCustomPriceConfigInput
}

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput
}

export type MutationUpdateDamageReportArgs = {
  damageReport: DamageReportUpdateInput
}

export type MutationUpdateDefaultPriceConfigArgs = {
  input: UpdateDefaultPriceConfigInput
}

export type MutationUpdateEmployeePictureArgs = {
  input: UpdateEmployeeProfilePictureInput
}

export type MutationUpdateHubArgs = {
  id: Scalars['ID']['input']
  input: HubDataInput
}

export type MutationUpdateMainInspectionDocumentCostsArgs = {
  input: InputMaybe<MainInspectionDocumentRequest>
}

export type MutationUpdateMainInspectionProcessDataArgs = {
  input: InputMaybe<UpdateMainInspectionProcessDataRequest>
}

export type MutationUpdateMileageBasedInspectionDocumentCostsArgs = {
  input: InputMaybe<MileageBasedInspectionDocumentRequest>
}

export type MutationUpdateMileageBasedInspectionProcessDataArgs = {
  input: InputMaybe<UpdateMileageBasedInspectionProcessDataRequest>
}

export type MutationUpdateRoadBlockageArgs = {
  roadBlockageId: Scalars['ID']['input']
  roadBlockageInput: RoadBlockageInput
  serviceAreaId: Scalars['ID']['input']
}

export type MutationUpdateRoadBlockageActivationArgs = {
  input: RoadBlockageActivationInput
  roadBlockageActivationID: Scalars['ID']['input']
}

export type MutationUpdateServiceAreaArgs = {
  input: ServiceAreaUpdateInput
  serviceAreaId: Scalars['String']['input']
}

export type MutationUpdateSimulatedAdVehicleArgs = {
  input: UpdateSimulatedAdVehicleInput
  sendToEcuClient: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationUpdateSimulatedAdVehicleSignalConnectivityArgs = {
  input: UpdateSimulatedAdVehicleSignalConnectivityInput
}

export type MutationUpdateSimulatedVehicleArgs = {
  input: UpdateSimulatedVehicleInput
}

export type MutationUpdateStopArgs = {
  input: StopInput
  serviceAreaId: Scalars['String']['input']
  stopId: Scalars['String']['input']
}

export type MutationUpdateTaskNotificationNoteArgs = {
  id: Scalars['ID']['input']
  note: InputMaybe<Scalars['String']['input']>
  version: InputMaybe<Scalars['Int']['input']>
}

export type MutationUpdateTaskNotificationStatusArgs = {
  id: Scalars['ID']['input']
  status: TaskNotificationStatus
  version: InputMaybe<Scalars['Int']['input']>
}

export type MutationUpdateTaskNotificationVehicleIdArgs = {
  id: Scalars['ID']['input']
  vehicleId: Scalars['ID']['input']
  version: InputMaybe<Scalars['Int']['input']>
}

export type MutationUpdateTurnRestrictionArgs = {
  serviceAreaId: Scalars['ID']['input']
  turnRestrictionId: Scalars['ID']['input']
  turnRestrictionInput: TurnRestrictionInput
}

export type MutationUpdateTurnRestrictionActivationArgs = {
  input: TurnRestrictionActivationInput
  turnRestrictionActivationId: Scalars['ID']['input']
}

export type MutationUpdateVicProcessDataArgs = {
  input: UpdateVicProcessDataRequest
}

export type MutationUpdateVehicleArgs = {
  input: UpdateVehicleInput
}

export type MutationUpdateVehicleGroupArgs = {
  input: UpdateVehicleGroupInput
}

export type MutationUpdateVehicleListArgs = {
  id: Scalars['ID']['input']
  input: VehicleListInput
}

export type MutationUpdateVehiclePrioritizationArgs = {
  input: VehiclePrioritizationInput
}

export type MutationUpdateVehicleRebalancingArgs = {
  id: Scalars['ID']['input']
  input: UpdateVehicleRebalancingInput
}

export type MutationUpdateWorkOrderArgs = {
  input: UpdateWorkOrderInput
}

export type MutationWipeBoxPcArgs = {
  input: WipeBoxPcInput
}

export type NamedLocation = {
  __typename?: 'NamedLocation'
  location: Maybe<TripHistoryEntryLocation>
  primaryAddress: Maybe<Scalars['String']['output']>
  primaryPoiName: Maybe<Scalars['String']['output']>
  secondaryAddress: Maybe<Scalars['String']['output']>
  secondaryPoiName: Maybe<Scalars['String']['output']>
}

export type NavigationStop = {
  __typename?: 'NavigationStop'
  active: Scalars['Boolean']['output']
  bearing: Scalars['Int']['output']
  id: Scalars['String']['output']
  routingLocation: Location
}

export enum NotInServiceReason {
  Disconnected = 'DISCONNECTED',
  Inactive = 'INACTIVE',
  NoRecentValidLocation = 'NO_RECENT_VALID_LOCATION',
  OutdatedHubReturn = 'OUTDATED_HUB_RETURN',
  PoolingDisabled = 'POOLING_DISABLED',
  Unresponsive = 'UNRESPONSIVE',
}

export type Offer = {
  __typename?: 'Offer'
  offeredDeliveryTime: Maybe<Scalars['Instant']['output']>
  offeredPickupTime: Maybe<Scalars['Instant']['output']>
  price: Maybe<TripHistoryEntryPrice>
}

export type OpenVehicleBackofficeInput = {
  isBatchInteraction: Scalars['Boolean']['input']
  reason: InteractionReason
  reasonFreeText: InputMaybe<Scalars['String']['input']>
  /** The token of the selected employee */
  token: Scalars['String']['input']
  vehicleID: Scalars['ID']['input']
}

export type OpenVehicleBackofficeSelfUserInput = {
  isBatchInteraction: Scalars['Boolean']['input']
  reason: InteractionReason
  reasonFreeText: InputMaybe<Scalars['String']['input']>
  vehicleID: Scalars['ID']['input']
}

export type OpenVehicleEmployeeInput = {
  /** To avoid accidentally opening vehicles somewhere else */
  location: LocationInput
  /** Only used for service staff */
  reason: InputMaybe<InteractionReason>
  /** Only used for service staff */
  reasonFreeText: InputMaybe<Scalars['String']['input']>
  token: Scalars['String']['input']
  vehicleID: Scalars['ID']['input']
}

export type PageInfo = {
  __typename?: 'PageInfo'
  endCursor: Maybe<Scalars['String']['output']>
  hasNextPage: Scalars['Boolean']['output']
  hasPreviousPage: Scalars['Boolean']['output']
  startCursor: Maybe<Scalars['String']['output']>
}

export type PaginationInput = {
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
}

export type PassengerAuthentificationFallbackRequestedNotification =
  TaskNotification & {
    __typename?: 'PassengerAuthentificationFallbackRequestedNotification'
    actionType: TaskActionType
    assigned: Maybe<TaskStateChangeDetails>
    createdAt: Scalars['DateTime']['output']
    foreignId: Scalars['ID']['output']
    id: Scalars['ID']['output']
    note: Maybe<TaskNotificationNote>
    notificationType: TaskNotificationType
    pended: Maybe<TaskStateChangeDetails>
    resolved: Maybe<TaskStateChangeDetails>
    serviceAreaUUID: Scalars['ID']['output']
    severity: TaskNotificationSeverity
    stateChanges: Array<TaskStateChangeDetails>
    status: TaskNotificationStatus
    trip: Maybe<Trip>
    vehicle: Maybe<Vehicle>
    version: Scalars['Int']['output']
  }

export type PassengerUnbuckled = TaskNotification & {
  __typename?: 'PassengerUnbuckled'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export type Passengers = {
  __typename?: 'Passengers'
  adults: Maybe<Scalars['Int']['output']>
  infants: Maybe<Scalars['Int']['output']>
}

export type PayPal = {
  __typename?: 'PayPal'
  email: Scalars['String']['output']
}

export type PaymentDetails = {
  __typename?: 'PaymentDetails'
  /** Cancellation fee details */
  fee: Maybe<Fee>
  /** Unique identifier shown on the invoice for a payment. Example: M-TI-X5NY7N */
  invoiceId: Maybe<Scalars['ID']['output']>
  isRefundable: Scalars['Boolean']['output']
  paymentErrors: Array<PaymentError>
  /** Unique identifier for the payment at MOIA (Shown on invoices and customer's bank statements). Example: 7JZVAMV7 */
  paymentId: Maybe<Scalars['ID']['output']>
  paymentMethod: Maybe<PaymentMethod>
  price: Price
  /** Unique identifier for the payment at the payment service provider (Adyen). Example: PD28T3XDPGXXGN82 */
  pspTransactionId: Maybe<Scalars['ID']['output']>
  refund: Maybe<Refund>
  /** The amount that was settled for this payment, i.e. that arrived in our account */
  settledAmount: Maybe<Money>
  /** Status of the payment, describing at what stage it is in its lifecycle. Examples: Authorized, Captured, Refunded, Settled */
  status: Scalars['String']['output']
}

export type PaymentError = {
  __typename?: 'PaymentError'
  /** The error code of the error */
  code: Maybe<Scalars['String']['output']>
  /** A general error message describing the error */
  message: Scalars['String']['output']
  /** The subsystem that is responsible for the error (E.g.: Adyen) */
  subSystem: Maybe<Scalars['String']['output']>
}

export type PaymentMethod = ApplePay | CreditCard | GooglePay | PayPal

export type PolygonLocationCondition = {
  __typename?: 'PolygonLocationCondition'
  polygon: Maybe<PriceConditionPolygon>
  type: Maybe<LocationConditionType>
}

export type PolygonLocationConditionInput = {
  polygon: InputMaybe<PriceConditionPolygonInput>
  type: InputMaybe<LocationConditionType>
}

export type Pooling = {
  __typename?: 'Pooling'
  /** Whether we have an active connection with the tablet */
  connected: Maybe<Scalars['Boolean']['output']>
  /** When was the last time the connection status was updated? */
  connectedUpdatedAt: Maybe<Scalars['DateTime']['output']>
  /** Does this vehicle participate in pooling? Preconditon for being bookable */
  enabled: Scalars['Boolean']['output']
  /** Since when was pooling enabled */
  enabledUpdatedAt: Maybe<Scalars['DateTime']['output']>
  /** In case pooling is off, what was the reason for it? */
  reason: Maybe<PoolingReason>
}

export enum PoolingReason {
  AtHub = 'AT_HUB',
  AutomatedPlannedBreakEnd = 'AUTOMATED_PLANNED_BREAK_END',
  AutomatedPlannedBreakStart = 'AUTOMATED_PLANNED_BREAK_START',
  AutomatedPlannedHubReturn = 'AUTOMATED_PLANNED_HUB_RETURN',
  AutomatedShiftEnd = 'AUTOMATED_SHIFT_END',
  AutomatedShiftStart = 'AUTOMATED_SHIFT_START',
  AutomatedShortBreakEnd = 'AUTOMATED_SHORT_BREAK_END',
  AutomatedShortBreakStart = 'AUTOMATED_SHORT_BREAK_START',
  BioBreak = 'BIO_BREAK',
  ConfirmedByHub = 'CONFIRMED_BY_HUB',
  EventModeStart = 'EVENT_MODE_START',
  ManualStart = 'MANUAL_START',
  PlannedBreak = 'PLANNED_BREAK',
  ServiceDisabled = 'SERVICE_DISABLED',
  ShiftEnd = 'SHIFT_END',
  ShortBreak = 'SHORT_BREAK',
  Unknown = 'UNKNOWN',
}

export type PoolingStateChangedLogRecord = ShiftStateLogRecord & {
  __typename?: 'PoolingStateChangedLogRecord'
  eventTime: Scalars['DateTime']['output']
  poolingEnabled: Scalars['Boolean']['output']
  /** reason for pooling disabled (null when pooling was enabled) */
  reason: Maybe<Scalars['String']['output']>
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
  vehicleId: Scalars['ID']['output']
}

export enum PowerplugState {
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
}

export type Price = {
  __typename?: 'Price'
  /** The trip price before any reductions. */
  originalAmount: Money
  /** The final price after all reductions. That is, the amount payable by the customer. */
  payableAmount: Money
}

export type PriceConditionPolygon = {
  __typename?: 'PriceConditionPolygon'
  /** ID of the corresponding polygon in the polygon library */
  id: Scalars['ID']['output']
  locations: Array<Location>
  name: Scalars['String']['output']
}

export type PriceConditionPolygonInput = {
  /** ID of the corresponding polygon in the polygon library */
  id: Scalars['ID']['input']
  locations: Array<LocationInput>
  name: Scalars['String']['input']
}

export type PriceConfigChangeLogEntry = {
  __typename?: 'PriceConfigChangeLogEntry'
  action: PriceConfigStateAction
  timestamp: Scalars['DateTime']['output']
  userEmail: Scalars['String']['output']
}

export type PriceConfigDemandParameters = {
  __typename?: 'PriceConfigDemandParameters'
  basePrice: Money
  metersIncludedInBasePrice: Scalars['Int']['output']
  percentile: Scalars['Float']['output']
  pricePerKm: Money
}

export type PriceConfigDemandParametersInput = {
  basePrice: MoneyInput
  metersIncludedInBasePrice: Scalars['Int']['input']
  percentile: Scalars['Float']['input']
  pricePerKm: MoneyInput
}

export type PriceConfigDisplayText = {
  __typename?: 'PriceConfigDisplayText'
  header: Maybe<Scalars['String']['output']>
  locale: Scalars['String']['output']
  text: Maybe<Scalars['String']['output']>
}

export type PriceConfigDisplayTextInput = {
  header: InputMaybe<Scalars['String']['input']>
  locale: Scalars['String']['input']
  text: InputMaybe<Scalars['String']['input']>
}

export type PriceConfigParameters = {
  __typename?: 'PriceConfigParameters'
  demandParamsHigh: PriceConfigDemandParameters
  demandParamsLow: PriceConfigDemandParameters
  groupDiscounts: Array<Scalars['Float']['output']>
  randomFraction: PriceConfigRandomFraction
}

export type PriceConfigParametersInput = {
  demandParamsHigh: PriceConfigDemandParametersInput
  demandParamsLow: PriceConfigDemandParametersInput
  groupDiscounts: Array<Scalars['Float']['input']>
  randomFraction: InputMaybe<PriceConfigRandomFractionInput>
}

export type PriceConfigPolygon = {
  __typename?: 'PriceConfigPolygon'
  archived: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  locations: Array<Location>
  name: Scalars['String']['output']
  serviceAreaID: Scalars['ID']['output']
}

/** Input object for adding a pricing polygon to a condition. */
export type PriceConfigPolygonInput = {
  /** ID of the corresponding polygon in the polygon library */
  id: Scalars['ID']['input']
  locations: Array<LocationInput>
  name: Scalars['String']['input']
}

export type PriceConfigPolygonsResponse = {
  __typename?: 'PriceConfigPolygonsResponse'
  polygons: Array<PriceConfigPolygon>
}

export type PriceConfigRandomFraction = {
  __typename?: 'PriceConfigRandomFraction'
  lowerBound: Scalars['Float']['output']
  upperBound: Scalars['Float']['output']
}

export type PriceConfigRandomFractionInput = {
  lowerBound: Scalars['Float']['input']
  upperBound: Scalars['Float']['input']
}

export enum PriceConfigServiceClass {
  Autonomous = 'AUTONOMOUS',
  Classic = 'CLASSIC',
  Express = 'EXPRESS',
  WheelchairAccessible = 'WHEELCHAIR_ACCESSIBLE',
}

export enum PriceConfigState {
  Approved = 'APPROVED',
  Pending = 'PENDING',
}

export enum PriceConfigStateAction {
  Approve = 'APPROVE',
  Create = 'CREATE',
  Delete = 'DELETE',
  Disapprove = 'DISAPPROVE',
  Update = 'UPDATE',
}

export type PriceConfigTimeSlot = {
  __typename?: 'PriceConfigTimeSlot'
  from: Scalars['TimeOfDay']['output']
  to: Scalars['TimeOfDay']['output']
}

export type PriceConfigTimeSlotInput = {
  from: Scalars['TimeOfDay']['input']
  to: Scalars['TimeOfDay']['input']
}

export type PricePartitionText = {
  __typename?: 'PricePartitionText'
  description: Scalars['String']['output']
  language: Language
  title: Scalars['String']['output']
}

export type PricePartitionTextInput = {
  description: Scalars['String']['input']
  language: Language
  title: Scalars['String']['input']
}

export type PricingAdultPassengerCountCondition = {
  __typename?: 'PricingAdultPassengerCountCondition'
  maxCount: Maybe<Scalars['Int']['output']>
  minCount: Maybe<Scalars['Int']['output']>
}

export type PricingAdultPassengerCountConditionInput = {
  maxCount: InputMaybe<Scalars['Int']['input']>
  minCount: InputMaybe<Scalars['Int']['input']>
}

export type PricingCampaign = {
  __typename?: 'PricingCampaign'
  conditions: PricingCondition
  description: Scalars['String']['output']
  discountPercentage: Scalars['Int']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  priority: Scalars['Float']['output']
  purpose: CampaignConfigPurpose
  serviceAreaID: Scalars['ID']['output']
  translations: Maybe<Array<PricePartitionText>>
  validFrom: Scalars['DateTime']['output']
  validTo: Scalars['DateTime']['output']
}

export type PricingCampaignInput = {
  conditions: PricingConditionInput
  description: Scalars['String']['input']
  discountPercentage: Scalars['Int']['input']
  name: Scalars['String']['input']
  priority: Scalars['Float']['input']
  purpose: CampaignConfigPurpose
  serviceAreaID: Scalars['ID']['input']
  translations: InputMaybe<Array<PricePartitionTextInput>>
  validFrom: Scalars['DateTime']['input']
  validTo: Scalars['DateTime']['input']
}

export type PricingCampaignResponse = {
  __typename?: 'PricingCampaignResponse'
  campaign: PricingCampaign
}

export type PricingCondition = {
  __typename?: 'PricingCondition'
  adultPassengerCountCondition: Maybe<PricingAdultPassengerCountCondition>
  polygonLocationConditions: Maybe<Array<PolygonLocationCondition>>
  serviceClassCondition: Maybe<PricingServiceClassCondition>
  timeCondition: Maybe<PricingTimeCondition>
}

export type PricingConditionInput = {
  adultPassengerCountCondition: InputMaybe<PricingAdultPassengerCountConditionInput>
  polygonLocationConditions: InputMaybe<Array<PolygonLocationConditionInput>>
  serviceClassCondition: InputMaybe<PricingServiceClassConditionInput>
  timeCondition: InputMaybe<PricingTimeConditionInput>
}

export type PricingServiceClassCondition = {
  __typename?: 'PricingServiceClassCondition'
  serviceClasses: Maybe<Array<PriceConfigServiceClass>>
}

export type PricingServiceClassConditionInput = {
  serviceClasses: InputMaybe<Array<PriceConfigServiceClass>>
}

export type PricingTimeCondition = {
  __typename?: 'PricingTimeCondition'
  days: Array<DayOfWeek>
  timeSlots: Maybe<Array<TimeOfDayInterval>>
}

export type PricingTimeConditionInput = {
  days: Array<DayOfWeek>
  timeSlots: InputMaybe<Array<TimeOfDayIntervalInput>>
}

export type ProlongCabinVideoStreamInput = {
  vehicleId: Scalars['String']['input']
}

export enum ProvisioningState {
  Done = 'DONE',
  Draft = 'DRAFT',
  Pending = 'PENDING',
}

export type Query = {
  __typename?: 'Query'
  /** Used to check if the Federation Gateway works end-to-end. Returns your headers as stringified JSON. */
  CANARY_headers: Scalars['String']['output']
  CANARY_health: Maybe<Scalars['String']['output']>
  /** Used to check if the Federation Gateway works end-to-end. Returns ISO8601 DateTime. */
  CANARY_timestamp: Scalars['String']['output']
  actionByID: Maybe<Action>
  activationsByRoadBlockage: Array<RoadBlockageActivation>
  activationsByTurnRestriction: Array<TurnRestrictionActivation>
  aggregatedVehicleHubArrivalsAndDepartures: Array<AggregatedVehicleHubArrivalsAndDeparturesResponse>
  /** All history events in reversed order for a specific item, cursor paginated and optional limited between startDate and endDate (inclusive) */
  allChanges: HistoryItems
  allChangesToRoadBlockages: HistoryItems
  allChangesToTurnRestrictions: HistoryItems
  areaByID: Maybe<Area>
  /** returns all areas (visible to the client by tenancy/service area) and when provided filtered by IDs and/or AreaType */
  areas: Array<Area>
  /**
   * Provides the assigned CDIS Box.
   * Null if no CDIS box is assigned.
   */
  assignedCdisBox: Maybe<AssignedCdisBox>
  /** Called by employee app. Gets a list of vehicles that are ACTIVE, ONLINE, CLOSED, above a certain SoC and ordered by distance. For service staff INACTIVE vehicles are added. */
  availableVehicles: AvailableVehiclesResponse
  backofficeStatusById: BackofficeStatus
  backofficeStatusGetLastVisit: BackofficeStatusesLastVisitResponse
  backofficeStatuses: BackofficeStatusesResponse
  backofficeUpdateById: BackofficeUpdate
  backofficeUpdateGetLastVisit: BackofficeUpdatesLastVisitResponse
  backofficeUpdatesByRights: BackofficeUpdateByRightsResponse
  backofficeUpdatesGetMediaPresignedUrl: BackofficeUpdateFilePresignedResponse
  backofficeUserByEmail: BackofficeUser
  backofficeUserById: BackofficeUser
  backofficeUserFleetsByEmail: Maybe<Array<BackofficeUserManagementFleetShort>>
  backofficeUserHistory: Maybe<Array<BackofficeUserManagementUserHistory>>
  backofficeUserManagementGroupByName: BackofficeUserManagementGroupExtended
  backofficeUserManagementGroupHistory: Maybe<
    Array<BackofficeUserManagementGroupHistory>
  >
  backofficeUserManagementGroups: Maybe<Array<BackofficeUserManagementGroup>>
  backofficeUserManagementUserFormDetails: BackofficeUserManagementUserFormDetails
  backofficeUserManagementUserTenants: Maybe<
    Array<BackofficeUserManagementUserTenant>
  >
  backofficeUserManagementUsersHistory: Maybe<
    Array<BackofficeUserManagementUserHistory>
  >
  backofficeUsers: Maybe<Array<BackofficeUser>>
  closestHub: ClosestHubResponse
  /** returns active or upcoming shift */
  currentShift: Maybe<Shift>
  currentlyDrivenRoute: Maybe<CurrentlyDrivenRouteResponse>
  customPriceConfig: Maybe<CustomPriceConfigResponse>
  customPriceConfigs: CustomPriceConfigsResponse
  /** Returns customer profile by id */
  customer: CustomerResponse
  /** Search customer by customer profile fields */
  customerSearch: CustomerSearchResponse
  /** Returns customer profiles by ids in the provided order, maximum 100 profiles, null in case a customer is not found */
  customers: CustomersResponse
  damageReports: DamageReportList
  /** Get all damage reports by state by vehicle */
  damageReportsByVehicle: Array<DamageReport>
  defaultPriceConfig: Maybe<DefaultPriceConfigResponse>
  defaultPriceConfigs: DefaultPriceConfigsResponse
  dsBossFileByScenarioId: DsBossGetFileResponse
  dsBossScenarioDetailsByScenarioId: DsBossScenarioDetailsResponse
  dsBossScenariosByServiceAreaId: DsBossScenariosResponse
  employee: Employee
  /** get the scheduled blocks for a given employee and all employees within the same block id */
  employeeGetScheduledBlocks: EmployeeGetScheduledBlocksResponse
  /** returns driver log associated to the given shift */
  employeeLogByShift: EmployeeLogResponse
  employeeMessage: EmployeeMessageResponse
  /** fetch all messages of the given categories for an employee. if categories is null or empty, all messages are returned. */
  employeeMessages: EmployeeMessagesResponse
  employeeQueryNotes: EmployeeQueryNotesResponse
  /** Returns timesheets for the given employee and date range, can only be queried by drivers */
  employeeTimesheets: Array<EmployeeTimesheet>
  /** returns monthly tipping amounts for the given employee */
  employeeTips: Maybe<EmployeeTipsResponse>
  /** employee (only) can query an employee vehicle interaction token */
  employeeVehicleInteraction: Maybe<EmployeeVehicleInteraction>
  /** generate a JWT signed zendesk URL to enable SSO for drivers */
  employeeZendeskSSO: EmployeeZendeskSsoResponse
  /** can be queried with EMPLOYEE_R, for full Employee info user must have EMPLOYEE_PERSONAL_DATA_R */
  employees: Employees
  fccAdAlertById: Maybe<FccAdAlert>
  fccAllOperatorAvailabilities: FccOperatorAvailabilityCountInServiceArea
  fccAuditLog: FccAuditLogList
  fccCauseError: Maybe<Scalars['Boolean']['output']>
  fccGroupedAdAlerts: FccGroupedAdAlerts
  fccMostUrgentAlert: Maybe<FccOperatorAlert>
  fccNoError: Maybe<Scalars['Boolean']['output']>
  fccNonGraphqlError: Maybe<Scalars['Boolean']['output']>
  fccOperatorState: Maybe<FccOperatorState>
  fccRandomlyCauseError: Maybe<Scalars['Boolean']['output']>
  fccServiceCancellation: Scalars['String']['output']
  fccVehicleInteraction: Scalars['String']['output']
  fccVehicleMission: Scalars['String']['output']
  fccVehicleStatus: Scalars['String']['output']
  /** Returns feedback by tripId */
  feedback: FeedbackResponse
  fleetMapVehicles: FleetMapVehicles
  /** Used by several BOFE tables and widgets, returns a list of vehicles. */
  fleetVehicles: VehiclesResponse
  getIncidents: Array<GetIncidentsResponse>
  getSimulatedADVehicle: Maybe<SimulatedAdVehicle>
  getSimulatedADVehicleIncidents: Array<SimulatedAdVehicleIncident>
  getSimulatedADVehicleSignalConnectivity: Maybe<SimulatedAdVehicleSignalConnectivity>
  hardwareConfiguration: HardwareConfiguration
  heatmapByID: Maybe<Heatmap>
  hubByID: Hub
  /** returns all hubs visible to the client, or filtered by IDs if provided */
  hubs: HubsResponse
  kml: Scalars['String']['output']
  /** Return last trips for customerId */
  lastTripsByCustomerId: Maybe<Array<Trip>>
  /** Return last trips for vehicleId */
  lastTripsByVehicleId: Maybe<Array<Trip>>
  /** Latest history event for a specific item. Optional fieldChanged is a RegEx to find only the latest history event where matching fields changed. */
  latestChange: Maybe<HistoryItem>
  latestChangeToRoadBlockage: Maybe<HistoryItem>
  latestChangeToTurnRestriction: Maybe<HistoryItem>
  latestHubLogRecord: Maybe<LatestHubLogRecordResponse>
  mainInspectionDoneProcesses: Array<MainInspectionProcess>
  mainInspectionDueProcesses: Array<MainInspectionProcess>
  mainInspectionProgressProcesses: Array<MainInspectionProcess>
  mainInspectionTestFacilities: Array<MainInspectionTestFacility>
  migrationTestPleaseIgnore: Maybe<Scalars['Boolean']['output']>
  migrationTestPleaseIgnore2: Maybe<Scalars['String']['output']>
  mileageBasedInspectionDoneProcesses: Array<MileageBasedInspectionProcess>
  mileageBasedInspectionDueProcesses: Array<MileageBasedInspectionProcess>
  mileageBasedInspectionProgressProcesses: Array<MileageBasedInspectionProcess>
  mileageBasedInspectionTestFacilities: Array<MileageBasedInspectionTestFacility>
  /** Called by employee app. Gets a list of vehicles for which the last interaction was an OPEN interaction by this employee. */
  openVehiclesForEmployee: Array<Vehicle>
  /** Return a relay connection of the latest trips of a customer, sorted by creation time */
  paginatedTripsByCustomerId: Maybe<TripConnection>
  /** Return a relay connection of the latest trips of a customer, sorted by promised pickup time */
  paginatedTripsByCustomerIdSortedByPickup: Maybe<TripConnection>
  /** Return a Relay connection of the latest trips of a vehicle, sorted by creation time */
  paginatedTripsByVehicleId: Maybe<TripConnection>
  /** Return a Relay connection of the latest trips of a vehicle, sorted by promised pickup time */
  paginatedTripsByVehicleIdSortedByPickup: Maybe<TripConnection>
  priceConfigPolygons: PriceConfigPolygonsResponse
  pricingCampaign: Maybe<PricingCampaignResponse>
  /** List of the customers that were referred */
  referees: Array<RefereeInfo>
  /** Who referred the customer */
  referrerInfo: Maybe<ReferrerInfo>
  rioAddressAutocomplete: RioAddressAutocompleteResponse
  rioAddressFromLocation: RioAddressFromLocationResponse
  rioAppPhotoUpload: RioAppPhotoUploadResponse
  rioAttachmentDownloadUrl: RioAttachmentDownloadUrlResponse
  rioAttachmentUploadUrl: RioAttachmentUploadUrlResponse
  rioLatestTemplate: RioLatestTemplateResponse
  rioReport: RioReportResponse
  rioReports: RioReportsResponse
  rioTempAttachmentDownloadUrl: RioTempAttachmentDownloadUrlResponse
  roadBlockageById: Maybe<RoadBlockage>
  roadBlockages: Array<RoadBlockage>
  roadSegmentSuggestion: Maybe<RoadSegmentSuggestion>
  roadSegmentSuggestionsInsidePolygon: Array<RoadSegmentSuggestion>
  searchV3: SearchResultV3
  serverTime: Scalars['Float']['output']
  serverTime2: Scalars['Float']['output']
  /** returns service area by ID or UUID */
  serviceAreaById: Maybe<ServiceArea>
  /** returns service areas */
  serviceAreas: Array<ServiceArea>
  /** returns shift by id */
  shift: Maybe<Shift>
  shiftCapacityPlanningFiles: Array<ShiftCapacityPlanningFile>
  /** Returns a list of RMP files that can be chosen for new capacity plannings. List is ordered, whereas the last file is the youngest. */
  shiftCapacityPlanningRmpFiles: Array<ShiftCapacityPlanningRmpFile>
  shiftCapacityPlannings: Array<ShiftCapacityPlanning>
  /** Returns the utilization of a selected area for a specific time */
  shiftHubReturnUtilization: ShiftPlannedHubReturnUtilizationResponse
  /** returns planned hub return section options for a given shift ID */
  shiftPlannedHubReturnSectionOptions: Maybe<ShiftPlannedHubReturnSectionOptionsResponse>
  /** returns execution state for a given shift ID */
  shiftState: Maybe<ShiftState>
  /** returns the available shift types for a given service area */
  shiftTypes: Array<ShiftType>
  shiftsActivities: Array<ShiftsActivity>
  /** Returns demand and supply of shifts for the given service area and date-time range (from/to inclusive) */
  shiftsDemandSupply: Array<ShiftsDemandSupplyData>
  shiftsForEmployee: Array<Shift>
  /** returns all shifts for a service area crossing the given time interval */
  shiftsInInterval: Array<Shift>
  /** Returns supply for selected interval. */
  shiftsSupply: Array<ShiftsSupplyData>
  simulatedVehicleByID: Maybe<SimulatedVehicle>
  /** returns stops by ID */
  stopById: Maybe<Stop>
  /** aggregated stop insight for the given stop id for the last given days */
  stopInsightForLastDays: Maybe<StopInsight>
  stopInsights: Maybe<Array<StopInsight>>
  /** returns a suggested stop for a coordinate */
  stopSuggestion: StopSuggestion
  /** returns validation results for a stop */
  stopValidation: StopValidationResults
  /** returns all stops for the given service area */
  stops: Array<Stop>
  taskNotificationById: Maybe<TaskNotification>
  taskNotifications: TaskNotificationList
  /** Return a trip with payment details for a given invoiceId */
  tripByInvoiceId: Maybe<Trip>
  /** Return a trip with payment details for a given paymentId */
  tripByPaymentId: Maybe<Trip>
  /** Return a trip with payment details for a given pspTransactionId */
  tripByPspTransactionId: Maybe<Trip>
  /** Return a trip with payment details */
  tripByTripId: Maybe<Trip>
  tripHistoryEntries: TripHistoryEntriesResponse
  tripHistoryEntry: TripHistoryEntryResponse
  turnRestrictionById: Maybe<TurnRestriction>
  turnRestrictions: Array<TurnRestriction>
  /**
   * Lists boxes that are unassigned for a vehicle
   * Telemetry data will not be forwarded from the box unless it is assigned.
   */
  unassignedCdisBoxes: Array<UnassignedCdisBox>
  unassignedTablets: Array<Tablet>
  vehicleByID: Vehicle
  /** Returns a vehicle for a given label, service area and tenant */
  vehicleByLabel: Maybe<Vehicle>
  vehicleByPhoneNumber: Maybe<Vehicle>
  vehicleChanges: VehicleChangeList
  vehicleCheckTopics: Array<VehicleCheckTopicTemplate>
  /** Get all vehicle checks with unacknowledged answers */
  vehicleChecks: VehicleCheckList
  vehicleGroupByID: VehicleGroup
  vehicleGroups: VehicleGroupsResponse
  vehicleHubArrivals: VehicleHubArrivalsResponse
  /** Returns a list of interactions for a specific vehicle (when and where a vehicle was opened or closed). Needed for the interactions tab of a vehicle. */
  vehicleInteractions: VehicleInteractionsResponse
  vehicleListByID: VehicleList
  vehicleLists: VehicleListsResponse
  vehicleParts: Array<VehiclePart>
  /** Returns all vehicles from dispatching's fleet state */
  vehiclesByFleetState: VehiclesByFleetStateResponse
  vehiclesByIDs: Array<Vehicle>
  /** Returns a list of active and inactive vehicles. */
  vehiclesForVehicleList: Array<Vehicle>
  /** resolves the VGA app version for an specific vehicle ID. */
  vgaVersion: VgaVersionInfo
  vicDoneProcesses: Array<VicProcess>
  vicDueProcesses: Array<VicProcess>
  workOrders: WorkOrdersQueryResponse
}

export type QueryActionByIdArgs = {
  actionID: Scalars['ID']['input']
  serviceAreaID: Scalars['ID']['input']
}

export type QueryActivationsByRoadBlockageArgs = {
  roadBlockageId: Scalars['ID']['input']
}

export type QueryActivationsByTurnRestrictionArgs = {
  turnRestrictionId: Scalars['ID']['input']
}

export type QueryAggregatedVehicleHubArrivalsAndDeparturesArgs = {
  hubID: Scalars['ID']['input']
  intervals: Array<DateTimeIntervalInput>
}

export type QueryAllChangesArgs = {
  endDate: InputMaybe<Scalars['DateTime']['input']>
  fieldChanged: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  nextCursor: InputMaybe<Scalars['String']['input']>
  startDate: InputMaybe<Scalars['DateTime']['input']>
  type: EventItemType
}

export type QueryAllChangesToRoadBlockagesArgs = {
  endDate: InputMaybe<Scalars['DateTime']['input']>
  fieldChanged: InputMaybe<Scalars['String']['input']>
  nextCursor: InputMaybe<Scalars['String']['input']>
  roadBlockageId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
  startDate: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryAllChangesToTurnRestrictionsArgs = {
  endDate: InputMaybe<Scalars['DateTime']['input']>
  fieldChanged: InputMaybe<Scalars['String']['input']>
  nextCursor: InputMaybe<Scalars['String']['input']>
  serviceAreaId: Scalars['ID']['input']
  startDate: InputMaybe<Scalars['DateTime']['input']>
  turnRestrictionId: Scalars['ID']['input']
}

export type QueryAreaByIdArgs = {
  areaID: Scalars['ID']['input']
  serviceAreaID: Scalars['ID']['input']
}

export type QueryAreasArgs = {
  areaTypes: InputMaybe<Array<AreaType>>
  ids: InputMaybe<Array<Scalars['ID']['input']>>
  serviceAreaID: InputMaybe<Scalars['ID']['input']>
}

export type QueryAssignedCdisBoxArgs = {
  fleetVehicleId: Scalars['ID']['input']
}

export type QueryAvailableVehiclesArgs = {
  location: LocationInput
  token: InputMaybe<Scalars['String']['input']>
}

export type QueryBackofficeStatusByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryBackofficeUpdateByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryBackofficeUpdatesGetMediaPresignedUrlArgs = {
  input: BackofficeUpdateGetMediaFilePresignedUrlInput
}

export type QueryBackofficeUserByEmailArgs = {
  email: Scalars['String']['input']
}

export type QueryBackofficeUserByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryBackofficeUserFleetsByEmailArgs = {
  email: Scalars['String']['input']
}

export type QueryBackofficeUserHistoryArgs = {
  id: Scalars['ID']['input']
}

export type QueryBackofficeUserManagementGroupByNameArgs = {
  name: Scalars['String']['input']
}

export type QueryBackofficeUserManagementGroupHistoryArgs = {
  name: Scalars['String']['input']
}

export type QueryBackofficeUserManagementUsersHistoryArgs = {
  input: BackofficeUserManagementUsersHistoryInput
}

export type QueryClosestHubArgs = {
  locationInput: LocationInput
}

export type QueryCurrentShiftArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryCurrentlyDrivenRouteArgs = {
  vehicleId: Scalars['ID']['input']
}

export type QueryCustomPriceConfigArgs = {
  id: Scalars['ID']['input']
}

export type QueryCustomPriceConfigsArgs = {
  dateRangeFilter: InputMaybe<DateTimeIntervalInput>
  nameFilter: InputMaybe<Scalars['String']['input']>
  orderBy: InputMaybe<CustomConfigOrderByInput>
  pagination: InputMaybe<PaginationInput>
  serviceAreaID: Scalars['ID']['input']
  stateFilter: InputMaybe<PriceConfigState>
}

export type QueryCustomerArgs = {
  id: Scalars['ID']['input']
}

export type QueryCustomerSearchArgs = {
  input: CustomerSearchInput
}

export type QueryCustomersArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type QueryDamageReportsArgs = {
  input: DamageReportSearch
}

export type QueryDamageReportsByVehicleArgs = {
  fromDate: InputMaybe<Scalars['DateTime']['input']>
  states: Array<DamageReportState>
  toDate: InputMaybe<Scalars['DateTime']['input']>
  vehicleID: Scalars['ID']['input']
}

export type QueryDefaultPriceConfigArgs = {
  id: Scalars['ID']['input']
}

export type QueryDefaultPriceConfigsArgs = {
  dateRangeFilter: InputMaybe<DateTimeIntervalInput>
  nameFilter: InputMaybe<Scalars['String']['input']>
  pagination: InputMaybe<PaginationInput>
  serviceAreaID: Scalars['ID']['input']
  stateFilter: InputMaybe<PriceConfigState>
}

export type QueryDsBossFileByScenarioIdArgs = {
  input: DsBossGetBasedOnScenarioId
}

export type QueryDsBossScenarioDetailsByScenarioIdArgs = {
  input: DsBossGetBasedOnScenarioId
}

export type QueryDsBossScenariosByServiceAreaIdArgs = {
  input: DsBossGetBasedOnServiceAreaId
}

export type QueryEmployeeArgs = {
  id: Scalars['ID']['input']
}

export type QueryEmployeeGetScheduledBlocksArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryEmployeeLogByShiftArgs = {
  shiftId: Scalars['ID']['input']
}

export type QueryEmployeeMessageArgs = {
  employeeId: Scalars['ID']['input']
  messageId: Scalars['ID']['input']
}

export type QueryEmployeeMessagesArgs = {
  categories: InputMaybe<Array<EmployeeMessageCategory>>
  employeeID: Scalars['ID']['input']
  year: InputMaybe<Scalars['Int']['input']>
}

export type QueryEmployeeQueryNotesArgs = {
  input: EmployeeQueryNotesInput
}

export type QueryEmployeeTimesheetsArgs = {
  employeeId: Scalars['ID']['input']
  from: Scalars['Date']['input']
  to: Scalars['Date']['input']
}

export type QueryEmployeeTipsArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryEmployeeVehicleInteractionArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryEmployeeZendeskSsoArgs = {
  returnTo: Scalars['String']['input']
}

export type QueryEmployeesArgs = {
  employeesFilter: InputMaybe<EmployeesFilterInput>
  pagination: InputMaybe<PaginationInput>
}

export type QueryFccAdAlertByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryFccAllOperatorAvailabilitiesArgs = {
  serviceAreaUUID: Scalars['ID']['input']
}

export type QueryFccAuditLogArgs = {
  input: FccAuditLogInput
}

export type QueryFccGroupedAdAlertsArgs = {
  serviceAreaUUID: Scalars['ID']['input']
}

export type QueryFccMostUrgentAlertArgs = {
  serviceAreaUUID: Scalars['ID']['input']
}

export type QueryFccOperatorStateArgs = {
  serviceAreaUUID: Scalars['ID']['input']
}

export type QueryFeedbackArgs = {
  tripId: Scalars['String']['input']
}

export type QueryFleetMapVehiclesArgs = {
  input: FleetMapVehiclesInput
}

export type QueryFleetVehiclesArgs = {
  input: VehicleSearchInput
}

export type QueryGetIncidentsArgs = {
  affected: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
  from: InputMaybe<Scalars['DateTime']['input']>
  open: Scalars['Boolean']['input']
  severity: InputMaybe<Array<InputMaybe<Severity>>>
  to: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryGetSimulatedAdVehicleArgs = {
  id: Scalars['ID']['input']
}

export type QueryGetSimulatedAdVehicleIncidentsArgs = {
  fleetVehicleId: Scalars['ID']['input']
}

export type QueryGetSimulatedAdVehicleSignalConnectivityArgs = {
  fleetVehicleId: Scalars['ID']['input']
}

export type QueryHardwareConfigurationArgs = {
  vehicleId: Scalars['ID']['input']
}

export type QueryHeatmapByIdArgs = {
  id: Scalars['ID']['input']
  serviceAreaID: Scalars['ID']['input']
}

export type QueryHubByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryHubsArgs = {
  ids: InputMaybe<Array<Scalars['ID']['input']>>
  serviceAreaUUID: InputMaybe<Scalars['ID']['input']>
}

export type QueryKmlArgs = {
  searchInput: SearchInput
  serviceAreaID: Scalars['ID']['input']
}

export type QueryLastTripsByCustomerIdArgs = {
  id: Scalars['ID']['input']
  limit: Scalars['Int']['input']
}

export type QueryLastTripsByVehicleIdArgs = {
  id: Scalars['ID']['input']
  limit: Scalars['Int']['input']
}

export type QueryLatestChangeArgs = {
  fieldChanged: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
  type: EventItemType
}

export type QueryLatestChangeToRoadBlockageArgs = {
  roadBlockageId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
}

export type QueryLatestChangeToTurnRestrictionArgs = {
  serviceAreaId: Scalars['ID']['input']
  turnRestrictionId: Scalars['ID']['input']
}

export type QueryLatestHubLogRecordArgs = {
  employeeId: Scalars['ID']['input']
}

export type QueryMainInspectionDoneProcessesArgs = {
  input: InspectionDoneProcessesSearch
}

export type QueryMainInspectionDueProcessesArgs = {
  input: InspectionDueProcessesSearch
}

export type QueryMainInspectionProgressProcessesArgs = {
  input: InspectionProgressProcessesSearch
}

export type QueryMainInspectionTestFacilitiesArgs = {
  serviceAreaUUID: Scalars['ID']['input']
}

export type QueryMileageBasedInspectionDoneProcessesArgs = {
  input: InspectionDoneProcessesSearch
}

export type QueryMileageBasedInspectionDueProcessesArgs = {
  input: InspectionDueProcessesSearch
}

export type QueryMileageBasedInspectionProgressProcessesArgs = {
  input: InspectionProgressProcessesSearch
}

export type QueryMileageBasedInspectionTestFacilitiesArgs = {
  serviceAreaUUID: Scalars['ID']['input']
}

export type QueryPaginatedTripsByCustomerIdArgs = {
  after: InputMaybe<Scalars['String']['input']>
  dateTimeInterval: InputMaybe<DateTimeIntervalInput>
  first: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
}

export type QueryPaginatedTripsByCustomerIdSortedByPickupArgs = {
  after: InputMaybe<Scalars['String']['input']>
  dateTimeInterval: InputMaybe<DateTimeIntervalInput>
  first: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
}

export type QueryPaginatedTripsByVehicleIdArgs = {
  after: InputMaybe<Scalars['String']['input']>
  dateTimeInterval: InputMaybe<DateTimeIntervalInput>
  first: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
}

export type QueryPaginatedTripsByVehicleIdSortedByPickupArgs = {
  after: InputMaybe<Scalars['String']['input']>
  dateTimeInterval: InputMaybe<DateTimeIntervalInput>
  first: InputMaybe<Scalars['Int']['input']>
  id: Scalars['ID']['input']
}

export type QueryPriceConfigPolygonsArgs = {
  serviceAreaID: Scalars['ID']['input']
}

export type QueryPricingCampaignArgs = {
  id: Scalars['ID']['input']
}

export type QueryRefereesArgs = {
  customerId: Scalars['String']['input']
}

export type QueryReferrerInfoArgs = {
  customerId: Scalars['String']['input']
}

export type QueryRioAddressAutocompleteArgs = {
  input: RioAddressAutocompleteInput
}

export type QueryRioAddressFromLocationArgs = {
  input: RioAddressFromLocationInput
}

export type QueryRioAttachmentDownloadUrlArgs = {
  input: RioAttachmentDownloadUrlInput
}

export type QueryRioAttachmentUploadUrlArgs = {
  input: RioAttachmentUploadUrlInput
}

export type QueryRioLatestTemplateArgs = {
  input: RioLatestTemplateInput
}

export type QueryRioReportArgs = {
  input: RioReportInput
}

export type QueryRioReportsArgs = {
  input: RioReportsInput
}

export type QueryRioTempAttachmentDownloadUrlArgs = {
  input: RioTempAttachmentDownloadUrlInput
}

export type QueryRoadBlockageByIdArgs = {
  roadBlockageId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
}

export type QueryRoadBlockagesArgs = {
  reportStatuses: InputMaybe<Array<RoadBlockageReportStatus>>
  serviceAreaId: Scalars['ID']['input']
}

export type QueryRoadSegmentSuggestionArgs = {
  location: LocationInput
  segmentId: InputMaybe<Scalars['String']['input']>
}

export type QueryRoadSegmentSuggestionsInsidePolygonArgs = {
  completelyInside: Scalars['Boolean']['input']
  locations: Array<LocationInput>
}

export type QuerySearchV3Args = {
  searchInput: SearchInput
  serviceAreaID: Scalars['ID']['input']
}

export type QueryServiceAreaByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryShiftArgs = {
  shiftId: Scalars['ID']['input']
}

export type QueryShiftCapacityPlanningFilesArgs = {
  capacityPlanningID: Scalars['ID']['input']
}

export type QueryShiftCapacityPlanningRmpFilesArgs = {
  input: ShiftCapacityPlanningRmpFilesInput
}

export type QueryShiftCapacityPlanningsArgs = {
  input: ShiftCapacityPlanningsInput
}

export type QueryShiftHubReturnUtilizationArgs = {
  input: ShiftPlannedHubReturnUtilizationInput
}

export type QueryShiftPlannedHubReturnSectionOptionsArgs = {
  shiftId: Scalars['ID']['input']
}

export type QueryShiftStateArgs = {
  shiftId: Scalars['ID']['input']
}

export type QueryShiftTypesArgs = {
  serviceAreaUUID: Scalars['ID']['input']
}

export type QueryShiftsActivitiesArgs = {
  filters: InputMaybe<ShiftsActivitiesFiltersInput>
  from: Scalars['Date']['input']
  serviceAreaId: Scalars['ID']['input']
  to: Scalars['Date']['input']
}

export type QueryShiftsDemandSupplyArgs = {
  from: Scalars['DateTime']['input']
  serviceAreaId: Scalars['ID']['input']
  to: Scalars['DateTime']['input']
}

export type QueryShiftsForEmployeeArgs = {
  employeeId: Scalars['ID']['input']
  from: Scalars['Date']['input']
  to: Scalars['Date']['input']
}

export type QueryShiftsInIntervalArgs = {
  filters: InputMaybe<ShiftsFiltersInput>
  from: Scalars['DateTime']['input']
  serviceAreaId: Scalars['ID']['input']
  to: Scalars['DateTime']['input']
}

export type QueryShiftsSupplyArgs = {
  from: Scalars['DateTime']['input']
  hubId: InputMaybe<Scalars['ID']['input']>
  serviceAreaId: Scalars['ID']['input']
  to: Scalars['DateTime']['input']
}

export type QuerySimulatedVehicleByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryStopByIdArgs = {
  serviceAreaId: Scalars['ID']['input']
  stopId: Scalars['ID']['input']
}

export type QueryStopInsightForLastDaysArgs = {
  days: Scalars['Int']['input']
  serviceAreaId: Scalars['ID']['input']
  stopId: Scalars['ID']['input']
}

export type QueryStopInsightsArgs = {
  from: Scalars['LocalDateTime']['input']
  granularity: Granularity
  serviceAreaId: Scalars['ID']['input']
  stopId: Scalars['ID']['input']
  to: Scalars['LocalDateTime']['input']
}

export type QueryStopSuggestionArgs = {
  location: LocationInput
  serviceAreaId: Scalars['ID']['input']
}

export type QueryStopValidationArgs = {
  serviceAreaId: Scalars['ID']['input']
  stopId: Scalars['ID']['input']
}

export type QueryStopsArgs = {
  insideServiceAreaOnly: InputMaybe<Scalars['Boolean']['input']>
  serviceAreaId: Scalars['ID']['input']
}

export type QueryTaskNotificationByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryTaskNotificationsArgs = {
  assignedToMe: InputMaybe<Scalars['Boolean']['input']>
  pagination: InputMaybe<PaginationInput>
  search: InputMaybe<Scalars['String']['input']>
  serviceAreaUUID: Scalars['ID']['input']
  sorting: InputMaybe<Array<TaskNotificationSorting>>
  status: InputMaybe<Array<TaskNotificationStatus>>
  types: InputMaybe<Array<TaskNotificationType>>
  vehicleHubLocation: InputMaybe<Array<Scalars['ID']['input']>>
}

export type QueryTripByInvoiceIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryTripByPaymentIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryTripByPspTransactionIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryTripByTripIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryTripHistoryEntriesArgs = {
  customerId: Scalars['ID']['input']
}

export type QueryTripHistoryEntryArgs = {
  id: Scalars['ID']['input']
}

export type QueryTurnRestrictionByIdArgs = {
  serviceAreaId: Scalars['ID']['input']
  turnRestrictionId: Scalars['ID']['input']
}

export type QueryTurnRestrictionsArgs = {
  serviceAreaId: Scalars['ID']['input']
}

export type QueryUnassignedCdisBoxesArgs = {
  fleetVehicleId: Scalars['ID']['input']
}

export type QueryVehicleByIdArgs = {
  id: InputMaybe<Scalars['ID']['input']>
}

export type QueryVehicleByLabelArgs = {
  input: VehicleByLabelInput
}

export type QueryVehicleByPhoneNumberArgs = {
  phoneNumber: Scalars['String']['input']
}

export type QueryVehicleChangesArgs = {
  input: VehicleChangesQueryInput
}

export type QueryVehicleCheckTopicsArgs = {
  language: DamageReportLanguage
  vehicleCheckType: VehicleCheckType
  vehicleID: Scalars['ID']['input']
}

export type QueryVehicleChecksArgs = {
  input: VehicleCheckSearch
}

export type QueryVehicleGroupByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryVehicleGroupsArgs = {
  paginationInput: InputMaybe<PaginationInput>
  tenant: InputMaybe<Scalars['String']['input']>
}

export type QueryVehicleHubArrivalsArgs = {
  hubID: Scalars['ID']['input']
  interval: DateTimeIntervalInput
}

export type QueryVehicleInteractionsArgs = {
  vehicleInteractionsQueryInput: VehicleInteractionsQueryInput
}

export type QueryVehicleListByIdArgs = {
  id: Scalars['ID']['input']
}

export type QueryVehicleListsArgs = {
  serviceAreaUUID: Scalars['ID']['input']
}

export type QueryVehiclePartsArgs = {
  language: DamageReportLanguage
  vehicleID: Scalars['ID']['input']
}

export type QueryVehiclesByFleetStateArgs = {
  inService: InputMaybe<Scalars['Boolean']['input']>
  returningNextToHubIDs: InputMaybe<Array<Scalars['ID']['input']>>
  serviceAreaUUID: InputMaybe<Scalars['ID']['input']>
}

export type QueryVehiclesByIDsArgs = {
  ids: Array<Scalars['ID']['input']>
}

export type QueryVehiclesForVehicleListArgs = {
  input: VehiclesForVehicleListInput
}

export type QueryVgaVersionArgs = {
  vehicleId: Scalars['ID']['input']
}

export type QueryVicDoneProcessesArgs = {
  input: InspectionDoneProcessesSearch
}

export type QueryVicDueProcessesArgs = {
  input: InspectionDueProcessesSearch
}

export type QueryWorkOrdersArgs = {
  input: WorkOrdersQueryInput
}

export type ReducedEmployee = {
  __typename?: 'ReducedEmployee'
  /** @deprecated Use the Employee field instead */
  employeeVehicleInteraction: EmployeeVehicleInteraction
  id: Scalars['ID']['output']
}

export type RefereeInfo = {
  __typename?: 'RefereeInfo'
  customer: Customer
  customerId: Scalars['String']['output']
  /** The time when the referral was redeemed */
  redeemedOn: Scalars['DateTime']['output']
  referralStatus: ReferralStatus
  /** Information on the profile who referred the current customer */
  referredBy: Maybe<ReferrerInfo>
  /** The time when the reward was sent out */
  rewardSentOn: Maybe<Scalars['String']['output']>
}

export enum ReferralStatus {
  /** The reward requirements are fulfilled but the reward was not yet received */
  AwaitingReward = 'AWAITING_REWARD',
  /** The reward requirements were not fulfilled during the referral time-frame */
  Failed = 'FAILED',
  /** The reward was received */
  RewardReceived = 'REWARD_RECEIVED',
  /** The referral had been redeemed but the reward requirements are not yet fulfilled */
  Started = 'STARTED',
}

export type ReferrerInfo = {
  __typename?: 'ReferrerInfo'
  customer: Customer
  customerId: Scalars['String']['output']
  /** Referral code of the customer */
  referralCode: Maybe<Scalars['String']['output']>
  /** Information on the profile who referred the current customer */
  referredBy: Maybe<ReferrerInfo>
}

export type Refund = {
  __typename?: 'Refund'
  /** The amount to be refunded, i.e. returned to the customer's account. */
  refundedAmount: Maybe<Money>
  /** The amount payed out to the customer's account. */
  settledAmount: Maybe<Money>
}

export type RequestCabinVideoStreamCredentials = {
  vehicleId: Scalars['String']['input']
}

export type RioAddressAutocompleteInput = {
  language: RioLanguage
  searchTerm: Scalars['String']['input']
  serviceAreaUuid: Scalars['String']['input']
}

export type RioAddressAutocompleteResponse = {
  __typename?: 'RioAddressAutocompleteResponse'
  addresses: Array<Scalars['String']['output']>
}

export type RioAddressFromLocationInput = {
  language: RioLanguage
  latitude: Scalars['Float']['input']
  longitude: Scalars['Float']['input']
}

export type RioAddressFromLocationResponse = {
  __typename?: 'RioAddressFromLocationResponse'
  address: Scalars['String']['output']
}

export type RioAppPhotoUploadResponse = {
  __typename?: 'RioAppPhotoUploadResponse'
  reports: Maybe<Array<RioAppReportPhotoUpload>>
}

export type RioAppReportPhotoUpload = {
  __typename?: 'RioAppReportPhotoUpload'
  photos: Maybe<Array<RioPhoto>>
  reportId: Scalars['ID']['output']
  shortId: Scalars['ID']['output']
}

export type RioAttachment = {
  __typename?: 'RioAttachment'
  attachmentId: Scalars['ID']['output']
  attachmentType: Maybe<RioAttachmentType>
  fileName: Scalars['String']['output']
  photoCategory: Maybe<RioPhotoCategory>
  size: Scalars['Int']['output']
  uploadedAt: Scalars['DateTime']['output']
  uploadedBy: Maybe<RioUser>
}

export type RioAttachmentDownloadUrl = {
  __typename?: 'RioAttachmentDownloadUrl'
  attachmentId: Scalars['String']['output']
  thumbnailUrl: Maybe<Scalars['String']['output']>
  url: Scalars['String']['output']
}

export type RioAttachmentDownloadUrlInput = {
  attachmentIds: Array<Scalars['String']['input']>
  reportId: Scalars['ID']['input']
}

export type RioAttachmentDownloadUrlResponse = {
  __typename?: 'RioAttachmentDownloadUrlResponse'
  urls: Maybe<Array<RioAttachmentDownloadUrl>>
}

export enum RioAttachmentType {
  Document = 'Document',
  Image = 'Image',
}

export type RioAttachmentUploadUrl = {
  __typename?: 'RioAttachmentUploadUrl'
  attachmentId: Scalars['String']['output']
  url: Scalars['String']['output']
}

export type RioAttachmentUploadUrlInput = {
  category: InputMaybe<RioCategory>
  fileName: Scalars['String']['input']
  fileSize: Scalars['Int']['input']
  photoCategory: InputMaybe<RioPhotoCategory>
  reportId: InputMaybe<Scalars['ID']['input']>
}

export type RioAttachmentUploadUrlResponse = {
  __typename?: 'RioAttachmentUploadUrlResponse'
  url: RioAttachmentUploadUrl
}

export enum RioCalculatedOutcomeValue {
  Fail = 'Fail',
  Pass = 'Pass',
}

export enum RioCategory {
  Accident = 'Accident',
  Disruption = 'Disruption',
  VehicleChecks = 'VehicleChecks',
}

export type RioComponent = {
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  label: RioLabel
}

export type RioComponentCheckbox = RioComponent & {
  __typename?: 'RioComponentCheckbox'
  checkboxLabel: Maybe<RioLabel>
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  description: Maybe<RioLabel>
  falseLabel: RioLabel
  label: RioLabel
  trueLabel: RioLabel
  viewLabel: Maybe<RioLabel>
}

export type RioComponentCheckboxInput = {
  checkboxLabel: InputMaybe<RioLabelInput>
  componentId: Scalars['String']['input']
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  description: InputMaybe<RioLabelInput>
  falseLabel: RioLabelInput
  label: RioLabelInput
  trueLabel: RioLabelInput
  viewLabel: InputMaybe<RioLabelInput>
}

export type RioComponentDate = RioComponent & {
  __typename?: 'RioComponentDate'
  allowFutureDates: Maybe<Scalars['Boolean']['output']>
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  label: RioLabel
}

export type RioComponentDateInput = {
  allowFutureDates: InputMaybe<Scalars['Boolean']['input']>
  componentId: Scalars['String']['input']
  componentType: RioComponentType
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  label: RioLabelInput
}

export type RioComponentDisruptionType = RioComponent & {
  __typename?: 'RioComponentDisruptionType'
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  disruptionTypes: Array<RioDisruptionTypes>
  label: RioLabel
}

export type RioComponentDisruptionTypeInput = {
  componentId: Scalars['String']['input']
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  disruptionTypes: Array<RioDisruptionTypes>
  label: RioLabelInput
}

export type RioComponentPassFail = RioComponent & {
  __typename?: 'RioComponentPassFail'
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  description: Maybe<RioLabel>
  failLabel: RioLabel
  label: RioLabel
  passFailValues: RioPassFailValues
  passLabel: RioLabel
  preselectedValue: Maybe<RioPassFailValue>
}

export type RioComponentPassFailInput = {
  componentId: Scalars['String']['input']
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  description: InputMaybe<RioLabelInput>
  failLabel: RioLabelInput
  label: RioLabelInput
  passFailValues: RioPassFailValuesInput
  passLabel: RioLabelInput
  preselectedValue: InputMaybe<RioPassFailValue>
}

export type RioComponentSeverity = RioComponent & {
  __typename?: 'RioComponentSeverity'
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  label: RioLabel
  severityOptions: Array<RioSeverityOptions>
}

export type RioComponentSeverityInput = {
  componentId: Scalars['String']['input']
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  label: RioLabelInput
  severityOptions: Array<RioSeverityOptions>
}

export type RioComponentSimple = RioComponent & {
  __typename?: 'RioComponentSimple'
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  label: RioLabel
}

export type RioComponentSimpleInput = {
  componentId: Scalars['String']['input']
  componentType: RioComponentType
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  label: RioLabelInput
}

export type RioComponentStaticValue = RioComponent & {
  __typename?: 'RioComponentStaticValue'
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  label: RioLabel
  staticValue: RioStaticValue
}

export type RioComponentStaticValueInput = {
  componentId: Scalars['String']['input']
  componentType: RioComponentType
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  label: RioLabelInput
  staticValue: RioStaticValue
}

export type RioComponentStatus = RioComponent & {
  __typename?: 'RioComponentStatus'
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  label: RioLabel
  statusOptions: Array<RioStatusOptions>
}

export type RioComponentStatusInput = {
  componentId: Scalars['String']['input']
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  label: RioLabelInput
  statusOptions: Array<RioStatusOptions>
}

export type RioComponentTextArea = RioComponent & {
  __typename?: 'RioComponentTextArea'
  caption: Maybe<RioLabel>
  componentId: Scalars['String']['output']
  componentType: RioComponentType
  dependingComponentIds: Maybe<Array<Scalars['String']['output']>>
  label: RioLabel
  placeholder: Maybe<RioLabel>
}

export type RioComponentTextAreaInput = {
  caption: InputMaybe<RioLabelInput>
  componentId: Scalars['String']['input']
  dependingComponentIds: InputMaybe<Array<Scalars['String']['input']>>
  label: RioLabelInput
  placeholder: InputMaybe<RioLabelInput>
}

export enum RioComponentType {
  Address = 'Address',
  Author = 'Author',
  CalculatedOutcome = 'CalculatedOutcome',
  Checkbox = 'Checkbox',
  CreationTime = 'CreationTime',
  Date = 'Date',
  DeleteAttachments = 'DeleteAttachments',
  DisruptionType = 'DisruptionType',
  Employee = 'Employee',
  EmployeeLastName = 'EmployeeLastName',
  EmployeeUuid = 'EmployeeUuid',
  HubUuid = 'HubUuid',
  PassFail = 'PassFail',
  ReportId = 'ReportId',
  ServiceArea = 'ServiceArea',
  ServiceAreaUuid = 'ServiceAreaUuid',
  Severity = 'Severity',
  StaticValue = 'StaticValue',
  Status = 'Status',
  Text = 'Text',
  TextArea = 'TextArea',
  Time = 'Time',
  Trip = 'Trip',
  UploadAttachments = 'UploadAttachments',
  Vehicle = 'Vehicle',
  VehicleMileage = 'VehicleMileage',
  VehicleType = 'VehicleType',
  VehicleUuid = 'VehicleUuid',
  VehicleVin = 'VehicleVin',
  ZendeskTicketNumber = 'ZendeskTicketNumber',
}

export type RioComponentsInput = {
  checkbox: InputMaybe<Array<RioComponentCheckboxInput>>
  date: InputMaybe<Array<RioComponentDateInput>>
  disruptionType: InputMaybe<RioComponentDisruptionTypeInput>
  passFail: InputMaybe<Array<RioComponentPassFailInput>>
  severity: InputMaybe<RioComponentSeverityInput>
  simple: InputMaybe<Array<RioComponentSimpleInput>>
  staticValue: InputMaybe<Array<RioComponentStaticValueInput>>
  status: InputMaybe<RioComponentStatusInput>
  textArea: InputMaybe<Array<RioComponentTextAreaInput>>
}

export type RioDateRange = {
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
}

export type RioDeleteReportInput = {
  reportId: Scalars['ID']['input']
  version: Scalars['Int']['input']
}

export type RioDeleteReportResponse = {
  __typename?: 'RioDeleteReportResponse'
  success: Scalars['Boolean']['output']
}

export enum RioDisruptionTypes {
  Customer = 'Customer',
  Vehicle = 'Vehicle',
}

export type RioLabel = {
  __typename?: 'RioLabel'
  de: Maybe<Scalars['String']['output']>
  en: Maybe<Scalars['String']['output']>
}

export type RioLabelInput = {
  de: InputMaybe<Scalars['String']['input']>
  en: InputMaybe<Scalars['String']['input']>
}

export enum RioLanguage {
  De = 'de',
  En = 'en',
}

export type RioLatestTemplateInput = {
  category: RioCategory
  name: Scalars['String']['input']
}

export type RioLatestTemplateResponse = {
  __typename?: 'RioLatestTemplateResponse'
  template: Maybe<RioTemplate>
}

export type RioListViewColumnMapping = {
  __typename?: 'RioListViewColumnMapping'
  columnType: RioListViewColumnType
  componentIdLink: Maybe<Array<Scalars['String']['output']>>
  componentIdValue: Maybe<Array<Scalars['String']['output']>>
  label: RioLabel
  reportAttribute: Maybe<Scalars['String']['output']>
}

export type RioListViewColumnMappingInput = {
  columnType: RioListViewColumnType
  componentIdLink: InputMaybe<Array<Scalars['String']['input']>>
  componentIdValue: InputMaybe<Array<Scalars['String']['input']>>
  label: RioLabelInput
  reportAttribute: InputMaybe<Scalars['String']['input']>
}

export enum RioListViewColumnType {
  Attachment = 'Attachment',
  Author = 'Author',
  DateTime = 'DateTime',
  Employee = 'Employee',
  ShortId = 'ShortId',
  Status = 'Status',
  Vehicle = 'Vehicle',
  ZendeskTicket = 'ZendeskTicket',
}

export type RioListViewConfig = {
  __typename?: 'RioListViewConfig'
  category: RioCategory
  columns: Array<RioListViewColumnMapping>
  tenant: Scalars['String']['output']
}

export enum RioPassFailValue {
  Fail = 'Fail',
  FailDataPrivacySigns = 'FailDataPrivacySigns',
  FailDocumentFolders = 'FailDocumentFolders',
  FailExterior = 'FailExterior',
  FailInterior = 'FailInterior',
  FailVehicleReadiness = 'FailVehicleReadiness',
  Pass = 'Pass',
  PassDataPrivacySigns = 'PassDataPrivacySigns',
  PassDocumentFolders = 'PassDocumentFolders',
  PassExterior = 'PassExterior',
  PassInterior = 'PassInterior',
  PassVehicleReadiness = 'PassVehicleReadiness',
}

export type RioPassFailValues = {
  __typename?: 'RioPassFailValues'
  fail: RioPassFailValue
  pass: RioPassFailValue
}

export type RioPassFailValuesInput = {
  fail: RioPassFailValue
  pass: RioPassFailValue
}

export type RioPhoto = {
  __typename?: 'RioPhoto'
  attachmentId: Scalars['String']['output']
  photoCategory: RioPhotoCategory
}

export enum RioPhotoCategory {
  AccidentSurroundings = 'AccidentSurroundings',
  OpponentVehicleDamage = 'OpponentVehicleDamage',
  PoliceCaseNumber = 'PoliceCaseNumber',
  VehicleDamage = 'VehicleDamage',
}

export type RioReport = {
  __typename?: 'RioReport'
  attachments: Maybe<Array<RioAttachment>>
  initialVersionCreatedAt: Scalars['DateTime']['output']
  initialVersionCreatedBy: RioUser
  reportId: Scalars['ID']['output']
  shortId: Scalars['ID']['output']
  template: RioTemplate
  values: Array<RioValue>
  version: Scalars['Int']['output']
  versionCreatedAt: Scalars['DateTime']['output']
  versionCreatedBy: RioUser
}

export type RioReportInput = {
  reportId: Scalars['ID']['input']
}

export type RioReportResponse = {
  __typename?: 'RioReportResponse'
  report: RioReport
}

export type RioReportsFilter = {
  componentType: RioComponentType
  values: Array<Scalars['String']['input']>
}

export type RioReportsInput = {
  category: RioCategory
  creationDateRange: InputMaybe<RioDateRange>
  filters: InputMaybe<Array<RioReportsFilter>>
  searchTerm: InputMaybe<Scalars['String']['input']>
  serviceAreaUuid: Scalars['String']['input']
}

export type RioReportsResponse = {
  __typename?: 'RioReportsResponse'
  listViewConfig: Maybe<RioListViewConfig>
  reports: Maybe<Array<RioReport>>
}

export type RioRule = {
  __typename?: 'RioRule'
  componentId: Maybe<Scalars['String']['output']>
  label: Maybe<RioLabel>
  otherComponentId: Maybe<Scalars['String']['output']>
  otherComponentValue: Maybe<Scalars['String']['output']>
  rule: RioRuleDefinition
  sectionId: Maybe<Scalars['String']['output']>
}

export enum RioRuleDefinition {
  Mandatory = 'Mandatory',
  MandatoryOnValue = 'MandatoryOnValue',
}

export type RioRuleInput = {
  componentId: InputMaybe<Scalars['String']['input']>
  label: InputMaybe<RioLabelInput>
  otherComponentId: InputMaybe<Scalars['String']['input']>
  otherComponentValue: InputMaybe<Scalars['String']['input']>
  rule: RioRuleDefinition
  sectionId: InputMaybe<Scalars['String']['input']>
}

export type RioSection = {
  __typename?: 'RioSection'
  componentIds: Array<Scalars['String']['output']>
  label: Maybe<RioLabel>
  sectionId: Scalars['String']['output']
  width: Maybe<RioSectionWidth>
}

export type RioSectionInput = {
  componentIds: Array<Scalars['String']['input']>
  label: RioLabelInput
  sectionId: Scalars['String']['input']
  width: InputMaybe<RioSectionWidth>
}

export enum RioSectionWidth {
  Full = 'Full',
  Half = 'Half',
}

export type RioSetReportStatusInput = {
  reportId: Scalars['ID']['input']
  status: RioStatusOptions
  version: Scalars['Int']['input']
}

export type RioSetReportStatusResponse = {
  __typename?: 'RioSetReportStatusResponse'
  reportId: Scalars['ID']['output']
  version: Scalars['Int']['output']
}

export enum RioSeverityOptions {
  Critical = 'Critical',
  Major = 'Major',
  Minor = 'Minor',
}

export enum RioStaticValue {
  Arrival = 'Arrival',
  Departure = 'Departure',
}

export enum RioStatusOptions {
  Blocked = 'Blocked',
  Closed = 'Closed',
  DriverPhotoUpload = 'DriverPhotoUpload',
  InProgress = 'InProgress',
  Intake = 'Intake',
  Open = 'Open',
  Pending = 'Pending',
}

export type RioStoreListViewConfigInput = {
  category: RioCategory
  columns: Array<RioListViewColumnMappingInput>
  tenant: Scalars['String']['input']
}

export type RioStoreListViewConfigResponse = {
  __typename?: 'RioStoreListViewConfigResponse'
  config: Maybe<RioListViewConfig>
}

export type RioStoreReportInput = {
  reportId: InputMaybe<Scalars['ID']['input']>
  shortId: InputMaybe<Scalars['ID']['input']>
  templateId: Scalars['ID']['input']
  values: Array<RioValueInput>
  version: InputMaybe<Scalars['Int']['input']>
}

export type RioStoreReportResponse = {
  __typename?: 'RioStoreReportResponse'
  reportId: Scalars['ID']['output']
  version: Scalars['Int']['output']
}

export type RioStoreTemplateInput = {
  category: RioCategory
  components: RioComponentsInput
  editReportTitle: InputMaybe<RioLabelInput>
  editSections: Array<RioSectionInput>
  immutable: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
  newReportTitle: InputMaybe<RioLabelInput>
  rules: InputMaybe<Array<RioRuleInput>>
  version: Scalars['Int']['input']
  viewReportTitle: InputMaybe<RioLabelInput>
  viewSections: Array<RioSectionInput>
}

export type RioStoreTemplateResponse = {
  __typename?: 'RioStoreTemplateResponse'
  template: Maybe<RioTemplate>
}

export type RioTempAttachmentDownloadUrlInput = {
  attachmentId: Scalars['String']['input']
  category: RioCategory
}

export type RioTempAttachmentDownloadUrlResponse = {
  __typename?: 'RioTempAttachmentDownloadUrlResponse'
  url: RioAttachmentDownloadUrl
}

export type RioTemplate = {
  __typename?: 'RioTemplate'
  category: RioCategory
  components: Array<RioComponent>
  editReportTitle: Maybe<RioLabel>
  editSections: Array<RioSection>
  id: Scalars['ID']['output']
  immutable: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
  newReportTitle: Maybe<RioLabel>
  rules: Maybe<Array<RioRule>>
  tenant: Scalars['String']['output']
  version: Scalars['Int']['output']
  viewReportTitle: Maybe<RioLabel>
  viewSections: Array<RioSection>
}

export type RioUser = {
  __typename?: 'RioUser'
  backofficeUser: Maybe<BackofficeUser>
  employee: Maybe<Employee>
}

export type RioValue = {
  __typename?: 'RioValue'
  componentId: Scalars['String']['output']
  value: Scalars['String']['output']
}

export type RioValueInput = {
  componentId: Scalars['String']['input']
  value: Scalars['String']['input']
}

export type RoadBlockage = {
  __typename?: 'RoadBlockage'
  created: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  isActive: Scalars['Boolean']['output']
  modified: Scalars['DateTime']['output']
  reason: RoadBlockageReason
  reportCount: Maybe<Scalars['Int']['output']>
  reportStatus: Maybe<RoadBlockageReportStatus>
  segments: Array<RoadSegment>
  status: RoadBlockageStatus
  validFrom: Maybe<Scalars['DateTime']['output']>
  validTo: Maybe<Scalars['DateTime']['output']>
}

export type RoadBlockageActivation = {
  __typename?: 'RoadBlockageActivation'
  id: Scalars['ID']['output']
  timeRange: TimeRange
}

export type RoadBlockageActivationInput = {
  roadBlockageId: Scalars['ID']['input']
  serviceAreaId: Scalars['ID']['input']
  timeRange: TimeRangeInput
}

export type RoadBlockageFilterInput = {
  reportStatus: InputMaybe<Array<SearchRoadBlockageReportStatus>>
  status: InputMaybe<Array<SearchRoadBlockageStatus>>
  type: InputMaybe<Array<SearchRoadBlockageType>>
}

export type RoadBlockageInput = {
  activationTimeRange: InputMaybe<TimeRangeInput>
  reason: RoadBlockageReason
  reportStatus: InputMaybe<RoadBlockageReportStatus>
  segments: Array<RoadSegmentInput>
}

export enum RoadBlockageReason {
  Event = 'Event',
  KeepOutZone = 'KeepOutZone',
  LongTermConstructionSite = 'LongTermConstructionSite',
  MoiaOnly = 'MOIAOnly',
  ReportedByDriver = 'ReportedByDriver',
  ShortTermConstructionSite = 'ShortTermConstructionSite',
  StructuralChange = 'StructuralChange',
}

export enum RoadBlockageReportStatus {
  Confirmed = 'Confirmed',
  Invalid = 'Invalid',
  Reported = 'Reported',
}

export enum RoadBlockageStatus {
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
}

export type RoadBlockageTaskNotification = TaskNotification & {
  __typename?: 'RoadBlockageTaskNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  roadBlockage: Maybe<RoadBlockage>
  serviceAreaId: Maybe<Scalars['String']['output']>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  version: Scalars['Int']['output']
}

export enum RoadCategory {
  Highway = 'HIGHWAY',
  LocalAccess = 'LOCAL_ACCESS',
  Main = 'MAIN',
  Residential = 'RESIDENTIAL',
  ResidentialMinor = 'RESIDENTIAL_MINOR',
}

export type RoadSegment = {
  __typename?: 'RoadSegment'
  direction: RoadSegmentDirection
  /** Contains a GeoJSON MultiLineString */
  geometry: Scalars['String']['output']
  hereSegmentId: Scalars['String']['output']
  /** Determines if the order of the segment's geometry is inverted */
  inverted: Maybe<Scalars['Boolean']['output']>
  name: Scalars['String']['output']
}

/** Direction of travel applicable for a segment going from the lower left node (lower latitude, or if equal then lower longitude, or if equal then lower z-level) towards the other node. */
export enum RoadSegmentDirection {
  Backward = 'BACKWARD',
  Both = 'BOTH',
  Forward = 'FORWARD',
}

export type RoadSegmentInput = {
  direction: RoadSegmentDirection
  /** Contains a GeoJSON MultiLineString */
  geometry: Scalars['String']['input']
  hereSegmentId: Scalars['String']['input']
  /** Determines if the order of the segment's geometry is inverted */
  inverted: InputMaybe<Scalars['Boolean']['input']>
  name: Scalars['String']['input']
}

export type RoadSegmentSuggestion = {
  __typename?: 'RoadSegmentSuggestion'
  /** Connections are only available in single segment suggestion queries and only one level deep */
  connections: Array<RoadSegmentSuggestion>
  direction: RoadSegmentDirection
  geometry: Scalars['String']['output']
  /** Determines if the order of the segment's geometry is inverted */
  inverted: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  segmentId: Scalars['String']['output']
}

export enum Role {
  Draft = 'DRAFT',
  Driver = 'DRIVER',
  FieldTester = 'FIELD_TESTER',
  VehicleService = 'VEHICLE_SERVICE',
}

export type Router = {
  __typename?: 'Router'
  macAddr: Scalars['String']['output']
  provisioningState: ProvisioningState
  serialNumber: Scalars['ID']['output']
}

export type RouterReplacedChange = FleetEntityChange & {
  __typename?: 'RouterReplacedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: RouterReplacedChangeFields
}

export type RouterReplacedChangeFields = VehicleChangeFields & {
  __typename?: 'RouterReplacedChangeFields'
  routerNew: Maybe<Router>
  routerOld: Maybe<Router>
  vehicleID: Scalars['ID']['output']
}

export enum SdsMode {
  Disengaged = 'DISENGAGED',
  Engaged = 'ENGAGED',
  Fault = 'FAULT',
  InCalibration = 'IN_CALIBRATION',
  NotAuthorized = 'NOT_AUTHORIZED',
  Transportation = 'TRANSPORTATION',
}

export type SdsModeWithTimestamp = {
  __typename?: 'SDSModeWithTimestamp'
  sdsMode: SdsMode
  timestamp: Scalars['DateTime']['output']
}

export enum SdsProvider {
  Mobileye = 'MOBILEYE',
  None = 'NONE',
  Simulated = 'SIMULATED',
}

export type ScheduleVehicleStateChangeInput = {
  changeReasonFreeText: InputMaybe<Scalars['String']['input']>
  /** Date and time when the vehicle is expected to be activated again, basically a downtime forecast. */
  expectedActivationAt: InputMaybe<Scalars['DateTime']['input']>
  scheduledAt: Scalars['DateTime']['input']
  vehicleID: Scalars['ID']['input']
  vehicleStateChangeReason: VehicleStateChangeReason
}

export type ScheduleVehicleStateChangeResponse = {
  __typename?: 'ScheduleVehicleStateChangeResponse'
  scheduledVehicleStateChange: ScheduledVehicleStateChange
}

export type ScheduleVehicleStateDeletionResponse = {
  __typename?: 'ScheduleVehicleStateDeletionResponse'
  id: Maybe<Scalars['ID']['output']>
  vehicleID: Scalars['ID']['output']
}

export type ScheduledVehicleStateChange = {
  __typename?: 'ScheduledVehicleStateChange'
  id: Scalars['ID']['output']
  nextState: VehicleState
  scheduledAt: Scalars['DateTime']['output']
  updatedAt: Scalars['DateTime']['output']
  vehicle: Vehicle
  vehicleStateChange: VehicleStateChange
}

export type ScheduledVehicleStateChangeDeleteInput = {
  /** The ID of of the scheduled state change */
  id: Scalars['ID']['input']
}

export enum SearchAreaStatus {
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
}

export enum SearchAreaType {
  Ad = 'AD',
  ConstructionSite = 'ConstructionSite',
  Cooperation = 'Cooperation',
  Event = 'Event',
  Funding = 'Funding',
  Hub = 'Hub',
  InFieldBreak = 'InFieldBreak',
  Other = 'Other',
  Scouting = 'Scouting',
  ShortBreak = 'ShortBreak',
}

export enum SearchDirection {
  Backward = 'Backward',
  Both = 'Both',
  Forward = 'Forward',
}

export type SearchInput = {
  areas: InputMaybe<AreaFilterInput>
  boundingBox: InputMaybe<BoundingBoxFilterInput>
  polygon: InputMaybe<Scalars['String']['input']>
  roadBlockages: InputMaybe<RoadBlockageFilterInput>
  searchTerm: InputMaybe<Scalars['String']['input']>
  stops: InputMaybe<StopFilterInput>
  turnRestrictions: InputMaybe<TurnRestrictionFilterInput>
}

export type SearchResultAreaItemV3 = SearchResultItemV3 & {
  __typename?: 'SearchResultAreaItemV3'
  areaType: Maybe<SearchAreaType>
  id: Scalars['ID']['output']
  keepOutZone: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  polygon: Shape
  status: SearchResultItemStatusV3
}

export enum SearchResultItemStatusV3 {
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
}

export type SearchResultItemV3 = {
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

export type SearchResultRoadBlockageItemV3 = SearchResultItemV3 & {
  __typename?: 'SearchResultRoadBlockageItemV3'
  created: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  reportCount: Scalars['Int']['output']
  reportStatus: Maybe<SearchRoadBlockageReportStatus>
  roadBlockageType: Maybe<SearchRoadBlockageType>
  segments: Array<SearchRoadSegment>
  status: SearchResultItemStatusV3
  validFrom: Maybe<Scalars['DateTime']['output']>
  validTo: Maybe<Scalars['DateTime']['output']>
}

export type SearchResultStopItemV3 = SearchResultItemV3 & {
  __typename?: 'SearchResultStopItemV3'
  active: Scalars['Boolean']['output']
  customerDisplayLocation: Location
  flags: Array<SearchStopFlag>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  stopType: Maybe<SearchStopType>
}

export type SearchResultTurnRestrictionItemV3 = SearchResultItemV3 & {
  __typename?: 'SearchResultTurnRestrictionItemV3'
  created: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  segments: Array<SearchRoadSegment>
  status: SearchResultItemStatusV3
  turnRestrictionType: Maybe<SearchTurnRestrictionType>
  validFrom: Maybe<Scalars['DateTime']['output']>
  validTo: Maybe<Scalars['DateTime']['output']>
}

export type SearchResultV3 = {
  __typename?: 'SearchResultV3'
  items: Array<Maybe<SearchResultItemV3>>
}

export enum SearchRoadBlockageReportStatus {
  Confirmed = 'Confirmed',
  Invalid = 'Invalid',
  Reported = 'Reported',
}

export enum SearchRoadBlockageStatus {
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
}

export enum SearchRoadBlockageType {
  Event = 'Event',
  LongTermConstructionSite = 'LongTermConstructionSite',
  MoiaOnly = 'MOIAOnly',
  ReportedByDriver = 'ReportedByDriver',
  ShortTermConstructionSite = 'ShortTermConstructionSite',
  StructuralChange = 'StructuralChange',
}

export type SearchRoadSegment = {
  __typename?: 'SearchRoadSegment'
  direction: Maybe<SearchDirection>
  geometry: Scalars['String']['output']
  hereSegmentId: Scalars['String']['output']
  name: Scalars['String']['output']
}

export enum SearchStopFlag {
  Ad = 'AD',
  HumanDriver = 'HumanDriver',
  HvvSwitchStop = 'HvvSwitchStop',
  Partnership = 'Partnership',
  PhysicalPromotion = 'PhysicalPromotion',
  RegularPassengers = 'RegularPassengers',
  WheelchairAccessible = 'WheelchairAccessible',
}

export enum SearchStopStatus {
  Active = 'Active',
  Inactive = 'Inactive',
}

export enum SearchStopType {
  Hub = 'Hub',
  InFieldBreak = 'InFieldBreak',
  Passenger = 'Passenger',
  PublicTransportation = 'PublicTransportation',
  ShortBreak = 'ShortBreak',
}

export enum SearchTurnRestrictionStatus {
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
}

export enum SearchTurnRestrictionType {
  Event = 'Event',
  LongTermConstructionSite = 'LongTermConstructionSite',
  MoiaOnly = 'MOIAOnly',
  ShortTermConstructionSite = 'ShortTermConstructionSite',
  StructuralChange = 'StructuralChange',
}

export type Seat = {
  __typename?: 'Seat'
  occupancy: SeatOccupancy
  seatbelt: Seatbelt
  seatbeltWarning: Scalars['Boolean']['output']
}

export type SeatInfo = {
  __typename?: 'SeatInfo'
  childSeats: Maybe<Scalars['Int']['output']>
  seats: Maybe<Scalars['Int']['output']>
  wheelchairs: Maybe<Scalars['Int']['output']>
}

export type SeatInput = {
  occupancy: InputMaybe<SeatOccupancy>
  seatbelt: InputMaybe<Seatbelt>
  seatbeltWarning: InputMaybe<Scalars['Boolean']['input']>
}

export enum SeatOccupancy {
  Empty = 'EMPTY',
  NotInstalled = 'NOT_INSTALLED',
  Occupied = 'OCCUPIED',
}

export enum Seatbelt {
  Fastened = 'FASTENED',
  NotAvailable = 'NOT_AVAILABLE',
  NotFastened = 'NOT_FASTENED',
  NotInstalled = 'NOT_INSTALLED',
}

export type Seats = {
  __typename?: 'Seats'
  cockpitLeft: Seat
  cockpitRight: Seat
  secondRowLeft: Seat
  secondRowMiddle: Seat
  secondRowRight: Seat
  thirdRowLeft: Seat
  thirdRowMiddle: Seat
  thirdRowRight: Seat
}

export type SeatsInput = {
  cockpitLeft: InputMaybe<SeatInput>
  cockpitRight: InputMaybe<SeatInput>
  secondRowLeft: InputMaybe<SeatInput>
  secondRowMiddle: InputMaybe<SeatInput>
  secondRowRight: InputMaybe<SeatInput>
  thirdRowLeft: InputMaybe<SeatInput>
  thirdRowMiddle: InputMaybe<SeatInput>
  thirdRowRight: InputMaybe<SeatInput>
}

export type SendEmployeeMessageInput = {
  acknowledgementRequired: InputMaybe<Scalars['Boolean']['input']>
  body: Scalars['String']['input']
  category: InputMaybe<EmployeeMessageCategory>
  employeeID: Scalars['ID']['input']
  /** If set, message can be only be read by driver, supervisor DM or hub manager */
  privacySensitive: InputMaybe<Scalars['Boolean']['input']>
  sendCopyAsMail: Scalars['Boolean']['input']
  sendCopyAsSms: Scalars['Boolean']['input']
  subject: InputMaybe<Scalars['String']['input']>
  url: InputMaybe<Scalars['String']['input']>
}

export type SendEmployeeMessageResponse = {
  __typename?: 'SendEmployeeMessageResponse'
  employeeMessage: EmployeeMessage
}

export type SendMassMessageInput = {
  acknowledgementRequired: InputMaybe<Scalars['Boolean']['input']>
  blocked: InputMaybe<Scalars['Boolean']['input']>
  body: Scalars['String']['input']
  category: InputMaybe<EmployeeMessageCategory>
  fulltext: InputMaybe<Scalars['String']['input']>
  /** additionally filter by hub */
  hubId: InputMaybe<Scalars['ID']['input']>
  /** additionally filter by roles */
  roles: Array<Role>
  sdoQualification: InputMaybe<Scalars['Boolean']['input']>
  sendCopyAsMail: Scalars['Boolean']['input']
  sendCopyAsSms: Scalars['Boolean']['input']
  serviceAreaId: Scalars['ID']['input']
  subject: InputMaybe<Scalars['String']['input']>
  supervisorUserId: InputMaybe<Scalars['ID']['input']>
  url: InputMaybe<Scalars['String']['input']>
  wavQualification: InputMaybe<Scalars['Boolean']['input']>
}

export type SendMassMessageResponse = {
  __typename?: 'SendMassMessageResponse'
  enqueuedMessages: Scalars['Int']['output']
}

export type SendVehicleToHubLogRecord = ShiftStateLogRecord & {
  __typename?: 'SendVehicleToHubLogRecord'
  area: Maybe<Area>
  eventTime: Scalars['DateTime']['output']
  expectedTimeAtHub: Maybe<Scalars['DateTime']['output']>
  returnReason: SendVehicleToHubReturnReason
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
  vehicle: Maybe<Vehicle>
}

export enum SendVehicleToHubReturnReason {
  Break = 'BREAK',
  OnDemand = 'ON_DEMAND',
  ShiftEnd = 'SHIFT_END',
  ShortBreak = 'SHORT_BREAK',
}

export type ServiceArea = {
  __typename?: 'ServiceArea'
  /** Experimental field: Please don't rely on this to be present in the future */
  autonomousVehiclesOnly: Scalars['Boolean']['output']
  childAge: ChildAge
  created: Scalars['DateTime']['output']
  currency: CurrencyCode
  customerTenants: Array<Scalars['String']['output']>
  defaultLanguage: LanguageCode
  displayNames: Scalars['DisplayNames']['output']
  id: Scalars['String']['output']
  locationAttributes: LocationAttributes
  modified: Scalars['DateTime']['output']
  modifiedBy: Scalars['String']['output']
  operatorTenants: Array<Scalars['String']['output']>
  ridepoolingServiceTenant: Scalars['String']['output']
  /**
   * Deprecated field: Please don't rely on this to be present in the future
   * @deprecated ServiceParameters is deprecated and will be removed in a future version.
   */
  serviceParameters: Maybe<ServiceParameters>
  tenants: Array<Scalars['String']['output']>
  useCaseType: UseCaseType
  uuid: Scalars['UUID']['output']
  validFrom: Scalars['DateTime']['output']
  vehicleOperationMode: VehicleOperationMode
}

export type ServiceAreaCreationInput = {
  childAge: ChildAgeInput
  currency: CurrencyCode
  customerTenants: InputMaybe<Array<Scalars['String']['input']>>
  defaultLanguage: LanguageCode
  displayNames: Scalars['DisplayNames']['input']
  idSuffix: InputMaybe<Scalars['String']['input']>
  locationAttributes: LocationAttributesCreationInput
  operatorTenants: InputMaybe<Array<Scalars['String']['input']>>
  referenceId: InputMaybe<Scalars['String']['input']>
  ridepoolingServiceTenant: InputMaybe<Scalars['String']['input']>
  /**
   * Deprecated field: Please don't rely on this to be present in the future
   * @deprecated ServiceParameters is deprecated and will be removed in a future version.
   */
  serviceParameters: InputMaybe<ServiceParametersInput>
  useCaseType: UseCaseType
  validFrom: Scalars['DateTime']['input']
}

export type ServiceAreaUpdateInput = {
  childAge: ChildAgeInput
  customerTenants: InputMaybe<Array<Scalars['String']['input']>>
  displayNames: Scalars['DisplayNames']['input']
  locationAttributes: LocationAttributesUpdateInput
  operatorTenants: InputMaybe<Array<Scalars['String']['input']>>
  ridepoolingServiceTenant: InputMaybe<Scalars['String']['input']>
  /**
   * Deprecated field: Please don't rely on this to be present in the future
   * @deprecated ServiceParameters is deprecated and will be removed in a future version.
   */
  serviceParameters: InputMaybe<ServiceParametersInput>
  validFrom: Scalars['DateTime']['input']
}

export type ServiceClassParameters = {
  __typename?: 'ServiceClassParameters'
  maxPrice: Money
  maxPricePercentageOfTaxiPrice: Scalars['Float']['output']
  minPrice: Maybe<Money>
  offerPriceDiscount: Maybe<Scalars['Float']['output']>
  prebookingSurcharge: Surcharge
}

export type ServiceClassParametersInput = {
  maxPrice: MoneyInput
  maxPricePercentageOfTaxiPrice: Scalars['Float']['input']
  minPrice: InputMaybe<MoneyInput>
  offerPriceDiscount: InputMaybe<Scalars['Float']['input']>
  prebookingSurcharge: SurchargeInput
}

/** Deprecated field: Please don't rely on this to be present in the future */
export type ServiceParameters = {
  __typename?: 'ServiceParameters'
  childSeats: Scalars['Int']['output']
  maxSeats: Scalars['Int']['output']
  wheelchairSeats: Scalars['Int']['output']
}

/** Deprecated field: Please don't rely on this to be present in the future */
export type ServiceParametersInput = {
  childSeats: Scalars['Int']['input']
  maxSeats: Scalars['Int']['input']
  wheelchairSeats: Scalars['Int']['input']
}

/** The accessibility flag for severely disabled customers */
export type SeverelyDisabled = {
  __typename?: 'SeverelyDisabled'
  /** Eligibility for another people */
  eligibility: Maybe<Eligibility>
  /** Validity of the severely disabled medical ID */
  validUntil: Maybe<Scalars['OffsetDateTime']['output']>
}

export enum Severity {
  Disrupted = 'DISRUPTED',
  Down = 'DOWN',
  Notice = 'NOTICE',
}

export type Shape = Geometry & {
  __typename?: 'Shape'
  geoJson: Scalars['String']['output']
  points: Array<Location>
}

export type Shift = {
  __typename?: 'Shift'
  breaks: Array<ShiftBreak>
  driver: Employee
  employeeAbsenceKey: Maybe<EmployeeAbsenceKey>
  fixedSetupTimes: ShiftFixedSetupTimes
  hub: Maybe<Hub>
  id: Scalars['ID']['output']
  /** Time this driver is expected to check-in for the shift start (includes fixed setup time for productive time) */
  plannedCheckIn: Scalars['DateTime']['output']
  /** Time this driver is expected to check-out the for shift end (includes fixed setup time for productive time) */
  plannedCheckOut: Scalars['DateTime']['output']
  /** Time this driver is expected to depart for the first drive */
  plannedFirstDeparture: Scalars['DateTime']['output']
  /** Time this driver is expected to arrive for the end of shift */
  plannedLastArrival: Scalars['DateTime']['output']
  /** returns the planned net working time for this shift (excluding planned break duration, excluding fixed setup time) */
  plannedNetWorkingTime: Scalars['Duration']['output']
  /** returns the planned net working time for this shift (excluding planned break duration, including fixed setup time) */
  plannedProductiveTime: Maybe<Scalars['Duration']['output']>
  shiftType: ShiftType
  shiftTypeOverrideDetails: Maybe<ShiftTypeOverrideDetails>
  state: ShiftState
  timeLogModel: ShiftTimeLogModel
}

export type ShiftApplyEmailInput = {
  body: Scalars['String']['input']
  subject: Scalars['String']['input']
}

export type ShiftApplyEmailResponse = {
  __typename?: 'ShiftApplyEmailResponse'
  send: Scalars['Boolean']['output']
}

export type ShiftBreak = {
  __typename?: 'ShiftBreak'
  duration: Scalars['Duration']['output']
  earliestStart: Scalars['DateTime']['output']
  latestEnd: Scalars['DateTime']['output']
  latestStart: Scalars['DateTime']['output']
}

export type ShiftCapacityPlanning = {
  __typename?: 'ShiftCapacityPlanning'
  createdAt: Scalars['DateTime']['output']
  createdBy: Maybe<BackofficeUser>
  /** The status of the execution of the capacity planning job in the backend */
  executionStatus: ShiftCapacityPlanningExecutionStatus
  /**
   * Indicates whether this capacity planning job has finished
   * @deprecated Use executionStatus instead, replace with executionStatus
   */
  finished: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  month: Scalars['YearMonth']['output']
  name: Scalars['String']['output']
  /** @deprecated Use rmpIteration instead, replace with rmpIteration */
  rmpFilename: Scalars['String']['output']
  rmpIteration: Scalars['YearMonth']['output']
  serviceArea: Maybe<ServiceArea>
  shiftHours: Scalars['Int']['output']
  /** A user defined status of the capacity planning */
  status: ShiftCapacityPlanningStatus
}

export type ShiftCapacityPlanningCreateInput = {
  month: Scalars['YearMonth']['input']
  name: Scalars['String']['input']
  rmpFilename: Scalars['String']['input']
  serviceAreaId: Scalars['ID']['input']
  shiftHours: Scalars['Int']['input']
}

export enum ShiftCapacityPlanningExecutionStatus {
  Failed = 'FAILED',
  Pending = 'PENDING',
  Running = 'RUNNING',
  Succeeded = 'SUCCEEDED',
}

export type ShiftCapacityPlanningFile = {
  __typename?: 'ShiftCapacityPlanningFile'
  mimeType: Scalars['String']['output']
  name: Scalars['String']['output']
  /** The size of the file in bytes */
  size: Scalars['Int']['output']
  url: Scalars['String']['output']
}

export type ShiftCapacityPlanningRmpFile = {
  __typename?: 'ShiftCapacityPlanningRmpFile'
  fileName: Scalars['String']['output']
  lastModified: Scalars['DateTime']['output']
  /** The minimum year and month this RMP file can create a capacity planning for */
  minimumPlannableMonth: Scalars['YearMonth']['output']
  /** The year and month of the RMP iteration for this file */
  rmpIteration: Scalars['YearMonth']['output']
}

export type ShiftCapacityPlanningRmpFilesInput = {
  serviceAreaId: Scalars['ID']['input']
}

export enum ShiftCapacityPlanningStatus {
  Draft = 'DRAFT',
  Final = 'FINAL',
}

export type ShiftCapacityPlanningUpdateStatusInput = {
  id: Scalars['ID']['input']
  newStatus: ShiftCapacityPlanningStatus
}

export type ShiftCapacityPlanningsInput = {
  serviceAreaId: Scalars['ID']['input']
}

export type ShiftChangedLogRecord = ShiftStateLogRecord & {
  __typename?: 'ShiftChangedLogRecord'
  eventTime: Scalars['DateTime']['output']
  logRecordNewShiftType: LogRecordShiftType
  logRecordOldShiftType: LogRecordShiftType
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
}

export type ShiftComment = {
  __typename?: 'ShiftComment'
  author: Maybe<Scalars['String']['output']>
  backofficeUser: Maybe<BackofficeUser>
  comment: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ShiftCommentInput = {
  comment: Scalars['String']['input']
  shiftId: Scalars['ID']['input']
}

export type ShiftConfirmTaskNotificationInput = {
  foreignId: Scalars['ID']['input']
}

export type ShiftConfirmTaskNotificationResponse = {
  __typename?: 'ShiftConfirmTaskNotificationResponse'
  confirmed: Scalars['Boolean']['output']
  foreignId: Scalars['ID']['output']
}

export type ShiftDeletePlannedHubReturnSectionInput = {
  section: ShiftPlannedHubReturnSectionCategory
  shiftId: Scalars['ID']['input']
}

export type ShiftDeletePlannedHubReturnSectionResponse = {
  __typename?: 'ShiftDeletePlannedHubReturnSectionResponse'
  planned: Array<ShiftPlannedHubReturnSection>
}

export type ShiftDeviationNotification = TaskNotification & {
  __typename?: 'ShiftDeviationNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  shift: Maybe<Shift>
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  version: Scalars['Int']['output']
}

export type ShiftEmployeeStatus = {
  __typename?: 'ShiftEmployeeStatus'
  type: ShiftEmployeeStatusType
  updatedAt: Scalars['DateTime']['output']
  /** @deprecated Use updatedByUser instead */
  updatedBy: Maybe<Scalars['String']['output']>
  updatedByUser: Maybe<BackofficeUser>
}

export type ShiftEmployeeStatusInput = {
  shiftId: Scalars['ID']['input']
  type: InputMaybe<ShiftEmployeeStatusType>
}

export enum ShiftEmployeeStatusType {
  Event = 'EVENT',
  NoShow = 'NO_SHOW',
  SickLeave = 'SICK_LEAVE',
  SickWithoutNote = 'SICK_WITHOUT_NOTE',
  SickWithNote = 'SICK_WITH_NOTE',
  SpecialTime = 'SPECIAL_TIME',
  Vacation = 'VACATION',
  WorkingAtHub = 'WORKING_AT_HUB',
}

export type ShiftFixedSetupTimes = {
  __typename?: 'ShiftFixedSetupTimes'
  /** Duration of after-shift activities (cleaning car, etc.) */
  afterShift: Scalars['Duration']['output']
  /** Setup duration before shift starts */
  beforeShift: Scalars['Duration']['output']
  /** Before break setup duration (e.g. finding a parking spot) */
  breakTotal: Scalars['Duration']['output']
}

export type ShiftLostAndFoundEmailInput = {
  body: Scalars['String']['input']
  subject: Scalars['String']['input']
}

export type ShiftLostAndFoundResponse = {
  __typename?: 'ShiftLostAndFoundResponse'
  send: Scalars['Boolean']['output']
}

export type ShiftOverrideScheduledHubReturnInput = {
  shiftId: Scalars['ID']['input']
}

export type ShiftOverrideTypeInput = {
  shiftId: Scalars['ID']['input']
  shiftType: ShiftType
}

export type ShiftOverrideTypeResponse = {
  __typename?: 'ShiftOverrideTypeResponse'
  shift: Shift
}

export type ShiftPlannedHubReturn = {
  __typename?: 'ShiftPlannedHubReturn'
  hubReturnReason: ShiftPlannedHubReturnReason
  plannedArea: Maybe<Area>
  plannedReturnTime: Scalars['DateTime']['output']
  providedReason: Maybe<ShiftPlannedHubReturnProvidedReason>
}

export type ShiftPlannedHubReturnArrivals = {
  __typename?: 'ShiftPlannedHubReturnArrivals'
  sectionBreakArrivalAt: Maybe<Scalars['DateTime']['output']>
  sectionFirstArrivalAt: Maybe<Scalars['DateTime']['output']>
  sectionSecondArrivalAt: Maybe<Scalars['DateTime']['output']>
  sectionShiftEndArrivalAt: Maybe<Scalars['DateTime']['output']>
}

export enum ShiftPlannedHubReturnProvidedReason {
  AccompanyingDrive = 'ACCOMPANYING_DRIVE',
  EmployeeTalk = 'EMPLOYEE_TALK',
  Event = 'EVENT',
  FixedTimeBreak = 'FIXED_TIME_BREAK',
  HubService = 'HUB_SERVICE',
  OtherReason = 'OTHER_REASON',
  Training = 'TRAINING',
  VehicleMaintenance = 'VEHICLE_MAINTENANCE',
  VehicleSwap = 'VEHICLE_SWAP',
  WorkersCouncil = 'WORKERS_COUNCIL',
}

export enum ShiftPlannedHubReturnReason {
  Break = 'BREAK',
  ShiftEnd = 'SHIFT_END',
}

export type ShiftPlannedHubReturnSection = {
  __typename?: 'ShiftPlannedHubReturnSection'
  plannedArea: Maybe<Area>
  plannedReturnTime: Scalars['DateTime']['output']
  providedReason: Maybe<ShiftPlannedHubReturnProvidedReason>
  section: ShiftPlannedHubReturnSectionCategory
}

export enum ShiftPlannedHubReturnSectionCategory {
  SectionBreak = 'SECTION_BREAK',
  SectionFirst = 'SECTION_FIRST',
  SectionSecond = 'SECTION_SECOND',
  SectionShiftEnd = 'SECTION_SHIFT_END',
}

export type ShiftPlannedHubReturnSectionOption = {
  __typename?: 'ShiftPlannedHubReturnSectionOption'
  /** contains only areas of type hub */
  availableAreas: Array<Area>
  availableBreakLocations: Array<Area>
  availableStartTimes: Array<Scalars['DateTime']['output']>
  earliestSectionStart: Maybe<Scalars['DateTime']['output']>
  latestSectionEnd: Maybe<Scalars['DateTime']['output']>
  plannedArea: Maybe<Area>
  plannedReturnTime: Maybe<Scalars['DateTime']['output']>
  providedReason: Maybe<ShiftPlannedHubReturnProvidedReason>
  section: ShiftPlannedHubReturnSectionCategory
}

export type ShiftPlannedHubReturnSectionOptionsResponse = {
  __typename?: 'ShiftPlannedHubReturnSectionOptionsResponse'
  comment: Maybe<ShiftComment>
  options: Array<ShiftPlannedHubReturnSectionOption>
}

export type ShiftPlannedHubReturnSections = {
  __typename?: 'ShiftPlannedHubReturnSections'
  arrivals: ShiftPlannedHubReturnArrivals
  deletePossibleFor: Array<ShiftPlannedHubReturnSectionCategory>
  planned: Array<ShiftPlannedHubReturnSection>
  planningPossibleFor: Array<ShiftPlannedHubReturnSectionCategory>
}

export type ShiftPlannedHubReturnUtilizationInput = {
  areaId: InputMaybe<Scalars['String']['input']>
  selectedTimeslot: Scalars['DateTime']['input']
  shiftId: Scalars['String']['input']
}

export type ShiftPlannedHubReturnUtilizationResponse = {
  __typename?: 'ShiftPlannedHubReturnUtilizationResponse'
  areasUtilization: Array<AreaUtilization>
}

export type ShiftPlannedHubReturns = {
  __typename?: 'ShiftPlannedHubReturns'
  planned: Array<ShiftPlannedHubReturn>
  planningPossibleFor: Array<ShiftPlannedHubReturnReason>
}

export type ShiftScheduledHubReturn = {
  __typename?: 'ShiftScheduledHubReturn'
  id: Scalars['ID']['output']
  overriddenAt: Maybe<Scalars['DateTime']['output']>
  overriddenBy: Maybe<Scalars['String']['output']>
  overriddenByUser: Maybe<BackofficeUser>
  returnReason: ShiftScheduledHubReturnReason
  source: ShiftScheduledHubReturnSource
}

export enum ShiftScheduledHubReturnReason {
  PlannedBreak = 'PLANNED_BREAK',
  SectionFirst = 'SECTION_FIRST',
  SectionSecond = 'SECTION_SECOND',
  ShiftEnd = 'SHIFT_END',
}

export enum ShiftScheduledHubReturnSource {
  Override = 'OVERRIDE',
  System = 'SYSTEM',
}

export type ShiftSetPlannedHubReturnSectionInput = {
  comment: InputMaybe<Scalars['String']['input']>
  plannedAreaId: Scalars['ID']['input']
  plannedReturnTime: Scalars['DateTime']['input']
  providedReason: InputMaybe<ShiftPlannedHubReturnProvidedReason>
  section: ShiftPlannedHubReturnSectionCategory
  shiftId: Scalars['ID']['input']
}

export type ShiftSetPlannedHubReturnSectionResponse = {
  __typename?: 'ShiftSetPlannedHubReturnSectionResponse'
  planned: Array<ShiftPlannedHubReturnSection>
  shiftComment: Maybe<ShiftComment>
}

export type ShiftState = {
  __typename?: 'ShiftState'
  break: ShiftStateBreak
  /** true if driver was checked out by operator/driver manager */
  checkedOutByOperator: Scalars['Boolean']['output']
  comment: Maybe<ShiftComment>
  employeeStatus: Maybe<ShiftEmployeeStatus>
  /** returns the current execution state (upcoming, active, missed, done). Null represents an unknown state */
  executionState: Maybe<ExecutionState>
  firstDepartureAt: Maybe<Scalars['DateTime']['output']>
  firstHubCheckIn: Maybe<Scalars['DateTime']['output']>
  lastArrivalAt: Maybe<Scalars['DateTime']['output']>
  lastHubCheckOut: Maybe<Scalars['DateTime']['output']>
  plannedHubReturnSections: ShiftPlannedHubReturnSections
  plannedHubReturns: ShiftPlannedHubReturns
  productiveEnd: Maybe<Scalars['DateTime']['output']>
  productiveStart: Maybe<Scalars['DateTime']['output']>
  scheduledHubReturn: Maybe<ShiftScheduledHubReturn>
  scheduledHubReturnForBreak: Maybe<Scalars['DateTime']['output']>
  shiftId: Scalars['ID']['output']
  shortBreaks: ShiftStateShortBreaks
  vehicle: Maybe<Vehicle>
  visibleHubReturn: Maybe<ShiftVisibleHubReturn>
  workingTimes: ShiftStateWorkingTime
}

export type ShiftStateBreak = {
  __typename?: 'ShiftStateBreak'
  duration: Maybe<Scalars['Duration']['output']>
  endedAt: Maybe<Scalars['DateTime']['output']>
  startedAt: Maybe<Scalars['DateTime']['output']>
}

export type ShiftStateBreakLogRecord = ShiftStateLogRecord & {
  __typename?: 'ShiftStateBreakLogRecord'
  duration: Scalars['Int']['output']
  earliestStart: Scalars['DateTime']['output']
  eventTime: Scalars['DateTime']['output']
  latestStart: Scalars['DateTime']['output']
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
}

export type ShiftStateHubLogRecord = ShiftStateLogRecord & {
  __typename?: 'ShiftStateHubLogRecord'
  createdBy: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  hub: Maybe<Hub>
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
}

export type ShiftStateLogRecord = {
  eventTime: Scalars['DateTime']['output']
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
}

export enum ShiftStateLogRecordType {
  AreaArrival = 'AREA_ARRIVAL',
  AreaDeparture = 'AREA_DEPARTURE',
  BreakStarted = 'BREAK_STARTED',
  HubCheckedIn = 'HUB_CHECKED_IN',
  HubCheckedOut = 'HUB_CHECKED_OUT',
  PoolingStateChanged = 'POOLING_STATE_CHANGED',
  SendVehicleToHub = 'SEND_VEHICLE_TO_HUB',
  ShiftChanged = 'SHIFT_CHANGED',
  ShortBreakStarted = 'SHORT_BREAK_STARTED',
  VehicleClosed = 'VEHICLE_CLOSED',
  VehicleOpened = 'VEHICLE_OPENED',
}

export type ShiftStateShortBreakLogRecord = ShiftStateLogRecord & {
  __typename?: 'ShiftStateShortBreakLogRecord'
  eventTime: Scalars['DateTime']['output']
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
}

export type ShiftStateShortBreakTaken = {
  __typename?: 'ShiftStateShortBreakTaken'
  duration: Scalars['Duration']['output']
  end: Scalars['DateTime']['output']
  start: Scalars['DateTime']['output']
}

export type ShiftStateShortBreaks = {
  __typename?: 'ShiftStateShortBreaks'
  /** Starting time of the currently running short breaks (or null if not in break) */
  inShortBreakSince: Maybe<Scalars['DateTime']['output']>
  /** History of executed short breaks */
  shortBreaksTaken: Array<ShiftStateShortBreakTaken>
}

export type ShiftStateVehicleLogRecord = ShiftStateLogRecord & {
  __typename?: 'ShiftStateVehicleLogRecord'
  createdBy: Scalars['String']['output']
  eventTime: Scalars['DateTime']['output']
  shiftId: Maybe<Scalars['ID']['output']>
  type: ShiftStateLogRecordType
  vehicle: Vehicle
}

export type ShiftStateWorkingTime = {
  __typename?: 'ShiftStateWorkingTime'
  currentlyProductive: Scalars['Boolean']['output']
  netWorkingTime: Maybe<Scalars['Duration']['output']>
  productiveTime: Maybe<Scalars['Duration']['output']>
}

export enum ShiftTimeLogModel {
  /** Hub check-in/check-out based work time calculation */
  PresenceTime = 'PRESENCE_TIME',
  /** Geofenced arrival/departure based work time calculation */
  ProductiveTime = 'PRODUCTIVE_TIME',
}

export enum ShiftType {
  NonPooling = 'NON_POOLING',
  PlutoPooling = 'PLUTO_POOLING',
  SafetyDriverOperation = 'SAFETY_DRIVER_OPERATION',
  WavPooling = 'WAV_POOLING',
}

export type ShiftTypeOverrideDetails = {
  __typename?: 'ShiftTypeOverrideDetails'
  /** New shift type value after override or `null` if no override is active */
  newShiftType: Maybe<ShiftType>
  /** Original shift type value before override */
  originalShiftType: ShiftType
  overriddenAt: Scalars['DateTime']['output']
  overriddenBy: Maybe<BackofficeUser>
}

export type ShiftVisibleHubReturn = {
  __typename?: 'ShiftVisibleHubReturn'
  area: Maybe<Area>
  expectedArrival: Scalars['DateTime']['output']
  reason: SendVehicleToHubReturnReason
}

export type ShiftsActivitiesFiltersInput = {
  hubId: InputMaybe<Scalars['ID']['input']>
  statuses: InputMaybe<Array<ShiftsActivityStatus>>
}

export type ShiftsActivity = {
  __typename?: 'ShiftsActivity'
  createdAt: Scalars['DateTime']['output']
  createdBy: Maybe<BackofficeUser>
  end: Scalars['DateTime']['output']
  hub: Maybe<Hub>
  id: Scalars['ID']['output']
  lastModifiedAt: Maybe<Scalars['DateTime']['output']>
  lastModifiedBy: Maybe<BackofficeUser>
  name: Scalars['String']['output']
  note: Maybe<Scalars['String']['output']>
  numberOfDrivers: Scalars['Int']['output']
  numberOfVehicles: Maybe<Scalars['Int']['output']>
  start: Scalars['DateTime']['output']
  status: ShiftsActivityStatus
  /** Indicates whether the supply was already reduced by changing existing shifts to non-pooling */
  supplyReducedByNonPoolingShifts: Scalars['Boolean']['output']
}

export enum ShiftsActivityStatus {
  Draft = 'DRAFT',
  Finished = 'FINISHED',
  Ongoing = 'ONGOING',
  Planned = 'PLANNED',
}

export type ShiftsCreateActivityInput = {
  draft: InputMaybe<Scalars['Boolean']['input']>
  end: Scalars['DateTime']['input']
  hubId: InputMaybe<Scalars['ID']['input']>
  name: Scalars['String']['input']
  note: InputMaybe<Scalars['String']['input']>
  numberOfDrivers: Scalars['Int']['input']
  numberOfVehicles: InputMaybe<Scalars['Int']['input']>
  serviceAreaId: Scalars['ID']['input']
  start: Scalars['DateTime']['input']
  supplyReducedByNonPoolingShifts: InputMaybe<Scalars['Boolean']['input']>
}

export type ShiftsDemandSupplyData = {
  __typename?: 'ShiftsDemandSupplyData'
  demandInShifts: Scalars['Float']['output']
  inServiceHours: Scalars['Boolean']['output']
  supplyInShifts: Scalars['Float']['output']
  timestamp: Scalars['DateTime']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ShiftsFiltersInput = {
  /** if not null or empty, only shifts planned for the given hubs are returned (default: all hubs allowed) */
  filterHubs: InputMaybe<Array<Scalars['String']['input']>>
  /** if enabled, include shifts that have started before but end within the specified range (default: true) */
  includeAlreadyStartedShifts: InputMaybe<Scalars['Boolean']['input']>
  /** if set, only shifts by employees supervised by given user id are selected (default: null) */
  supervisorUserId: InputMaybe<Scalars['ID']['input']>
}

export type ShiftsSupplyData = {
  __typename?: 'ShiftsSupplyData'
  latestUpdate: Scalars['DateTime']['output']
  nonPoolingShifts: Scalars['Int']['output']
  supplyInPlutoShifts: Scalars['Int']['output']
  supplyInSdoShifts: Scalars['Int']['output']
  supplyInWavShifts: Scalars['Int']['output']
  timestamp: Scalars['DateTime']['output']
}

export type ShiftsUpdateActivityInput = {
  draft: InputMaybe<Scalars['Boolean']['input']>
  end: Scalars['DateTime']['input']
  hubId: InputMaybe<Scalars['ID']['input']>
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  note: InputMaybe<Scalars['String']['input']>
  numberOfDrivers: Scalars['Int']['input']
  numberOfVehicles: InputMaybe<Scalars['Int']['input']>
  start: Scalars['DateTime']['input']
  supplyReducedByNonPoolingShifts: InputMaybe<Scalars['Boolean']['input']>
}

export type ShortBreakDetails = {
  __typename?: 'ShortBreakDetails'
  stop: Maybe<NavigationStop>
}

export type SimulateDoorActuationResponseInput = {
  /** The error added to the Cabin Safety event. No error signals a successful response. */
  error: InputMaybe<TestDoorActuationError>
  transactionId: Scalars['ID']['input']
  vin: Scalars['ID']['input']
}

export type SimulateEventInput = {
  transactionId: InputMaybe<Scalars['ID']['input']>
  vin: Scalars['ID']['input']
}

export type SimulatedAdVehicle = {
  __typename?: 'SimulatedADVehicle'
  centralLockState: CentralLockState
  /** Connection state between vehicle and mqtt broker */
  connectedState: Scalars['Boolean']['output']
  /** Current vehicle power consumption in watts per second */
  consumedEnergy: Scalars['Float']['output']
  deviceName: Maybe<Scalars['String']['output']>
  /** true if there is any door open */
  doorsOpen: Scalars['Boolean']['output']
  exteriorAirTemperature: Scalars['Float']['output']
  /** Heading of the vehicle in degrees clock-wise, 0 is north */
  heading: Scalars['Float']['output']
  highVoltBatteryPercentage: Scalars['Percentage']['output']
  id: Scalars['ID']['output']
  ignitionState: IgnitionState
  interiorTemperature: Scalars['Float']['output']
  /** 0%  door closed, 100% door opened until soft end stop, >100% door opened beyond the soft end stop */
  leftSlidingDoorPosition: Scalars['Percentage']['output']
  location: Location
  /** Mileage in km range from 0 to 1048573. */
  mileage: Scalars['Int']['output']
  /** Remaining cruising range in kilometers */
  remainingCruisingRange: Scalars['Float']['output']
  /** 0%  door closed, 100% door opened until soft end stop, >100% door opened beyond the soft end stop */
  rightSlidingDoorPosition: Scalars['Percentage']['output']
  /** Mode of the Self Driving System */
  sdsMode: SdsMode
  /** Seatbelt and occupancy states for each seat of vehicle */
  seats: Seats
  /** Current speed in meters / second */
  speed: Scalars['Float']['output']
  tireWarning: TireWarning
  updatedAt: Maybe<Scalars['String']['output']>
  vin: Scalars['String']['output']
}

export type SimulatedAdVehicleIncident = {
  __typename?: 'SimulatedADVehicleIncident'
  criticality: Criticality
  description: Scalars['String']['output']
  fleetVehicleId: Scalars['ID']['output']
  occurrenceTimestamp: Scalars['Date']['output']
  status: Incidentstatus
  tripInterruption: TripInterruptions
  type: IncidentTypeIds
  vehicleIncidentId: Scalars['Int']['output']
}

export type SimulatedAdVehicleSignalConnectivity = {
  __typename?: 'SimulatedADVehicleSignalConnectivity'
  connectedState: Scalars['Boolean']['output']
  fleetVehicleId: Scalars['ID']['output']
}

export type SimulatedVehicle = {
  __typename?: 'SimulatedVehicle'
  cdisError: Maybe<CdisError>
  id: Scalars['String']['output']
  telematics: Maybe<TelematicBoxState>
  vin: Scalars['String']['output']
}

export enum SimulatedVehicleEventType {
  Battery = 'BATTERY',
  ChargingCable = 'CHARGING_CABLE',
  ClimateControl = 'CLIMATE_CONTROL',
  Connectivity = 'CONNECTIVITY',
  Ignition = 'IGNITION',
  Position = 'POSITION',
  State = 'STATE',
  WakeUp = 'WAKE_UP',
}

export enum Sort {
  Asc = 'asc',
  Desc = 'desc',
}

export enum SortOrder {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type StateChangeResponse = {
  __typename?: 'StateChangeResponse'
  state: VehicleState
  success: Scalars['Boolean']['output']
}

export type Stop = {
  __typename?: 'Stop'
  actions: Array<Action>
  address: StopAddress
  areas: Array<Area>
  bearing: Scalars['Int']['output']
  created: Scalars['DateTime']['output']
  customerDisplayLocation: Location
  excludeFromReallocations: Scalars['Boolean']['output']
  flags: Maybe<Array<StopFlag>>
  hasOverlappingActions: Scalars['Boolean']['output']
  id: Scalars['String']['output']
  location: Maybe<Location>
  modified: Scalars['DateTime']['output']
  modifiedBy: Scalars['String']['output']
  modifiedByActionId: Maybe<Scalars['String']['output']>
  names: Scalars['DisplayNames']['output']
  roadCategory: Maybe<RoadCategory>
  routingLocation: Location
  serviceArea: ServiceArea
  stopActivation: StopActivation
  stopType: Maybe<StopType>
}

export type StopAccessibilityDownvotedByDrivers = TaskNotification & {
  __typename?: 'StopAccessibilityDownvotedByDrivers'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  stop: Maybe<Stop>
  version: Scalars['Int']['output']
}

export type StopActivation = {
  __typename?: 'StopActivation'
  active: Scalars['Boolean']['output']
  inactiveReasonCategory: Maybe<InactiveReasonCategory>
  inactiveReasonLabel: Maybe<Scalars['String']['output']>
}

export type StopActivationFailedNotification = TaskNotification & {
  __typename?: 'StopActivationFailedNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  stop: Maybe<Stop>
  version: Scalars['Int']['output']
}

export type StopAddress = {
  __typename?: 'StopAddress'
  city: Maybe<Scalars['String']['output']>
  houseNumber: Maybe<Scalars['String']['output']>
  postalCode: Maybe<Scalars['String']['output']>
  street: Maybe<Scalars['String']['output']>
}

export type StopAddressInput = {
  city: InputMaybe<Scalars['String']['input']>
  houseNumber: InputMaybe<Scalars['String']['input']>
  postalCode: InputMaybe<Scalars['String']['input']>
  street: InputMaybe<Scalars['String']['input']>
}

export type StopAreaMissingActiveStopsNotification = TaskNotification & {
  __typename?: 'StopAreaMissingActiveStopsNotification'
  actionType: TaskActionType
  area: Maybe<Area>
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  version: Scalars['Int']['output']
}

export type StopDownVotedByDrivers = TaskNotification & {
  __typename?: 'StopDownVotedByDrivers'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  stop: Maybe<Stop>
  version: Scalars['Int']['output']
}

export type StopFilterInput = {
  flags: InputMaybe<Array<SearchStopFlag>>
  insideServiceArea: InputMaybe<Scalars['Boolean']['input']>
  status: InputMaybe<Array<SearchStopStatus>>
  type: InputMaybe<Array<SearchStopType>>
}

export enum StopFlag {
  Ad = 'AD',
  HumanDriver = 'HUMAN_DRIVER',
  HvvSwitchStop = 'HVV_SWITCH_STOP',
  Partnership = 'PARTNERSHIP',
  PhysicalPromotion = 'PHYSICAL_PROMOTION',
  RegularPassengers = 'REGULAR_PASSENGERS',
  WheelchairAccessible = 'WHEELCHAIR_ACCESSIBLE',
}

export type StopInput = {
  address: StopAddressInput
  bearing: Scalars['Int']['input']
  customerDisplayLocation: LocationInput
  /** Defaults to 'false' */
  excludeFromReallocations: InputMaybe<Scalars['Boolean']['input']>
  flags: InputMaybe<Array<StopFlag>>
  names: Scalars['DisplayNames']['input']
  roadCategory: InputMaybe<RoadCategory>
  routingLocation: LocationInput
  stopType: InputMaybe<StopType>
}

export type StopInsight = {
  __typename?: 'StopInsight'
  dropOffs: Maybe<Scalars['Int']['output']>
  from: Maybe<Scalars['LocalDateTime']['output']>
  passengerVotesDown: Maybe<Scalars['Int']['output']>
  passengerVotesDownNoReason: Maybe<Scalars['Int']['output']>
  passengerVotesDownReachabilityNarrowOrNoSidewalk: Maybe<
    Scalars['Int']['output']
  >
  passengerVotesDownReachabilityObstacles: Maybe<Scalars['Int']['output']>
  passengerVotesDownReachabilityRoadblock: Maybe<Scalars['Int']['output']>
  passengerVotesDownReachabilityTooDark: Maybe<Scalars['Int']['output']>
  passengerVotesDownReachabilityUnevenSidewalk: Maybe<Scalars['Int']['output']>
  passengerVotesDownReachabilityUnsafePedestrianCrossing: Maybe<
    Scalars['Int']['output']
  >
  passengerVotesDownStopConditionNarrowOrNoSidewalk: Maybe<
    Scalars['Int']['output']
  >
  passengerVotesDownStopConditionObstacles: Maybe<Scalars['Int']['output']>
  passengerVotesDownStopConditionTooDark: Maybe<Scalars['Int']['output']>
  passengerVotesDownStopConditionUnevenSidewalk: Maybe<Scalars['Int']['output']>
  passengerVotesDownStopConditionUnsafePedestrianCrossing: Maybe<
    Scalars['Int']['output']
  >
  passengerVotesUp: Maybe<Scalars['Int']['output']>
  pickupCancellations: Maybe<Scalars['Int']['output']>
  pickupNoShows: Maybe<Scalars['Int']['output']>
  pickupPurchases: Maybe<Scalars['Int']['output']>
  pickups: Maybe<Scalars['Int']['output']>
  stopId: Scalars['String']['output']
  to: Maybe<Scalars['LocalDateTime']['output']>
  votesDown: Scalars['Int']['output']
  votesDownDifficultToStop: Maybe<Scalars['Int']['output']>
  votesDownNotWheelchairAccessible: Maybe<Scalars['Int']['output']>
  votesDownUnknown: Maybe<Scalars['Int']['output']>
  votesDownUnreachable: Maybe<Scalars['Int']['output']>
  votesDownWrongPosition: Maybe<Scalars['Int']['output']>
  votesUp: Maybe<Scalars['Int']['output']>
}

export enum StopLocationValidatorName {
  ActiveOnlyInsideServiceArea = 'ACTIVE_ONLY_INSIDE_SERVICE_AREA',
  AddressesMatch = 'ADDRESSES_MATCH',
  AdFlagAllowed = 'AD_FLAG_ALLOWED',
  BearingAndStreetMatch = 'BEARING_AND_STREET_MATCH',
  CustomerAndRoutingLocationDistance = 'CUSTOMER_AND_ROUTING_LOCATION_DISTANCE',
  DefaultLocaleNameIsPresent = 'DEFAULT_LOCALE_NAME_IS_PRESENT',
  DistanceToBusStop = 'DISTANCE_TO_BUS_STOP',
  DistanceToFireBrigadeAccess = 'DISTANCE_TO_FIRE_BRIGADE_ACCESS',
  DistanceToIntersection = 'DISTANCE_TO_INTERSECTION',
  DistanceToLevelCrossing = 'DISTANCE_TO_LEVEL_CROSSING',
  DistanceToOtherStops = 'DISTANCE_TO_OTHER_STOPS',
  DistanceToPedestrianCrossing = 'DISTANCE_TO_PEDESTRIAN_CROSSING',
  DistanceToTaxiStand = 'DISTANCE_TO_TAXI_STAND',
  DistanceToTrafficLights = 'DISTANCE_TO_TRAFFIC_LIGHTS',
  HubsNotUpdatable = 'HUBS_NOT_UPDATABLE',
  InsideServiceAreaBounds = 'INSIDE_SERVICE_AREA_BOUNDS',
  LocationMustBeRoutableByCar = 'LOCATION_MUST_BE_ROUTABLE_BY_CAR',
  NonPassengerStop = 'NON_PASSENGER_STOP',
  OutsideSharpCurve = 'OUTSIDE_SHARP_CURVE',
  ReallocationDistanceToServiceAreaBorder = 'REALLOCATION_DISTANCE_TO_SERVICE_AREA_BORDER',
  RequireOneOfFlagsPresent = 'REQUIRE_ONE_OF_FLAGS_PRESENT',
  RoadCategory = 'ROAD_CATEGORY',
  UpdateInsideRadius = 'UPDATE_INSIDE_RADIUS',
  WheelchairLanes = 'WHEELCHAIR_LANES',
  WheelchairObstacles = 'WHEELCHAIR_OBSTACLES',
  WheelchairSidewalkIncline = 'WHEELCHAIR_SIDEWALK_INCLINE',
  WheelchairSidewalkSurface = 'WHEELCHAIR_SIDEWALK_SURFACE',
  WheelchairStreetSurface = 'WHEELCHAIR_STREET_SURFACE',
  WheelchairStreetWidth = 'WHEELCHAIR_STREET_WIDTH',
}

export type StopPotentiallyUnreachable = TaskNotification & {
  __typename?: 'StopPotentiallyUnreachable'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  stop: Maybe<Stop>
  version: Scalars['Int']['output']
}

export type StopSuggestion = {
  __typename?: 'StopSuggestion'
  address: Maybe<StopAddress>
  bearing: Maybe<Scalars['Int']['output']>
  customerDisplayLocation: Maybe<Location>
  errors: Array<StopValidationError>
  flags: Maybe<Array<StopFlag>>
  names: Maybe<Scalars['DisplayNames']['output']>
  oneWayStreet: Maybe<Scalars['Boolean']['output']>
  roadCategory: Maybe<RoadCategory>
  routingLocation: Maybe<Location>
  stopActivation: Maybe<StopActivation>
  validation: Maybe<StopValidationResults>
}

export enum StopType {
  StopTypeHub = 'STOP_TYPE_HUB',
  StopTypeInFieldBreak = 'STOP_TYPE_IN_FIELD_BREAK',
  StopTypePublicTransportation = 'STOP_TYPE_PUBLIC_TRANSPORTATION',
  StopTypeShortBreak = 'STOP_TYPE_SHORT_BREAK',
}

export type StopValidationError = {
  __typename?: 'StopValidationError'
  code: Scalars['String']['output']
  details: Array<DetailsDto>
  message: Scalars['String']['output']
}

export type StopValidationFailed = TaskNotification & {
  __typename?: 'StopValidationFailed'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  stop: Maybe<Stop>
  version: Scalars['Int']['output']
}

export type StopValidationResult = {
  __typename?: 'StopValidationResult'
  actual: Maybe<Scalars['String']['output']>
  error: Maybe<Scalars['String']['output']>
  expected: Maybe<Scalars['String']['output']>
  status: StopValidationStatus
  validator: StopLocationValidatorName
}

export type StopValidationResults = {
  __typename?: 'StopValidationResults'
  accessibilityResults: Array<StopValidationResult>
  accessibilityStatus: StopValidationStatus
  results: Array<StopValidationResult>
  status: StopValidationStatus
}

export enum StopValidationStatus {
  Failed = 'FAILED',
  NotApplicable = 'NOT_APPLICABLE',
  Passed = 'PASSED',
  Unknown = 'UNKNOWN',
}

export type Subscription = {
  __typename?: 'Subscription'
  /** Used to check if the Subscription Gateway works end-to-end. Returns your connection context as stringified JSON. */
  CANARY_context: Scalars['String']['output']
  /** Used to check if the Subscription Gateway works end-to-end. Returns ISO8601 DateTime. */
  CANARY_timestamp: Scalars['String']['output']
  currentTime: Scalars['Float']['output']
  fccDoorActuationStatus: FccDoorActuationStatus
  fccPassengerAuthenticationState: FccPassengerAuthentication
  fccServiceCancellationState: FccServiceCancellationState
  fccVehicleLocation: FccVehicleLocation
  fccVehicleRoute: FccVehicleRoute
  fccVehicleTelemetry: FccVehicleTelemetry
  vehicleHubInformation: VehicleHubInformation
  vehicleInteraction: VehicleInteractionResponse
  vehicleTelematics: TelematicBoxState
}

export type SubscriptionFccDoorActuationStatusArgs = {
  vehicleId: Scalars['ID']['input']
}

export type SubscriptionFccPassengerAuthenticationStateArgs = {
  tripId: Scalars['ID']['input']
}

export type SubscriptionFccServiceCancellationStateArgs = {
  vehicleId: Scalars['String']['input']
}

export type SubscriptionFccVehicleLocationArgs = {
  vehicleId: Scalars['ID']['input']
}

export type SubscriptionFccVehicleRouteArgs = {
  vehicleId: Scalars['ID']['input']
}

export type SubscriptionFccVehicleTelemetryArgs = {
  vehicleId: Scalars['ID']['input']
}

export type SubscriptionVehicleHubInformationArgs = {
  vehicleId: Scalars['ID']['input']
}

export type SubscriptionVehicleInteractionArgs = {
  id: Scalars['ID']['input']
}

export type SubscriptionVehicleTelematicsArgs = {
  vehicleId: Scalars['ID']['input']
}

/** Represents a surcharge. Either relativeSurcharge or absoluteSurcharge has to be set, depending on the value in surchargeType */
export type Surcharge = {
  __typename?: 'Surcharge'
  /** Absolute surcharge */
  absoluteSurcharge: Maybe<Money>
  relativeSurcharge: Maybe<Scalars['Float']['output']>
  surchargeType: SurchargeType
}

/** Represents a surcharge. Either relativeSurcharge or absoluteSurcharge has to be set, depending on the value in surchargeType */
export type SurchargeInput = {
  /** Absolute surcharge */
  absoluteSurcharge: InputMaybe<MoneyInput>
  relativeSurcharge: InputMaybe<Scalars['Float']['input']>
  surchargeType: SurchargeType
}

export enum SurchargeType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE',
}

export type Tablet = {
  __typename?: 'Tablet'
  /** Tablet has development mode enabled, is in development MDM organization group */
  developmentMode: Maybe<Scalars['Boolean']['output']>
  imei: Scalars['ID']['output']
  /** Indicates if the tablet is a simulated tablet (not connected to the MDM) */
  isSimulated: Maybe<Scalars['Boolean']['output']>
  phoneNumber: Maybe<Scalars['String']['output']>
}

export type TabletPhoneNumberReplacedChange = FleetEntityChange & {
  __typename?: 'TabletPhoneNumberReplacedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: TabletPhoneNumberReplacedChangeFields
}

export type TabletPhoneNumberReplacedChangeFields = VehicleChangeFields & {
  __typename?: 'TabletPhoneNumberReplacedChangeFields'
  phoneNumberNew: Maybe<Scalars['String']['output']>
  phoneNumberOld: Maybe<Scalars['String']['output']>
  vehicleID: Scalars['ID']['output']
}

export type TabletReplacedChange = FleetEntityChange & {
  __typename?: 'TabletReplacedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: TabletReplacedChangeFields
}

export type TabletReplacedChangeFields = VehicleChangeFields & {
  __typename?: 'TabletReplacedChangeFields'
  tabletNew: Maybe<Tablet>
  tabletOld: Maybe<Tablet>
  vehicleID: Scalars['ID']['output']
}

export enum TaskActionType {
  Alert = 'ALERT',
  Task = 'TASK',
}

export enum TaskColumns {
  AssignedAt = 'ASSIGNED_AT',
  CreatedAt = 'CREATED_AT',
  CustomTimestamp = 'CUSTOM_TIMESTAMP',
  ResolvedAt = 'RESOLVED_AT',
  State = 'STATE',
  /** Sort by the hub display name the assigned vehicle is currently located at. */
  VehicleHubLocation = 'VEHICLE_HUB_LOCATION',
  VehicleLabel = 'VEHICLE_LABEL',
}

export type TaskNotification = {
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  version: Scalars['Int']['output']
}

export type TaskNotificationList = ListResponse & {
  __typename?: 'TaskNotificationList'
  tasks: Array<TaskNotification>
  total: Scalars['Int']['output']
}

export type TaskNotificationNote = {
  __typename?: 'TaskNotificationNote'
  backofficeUser: Maybe<BackofficeUser>
  note: Scalars['String']['output']
  timestamp: Scalars['DateTime']['output']
}

export enum TaskNotificationSeverity {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
}

export type TaskNotificationSorting = {
  column: TaskColumns
  order: SortOrder
}

export enum TaskNotificationStatus {
  InProgress = 'IN_PROGRESS',
  New = 'NEW',
  Pending = 'PENDING',
  Solved = 'SOLVED',
}

export enum TaskNotificationType {
  FcPassengerAuthenticationFallbackRequestNotification = 'FC_PASSENGER_AUTHENTICATION_FALLBACK_REQUEST_NOTIFICATION',
  /** @deprecated Migrated to fcc-ad-alerts. Use FccAdAlert (type: FccAdAlertType.PASSENGER_UNBUCKLED) instead. */
  FcPassengerUnbuckled = 'FC_PASSENGER_UNBUCKLED',
  FcVehicleCabinSafetyCheckRequiredNotification = 'FC_VEHICLE_CABIN_SAFETY_CHECK_REQUIRED_NOTIFICATION',
  /** @deprecated Migrated to fcc-ad-alerts. Use FccAdAlert (type: FccAdAlertType.EMERGENCY_STOP) instead.  */
  FcVehicleEmergencyStopNotification = 'FC_VEHICLE_EMERGENCY_STOP_NOTIFICATION',
  FcVehicleOutOfServiceNotification = 'FC_VEHICLE_OUT_OF_SERVICE_NOTIFICATION',
  /** @deprecated Migrated to fcc-ad-alerts. Use FccAdAlert (type: FccAdAlertType.WAYPOINT_ATTENDANCE) instead.  */
  FcVehicleWaypointAttendance = 'FC_VEHICLE_WAYPOINT_ATTENDANCE',
  IvcCameraErrorCameraNotReachable = 'IVC_CAMERA_ERROR_CAMERA_NOT_REACHABLE',
  IvcCameraErrorCameraRecordingsNotChecked = 'IVC_CAMERA_ERROR_CAMERA_RECORDINGS_NOT_CHECKED',
  IvcCameraErrorCameraResponseTypeError = 'IVC_CAMERA_ERROR_CAMERA_RESPONSE_TYPE_ERROR',
  IvcCameraErrorCameraSdCardNotFound = 'IVC_CAMERA_ERROR_CAMERA_SD_CARD_NOT_FOUND',
  IvcCameraErrorCameraSdCardSizeMismatch = 'IVC_CAMERA_ERROR_CAMERA_SD_CARD_SIZE_MISMATCH',
  IvcCameraErrorCameraWebserverNotReachable = 'IVC_CAMERA_ERROR_CAMERA_WEBSERVER_NOT_REACHABLE',
  IvcCameraErrorNoMatchingRecordingsFound = 'IVC_CAMERA_ERROR_NO_MATCHING_RECORDINGS_FOUND',
  IvcCameraErrorNoTimedateFromCamera = 'IVC_CAMERA_ERROR_NO_TIMEDATE_FROM_CAMERA',
  IvcCameraErrorNtpNotConfigured = 'IVC_CAMERA_ERROR_NTP_NOT_CONFIGURED',
  IvcCdisErrorHostUnreachable = 'IVC_CDIS_ERROR_HOST_UNREACHABLE',
  IvcCdisErrorTimedOut = 'IVC_CDIS_ERROR_TIMED_OUT',
  IvcCdisNotReachable = 'IVC_CDIS_NOT_REACHABLE',
  IvcCdisNoMapAvailable = 'IVC_CDIS_NO_MAP_AVAILABLE',
  IvcInternetNotReachable = 'IVC_INTERNET_NOT_REACHABLE',
  IvcRouterNotReachable = 'IVC_ROUTER_NOT_REACHABLE',
  IvcRouterOffline = 'IVC_ROUTER_OFFLINE',
  SamRoadBlockageReported = 'SAM_ROAD_BLOCKAGE_REPORTED',
  /** @deprecated Will be split into various SHIFT_XXX enum values */
  ShiftDeviationNotification = 'SHIFT_DEVIATION_NOTIFICATION',
  ShiftDrivingWithoutBreakNotification = 'SHIFT_DRIVING_WITHOUT_BREAK_NOTIFICATION',
  ShiftMissingBreakStartedNotification = 'SHIFT_MISSING_BREAK_STARTED_NOTIFICATION',
  ShiftMissingHubCheckinNotification = 'SHIFT_MISSING_HUB_CHECKIN_NOTIFICATION',
  ShiftMissingHubCheckoutNotification = 'SHIFT_MISSING_HUB_CHECKOUT_NOTIFICATION',
  ShiftPoolingOffDepartureNotification = 'SHIFT_POOLING_OFF_DEPARTURE_NOTIFICATION',
  ShiftTimesInvalidBreakNotification = 'SHIFT_TIMES_INVALID_BREAK_NOTIFICATION',
  /** @deprecated Not emitted anymore, use SHIFT_TIMES_MISSING_CLOCK_EVENT_NOTIFICATION instead */
  ShiftTimesMissingBreakEndNotification = 'SHIFT_TIMES_MISSING_BREAK_END_NOTIFICATION',
  ShiftTimesMissingClockEventNotification = 'SHIFT_TIMES_MISSING_CLOCK_EVENT_NOTIFICATION',
  ShiftTimesShiftTypeChangeNotification = 'SHIFT_TIMES_SHIFT_TYPE_CHANGE_NOTIFICATION',
  StopAccessibilityDownvotedByDrivers = 'STOP_ACCESSIBILITY_DOWNVOTED_BY_DRIVERS',
  StopAccessibilityValidationFailed = 'STOP_ACCESSIBILITY_VALIDATION_FAILED',
  StopActivationFailedNotification = 'STOP_ACTIVATION_FAILED_NOTIFICATION',
  StopAreaMissingActiveStopsNotification = 'STOP_AREA_MISSING_ACTIVE_STOPS_NOTIFICATION',
  StopDownVotedByDrivers = 'STOP_DOWN_VOTED_BY_DRIVERS',
  StopPotentiallyUnreachable = 'STOP_POTENTIALLY_UNREACHABLE',
  StopValidationFailed = 'STOP_VALIDATION_FAILED',
  TripPrebookingNoAssignment = 'TRIP_PREBOOKING_NO_ASSIGNMENT',
  Vehicle_12VBatteryCriticalNotification = 'VEHICLE_12V_BATTERY_CRITICAL_NOTIFICATION',
  VehicleCdisInterruptedNotification = 'VEHICLE_CDIS_INTERRUPTED_NOTIFICATION',
  VehicleChargingErrorNotification = 'VEHICLE_CHARGING_ERROR_NOTIFICATION',
  VehicleChargingNeededNotification = 'VEHICLE_CHARGING_NEEDED_NOTIFICATION',
  VehicleIsStandingNotification = 'VEHICLE_IS_STANDING_NOTIFICATION',
  VehicleLowStateOfChargeNotification = 'VEHICLE_LOW_STATE_OF_CHARGE_NOTIFICATION',
  VehicleNoBookingNotification = 'VEHICLE_NO_BOOKING_NOTIFICATION',
  VehicleNoHubReturnNotification = 'VEHICLE_NO_HUB_RETURN_NOTIFICATION',
  VehicleOutOfServiceAreaNotification = 'VEHICLE_OUT_OF_SERVICE_AREA_NOTIFICATION',
  VehicleWithoutDriverNotification = 'VEHICLE_WITHOUT_DRIVER_NOTIFICATION',
}

export type TaskStateChangeDetails = {
  __typename?: 'TaskStateChangeDetails'
  backofficeUser: Maybe<BackofficeUser>
  backofficeUserId: Maybe<Scalars['ID']['output']>
  initiatedBySystem: Scalars['Boolean']['output']
  timestamp: Maybe<Scalars['DateTime']['output']>
  to: TaskNotificationStatus
}

export type TelematicBoxState = {
  __typename?: 'TelematicBoxState'
  batteryEnergy: Maybe<Scalars['Float']['output']>
  /** The voltage of the 12V battery */
  batteryVoltage: Maybe<Scalars['Float']['output']>
  /** A representation of the physical central lock. Independent from our purely digital representation of vehicle interactions */
  centralLock: Maybe<CentralLockState>
  chargingMode: Maybe<ChargingMode>
  climateControlActive: Maybe<Scalars['Boolean']['output']>
  connectedState: Maybe<ConnectedState>
  consumedEnergy: Maybe<Scalars['Float']['output']>
  cruisingRange: Maybe<Scalars['Float']['output']>
  doors: Maybe<DoorState>
  /** State of health of the battery of CDIS vehicles */
  highVoltageBatteryHealth: Maybe<Scalars['Float']['output']>
  /** Only for electric vehicles, also referred to as State of Charge (SoC). Provided in % */
  highVoltageBatteryLevel: Maybe<Scalars['Float']['output']>
  ignition: Maybe<IgnitionState>
  interiorTemperature: Maybe<Scalars['Float']['output']>
  /** The vehicle key needs to be inserted into a specific holder in the driver cabin. */
  keyFob: Maybe<KeyFobState>
  /** @deprecated Please use locationWithTimestamp instead */
  location: Maybe<Location>
  /** @deprecated Please use locationWithTimestamp instead */
  locationTimestamp: Maybe<Scalars['DateTime']['output']>
  locationWithTimestamp: Maybe<LocationWithTimestamp>
  mileage: Maybe<Scalars['Float']['output']>
  powerPlug: Maybe<PowerplugState>
  /** How many kilometers the vehicle can still go without getting charged */
  remainingCruisingRange: Maybe<Scalars['Float']['output']>
  sdsMode: Maybe<SdsMode>
  speed: Maybe<Scalars['Float']['output']>
  targetTemperature: Maybe<Scalars['Float']['output']>
  timestamp: Maybe<Scalars['DateTime']['output']>
  vehicleId: Scalars['ID']['output']
  windows: Maybe<WindowState>
}

export type TelematicBoxStateInput = {
  batteryVoltage: Scalars['Float']['input']
  centralLock: CentralLockState
  chargingMode: ChargingMode
  climateControlActive: Scalars['Boolean']['input']
  connectedState: ConnectedState
  cruisingRange: Scalars['Float']['input']
  doors: DoorState
  highVoltageBatteryHealth: Scalars['Float']['input']
  highVoltageBatteryLevel: Scalars['Float']['input']
  ignition: IgnitionState
  interiorTemperature: Scalars['Float']['input']
  keyFob: KeyFobState
  location: LocationInput
  locationTimestamp: Scalars['DateTime']['input']
  mileage: Scalars['Float']['input']
  powerPlug: PowerplugState
  targetTemperature: Scalars['Float']['input']
  windows: WindowState
}

/** Terms and conditions accepted by the customer */
export type TermsAndConditions = {
  __typename?: 'TermsAndConditions'
  /** Date when the customer confirmed the version (ISO-8601 Date and time with offset) */
  confirmationDate: Maybe<Scalars['OffsetDateTime']['output']>
  /** Version of the confirmed terms and conditions */
  version: Scalars['String']['output']
}

export enum TestDoorActuationError {
  SlidingDoorDeactivated = 'SLIDING_DOOR_DEACTIVATED',
  UnknownError = 'UNKNOWN_ERROR',
  VehicleMoving = 'VEHICLE_MOVING',
  VehicleNotParked = 'VEHICLE_NOT_PARKED',
}

export enum TestTaskNotificationSeverity {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
}

export type TimeManagementNotification = TaskNotification & {
  __typename?: 'TimeManagementNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  employee: Maybe<Employee>
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  shift: Maybe<Shift>
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export type TimeOfDayInterval = {
  __typename?: 'TimeOfDayInterval'
  from: Scalars['TimeOfDay']['output']
  to: Scalars['TimeOfDay']['output']
}

export type TimeOfDayIntervalInput = {
  from: Scalars['TimeOfDay']['input']
  to: Scalars['TimeOfDay']['input']
}

export type TimeRange = {
  __typename?: 'TimeRange'
  from: Scalars['DateTime']['output']
  recurring: Maybe<Scalars['String']['output']>
  to: Maybe<Scalars['DateTime']['output']>
}

export type TimeRangeInput = {
  from: Scalars['DateTime']['input']
  recurring: InputMaybe<Scalars['String']['input']>
  to: InputMaybe<Scalars['DateTime']['input']>
}

export enum TireWarning {
  Acoustics = 'ACOUSTICS',
  CalibrateTirePressures = 'CALIBRATE_TIRE_PRESSURES',
  CalibrateTirePressureTextGong = 'CALIBRATE_TIRE_PRESSURE_TEXT_GONG',
  CalibrateTirePressureTextGongLamp = 'CALIBRATE_TIRE_PRESSURE_TEXT_GONG_LAMP',
  FrontLeftWarningLevel_1 = 'FRONT_LEFT_WARNING_LEVEL_1',
  FrontLeftWarningLevel_2 = 'FRONT_LEFT_WARNING_LEVEL_2',
  FrontLeftWarningLevel_3 = 'FRONT_LEFT_WARNING_LEVEL_3',
  FrontLeftWarningLevelLampText = 'FRONT_LEFT_WARNING_LEVEL_LAMP_TEXT',
  FrontRightWarningLevel_1 = 'FRONT_RIGHT_WARNING_LEVEL_1',
  FrontRightWarningLevel_2 = 'FRONT_RIGHT_WARNING_LEVEL_2',
  FrontRightWarningLevel_3 = 'FRONT_RIGHT_WARNING_LEVEL_3',
  FrontRightWarningLevelLampText = 'FRONT_RIGHT_WARNING_LEVEL_LAMP_TEXT',
  GeneralWarningLevel_1 = 'GENERAL_WARNING_LEVEL_1',
  GeneralWarningLevel_2 = 'GENERAL_WARNING_LEVEL_2',
  GeneralWarningLevel_3 = 'GENERAL_WARNING_LEVEL_3',
  GeneralWarningLevelLampText = 'GENERAL_WARNING_LEVEL_LAMP_TEXT',
  IndicatorLight = 'INDICATOR_LIGHT',
  NoWarning = 'NO_WARNING',
  RearLeftWarningLevel_1 = 'REAR_LEFT_WARNING_LEVEL_1',
  RearLeftWarningLevel_2 = 'REAR_LEFT_WARNING_LEVEL_2',
  RearLeftWarningLevel_3 = 'REAR_LEFT_WARNING_LEVEL_3',
  RearLeftWarningLevelLampText = 'REAR_LEFT_WARNING_LEVEL_LAMP_TEXT',
  RearRightWarningLevel_1 = 'REAR_RIGHT_WARNING_LEVEL_1',
  RearRightWarningLevel_2 = 'REAR_RIGHT_WARNING_LEVEL_2',
  RearRightWarningLevel_3 = 'REAR_RIGHT_WARNING_LEVEL_3',
  RearRightWarningLevelLampText = 'REAR_RIGHT_WARNING_LEVEL_LAMP_TEXT',
  SystemFailure = 'SYSTEM_FAILURE',
  SystemFailureLampText = 'SYSTEM_FAILURE_LAMP_TEXT',
  TireChangeDetected = 'TIRE_CHANGE_DETECTED',
}

export type TriggerTaskNotificationInput = {
  area_id: InputMaybe<Scalars['ID']['input']>
  employee_id: InputMaybe<Scalars['ID']['input']>
  serviceAreaUUID: Scalars['ID']['input']
  severity: InputMaybe<TestTaskNotificationSeverity>
  shift_id: InputMaybe<Scalars['ID']['input']>
  stop_id: InputMaybe<Scalars['ID']['input']>
  stop_id_2: InputMaybe<Scalars['ID']['input']>
  tenant: InputMaybe<Scalars['String']['input']>
  timestamp: InputMaybe<Scalars['DateTime']['input']>
  trip_id: InputMaybe<Scalars['ID']['input']>
  /** One of TaskNotificationType */
  type: Scalars['String']['input']
  vehicle_id: InputMaybe<Scalars['ID']['input']>
}

export type Trip = {
  __typename?: 'Trip'
  /** Amount of people age 14 or older. */
  adults: Scalars['Int']['output']
  /** Amount of people younger than 14. */
  children: Scalars['Int']['output']
  /** Moment of creation of the Trip. */
  createdAt: Scalars['DateTime']['output']
  /** The customer who ordered the trip. */
  customer: Maybe<Customer>
  /** The id of the customer of the trip. */
  customerId: Scalars['ID']['output']
  /** The moment when the customer was delivered. */
  deliveredAt: Maybe<Scalars['DateTime']['output']>
  /** The location where the customer has actually been delivered. */
  deliveredLocation: Maybe<Location>
  /** Transfer information for the delivery. */
  delivery: TripTransferInformation
  /** The committed delivery stop, null until a delivery stop was committed to. */
  deliveryStop: Maybe<TripStop>
  /** The delivery window at the time of trip creation. */
  deliveryWindowAtCreation: Maybe<DateTimeInterval>
  /** Coordinates and street name (optional POI) of the place where the passenger wants to go after being dropped off. */
  destination: TripNamedLocation
  /** The driver of the vehicle at the time of the trip, this is undefined until Pickup or NoShow Event and for non-AD trips. */
  driver: Maybe<Employee>
  /** The id of the driver of the vehicle, this is undefined until Pickup or NoShow Event and for non-AD trips. */
  driverId: Maybe<Scalars['ID']['output']>
  /** Indicates the driving system of vehicles acceptable to the user. */
  drivingSystem: TripDrivingSystem
  /** The earliest pickup time at the time of trip creation. */
  earliestPickupTimeAtCreation: Maybe<Scalars['DateTime']['output']>
  /** Parameters for the 'Express' service class. */
  express: Scalars['Boolean']['output']
  feedback: Maybe<Feedback>
  /** The fixed vehicle, if already specified. */
  fixedVehicle: Maybe<Vehicle>
  /** The fixed vehicle ID. */
  fixedVehicleId: Maybe<Scalars['ID']['output']>
  /** The globally unique id of the trip. */
  id: Scalars['ID']['output']
  /** The location of the vehicle at the moment of a no-show. */
  notShownUpLocation: Maybe<Location>
  /** The time when the customer's order is received by booking. */
  orderTime: Maybe<Scalars['DateTime']['output']>
  /** Coordinates and street name (optional POI) of the place where the passenger starts walking towards the next virtual stop. */
  origin: TripNamedLocation
  paymentDetails: Maybe<PaymentDetails>
  /** The moment when the customer was picked up. */
  pickedUpAt: Maybe<Scalars['DateTime']['output']>
  /** The location where the customer has actually been picked up. */
  pickedUpLocation: Maybe<Location>
  /** Transfer information for the pickup. */
  pickup: TripTransferInformation
  /** The committed pickup stop, this is null until a pickup stop was committed to. */
  pickupStop: Maybe<TripStop>
  /** The pickup window at the time of trip creation. */
  pickupWindowAtCreation: Maybe<DateTimeInterval>
  /** If the trip was pre-booked. */
  prebooked: Scalars['Boolean']['output']
  /** Latest pickup and delivery estimated schedule if trip is not yet completed, this is null until the trip was committed to. */
  schedule: Maybe<TripSchedule>
  /** The seat configuration for the trip. */
  seatInfo: TripSeatInfo
  /** The service area in which the trip is executed. */
  serviceArea: Maybe<ServiceArea>
  /** The id of the service area in which the trip is executed. */
  serviceAreaId: Scalars['String']['output']
  /** Current state of the trip. */
  state: TripExecutionStatus
  /** The tenant associated with the trip. */
  tenant: Scalars['TripTenant']['output']
  /** Moment of latest update of the Trip. */
  updatedAt: Scalars['DateTime']['output']
  /** The assigned vehicle, this is null until a vehicle is assigned. */
  vehicles: Array<TripVehicle>
}

export type TripConnection = {
  __typename?: 'TripConnection'
  /** Models a Relay connection edge for the type Trip. */
  edges: Array<TripEdge>
  /** Models pagination info for a connection. */
  pageInfo: PageInfo
}

export enum TripDrivingSystem {
  /** Autonomous vehicle. */
  Autonomous = 'Autonomous',
  /** Human driver vehicle. */
  Manual = 'Manual',
}

export type TripEdge = {
  __typename?: 'TripEdge'
  /** Pagination cursor for the current edge. */
  cursor: Scalars['String']['output']
  /** Trip for the current edge. */
  node: Trip
}

export enum TripExecutionStatus {
  /** The service has been aborted by MOIA. This means that the trip will not be executed. */
  Aborted = 'Aborted',
  /** The customer is sitting in the car. */
  Boarded = 'Boarded',
  /** The trip was cancelled by the customer. This means that the trip will not be executed. */
  Cancelled = 'Cancelled',
  /** The trip could be committed after payment. This means that the trip will be executed. */
  Committed = 'Committed',
  /** The customer was delivered. */
  Delivered = 'Delivered',
  /** A vehicle has been found and assigned. Payment can start the authorization process. */
  Dispatched = 'Dispatched',
  /** The customer did not show up at the pickup stop. This means that the trip will not be executed. */
  NoShow = 'NoShow',
  /** The customer didn't successfully complete the payment authorization. This means that the trip will not be executed. */
  PaymentAuthorizationFailed = 'PaymentAuthorizationFailed',
  /** The trip could not be committed and is rejected. This means that the trip will not be executed. */
  Rejected = 'Rejected',
  /** The trip lost its vehicle assignment and could not be saved. This means that the trip will not be executed. */
  TripLost = 'TripLost',
  /** Initial state after ordering a trip. */
  Uncommitted = 'Uncommitted',
  /** The customer should be walking to the pickup stop. */
  WalkToPickup = 'WalkToPickup',
}

export type TripHistoryEntriesResponse = {
  __typename?: 'TripHistoryEntriesResponse'
  tripHistoryEntries: Array<TripHistoryEntry>
}

export type TripHistoryEntry = {
  __typename?: 'TripHistoryEntry'
  cancellationType: Maybe<CancellationType>
  cardData: Maybe<CardData>
  completionTimestamp: Maybe<Scalars['Instant']['output']>
  customerId: Scalars['String']['output']
  destination: Maybe<NamedLocation>
  failedReason: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  invoiceIdShort: Maybe<Scalars['String']['output']>
  offer: Maybe<Offer>
  orderTimestamp: Maybe<Scalars['Instant']['output']>
  origin: Maybe<NamedLocation>
  passengers: Maybe<Passengers>
  pickupTimestamp: Maybe<Scalars['Instant']['output']>
  price: Maybe<TripHistoryEntryPrice>
  seatInfo: Maybe<SeatInfo>
  serviceAreaId: Maybe<Scalars['String']['output']>
  ttl: Maybe<Scalars['Instant']['output']>
}

export type TripHistoryEntryLocation = {
  __typename?: 'TripHistoryEntryLocation'
  lat: Maybe<Scalars['Float']['output']>
  lon: Maybe<Scalars['Float']['output']>
}

export type TripHistoryEntryPrice = {
  __typename?: 'TripHistoryEntryPrice'
  amount: Maybe<Scalars['String']['output']>
  currency: Maybe<Scalars['String']['output']>
}

export type TripHistoryEntryResponse = {
  __typename?: 'TripHistoryEntryResponse'
  tripHistoryEntry: Maybe<TripHistoryEntry>
}

export enum TripInterruptions {
  ImmediateStopReq = 'IMMEDIATE_STOP_REQ',
  NoInterruption = 'NO_INTERRUPTION',
  PulloverAsapReq = 'PULLOVER_ASAP_REQ',
  PulloverReq = 'PULLOVER_REQ',
  ReducedDynamics = 'REDUCED_DYNAMICS',
}

export type TripNamedLocation = {
  __typename?: 'TripNamedLocation'
  /** The location as latitude and longitude. */
  location: Location
  /** Street name and number, used for trip-history and domain events. */
  primaryAddress: Scalars['String']['output']
  /** The name of a point of interest (e.g. Hamburg Airport). */
  primaryPoiName: Maybe<Scalars['String']['output']>
  /** Secondary address line, usually containing zip code and city, might also contain the country. */
  secondaryAddress: Maybe<Scalars['String']['output']>
  /** Additional information for a point of interest (e.g. City, Country). */
  secondaryPoiName: Maybe<Scalars['String']['output']>
}

export type TripPrebookingNoAssignment = TaskNotification & {
  __typename?: 'TripPrebookingNoAssignment'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  dropoffStop: Maybe<Stop>
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  pickupStop: Maybe<Stop>
  rejectionAt: Scalars['DateTime']['output']
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  trip: Maybe<Trip>
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export enum TripPunctuality {
  /** Vehicle is delayed. */
  Delayed = 'Delayed',
  /** Vehicle is too early. */
  Early = 'Early',
  /** Vehicle on time. */
  OnTime = 'OnTime',
}

export type TripSchedule = {
  __typename?: 'TripSchedule'
  /** Expected time when the vehicle will be at the delivery point. */
  delivery: Scalars['DateTime']['output']
  /** Indicates punctuality for the apps for the delivery. */
  deliveryPunctuality: TripPunctuality
  /** Expected time when the vehicle will be at the pickup point (communicated ETA to the customer). */
  pickup: Scalars['DateTime']['output']
  /** Indicates punctuality for the apps for the pickup. */
  pickupPunctuality: TripPunctuality
}

export type TripSeatInfo = {
  __typename?: 'TripSeatInfo'
  /** Number of booster seats (>125cm / >22kg). Need a corresponding seat and child. */
  boosterSeats: Scalars['Int']['output']
  /** Number of child seats (9-36kg). Need a corresponding seat and child. */
  childSeats: Scalars['Int']['output']
  /** Total amount of seats. */
  seats: Scalars['Int']['output']
  /** Number of wheelchairs. Only if this feature is supported in the service-area and by the tenant. */
  wheelchairs: Scalars['Int']['output']
}

export type TripStop = {
  __typename?: 'TripStop'
  stop: Maybe<Stop>
  stopId: Scalars['String']['output']
}

export type TripTransferInformation = {
  __typename?: 'TripTransferInformation'
  /** Promised time when the transfer will happen. Used for internal optimization and PrebookingAlerting on BOFE (not for customer facing ETA) */
  promisedTime: DateTimeInterval
}

export type TripVehicle = {
  __typename?: 'TripVehicle'
  /** Whether the vehicle has been fixed, guaranteeing no further assignments. */
  fixed: Scalars['Boolean']['output']
  vehicle: Maybe<Vehicle>
  vehicleId: Scalars['ID']['output']
}

export type TurnRestriction = {
  __typename?: 'TurnRestriction'
  created: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  modified: Scalars['DateTime']['output']
  reason: TurnRestrictionReason
  segments: Array<RoadSegment>
  status: TurnRestrictionStatus
  validFrom: Maybe<Scalars['DateTime']['output']>
  validTo: Maybe<Scalars['DateTime']['output']>
}

export type TurnRestrictionActivation = {
  __typename?: 'TurnRestrictionActivation'
  id: Scalars['ID']['output']
  timeRange: TimeRange
}

export type TurnRestrictionActivationInput = {
  serviceAreaId: Scalars['ID']['input']
  timeRange: TimeRangeInput
  turnRestrictionId: Scalars['ID']['input']
}

export type TurnRestrictionFilterInput = {
  status: InputMaybe<Array<SearchTurnRestrictionStatus>>
  type: InputMaybe<Array<SearchTurnRestrictionType>>
}

export type TurnRestrictionInput = {
  activationTimeRange: InputMaybe<TimeRangeInput>
  reason: TurnRestrictionReason
  segments: Array<RoadSegmentInput>
}

export enum TurnRestrictionReason {
  Event = 'Event',
  LongTermConstructionSite = 'LongTermConstructionSite',
  MoiaOnly = 'MOIAOnly',
  ShortTermConstructionSite = 'ShortTermConstructionSite',
  StructuralChange = 'StructuralChange',
}

export enum TurnRestrictionStatus {
  Active = 'Active',
  Expired = 'Expired',
  Inactive = 'Inactive',
  Scheduled = 'Scheduled',
}

export type UnassignCdisBoxFromVehicleInput = {
  fleetVehicleId: Scalars['ID']['input']
  imei: Scalars['String']['input']
}

export type UnassignTabletFromVehicleInput = {
  vehicleId: Scalars['ID']['input']
}

/** A Cdis box that has reported for a vehicle but whose connection has not been approved */
export type UnassignedCdisBox = {
  __typename?: 'UnassignedCdisBox'
  /** A unique serial number for identifying devices (International Mobile Equipment Identity) */
  imei: Scalars['String']['output']
  /** Timestamp at which the box was last updated */
  updatedAt: Scalars['Date']['output']
}

export type UpdateCustomPriceConfigInput = {
  childrenPricing: ChildrenPricingInput
  classicServiceClass: ServiceClassParametersInput
  conditions: InputMaybe<CustomPriceConfigConditionsInput>
  couponsAllowed: InputMaybe<Scalars['Boolean']['input']>
  displayText: Array<CustomPriceConfigDisplayTextInput>
  dynamicPricing: InputMaybe<DynamicPricingInput>
  expressServiceClass: ServiceClassParametersInput
  expressServiceClassSurcharge: MoneyInput
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  parameters: PriceConfigParametersInput
  priority: InputMaybe<Scalars['Int']['input']>
  purpose: InputMaybe<CustomPriceConfigPurpose>
  validFrom: Scalars['DateTime']['input']
  validUntil: Scalars['DateTime']['input']
  version: Scalars['Int']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type UpdateCustomPriceConfigResponse = {
  __typename?: 'UpdateCustomPriceConfigResponse'
  config: CustomPriceConfigResponse
}

export type UpdateCustomerInput = {
  email: InputMaybe<Scalars['String']['input']>
  firstName: InputMaybe<Scalars['String']['input']>
  id: Scalars['ID']['input']
  lastName: InputMaybe<Scalars['String']['input']>
  severelyDisabled: InputMaybe<UpdateCustomerSeverelyDisabledInput>
}

export type UpdateCustomerResponse = {
  __typename?: 'UpdateCustomerResponse'
  data: Maybe<UpdateCustomerResponseData>
  errors: Maybe<Array<UpdateCustomerResponseError>>
}

export type UpdateCustomerResponseData = {
  __typename?: 'UpdateCustomerResponseData'
  customer: Customer
}

export type UpdateCustomerResponseError = {
  __typename?: 'UpdateCustomerResponseError'
  message: Scalars['String']['output']
  path: Array<Scalars['String']['output']>
}

export type UpdateCustomerSeverelyDisabledDataInput = {
  eligibility: Eligibility
  validUntil: Scalars['String']['input']
}

export type UpdateCustomerSeverelyDisabledInput = {
  data: InputMaybe<UpdateCustomerSeverelyDisabledDataInput>
}

export type UpdateDefaultPriceConfigInput = {
  childrenPricing: ChildrenPricingInput
  classicServiceClass: ServiceClassParametersInput
  dynamicPricing: InputMaybe<DynamicPricingInput>
  expressServiceClass: ServiceClassParametersInput
  expressServiceClassSurcharge: MoneyInput
  id: Scalars['ID']['input']
  name: Scalars['String']['input']
  parameters: PriceConfigParametersInput
  validFrom: Scalars['DateTime']['input']
  version: Scalars['Int']['input']
}

/** For possible errors see type CreateDefaultPriceConfigResponse */
export type UpdateDefaultPriceConfigResponse = {
  __typename?: 'UpdateDefaultPriceConfigResponse'
  config: DefaultPriceConfigResponse
}

export enum UpdateEmployeeProfilePictureAction {
  Delete = 'DELETE',
  GenerateUploadUrl = 'GENERATE_UPLOAD_URL',
}

export type UpdateEmployeeProfilePictureInput = {
  action: UpdateEmployeeProfilePictureAction
  id: Scalars['String']['input']
}

export type UpdateEmployeeProfilePictureResponse = {
  __typename?: 'UpdateEmployeeProfilePictureResponse'
  deleted: Scalars['Boolean']['output']
  url: Maybe<Scalars['String']['output']>
}

/** Note: All fields need to be retrieved. Fields with a `null` value will be deleted. */
export type UpdateMainInspectionProcessDataRequest = {
  /** Appointment date for the next inspection. Must be before dueDate. */
  appointmentDate: InputMaybe<Scalars['DateTime']['input']>
  documents: InputMaybe<Array<MainInspectionDocumentRequest>>
  /** The associated scheduled inactivation of the vehicle for this inspection (TODO: add type when ScheduledVehicleStateChange is a top-level type with a resolver in fleet-backend) */
  inactivationSchedulerId: InputMaybe<Scalars['ID']['input']>
  processId: Scalars['ID']['input']
  testFacilityId: InputMaybe<Scalars['ID']['input']>
}

export type UpdateMileageBasedInspectionProcessDataRequest = {
  /** Pickup date for the vehicle. */
  appointmentDate: InputMaybe<Scalars['DateTime']['input']>
  documents: InputMaybe<Array<MileageBasedInspectionDocumentRequest>>
  /** The associated scheduled inactivation of the vehicle for this inspection (TODO: add type when ScheduledVehicleStateChange is a top-level type with a resolver in fleet-backend) */
  inactivationSchedulerId: InputMaybe<Scalars['ID']['input']>
  /** UUID of hub at which the vehicle will be picked up */
  pickupHub: InputMaybe<Scalars['ID']['input']>
  processId: Scalars['ID']['input']
  testFacilityId: InputMaybe<Scalars['ID']['input']>
}

export type UpdateSimulatedAdVehicleIncidentsInput = {
  criticality: Criticality
  description: InputMaybe<Scalars['String']['input']>
  fleetVehicleId: Scalars['ID']['input']
  occurrenceTimestamp: InputMaybe<Scalars['Date']['input']>
  tripInterruption: TripInterruptions
  type: IncidentTypeIds
}

export type UpdateSimulatedAdVehicleInput = {
  centralLockState: InputMaybe<CentralLockState>
  connectedState: InputMaybe<Scalars['Boolean']['input']>
  consumedEnergy: InputMaybe<Scalars['Float']['input']>
  doorsOpen: InputMaybe<Scalars['Boolean']['input']>
  exteriorAirTemperature: InputMaybe<Scalars['Float']['input']>
  fleetVehicleId: Scalars['ID']['input']
  heading: InputMaybe<Scalars['Float']['input']>
  highVoltBatteryPercentage: InputMaybe<Scalars['Percentage']['input']>
  ignitionState: InputMaybe<IgnitionState>
  interiorTemperature: InputMaybe<Scalars['Float']['input']>
  latitude: InputMaybe<Scalars['Float']['input']>
  leftSlidingDoorPosition: InputMaybe<Scalars['Percentage']['input']>
  longitude: InputMaybe<Scalars['Float']['input']>
  mileage: InputMaybe<Scalars['Int']['input']>
  remainingCruisingRange: InputMaybe<Scalars['Float']['input']>
  rightSlidingDoorPosition: InputMaybe<Scalars['Percentage']['input']>
  sdsMode: InputMaybe<SdsMode>
  seats: InputMaybe<SeatsInput>
  speed: InputMaybe<Scalars['Float']['input']>
  tireWarning: InputMaybe<TireWarning>
}

export type UpdateSimulatedAdVehicleSignalConnectivityInput = {
  connectedState: Scalars['Boolean']['input']
  fleetVehicleId: Scalars['ID']['input']
}

export type UpdateSimulatedVehicleInput = {
  cdisError: InputMaybe<CdisError>
  id: Scalars['ID']['input']
  telematics: TelematicBoxStateInput
  vin: Scalars['ID']['input']
}

export type UpdateVicProcessDataRequest = {
  processId: Scalars['ID']['input']
  /** UUID of a hub */
  testFacility: InputMaybe<Scalars['ID']['input']>
  /** Date for the inspection */
  testingDate: InputMaybe<Scalars['DateTime']['input']>
}

export type UpdateVehicleGroupInput = {
  config: VehicleGroupConfigurationInput
  id: Scalars['ID']['input']
}

export type UpdateVehicleInput = {
  id: Scalars['ID']['input']
  vehicleConfiguration: VehicleConfigurationInput
}

export type UpdateVehicleRebalancingInput = {
  /** Activate or deactivate rebalancing for this vehicle */
  rebalancingEnabled: Scalars['Boolean']['input']
  /**
   * How long the rebalancing setting be valid. After this time, the setting
   * will be reset to the global rebalancing behavior.
   */
  validUntil: Scalars['DateTime']['input']
}

export type UpdateWorkOrderInput = {
  id: Scalars['ID']['input']
  status: WorkOrderStatus
}

export enum UseCaseType {
  InternalTest = 'INTERNAL_TEST',
  Real = 'REAL',
  SimulationFleet = 'SIMULATION_FLEET',
  SimulationFull = 'SIMULATION_FULL',
  Systemtest = 'SYSTEMTEST',
}

export type VgaVersionInfo = {
  __typename?: 'VGAVersionInfo'
  appVersion: Maybe<Scalars['String']['output']>
  appVersionCode: Maybe<Scalars['Int']['output']>
  fleetVehicleId: Maybe<Scalars['ID']['output']>
}

export type VicProcess = FleetProcess & {
  __typename?: 'VICProcess'
  /** Readable unique identifier */
  case: Scalars['ID']['output']
  finishedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  /** Has the vehicle been activated after the inspection */
  inspectionState: VicProcessState
  /** The mileage at the point in time the inspection was marked as done */
  mileageAtInspectionDone: Maybe<Scalars['Float']['output']>
  mileageAtStart: Scalars['Float']['output']
  /** A vehicle inspection is triggered after thresholds of 15_000 km, 45_000 km, etc */
  mileageThreshold: Scalars['Float']['output']
  serviceAreaUUID: Scalars['ID']['output']
  startedAt: Scalars['DateTime']['output']
  state: FleetProcessState
  /** UUID of a hub */
  testFacility: Maybe<Hub>
  /** Date for the inspection */
  testingDate: Maybe<Scalars['DateTime']['output']>
  type: FleetProcessType
  updatedAt: Maybe<Scalars['DateTime']['output']>
  vehicle: Vehicle
}

export enum VicProcessState {
  Done = 'DONE',
  Due = 'DUE',
}

export type VicProcessTransitionRequest = {
  nextState: VicProcessState
  processId: Scalars['ID']['input']
}

export type Vehicle = {
  __typename?: 'Vehicle'
  /** Available for autonomous vehicles only. */
  adSeatsLayout: Maybe<FccSeatLayout>
  baseHub: Maybe<Hub>
  /** The voltage of the 12V battery */
  boardVoltage: Maybe<Scalars['Float']['output']>
  cabinSafetyCheckState: Maybe<FccCabinSafetyCheckState>
  /** A representation of the physical central lock. Independent from our purely digital representation of vehicle interactions */
  centralLocked: Maybe<Scalars['Boolean']['output']>
  changeReasonFreeText: Maybe<Scalars['String']['output']>
  chargingMode: Maybe<ChargingMode>
  chargingSinceTimestamp: Maybe<Scalars['DateTime']['output']>
  climateControlState: Maybe<ClimateControlState>
  /** Indicated the type of concession this vehicle has associated with it */
  concessionType: Maybe<VehicleConcessionType>
  /** The connectivity of the CDIS box */
  connectedState: Maybe<ConnectedState>
  createdAt: Maybe<Scalars['DateTime']['output']>
  cruisingRange: Maybe<Scalars['Float']['output']>
  /** The hub, if a vehicle is currently inside a hub area */
  currentlyLocatedInHub: Maybe<Hub>
  currentlyOpen: Scalars['Boolean']['output']
  deFleetingDate: Maybe<Scalars['DateTime']['output']>
  engineType: Maybe<VehicleEngineType>
  fccCauseError: Maybe<Scalars['Boolean']['output']>
  fccCurrentAdAlerts: Maybe<FccAdAlerts>
  fccNextHubReturn: Maybe<FccWaypointHubReturnAction>
  fccNextWaypoint: Maybe<FccWaypoint>
  fccNoError: Maybe<Scalars['Boolean']['output']>
  /** Waypoints in the route that are unfinished including a Hub Return at the very end. */
  fccUpcomingWaypoints: Maybe<Array<FccWaypoint>>
  firstRegistrationDate: Maybe<Scalars['DateTime']['output']>
  /** Only for (archived) vehicles with combustion engines */
  fuelLevel: Maybe<Scalars['Float']['output']>
  hardwareConfiguration: Maybe<HardwareConfiguration>
  /** State of health of the battery of CDIS vehicles */
  highVoltageBatteryHealth: Maybe<Scalars['Float']['output']>
  /** Only for electric vehicles, also referred to as State of Charge (SoC). Provided in % */
  highVoltageBatteryLevel: Maybe<Scalars['Float']['output']>
  /** The hub at which the vehicle was registered (use locatedAtHub to find out where it currently is) */
  homeHub: Maybe<Hub>
  id: Scalars['ID']['output']
  /** Ignition state with a timestamp */
  ignition: Maybe<Ignition>
  inFleetingDate: Maybe<Scalars['DateTime']['output']>
  /**
   * [TrEx] Is this vehicle considered to be in service ("bookable")? Based on a lot of different factors
   * including active, connected, pooling, responsivness, recent location updates and not having an old hub return
   */
  inService: Maybe<Scalars['Boolean']['output']>
  inVehicleLists: Array<VehicleList>
  /** The date since when the vehicle is inactive, even if the reason changed in between. Null if vehicle is not inactive. */
  inactiveSince: Maybe<Scalars['DateTime']['output']>
  interiorTemperature: Maybe<Scalars['Float']['output']>
  /** Convenience condition: state == ACTIVE && lastInteractionType != OPEN && lastInteractionHardwareKeyUsed != true && highVoltageBatteryLevel > 60.0 */
  isAvailable: Scalars['Boolean']['output']
  isNotMoving: Scalars['Boolean']['output']
  /** Indicates whether the vehicle is prioritized or not */
  isPrioritized: Scalars['Boolean']['output']
  /**
   * [TrEx] Is this vehicle responsive? This means it could accept mission updates.
   * Needed for consideration if a vehicle is in service
   */
  isResponsive: Maybe<Scalars['Boolean']['output']>
  isSelfDriving: Scalars['Boolean']['output']
  isWheelchairAccessible: Maybe<Scalars['Boolean']['output']>
  label: Maybe<Scalars['Int']['output']>
  lastHUDate: Maybe<Scalars['DateTime']['output']>
  /** The regular interval of the last inspection in km. Missing value indicates that there was no inspection so far. */
  lastMileageBasedInspectionRegularInterval: Maybe<Scalars['Int']['output']>
  /** Last time a vehicle was closed by driver */
  lastUsedInShift: Maybe<Scalars['DateTime']['output']>
  lastVehicleInteraction: Maybe<VehicleInteraction>
  /** The last occurrence when the state of the vehicle was changed, adding why, when and by whom */
  lastVehicleStateChange: Maybe<VehicleStateChange>
  latestADTelemetry: Maybe<AdTelemetry>
  latestLocation: Maybe<VehicleLocation>
  licensePlate: Maybe<Scalars['String']['output']>
  mileage: Maybe<Scalars['Float']['output']>
  nextHubReturn: Maybe<HubReturn>
  /** The date until which the next main inspection (Hauptuntersuchung) has to happen (by law) */
  nextMainInspectionDate: Maybe<Scalars['DateTime']['output']>
  /** The mileage in km at which the next mileage based inspection is planned. */
  nextMileageBasedInspectionAt: Maybe<Scalars['Int']['output']>
  nextPossibleStates: Array<VehicleState>
  /** [TrEx] The next waypoint ahead */
  nextWaypoint: Maybe<Waypoint>
  /** [TrEx] A list of the reasons that are contributing to marking the vehicle as not in-service */
  notInServiceReasons: Maybe<Array<NotInServiceReason>>
  /** If open. Null indicates a closed vehicle */
  openedBy: Maybe<VehicleInteractionUserType>
  /** [TrEx] Information around the pooling state, if it is enabled, the reason for it and if there is a connection */
  pooling: Maybe<Pooling>
  powerPlugState: Maybe<PowerplugState>
  /** Indicates the reasons as to why the vehicle can not be prioritized, if it's blank, it's prioritizable! */
  prioritizationImpossibleReasons: Array<VehiclePrioritizationImpossibleReason>
  rebalancingEnabled: Scalars['Boolean']['output']
  rebalancingSettingValidUntil: Scalars['DateTime']['output']
  /** [TrEx] When was responsivness updated the last time */
  responsivenessUpdatedAt: Maybe<Scalars['DateTime']['output']>
  /** Upcoming state changes (e.g. for inspections, marketing events, etc), ordered by scheduled date ASC */
  scheduledStateChanges: Maybe<Array<ScheduledVehicleStateChange>>
  /** Configuration of the regular-sized adult seats */
  seatConfiguration: Maybe<VehicleSeatConfiguration>
  serviceArea: Maybe<ServiceArea>
  /** UUID of the vehicle's service area */
  serviceAreaID: Scalars['ID']['output']
  /** UUID of the vehicle's service area */
  serviceAreaUUID: Scalars['ID']['output']
  state: VehicleState
  /** [TrEx] Location from the tablet. This is a distinct value from the telemetry location from the vehicle itself, and should generally be preferred as this is the source-of-truth for pooling */
  tabletLocation: Maybe<Location>
  /** [TrEx] Last time the location was updated from the tablet. */
  tabletLocationUpdatedAt: Maybe<Scalars['DateTime']['output']>
  taskNotifications: Maybe<TaskNotificationList>
  tenant: Scalars['String']['output']
  /** [TrEx] How many children are currently in the vehicle? Based on child seats */
  totalChildrenInVehicle: Maybe<Scalars['Int']['output']>
  /** [TrEx] How many passengers are currently in the vehicle? */
  totalPassengersInVehicle: Maybe<Scalars['Int']['output']>
  /** [TrEx] Amount of passengers that will be picked up */
  totalPassengersToPickUp: Maybe<Scalars['Int']['output']>
  /** [TrEx] How many wheelchair users are currently in the vehicle? */
  totalWheelchairUsersInVehicle: Maybe<Scalars['Int']['output']>
  updatedAt: Maybe<Scalars['DateTime']['output']>
  vehicleCheckStatus: Maybe<VehicleCheckStatus>
  vehicleGroup: Maybe<VehicleGroup>
  vehicleStateChangeReason: Maybe<VehicleStateChangeReason>
  vgaVersion: Maybe<VgaVersionInfo>
  vin: Maybe<Scalars['String']['output']>
  /** [TrEx] All waypoints ahead */
  waypoints: Maybe<Array<Waypoint>>
}

export type VehicleTaskNotificationsArgs = {
  status: Array<TaskNotificationStatus>
  types: Array<TaskNotificationType>
}

export enum VehicleAvailability {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE',
}

export type VehicleAvailabilityNotification = TaskNotification & {
  __typename?: 'VehicleAvailabilityNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  employee: Maybe<Employee>
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export type VehicleByLabelInput = {
  label: Scalars['Int']['input']
  serviceAreaUUID: Scalars['ID']['input']
  tenant: Scalars['String']['input']
}

export type VehicleChangeFields = {
  vehicleID: Scalars['ID']['output']
}

export type VehicleChangeList = CursorListResponse & {
  __typename?: 'VehicleChangeList'
  nextCursor: Maybe<Scalars['ID']['output']>
  total: Scalars['Int']['output']
  vehicleChanges: Array<FleetEntityChange>
}

export enum VehicleChangeType {
  BoxPcReplaced = 'BOX_PC_REPLACED',
  BoxPcWiped = 'BOX_PC_WIPED',
  CameraReplaced = 'CAMERA_REPLACED',
  CdisBoxReplaced = 'CDIS_BOX_REPLACED',
  HuDatesUpdated = 'HU_DATES_UPDATED',
  KeyRfidReplaced = 'KEY_RFID_REPLACED',
  RouterReplaced = 'ROUTER_REPLACED',
  TabletPhoneNumberReplaced = 'TABLET_PHONE_NUMBER_REPLACED',
  TabletReplaced = 'TABLET_REPLACED',
  VehicleCreated = 'VEHICLE_CREATED',
  VehicleServiceAborted = 'VEHICLE_SERVICE_ABORTED',
  VehicleStateUpdated = 'VEHICLE_STATE_UPDATED',
  VehicleUpdated = 'VEHICLE_UPDATED',
}

export type VehicleChangesQueryInput = {
  changeType: InputMaybe<VehicleChangeType>
  fromDateTime: InputMaybe<Scalars['DateTime']['input']>
  pagination: InputMaybe<CursorPaginationInput>
  toDateTime: InputMaybe<Scalars['DateTime']['input']>
  vehicleID: Scalars['ID']['input']
}

export type VehicleCheck = {
  __typename?: 'VehicleCheck'
  answers: Array<VehicleCheckAnswer>
  createdAt: Scalars['DateTime']['output']
  employee: Maybe<Employee>
  id: Scalars['ID']['output']
  shiftId: Maybe<Scalars['ID']['output']>
  vehicle: Maybe<Vehicle>
}

export type VehicleCheckAnswer = {
  __typename?: 'VehicleCheckAnswer'
  acknowledged: Scalars['Boolean']['output']
  answerText: Maybe<Scalars['String']['output']>
  checked: Scalars['Boolean']['output']
  /** @deprecated Do not use. Only needed temporarily for migration */
  id: Maybe<Scalars['String']['output']>
  questionKey: Scalars['String']['output']
}

export type VehicleCheckAnswerInput = {
  answerText: InputMaybe<Scalars['String']['input']>
  checked: Scalars['Boolean']['input']
  questionKey: Scalars['String']['input']
}

export type VehicleCheckInput = {
  answers: Array<VehicleCheckAnswerInput>
  shiftId: InputMaybe<Scalars['ID']['input']>
  vehicleCheckType: VehicleCheckType
  vehicleId: Scalars['ID']['input']
}

export type VehicleCheckList = CursorListResponse & {
  __typename?: 'VehicleCheckList'
  nextCursor: Maybe<Scalars['ID']['output']>
  total: Scalars['Int']['output']
  vehicleChecks: Array<VehicleCheck>
}

export type VehicleCheckQuestionTemplate = {
  __typename?: 'VehicleCheckQuestionTemplate'
  /** Is it possible to add free text for this question */
  answerTextAllowed: Scalars['Boolean']['output']
  key: Scalars['ID']['output']
  /** The localized text for this question */
  text: Scalars['String']['output']
}

/** Optionally filter list only for a specific vehicle or service area. */
export type VehicleCheckSearch = {
  acknowledged: InputMaybe<Scalars['Boolean']['input']>
  pagination: InputMaybe<CursorPaginationInput>
  serviceAreaUUID: InputMaybe<Scalars['ID']['input']>
  vehicleID: InputMaybe<Scalars['ID']['input']>
}

export enum VehicleCheckStatus {
  CheckOnCloseDone = 'CHECK_ON_CLOSE_DONE',
  CheckOnOpenDone = 'CHECK_ON_OPEN_DONE',
  /** @deprecated Use currentlyOpen field of Vehicle instead */
  VehicleOpen = 'VEHICLE_OPEN',
}

export type VehicleCheckTopicTemplate = {
  __typename?: 'VehicleCheckTopicTemplate'
  employeeSpecific: Scalars['Boolean']['output']
  /** The localized headline of this topic */
  headline: Scalars['String']['output']
  key: Scalars['ID']['output']
  questions: Array<VehicleCheckQuestionTemplate>
  /** The localized text for this topic */
  text: Scalars['String']['output']
  title: Scalars['String']['output']
  vehicleCheckType: VehicleCheckType
}

export enum VehicleCheckType {
  CheckOnClose = 'CHECK_ON_CLOSE',
  CheckOnOpen = 'CHECK_ON_OPEN',
}

export enum VehicleConcessionType {
  None = 'NONE',
  Rental = 'RENTAL',
  RentalAndTransport = 'RENTAL_AND_TRANSPORT',
  Transport = 'TRANSPORT',
}

export type VehicleConfigurationInput = {
  concessionType: InputMaybe<VehicleConcessionType>
  deFleetingDate: InputMaybe<Scalars['DateTime']['input']>
  firstRegistrationDate: InputMaybe<Scalars['DateTime']['input']>
  hubId: InputMaybe<Scalars['ID']['input']>
  label: InputMaybe<Scalars['Int']['input']>
  licensePlate: InputMaybe<Scalars['String']['input']>
  nextMainInspectionDate: InputMaybe<Scalars['DateTime']['input']>
  reservedChildSeats: InputMaybe<Scalars['Int']['input']>
  reservedSeats: InputMaybe<Scalars['Int']['input']>
  reservedWheelchairSeats: InputMaybe<Scalars['Int']['input']>
  serviceAreaUUID: Scalars['String']['input']
  vehicleGroupId: InputMaybe<Scalars['ID']['input']>
}

export type VehicleCreatedChange = FleetEntityChange & {
  __typename?: 'VehicleCreatedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: VehicleCreatedChangeFields
}

export type VehicleCreatedChangeFields = VehicleChangeFields & {
  __typename?: 'VehicleCreatedChangeFields'
  tenant: Maybe<Scalars['String']['output']>
  vehicleID: Scalars['ID']['output']
  vin: Maybe<Scalars['ID']['output']>
}

export enum VehicleEngineType {
  Electric = 'ELECTRIC',
  Fuel = 'FUEL',
}

export type VehicleGroup = {
  __typename?: 'VehicleGroup'
  boxProvider: BoxProvider
  childSeatSpecs: Array<ChildSeatSpec>
  displayName: Scalars['String']['output']
  engineType: VehicleEngineType
  groupType: VehicleGroupType
  id: Scalars['ID']['output']
  isWheelchairAccessible: Scalars['Boolean']['output']
  minStateOfCharge: Scalars['Float']['output']
  sdsProvider: SdsProvider
  seats: Scalars['Int']['output']
  serviceAreaUUIDs: Array<Scalars['String']['output']>
  tenant: Scalars['String']['output']
  wheelchairSeats: Scalars['Int']['output']
}

export type VehicleGroupConfigurationInput = {
  boxProvider: BoxProvider
  /** The list should always have a size of 3. Unused specs should have isAvailable=false */
  childSeatSpecs: Array<ChildSeatSpecInput>
  displayName: Scalars['String']['input']
  engineType: VehicleEngineType
  groupType: VehicleGroupType
  minStateOfCharge: Scalars['Float']['input']
  sdsProvider: InputMaybe<SdsProvider>
  seats: Scalars['Int']['input']
  serviceAreaUUIDs: InputMaybe<Array<Scalars['String']['input']>>
  tenant: Scalars['String']['input']
  wheelchairSeats: Scalars['Int']['input']
}

export type VehicleGroupResponse = {
  __typename?: 'VehicleGroupResponse'
  success: Scalars['Boolean']['output']
  vehicleGroup: VehicleGroup
}

export enum VehicleGroupType {
  ECrafter = 'E_CRAFTER',
  IdBuzz = 'ID_BUZZ',
  Pluto = 'PLUTO',
  T6 = 'T6',
  Tge = 'TGE',
}

export type VehicleGroupsResponse = {
  __typename?: 'VehicleGroupsResponse'
  total: Scalars['Int']['output']
  vehicleGroups: Array<VehicleGroup>
}

export type VehicleHubArrivalsResponse = ListResponse & {
  __typename?: 'VehicleHubArrivalsResponse'
  total: Scalars['Int']['output']
  vehicles: Array<Vehicle>
}

export type VehicleHubInformation = {
  __typename?: 'VehicleHubInformation'
  /** The hub the vehicle is currently located in (might also just be a shift break to charge the vehicle). Null if the vehicle is in the field. */
  locatedInHub: Maybe<Scalars['ID']['output']>
  vehicleId: Scalars['ID']['output']
}

export type VehicleIncidentNotification = TaskNotification & {
  __typename?: 'VehicleIncidentNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export type VehicleInteraction = {
  __typename?: 'VehicleInteraction'
  /** If the interaction was executed via backoffice, this is a reference to the user that did that */
  backofficeUser: Maybe<BackofficeUser>
  /** The employee (driver, service staff, etc) for whom the interaction was executed */
  employee: Maybe<Employee>
  /** True if the emergeny tab in the backoffice was used */
  hardwareKeyUsed: Scalars['Boolean']['output']
  /** The high voltage battery level of the vehicle at the time of the interaction */
  highVoltageBatteryLevel: Maybe<Scalars['Float']['output']>
  /** Only set if the vehicle that was interacted with was inside a hub */
  hub: Maybe<Hub>
  id: Scalars['ID']['output']
  /** Whether the interaction was executed from the backoffice or employee app */
  isBackofficeInteraction: Scalars['Boolean']['output']
  /** The vehicle mileage at the time of the interaction */
  mileage: Maybe<Scalars['Float']['output']>
  /** If the interaction was executed from the backoffice, a reason may be provided */
  reason: Maybe<Scalars['String']['output']>
  /** Shift ID of the employee (driver, service staff, etc) for whom the interaction was executed */
  shiftId: Maybe<Scalars['ID']['output']>
  timestamp: Scalars['DateTime']['output']
  type: VehicleInteractionType
}

export enum VehicleInteractionError {
  AlreadyOpenedAnotherVehicle = 'ALREADY_OPENED_ANOTHER_VEHICLE',
  BoxProviderException = 'BOX_PROVIDER_EXCEPTION',
  CentralLockingTimeout = 'CENTRAL_LOCKING_TIMEOUT',
  EmployeeIdMissing = 'EMPLOYEE_ID_MISSING',
  EmployeeSuspended = 'EMPLOYEE_SUSPENDED',
  MissingPermission = 'MISSING_PERMISSION',
  NoBoxId = 'NO_BOX_ID',
  NoMatchingInteraction = 'NO_MATCHING_INTERACTION',
  PreconditionDoorOpen = 'PRECONDITION_DOOR_OPEN',
  PreconditionFuelCardMissing = 'PRECONDITION_FUEL_CARD_MISSING',
  PreconditionIgnitionOn = 'PRECONDITION_IGNITION_ON',
  PreconditionKeyFob = 'PRECONDITION_KEY_FOB',
  PreconditionLightsOn = 'PRECONDITION_LIGHTS_ON',
  PreconditionWindowOpen = 'PRECONDITION_WINDOW_OPEN',
  ShiftIdMissing = 'SHIFT_ID_MISSING',
  StateIncompatible = 'STATE_INCOMPATIBLE',
  Unknown = 'UNKNOWN',
  VehicleAlreadyClosed = 'VEHICLE_ALREADY_CLOSED',
  VehicleAlreadyOpen = 'VEHICLE_ALREADY_OPEN',
  VehicleClosedWithHardwareKey = 'VEHICLE_CLOSED_WITH_HARDWARE_KEY',
  VehicleInteractionInFlightConflict = 'VEHICLE_INTERACTION_IN_FLIGHT_CONFLICT',
  VehicleNotFound = 'VEHICLE_NOT_FOUND',
  VehicleReservedToOpen = 'VEHICLE_RESERVED_TO_OPEN',
  VehicleTooFarAway = 'VEHICLE_TOO_FAR_AWAY',
  WrongEmployee = 'WRONG_EMPLOYEE',
}

export type VehicleInteractionResponse = {
  __typename?: 'VehicleInteractionResponse'
  errors: Array<VehicleInteractionError>
  id: Scalars['ID']['output']
  interactionID: Maybe<Scalars['ID']['output']>
  success: Scalars['Boolean']['output']
}

export enum VehicleInteractionType {
  Close = 'CLOSE',
  Open = 'OPEN',
}

export enum VehicleInteractionUserType {
  Driver = 'DRIVER',
  FieldTester = 'FIELD_TESTER',
  Service = 'SERVICE',
}

export type VehicleInteractionsQueryInput = {
  fromDateTime: InputMaybe<Scalars['DateTime']['input']>
  limit: InputMaybe<Scalars['Int']['input']>
  offset: InputMaybe<Scalars['Int']['input']>
  toDateTime: InputMaybe<Scalars['DateTime']['input']>
  vehicleID: Scalars['ID']['input']
}

export type VehicleInteractionsResponse = {
  __typename?: 'VehicleInteractionsResponse'
  count: Scalars['Int']['output']
  vehicleInteractions: Array<VehicleInteraction>
}

export type VehicleList = {
  __typename?: 'VehicleList'
  createdAt: Scalars['DateTime']['output']
  description: Maybe<Scalars['String']['output']>
  entries: Array<VehicleListEntry>
  /** UUID, generated by the backend */
  id: Scalars['ID']['output']
  lastEditedAt: Scalars['DateTime']['output']
  /** Provided by the backoffice user */
  name: Scalars['String']['output']
  serviceAreaUUID: Scalars['ID']['output']
  tenant: Scalars['String']['output']
}

export type VehicleListEntry = {
  __typename?: 'VehicleListEntry'
  addedAt: Scalars['DateTime']['output']
  addedBy: Maybe<BackofficeUser>
  vehicle: Vehicle
}

export type VehicleListInput = {
  /** Extra description of the vehicle list */
  description: InputMaybe<Scalars['String']['input']>
  /** Name of the vehicle list */
  name: Scalars['String']['input']
  /** The service area this list belongs to (and all of its vehicles) */
  serviceAreaUUID: Scalars['ID']['input']
  /** The tenant this vehicle list belongs to */
  tenant: Scalars['String']['input']
}

export type VehicleListResponse = {
  __typename?: 'VehicleListResponse'
  success: Scalars['Boolean']['output']
  vehicleList: VehicleList
}

export type VehicleListsResponse = {
  __typename?: 'VehicleListsResponse'
  total: Scalars['Int']['output']
  vehicleLists: Array<VehicleList>
}

export type VehicleLocation = {
  __typename?: 'VehicleLocation'
  heading: Maybe<Scalars['Float']['output']>
  location: Maybe<Location>
  locationSource: Maybe<LocationSourceType>
  timestamp: Maybe<Scalars['DateTime']['output']>
}

export enum VehicleOperationMode {
  Autonomous = 'Autonomous',
  Hybrid = 'Hybrid',
  Manual = 'Manual',
}

export type VehiclePart = {
  __typename?: 'VehiclePart'
  /** The top-level category for the part - e.g interior, exterior */
  area: VehiclePartLevel
  id: Scalars['ID']['output']
  /** The part is detailing the subArea - e.g. light_left */
  part: VehiclePartLevel
  /** Further categorization if the area - e.g. passenger_side */
  subArea: VehiclePartLevel
}

export type VehiclePartLevel = {
  __typename?: 'VehiclePartLevel'
  key: Scalars['ID']['output']
  /** The localized text of this part */
  text: Scalars['String']['output']
}

export enum VehiclePrioritizationImpossibleReason {
  InsufficientStateOfCharge = 'INSUFFICIENT_STATE_OF_CHARGE',
  NotActive = 'NOT_ACTIVE',
  NotClosed = 'NOT_CLOSED',
  NotOnline = 'NOT_ONLINE',
}

export type VehiclePrioritizationInput = {
  isPrioritized: Scalars['Boolean']['input']
  vehicleID: Scalars['ID']['input']
}

export type VehicleResponse = {
  __typename?: 'VehicleResponse'
  success: Scalars['Boolean']['output']
  vehicle: Vehicle
}

export type VehicleRoute = {
  __typename?: 'VehicleRoute'
  routeShape: Scalars['String']['output']
}

export type VehicleSearchInput = {
  /** If not provided will return both available and unavailable vehicles */
  availability: InputMaybe<VehicleAvailability>
  /** The base hub is the hub from where the vehicle starts and where its shifts end. */
  baseHubs: InputMaybe<Array<Scalars['ID']['input']>>
  boardVoltageRange: InputMaybe<FloatRangeInput>
  chargingModes: InputMaybe<Array<ChargingMode>>
  concessionTypes: InputMaybe<Array<VehicleConcessionType>>
  /** Values can be hub IDs or 'IN_FIELD'. If empty vehicles won't be filtered. */
  currentLocations: InputMaybe<Array<Scalars['String']['input']>>
  deactivation: InputMaybe<Deactivation>
  engineType: InputMaybe<VehicleEngineType>
  hasReservedSeats: InputMaybe<Scalars['Boolean']['input']>
  /** @deprecated please use highVoltageBatteryPercentageRanges instead */
  hasScheduledStateChange: InputMaybe<Scalars['Boolean']['input']>
  highVoltageBatteryPercentageRange: InputMaybe<FloatRangeInput>
  highVoltageBatteryPercentageRanges: InputMaybe<Array<FloatRangeInput>>
  /** Filter by vehicles that are part of vehicle lists with the provided IDs */
  inVehicleLists: InputMaybe<Array<Scalars['ID']['input']>>
  isSelfDriving: InputMaybe<Scalars['Boolean']['input']>
  isWheelchairAccessible: InputMaybe<Scalars['Boolean']['input']>
  lastInteracted: InputMaybe<DateTimeIntervalInput>
  lastInteractionTypeFilter: InputMaybe<Array<VehicleInteractionType>>
  lastInteractionUserTypes: InputMaybe<Array<VehicleInteractionUserType>>
  /** Whether the vehicle is physically locked. Independent from the vehicle interaction state. */
  locked: InputMaybe<Scalars['Boolean']['input']>
  pluggedIn: InputMaybe<Scalars['Boolean']['input']>
  /** Search by label, license plate or VIN */
  search: InputMaybe<Scalars['String']['input']>
  serviceAreaUUID: Scalars['ID']['input']
  states: InputMaybe<Array<VehicleState>>
}

/**
 * The resolved seat configuration of a vehicle's regular, child or wheelchair seats.
 *
 * The total depends on the type (Group) of vehicle (e.g. Pluto, T6, with different configurations)
 *
 * A Vehicle with 5 available seats out of 6 total seats would imply 1 reserved seat.
 *
 * Query whichever value(s) you need to display, and avoid calculating any of these.
 */
export type VehicleSeatConfiguration = {
  __typename?: 'VehicleSeatConfiguration'
  available: Scalars['Int']['output']
  availableWheelchairSeats: Maybe<Scalars['Int']['output']>
  childSeatSpecs: Array<ChildSeatSpec>
  reserved: Scalars['Int']['output']
  total: Scalars['Int']['output']
}

export type VehicleServiceAbortedChange = FleetEntityChange & {
  __typename?: 'VehicleServiceAbortedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: VehicleServiceAbortedChangeFields
}

export type VehicleServiceAbortedChangeFields = VehicleChangeFields & {
  __typename?: 'VehicleServiceAbortedChangeFields'
  otherReasonDescription: Maybe<Scalars['String']['output']>
  reason: Maybe<FccCancellationReason>
  returnToHub: Maybe<Hub>
  vehicleID: Scalars['ID']['output']
}

export enum VehicleState {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT',
  Inactive = 'INACTIVE',
}

export type VehicleStateChange = {
  __typename?: 'VehicleStateChange'
  changeReasonFreeText: Maybe<Scalars['String']['output']>
  createdAt: Maybe<Scalars['DateTime']['output']>
  createdByUser: Maybe<BackofficeUser>
  /** Date and time when the vehicle is expected to be activated again, basically a downtime forecast. */
  expectedActivationAt: Maybe<Scalars['DateTime']['output']>
  updatedAt: Maybe<Scalars['DateTime']['output']>
  updatedBy: Maybe<BackofficeUser>
  vehicleStateChangeReason: Maybe<VehicleStateChangeReason>
}

export enum VehicleStateChangeReason {
  DamagesOther = 'DAMAGES_OTHER',
  DamagesSelfRepairAtHub = 'DAMAGES_SELF_REPAIR_AT_HUB',
  DamagesSmartRepairAtHub = 'DAMAGES_SMART_REPAIR_AT_HUB',
  DamagesWorkshop = 'DAMAGES_WORKSHOP',
  Defects_12V = 'DEFECTS_12V',
  DefectsErrorMessage = 'DEFECTS_ERROR_MESSAGE',
  DefectsHighVoltage = 'DEFECTS_HIGH_VOLTAGE',
  DefectsHighVoltageBjb = 'DEFECTS_HIGH_VOLTAGE_BJB',
  DefectsIvc = 'DEFECTS_IVC',
  DefectsOther = 'DEFECTS_OTHER',
  EventsCleaning = 'EVENTS_CLEANING',
  EventsDefleeting = 'EVENTS_DEFLEETING',
  EventsMarketing = 'EVENTS_MARKETING',
  EventsOther = 'EVENTS_OTHER',
  EventsRental = 'EVENTS_RENTAL',
  EventsServiceCancellation = 'EVENTS_SERVICE_CANCELLATION',
  EventsStickers = 'EVENTS_STICKERS',
  EventsUsableReserve = 'EVENTS_USABLE_RESERVE',
  ServiceMainInspection = 'SERVICE_MAIN_INSPECTION',
  ServiceMileageInspection = 'SERVICE_MILEAGE_INSPECTION',
  ServiceMoiaVwAction = 'SERVICE_MOIA_VW_ACTION',
  ServiceOther = 'SERVICE_OTHER',
  ServiceTireCheckSwap = 'SERVICE_TIRE_CHECK_SWAP',
  ServiceVic = 'SERVICE_VIC',
}

export type VehicleStateUpdatedChange = FleetEntityChange & {
  __typename?: 'VehicleStateUpdatedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  /** Timestamp of the last entity change of the same type */
  changedAtPreviously: Maybe<Scalars['DateTime']['output']>
  changes: VehicleStateUpdatedChangeFields
}

export type VehicleStateUpdatedChangeFields = VehicleChangeFields & {
  __typename?: 'VehicleStateUpdatedChangeFields'
  reasonFreeTextNew: Maybe<Scalars['String']['output']>
  reasonFreeTextOld: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'vehicleStateChangeReasonNew' instead */
  reasonNew: Maybe<Scalars['String']['output']>
  /** @deprecated Use 'vehicleStateChangeReasonOld' instead */
  reasonOld: Maybe<Scalars['String']['output']>
  scheduledNew: Maybe<Scalars['Boolean']['output']>
  scheduledOld: Maybe<Scalars['Boolean']['output']>
  stateNew: Maybe<VehicleState>
  stateOld: Maybe<VehicleState>
  vehicleID: Scalars['ID']['output']
  vehicleStateChangeReasonNew: Maybe<VehicleStateChangeReason>
  vehicleStateChangeReasonOld: Maybe<VehicleStateChangeReason>
}

export type VehicleSurveillanceNotification = TaskNotification & {
  __typename?: 'VehicleSurveillanceNotification'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  employee: Maybe<Employee>
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export type VehicleUpdatedChange = FleetEntityChange & {
  __typename?: 'VehicleUpdatedChange'
  backofficeUser: Maybe<BackofficeUser>
  changedAt: Scalars['DateTime']['output']
  changes: VehicleUpdatedChangeFields
}

export type VehicleUpdatedChangeFields = VehicleChangeFields & {
  __typename?: 'VehicleUpdatedChangeFields'
  boxPcNew: Maybe<BoxPc>
  boxPcOld: Maybe<BoxPc>
  deFleetingDateNew: Maybe<Scalars['DateTime']['output']>
  deFleetingDateOld: Maybe<Scalars['DateTime']['output']>
  firstRegistrationDateNew: Maybe<Scalars['DateTime']['output']>
  firstRegistrationDateOld: Maybe<Scalars['DateTime']['output']>
  hubNew: Maybe<Hub>
  hubOld: Maybe<Hub>
  inFleetingDateNew: Maybe<Scalars['DateTime']['output']>
  inFleetingDateOld: Maybe<Scalars['DateTime']['output']>
  ioTNameNew: Maybe<Scalars['String']['output']>
  ioTNameOld: Maybe<Scalars['String']['output']>
  labelNew: Maybe<Scalars['Int']['output']>
  labelOld: Maybe<Scalars['Int']['output']>
  lastMainInspectionDateNew: Maybe<Scalars['DateTime']['output']>
  lastMainInspectionDateOld: Maybe<Scalars['DateTime']['output']>
  licensePlateNew: Maybe<Scalars['String']['output']>
  licensePlateOld: Maybe<Scalars['String']['output']>
  nextMainInspectionDateNew: Maybe<Scalars['DateTime']['output']>
  nextMainInspectionDateOld: Maybe<Scalars['DateTime']['output']>
  phoneNumberNew: Maybe<Scalars['String']['output']>
  phoneNumberOld: Maybe<Scalars['String']['output']>
  reservedChildSeatsNew: Maybe<Scalars['Int']['output']>
  reservedChildSeatsOld: Maybe<Scalars['Int']['output']>
  reservedSeatsNew: Maybe<Scalars['Int']['output']>
  reservedSeatsOld: Maybe<Scalars['Int']['output']>
  reservedWheelchairSeatsNew: Maybe<Scalars['Int']['output']>
  reservedWheelchairSeatsOld: Maybe<Scalars['Int']['output']>
  serviceAreaNew: Maybe<ServiceArea>
  serviceAreaOld: Maybe<ServiceArea>
  /** @deprecated Please use TabletReplacedChangeFields instead */
  tabletIdNew: Maybe<Scalars['String']['output']>
  /** @deprecated Please use TabletReplacedChangeFields instead */
  tabletIdOld: Maybe<Scalars['String']['output']>
  vehicleGroupNew: Maybe<VehicleGroup>
  vehicleGroupOld: Maybe<VehicleGroup>
  vehicleID: Scalars['ID']['output']
}

export type VehicleWaypointAttendance = TaskNotification & {
  __typename?: 'VehicleWaypointAttendance'
  actionType: TaskActionType
  assigned: Maybe<TaskStateChangeDetails>
  createdAt: Scalars['DateTime']['output']
  foreignId: Scalars['ID']['output']
  id: Scalars['ID']['output']
  note: Maybe<TaskNotificationNote>
  notificationType: TaskNotificationType
  pended: Maybe<TaskStateChangeDetails>
  resolved: Maybe<TaskStateChangeDetails>
  serviceAreaUUID: Scalars['ID']['output']
  severity: TaskNotificationSeverity
  stateChanges: Array<TaskStateChangeDetails>
  status: TaskNotificationStatus
  vehicle: Maybe<Vehicle>
  version: Scalars['Int']['output']
}

export type VehiclesByFleetStateResponse = ListResponse & {
  __typename?: 'VehiclesByFleetStateResponse'
  /** Nr of vehicles in the fleet for the provided input parameters */
  total: Scalars['Int']['output']
  /** All the vehicle in the fleet for the provided input parameters */
  vehicles: Array<Vehicle>
}

export type VehiclesForVehicleListInput = {
  label: InputMaybe<Scalars['Int']['input']>
  serviceAreaUUID: Scalars['ID']['input']
  tenant: Scalars['String']['input']
}

export type VehiclesResponse = {
  __typename?: 'VehiclesResponse'
  total: Scalars['Int']['output']
  vehicles: Array<Vehicle>
}

export type Waypoint = {
  __typename?: 'Waypoint'
  /**
   * Actions of this waypoint
   * @deprecated Use `waypointActions`, this field will be removed soon.
   */
  actions: Array<WaypointActionKind>
  /** The stop at this waypoint */
  stop: Maybe<Stop>
  /** Is this waypoint visible for the driver? */
  visible: Scalars['Boolean']['output']
  /** Actions of this waypoint */
  waypointActions: Array<WaypointAction>
}

export type WaypointAction = {
  __typename?: 'WaypointAction'
  /** The time when this action should take place */
  at: Maybe<Scalars['DateTime']['output']>
  /** In case of a hub return what is the reason for it? */
  hubReturnReason: Maybe<HubReturnReason>
  /** Unique identifier for this action */
  id: Scalars['ID']['output']
  /** What is planned to happen at this waypoint? */
  kind: WaypointActionKind
  /** In case of a reallocation what is the radius */
  reallocationRadius: Maybe<Scalars['Int']['output']>
  /** An id referencing the thing that is about to happen at that waypoint. E.g. a tripId for a trip */
  referenceId: Maybe<Scalars['String']['output']>
  /** specification when this action SHOULD happen. */
  timeWindow: Maybe<WaypointActionTimeWindow>
  /** How many children are affected for this waypoint action? Based on child seats */
  totalChildren: Maybe<Scalars['Int']['output']>
  /** How many passegers are affected for this waypoint action? */
  totalPassengers: Maybe<Scalars['Int']['output']>
  /** How many wheelchair users are affected for this waypoint action? */
  totalWheelchairUsers: Maybe<Scalars['Int']['output']>
  /** In case of a delivery or pickup, this contains the resolved Trip */
  trip: Maybe<Trip>
}

export enum WaypointActionKind {
  Delivery = 'DELIVERY',
  Hubreturn = 'HUBRETURN',
  Pickup = 'PICKUP',
  Reallocation = 'REALLOCATION',
}

export type WaypointActionTimeWindow = {
  __typename?: 'WaypointActionTimeWindow'
  end: Maybe<Scalars['DateTime']['output']>
  start: Maybe<Scalars['DateTime']['output']>
}

export enum WindowState {
  AllClosed = 'ALL_CLOSED',
  Open = 'OPEN',
}

export type WipeBoxPcInput = {
  vehicleId: Scalars['ID']['input']
}

export type WorkOrder = {
  __typename?: 'WorkOrder'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  /** Only set if the work order is closed. Unit is km. */
  mileageAtClosing: Maybe<Scalars['Int']['output']>
  orderNumber: Scalars['Int']['output']
  serviceAreaUUID: Scalars['ID']['output']
  serviceItem: Maybe<WorkOrderServiceItem>
  status: WorkOrderStatus
  statusChangedAt: Maybe<Scalars['DateTime']['output']>
  vehicle: Maybe<Vehicle>
  vehicleTenant: Maybe<Scalars['String']['output']>
}

export enum WorkOrderColumn {
  BaseHub = 'BASE_HUB',
  CreatedAt = 'CREATED_AT',
  OrderNumber = 'ORDER_NUMBER',
  ServiceItem = 'SERVICE_ITEM',
  Status = 'STATUS',
  StatusChangedAt = 'STATUS_CHANGED_AT',
  Vehicle = 'VEHICLE',
}

export enum WorkOrderServiceItem {
  Cleaning = 'CLEANING',
  SpecialCleaning = 'SPECIAL_CLEANING',
}

export type WorkOrderSorting = {
  column: WorkOrderColumn
  order: SortOrder
}

export enum WorkOrderStatus {
  Closed = 'CLOSED',
  Open = 'OPEN',
}

export type WorkOrdersQueryInput = {
  after: InputMaybe<Scalars['DateTime']['input']>
  baseHub: InputMaybe<Scalars['ID']['input']>
  before: InputMaybe<Scalars['DateTime']['input']>
  /** Either null (all vehicles), a hub ID or 'IN_FIELD' */
  currentLocation: InputMaybe<Scalars['String']['input']>
  pagination: PaginationInput
  /** This will search for vehicle labels, must be a number! */
  search: InputMaybe<Scalars['String']['input']>
  serviceAreaUUID: Scalars['ID']['input']
  /** If this is null, work orders with all kinds of service Items will be returned */
  serviceItem: InputMaybe<WorkOrderServiceItem>
  sorting: InputMaybe<WorkOrderSorting>
  status: InputMaybe<WorkOrderStatus>
  vehicleID: InputMaybe<Scalars['ID']['input']>
}

export type WorkOrdersQueryResponse = {
  __typename?: 'WorkOrdersQueryResponse'
  total: Scalars['Int']['output']
  workOrders: Array<WorkOrder>
}
