/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'
import * as Types from '../../../../graphql/src/lib/graphql/generated/types'

const defaultOptions = {} as const
export type DisableCustomerMutationVariables = Types.Exact<{
  input: Types.DisableCustomerInput
}>

export type DisableCustomerMutation = {
  __typename?: 'Mutation'
  disableCustomer: { __typename?: 'DisableCustomerResponse'; success: boolean }
}

export const DisableCustomerDocument = gql`
  mutation disableCustomer($input: DisableCustomerInput!) {
    disableCustomer(input: $input) {
      success
    }
  }
`
export type DisableCustomerMutationFn = Apollo.MutationFunction<
  DisableCustomerMutation,
  DisableCustomerMutationVariables
>

/**
 * __useDisableCustomerMutation__
 *
 * To run a mutation, you first call `useDisableCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableCustomerMutation, { data, loading, error }] = useDisableCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisableCustomerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisableCustomerMutation,
    DisableCustomerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    DisableCustomerMutation,
    DisableCustomerMutationVariables
  >(DisableCustomerDocument, options)
}
export type DisableCustomerMutationHookResult = ReturnType<
  typeof useDisableCustomerMutation
>
export type DisableCustomerMutationResult =
  Apollo.MutationResult<DisableCustomerMutation>
export type DisableCustomerMutationOptions = Apollo.BaseMutationOptions<
  DisableCustomerMutation,
  DisableCustomerMutationVariables
>
