/* Generated. This file cannot be edited directly. Please check codegen.ts for configuration. */

/* eslint-disable */
// @ts-nocheck
import { gql } from '@apollo/client'
import * as Types from '../../../../../graphql/src/lib/graphql/generated/types'

export type AlertVehicleMapVehicleFragment = {
  __typename?: 'Vehicle'
  latestLocation: {
    __typename?: 'VehicleLocation'
    heading: number | null
    location: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    } | null
  } | null
  upcomingWaypoints: Array<{
    __typename?: 'FccWaypoint'
    stop: {
      __typename?: 'Stop'
      routingLocation: {
        __typename?: 'Location'
        latitude: number
        longitude: number
      }
    } | null
    actions: Array<
      | { __typename?: 'FccWaypointDeliveryAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointPickupAction'; estimatedAt: string }
      | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
    >
  }> | null
}

export type AlertVehicleMapWaypointFragment = {
  __typename?: 'FccWaypoint'
  stop: {
    __typename?: 'Stop'
    routingLocation: {
      __typename?: 'Location'
      latitude: number
      longitude: number
    }
  } | null
  actions: Array<
    | { __typename?: 'FccWaypointDeliveryAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointHubReturnAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointPickupAction'; estimatedAt: string }
    | { __typename?: 'FccWaypointReallocationAction'; estimatedAt: string }
  >
}

export type AlertVehicleMapLocationFragment = {
  __typename?: 'Location'
  latitude: number
  longitude: number
}

export const AlertVehicleMapLocationFragmentDoc = gql`
  fragment AlertVehicleMapLocation on Location {
    latitude
    longitude
  }
`
export const AlertVehicleMapWaypointFragmentDoc = gql`
  fragment AlertVehicleMapWaypoint on FccWaypoint {
    stop {
      routingLocation {
        ...AlertVehicleMapLocation
      }
    }
    actions {
      ... on FccWaypointDeliveryAction {
        estimatedAt
      }
      ... on FccWaypointPickupAction {
        estimatedAt
      }
      ... on FccWaypointHubReturnAction {
        estimatedAt
      }
      ... on FccWaypointReallocationAction {
        estimatedAt
      }
    }
  }
  ${AlertVehicleMapLocationFragmentDoc}
`
export const AlertVehicleMapVehicleFragmentDoc = gql`
  fragment AlertVehicleMapVehicle on Vehicle {
    latestLocation {
      location {
        ...AlertVehicleMapLocation
      }
      heading
    }
    upcomingWaypoints: fccUpcomingWaypoints {
      ...AlertVehicleMapWaypoint
    }
  }
  ${AlertVehicleMapLocationFragmentDoc}
  ${AlertVehicleMapWaypointFragmentDoc}
`
