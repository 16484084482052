// ~~ Generated. This file cannot be edited directly. Please look at libs/nx-tooling/src/generators/backoffice-project/generator.ts
import { lazy } from 'react'
import { WelcomeAreaId, WelcomeAreaRoutes } from '@backoffice-frontend/common'
import { loadLazyWithTimeoutRetry } from '@backoffice-frontend/lazy-loading'
import { SuspenseLoadingWrapper } from '@backoffice-frontend/patterns'
import { WelcomeAreaNavigation } from './WelcomeAreaNavigation'

const LazyWelcomeComponent = lazy(() =>
  loadLazyWithTimeoutRetry(() => import('./WelcomeArea')),
)

const WelcomeComponent = () => (
  <SuspenseLoadingWrapper>
    <LazyWelcomeComponent />
  </SuspenseLoadingWrapper>
)

export const WelcomeArea = {
  id: WelcomeAreaId,
  NavigationComponent: WelcomeAreaNavigation,
  route: {
    path: WelcomeAreaRoutes.route,
    component: WelcomeComponent,
  },
}
