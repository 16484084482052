import type { Map } from 'leaflet'
import { icon } from 'leaflet'
import { CustomMarkerTypes } from '@backoffice-frontend/common'
import type { BoundingBox } from '@backoffice-frontend/common'
import type { LocationAttributes } from '@backoffice-frontend/graphql'

export const getMoiaMapMarkerIcon = (
  customMarkerType: CustomMarkerTypes,
  largeIcon = false,
) => {
  if (!customMarkerType) {
    throw new Error('No marker type given.')
  }

  switch (customMarkerType) {
    case CustomMarkerTypes.ACTIVE_STOP:
      return icon({
        iconUrl: '/assets/maps/stop_active.svg',
        iconSize: largeIcon ? [30, 34] : [16, 18],
        iconAnchor: [8, 8],
      })

    case CustomMarkerTypes.INACTIVE_STOP:
      return icon({
        iconUrl: '/assets/maps/stop_inactive.svg',
        iconSize: largeIcon ? [30, 34] : [16, 18],
        iconAnchor: [8, 8],
      })

    case CustomMarkerTypes.STOP_TYPE_HUB:
      return icon({
        iconUrl: '/assets/maps/stop_type_hub_black.svg',
        iconSize: largeIcon ? [30, 34] : [16, 18],
        iconAnchor: [8, 8],
      })

    case CustomMarkerTypes.STOP_TYPE_IN_FIELD_BREAK:
      return icon({
        iconUrl: '/assets/maps/stop_type_IFB_gold.svg',
        iconSize: largeIcon ? [30, 34] : [16, 18],
        iconAnchor: [8, 8],
      })

    case CustomMarkerTypes.ROUTING_LOCATION:
      return icon({
        iconUrl: '/assets/maps/stop_routingLocation.svg',
        iconSize: [14, 30],
        iconAnchor: [7, 15],
      })

    case CustomMarkerTypes.CUSTOMER_DISPLAY_LOCATION:
      return icon({
        iconUrl: '/assets/maps/stop_customerDisplayLocation.svg',
        iconSize: [16, 24],
        iconAnchor: [8, 16],
      })

    default:
      throw new Error(`No custom Marker found for type: ${customMarkerType}`)
  }
}

type ServiceAreaLocations = {
  locationAttributes: Pick<
    LocationAttributes,
    'bottomRight' | 'topLeft' | 'defaultLocation'
  >
}

export const getServiceAreaMapCenter = (
  serviceArea: ServiceAreaLocations | null | undefined,
): [number, number] | undefined => {
  if (serviceArea?.locationAttributes.defaultLocation) {
    const center = serviceArea.locationAttributes.defaultLocation
    return [center.latitude, center.longitude]
  }

  if (serviceArea?.locationAttributes) {
    const { topLeft, bottomRight } = serviceArea.locationAttributes
    const center = {
      lat: (+topLeft.latitude + +bottomRight.latitude) / 2,

      lon: (+topLeft.longitude + +bottomRight.longitude) / 2,
    }

    return [center.lat, center.lon]
  }
  return undefined
}

export const getServiceAreaBoundingBox = (
  serviceArea: ServiceAreaLocations | undefined,
): BoundingBox | undefined => {
  if (serviceArea?.locationAttributes) {
    const { topLeft, bottomRight } = serviceArea.locationAttributes

    return [
      topLeft.longitude,
      bottomRight.latitude,
      bottomRight.longitude,
      topLeft.latitude,
    ]
  }

  return undefined
}

type SupportedShapes = 'Marker' | 'Polygon' | 'Line'

export const disableDraw = (map: Map, shapeType: SupportedShapes) => {
  map.pm?.disableDraw(shapeType)
}
